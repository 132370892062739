import { Form, Input, Radio } from 'antd';
import React from 'react';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../utils/defaultClasses';
import Tooltip from '../../Common/Tooltip';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
const { TextArea } = Input;
export default function RequestBrokerContent() {
  return (
    <>
      <div className="p-[20px] max-w-[400px]">
        <div>
          <h3 className="text-color-primary text-lg font-medium">
            Request for Broker
          </h3>
          <p className="text-color-secondary">
            This form will used to request brokers.
          </p>
        </div>
        <div>
          <Form layout="vertical">
            <div className="row">
              <div className="col-12 col-md-12">
                <Form.Item
                  label={
                    <>
                      <span className="flex items-center gap-0.5">
                        Title
                        <span className="text-red-2">*</span>
                      </span>
                    </>
                  }
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your title.',
                    },
                  ]}
                  className="mb-2"
                >
                  <Input
                    className={`${formClass}`}
                    placeholder="Enter Title"
                    name="firstName"
                    // value={setSignUpData.firstName}
                    // onChange={handelChange}
                  />
                </Form.Item>
              </div>
              <div className="col-12 col-md-12">
                <Form.Item
                  label={
                    <>
                      <span className="flex items-center gap-0.5">
                        Content
                        <span className="text-red-2">*</span>
                      </span>
                    </>
                  }
                  name="content"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your content.',
                    },
                  ]}
                >
                  {/* <TextArea
                    rows={4}
                    className={`${formClass}`}
                    name="content"
                    placeholder="Write more detail here"
                  /> */}
                  <textarea
                    id="exampleFormControlTextarea1"
                    rows={4}
                    placeholder="Write more detail here"
                    className={`${formClass}`}
                  ></textarea>
                </Form.Item>
              </div>
              <div className="col-12 col-md-12">
                <Form.Item
                  // label=""
                  name="prefer"
                  //   rules={[
                  //     {
                  //       required: true,
                  //       message: 'Please mention your role.',
                  //     },
                  //   ]}
                >
                  <Radio.Group>
                    <Radio value={'Business'} className="text-color-primary">
                      New Feature/Suggestion
                    </Radio>
                    <Radio value={'Enterprise'} className="text-color-primary">
                      Request Broker
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </div>
              <div className="col-12 col-md-12 space-y-0.5">
                <div className="flex gap-2 w-full">
                  <button type="button" className={`${btnSecondayClass}`}>
                    Upload File
                  </button>
                  <div className="flex items-center text-color-primary">
                    <Tooltip title={'Upload png only.'}>
                      <AiOutlineQuestionCircle />
                    </Tooltip>
                    <span>Max. file size: 2 MB</span>
                  </div>
                </div>
                <div>
                  <span className="text-color-primary">No file chosen</span>
                </div>
              </div>
              <div className="col-12 col-md-12">
                <div className="flex gap-3 w-full mt-[20px] ">
                  <div className="w-full">
                    <button className={`${btnSecondayClass} w-full`}>
                      Cancel
                    </button>
                  </div>
                  <div className="w-full">
                    <button className={`${btnPrimaryClass} w-full`}>
                      Submit
                    </button>
                  </div>
                </div>
                <div className="mt-2.5">
                  <span className="font-medium text-color-primary">
                    Note: check brokers if your broker has been added already.
                  </span>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}
