import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    liveReportsData: [],
    virtualReportsData: [],
    userTradeData: [],
    virtualReportsDataSeperated: {
        active: [],
        inActive: []
    },
    liveReportsDataSeperated: {
        active: [],
        inActive: []
    },
    selectedKeys: [],
};

export const tradeReportDetails = createSlice({
    name: 'tradeReportDetails',
    initialState,
    reducers: {
        setVirtualReportsdata: (state, { payload }) => {
            // console.log('payload', payload);
            state.virtualReportsData = payload;
        },
        setLiveReportsdata: (state, { payload }) => {
            state.liveReportsData = payload;
        },
        setTradeData: (state, { payload }) => {
            state.userTradeData = payload;
        },

        setSelectedKeys: (state, { payload }) => {
            state.selectedKeys = payload;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    setLiveReportsdata,
    setVirtualReportsdata,
    setSelectedKeys, setTradeData
} = tradeReportDetails.actions;

export default tradeReportDetails.reducer;
