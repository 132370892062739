import { EyeInvisibleOutlined } from '@ant-design/icons';
import { Form, Input } from 'antd';
import { useRef, useState } from 'react';
import { AiOutlineEye } from 'react-icons/ai';
import secureLocalStorage from 'react-secure-storage';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../utils/defaultClasses';
import useLogout from './useLogout';
import axios from 'axios';
import config from '../../Config/config';
import { toast } from 'react-toastify';
import useToGetId from './useToGetId';

function useChangePassword() {
  const formRef = useRef();
  const token = localStorage.getItem('token');
  const { userid } = useToGetId();

  const { logoutUser } = useLogout();
  let oldPassword = secureLocalStorage.getItem('toGetPass');
  const [isModalVisible, setIsModalVisible] = useState(false);

  const closePasswordModal = (value) => {
    setIsModalVisible(value);
  };

  // Custom validation rules for password fields
  const passwordValidationRules = {
    currentPassword: {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject('Current Password is required');
        }
        if (value !== oldPassword.password && value) {
          return Promise.reject('Current Password is incorrect!');
        }
        return Promise.resolve();
      },
    },
    newPassword: {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject('New Password is required');
        }
        if (value && value.length < 8) {
          return Promise.reject(
            'New Password must be at least 8 characters long!',
          );
        }
        if (value === oldPassword.password) {
          return Promise.reject(
            'Current password and new password can not be the same.',
          );
        }
        return Promise.resolve();
      },
    },
    verifyPassword: {
      validator: (_, value, { getFieldValue }) => {
        const newPassword = formRef.current.getFieldValue('newPassword');
        if (!value) {
          return Promise.reject('Verify Password is required');
        }

        if (value !== newPassword && value) {
          return Promise.reject(
            'The two passwords that you entered do not match!',
          );
        }
        return Promise.resolve();
      },
    },
  };

  const changePassword = (values) => {
    let payload = {
      old_password: values.currentPassword,
      new_password: values.newPassword,
      // user_id: updatedPassword.user_id,
    };

    axios
      .put(`${config.base_url}${config.update_user}/${userid.id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        // setButtonLoader(true);
        setIsModalVisible(false);
        setTimeout(() => {
          logoutUser();
        }, 1000);

        return toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
      })
      .catch((error) => {
        // setButtonLoader(true);
      });
  };

  const getPasswordModal = () => {
    return (
      <div className="col-12">
        <Form
          className="space-y-3"
          layout="vertical"
          ref={formRef}
          onFinish={changePassword}
          validateMessages={passwordValidationRules}
        >
          <Form.Item
            // label="Current Password"
            label={
              <>
                <span>Current Password</span>
                <span className="text-red-2">*</span>
              </>
            }
            name="currentPassword"
            rules={[
              // { required: true },
              {
                validator: passwordValidationRules.currentPassword.validator,
              },
            ]}
            className="mb-0"
          >
            <Input.Password
              placeholder="Enter current password"
              name="old_password"
              iconRender={(visible) =>
                visible ? (
                  <span>
                    <AiOutlineEye className="text-color-primary text-base" />
                  </span>
                ) : (
                  <span>
                    <EyeInvisibleOutlined className="text-color-primary text-base" />
                  </span>
                )
              }
              className={`${formClass}`}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span>New Password</span>
                <span className="text-red-2">*</span>
              </>
            }
            // label="New Password"
            name="newPassword"
            className="mb-0"
            rules={[
              {
                validator: passwordValidationRules.newPassword.validator,
              },
              // { min: 8 },
              // {
              //   pattern:
              //     /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
              // },
            ]}
          >
            <Input.Password
              placeholder="Enter new password"
              name="newPassword"
              iconRender={(visible) =>
                visible ? (
                  <span>
                    <AiOutlineEye className="text-color-primary text-base" />
                  </span>
                ) : (
                  <span>
                    <EyeInvisibleOutlined className="text-color-primary text-base" />
                  </span>
                )
              }
              className={`${formClass}`}
            />
          </Form.Item>
          <Form.Item
            label={
              <>
                <span>Verify Password</span>
                <span className="text-red-2">*</span>
              </>
            }
            // label="Verify Password"
            name="verifyPassword"
            className="mb-0"
            dependencies={['newPassword']}
            rules={[
              {
                validator: passwordValidationRules.verifyPassword.validator,
              },
            ]}
          >
            <Input.Password
              placeholder="Enter verify password"
              name="verify_password"
              iconRender={(visible) =>
                visible ? (
                  <span>
                    <AiOutlineEye className="text-color-primary text-base" />
                  </span>
                ) : (
                  <span>
                    <EyeInvisibleOutlined className="text-color-primary text-base" />
                  </span>
                )
              }
              className={`${formClass}`}
            />
          </Form.Item>
          <div className="d-flex justify-end items-center gap-2 mt-4">
            <button
              onClick={() => {
                setIsModalVisible(false);
              }}
              type="button"
              className={`${btnSecondayClass}`}
            >
              Cancel
            </button>
            <button
              type="primary"
              htmlType="submit"
              className={`${btnPrimaryClass}`}
            >
              Update Password
            </button>
          </div>
        </Form>
      </div>
    );
  };

  return {
    getPasswordModal,
    isModalVisible,
    closePasswordModal,
    setIsModalVisible,
  };
}

export default useChangePassword;
