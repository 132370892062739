import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { userForgetPassword } from '../../../components/helpers/api/Auth/auth';
import { useNavigate } from 'react-router-dom';

const useForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const insertNote = useCallback(() => {
    const { email } = form.getFieldsValue();
    let payload = {
      email: email,
    };

    return userForgetPassword(payload);
  }, [form]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      const { email } = form.getFieldsValue();
      localStorage.setItem('EmailID', JSON.stringify(email));
      form.resetFields();
      if (res.data.message === 'Email not registered') {
        return toast.error(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      } else {
        toast.success(res.data.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        navigate(`/user-reset-password?email=${email}`);
      }
    },
    onError: (err) => {
      console.log('err', err);
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const submitForm = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  const value = useMemo(() => {
    return { form, submitForm };
  }, [form, submitForm]);

  return value;
};

export default useForgotPassword;
