import React from 'react';
import { btnPrimaryClass } from '../../../components/utils/defaultClasses';

export default function SubscriptionOrderSummary({
  userChangedPlan,
  handleReviewOrder,
}) {
  return (
    <>
      <div className="w-full">
        <div className="mb-[16px]">
          <h5 className="text-base font-medium text-color-primary flex flex-row items-center">
            Order Summary
          </h5>
        </div>
        <table className=" table-auto w-full text-sm overflow-hidden">
          <thead className="bg-color-base-2">
            <tr className="">
              <th className="border-b border-level-2 font-semibold p-3.5 pl-8 pt-2.5 pb-2.5 text-color-secondary text-left">
                Item
              </th>
              <th className="border-b border-level-2 font-semibold p-3.5 pl-8 pt-2.5 pb-2.5 text-color-secondary text-left">
                Unit Price
              </th>
              <th className="border-b border-level-2 font-semibold p-3.5 pl-8 pt-2.5 pb-2.5 text-color-secondary text-left">
                No. of Units
              </th>
              <th className="border-b border-level-2 font-semibold p-3.5 pl-8 pt-2.5 pb-2.5 text-color-secondary text-right pr-8">
                Total/month
              </th>
            </tr>
          </thead>
          <tbody className="bg-color-base-1">
            <tr>
              <td className="border-b border-level-2 font-medium p-2.5 py-3.5 pl-8 text-color-primary">
                {userChangedPlan.plan_name} Plan
              </td>
              <td className="border-b border-level-2 font-medium p-2.5 py-3.5 pl-8 text-color-primary">
                ₹{userChangedPlan.price} /user/
                {`${
                  userChangedPlan?.plan_type === 'annual' ? 'year' : 'month'
                }`}
              </td>
              <td className="border-b border-level-2 font-medium p-2.5 py-3.5 pl-8 text-color-primary">
                -
              </td>
              <td className="border-b border-level-2 font-medium p-2.5 py-3.5 pl-8 text-color-primary text-right pr-8">
                ₹ {userChangedPlan.price}
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                className="border-b border-level-2 font-semibold p-2.5 pl-8 text-color-primary text-right pr-4"
              >
                Tax
              </td>
              <td className="border-b border-level-2 font-semibold p-2.5 pl-8 text-color-primary text-right pr-8">
                ₹ 0*
              </td>
            </tr>
            <tr>
              <td
                colspan="3"
                className="border-b-0 border-level-2 font-semibold p-2.5 pl-8 text-color-primary bg-color-base-2/80 text-right pr-4"
              >
                Total Amount
              </td>
              <td className="border-b-0 border-level-2 font-semibold p-2.5 pl-8 text-color-primary bg-component-gray-2 dark:bg-color-base-4 text-right pr-8">
                ₹ {userChangedPlan.price}
              </td>
            </tr>
          </tbody>
        </table>
        <div>
          <span className="text-xs">
            <small>
              * Tax mentioned may vary. The final tax amount will be reflected
              in your invoice.Confirm
            </small>
          </span>
        </div>
        <div className="mt-3.5 flex justify-end">
          <button
            className={`${btnPrimaryClass}`}
            onClick={() => handleReviewOrder('3')}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
}
