import React, { useCallback, useState } from 'react';
import NoDataFoundV2 from '../../../components/Common/NoDataFoundV2';
import { BsCreditCard, BsPlus } from 'react-icons/bs';
import AddCardModal from './AddCardModal';
import useSaveUpdateCard from '../../../components/hooks/useSaveUpdateCard';
import useSaveCard from '../../../components/hooks/useSaveCard';

function NoCardsAvailable({ setIsAddCard }) {
  // const { getCards } = useSaveCard();

  return (
    <>
      <div className="flex justify-center">
        <NoDataFoundV2
          icon={
            <>
              <BsCreditCard className="text-4xl text-color-secondary" />
            </>
          }
          title={<>You currently don't have any cards in your list.</>}
          subTitle={<>Click 'Add Card' to add a new card.</>}
          minHeight={'min-h-[200px]'}
          onSubmit={() => {
            setIsAddCard(true);
          }}
          onCancel={() => {}}
          buttonProps={{
            primaryBtnText: 'Add Card',
            // secondaryBtnText: 'Select system venue',
            primaryBtnIcon: (
              <>
                <span>
                  <BsPlus className="text-lg" />
                </span>
              </>
            ),
          }}
        />
      </div>
    </>
  );
}

export default React.memo(NoCardsAvailable);
