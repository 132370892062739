import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../../../components/Common/Modal';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../../components/utils/defaultClasses';
import { Form, Input, Select, message } from 'antd';
import { BsChevronDown } from 'react-icons/bs';
import { HiOutlineCreditCard } from 'react-icons/hi2';
import { GetCardType } from '../../../utils/GetCardType';
import { useMutation } from '@tanstack/react-query';
import { addpaymentsCards } from '../../../API/profile/CardsApi';
import { toast } from 'react-toastify';
import useSaveUpdateCard from '../../../components/hooks/useSaveUpdateCard';

function AddCardModal({
  isAddCard,
  setIsAddCard,
  formData,
  handleSubmit,
  isEdit,
  setIsEdit,
  setFormData = () => {},
}) {
  // console.log('formData', formData);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [cardType, setCardType] = useState(formData?.card_type);

  const handleCardNumberChange = (e) => {
    const value = e.target.value;
    form.setFieldsValue({ card_number: value });
    setCardType(GetCardType(value));
  };

  const handleData = (values) => {
    // console.log('values', values);

    let payload = {
      card_name: values?.card_name,
      card_number: values?.card_number,
      expiration_date: `${values.expiration_year.toString().slice(2)}/${
        values.expiration_month
      }`,
      card_type: cardType,
      cvv: values?.cvv,
    };
    handleSubmit(payload);

    // addCardToList.mutate(payload);
    // form.resetFields();
    setFormData({
      card_name: '',
      card_number: '',
      expiration_month: null,
      expiration_year: null,
      cvv: '',
      card_type: '',
    });
    setCardType('');
    setIsAddCard(false);
    setIsEdit(false);
  };

  const handleKeyDown = (e) => {
    if (
      !(e.keyCode >= 48 && e.keyCode <= 57) &&
      !(e.keyCode >= 96 && e.keyCode <= 105) &&
      !(
        e.keyCode === 8 ||
        e.keyCode === 9 ||
        e.keyCode === 37 ||
        e.keyCode === 39 ||
        e.keyCode === 46
      )
    ) {
      // Delete, Tab, Left/Right Arrow, Delete
      e.preventDefault();
    }
  };

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState('');
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // getMonth() is zero-based

  const yearOptions = Array.from({ length: 11 }, (_, index) => ({
    value: `${currentYear + index}`,
    label: `${currentYear + index}`,
  })).filter((year) => {
    if (!selectedMonth) return true; // If no month is selected, show all years
    if (Number(selectedMonth) < currentMonth) {
      return year.value > currentYear; //if the previous month is selected show next years
    }
    return true;
  });

  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: `${index + 1 < 10 ? '0' + (index + 1) : index + 1}`,
    label: `${index + 1 < 10 ? '0' + (index + 1) : index + 1}`,
  })).filter((month) => {
    if (!selectedYear) return true; // If no year is selected, show all months
    if (selectedYear === `${currentYear}`) {
      return month.value >= currentMonth; // Show only months from the current month onwards
    }
    return true; // Otherwise, show all months
  });

  // const validateCardType = (rule, value) => {
  //   // Custom validation logic for the card type
  //   const cardNumber = value.replace(/\s/g, ''); // Remove white spaces
  //   const cardType = GetCardType(cardNumber);
  //   if (!cardType) {
  //     return Promise.reject('Unknown Card');
  //   }
  //   return Promise.resolve();
  // };

  useEffect(() => {
    form.setFieldsValue({ ...formData });
  }, [formData]);

  return (
    <div>
      <Modal
        open={isAddCard}
        setIsOpen={() => {
          setIsAddCard(false);
          setIsEdit(false);
          form.resetFields();
          setFormData({
            card_name: '',
            card_number: '',
            expiration_month: '',
            expiration_year: '',
            cvv: '',
            card_type: '',
          });
        }}
        maxWidth={`w-full max-w-lg`}
        preventClosingOutsideClick
        header={
          <div className="flex flex-col justify-center py-2">
            <div className="w-full flex items-center">
              <span className="text-color-primary text-base font-medium">
                {isEdit ? 'Update Card' : 'Add New Card'}
              </span>
            </div>
          </div>
        }
        footer={
          <div className="d-flex justify-end items-center gap-2">
            <button
              onClick={() => {
                setIsAddCard(false);
                setCardType('');
                setIsEdit(false);
                form.resetFields();
                setFormData({
                  card_name: '',
                  card_number: '',
                  expiration_month: '',
                  expiration_year: '',
                  cvv: '',
                  card_type: '',
                });
              }}
              type="button"
              className={`${btnSecondayClass} text-sm`}
            >
              Discard
            </button>
            <button
              type="primary"
              htmlType="submit"
              className={`${btnPrimaryClass} text-sm`}
              onClick={() => form.submit()}
            >
              {isEdit ? 'Update' : 'Submit'}
            </button>
          </div>
        }
        bodyClass={
          'w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full'
        }
      >
        <>
          <div className="row justify-content-center">
            <div className="col-12">
              <Form
                form={form}
                className="space-y-3"
                layout="vertical"
                onFinish={handleData}
                initialValues={{
                  remember: true,
                }}
                autoComplete="off"
                // initialValues={formData}
              >
                <Form.Item
                  label={
                    <>
                      <span>Name on Card</span>
                      <span className="text-red-2">*</span>
                    </>
                  }
                  // label="Name on Card"
                  name="card_name"
                  rules={[{ required: true, message: 'Name is required' }]}
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter Name"
                    name="card_name"
                    className={`${formClass}`}
                    maxLength={20}
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <>
                      <span>Card Number</span>
                      <span className="text-red-2">*</span>
                    </>
                  }
                  // label="Card Number"
                  name="card_number"
                  rules={[
                    { required: true, message: 'Card number is required' },
                  ]}
                  className="mb-0"
                >
                  {/* <div className="relative"> */}
                  <Input
                    placeholder="Enter Card Number"
                    name="card_number"
                    className={`${formClass} pr-8`}
                    onChange={handleCardNumberChange}
                    maxLength={19}
                    onKeyDown={handleKeyDown}
                  />
                </Form.Item>
                <div className="row">
                  <div className="col-lg-4">
                    <Form.Item
                      label={
                        <>
                          <span>Expiration Date</span>
                          <span className="text-red-2">*</span>
                        </>
                      }
                      // label="Expiration Date"
                      name="expiration_month"
                      rules={[
                        {
                          required: true,
                          message: 'Expiration month is required',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        placeholder="Month"
                        size="large"
                        options={monthOptions}
                        className={'min-h-[38px]'}
                        value={selectedMonth}
                        onChange={(value) => setSelectedMonth(value)}
                        suffixIcon={
                          <BsChevronDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4">
                    <Form.Item
                      label=" "
                      name="expiration_year"
                      rules={[
                        {
                          required: true,
                          message: 'Expiration year is required',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        placeholder="Year"
                        size="large"
                        className={'min-h-[38px]'}
                        options={yearOptions}
                        value={selectedYear}
                        onChange={(value) => setSelectedYear(value)}
                      >
                        {yearOptions.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  {/* <div className="col-lg-4">
                    <Form.Item
                      label="Expiration Date"
                      name="expiration_month"
                      rules={[
                        {
                          required: true,
                          message: 'Expiration month is required',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        placeholder="Month"
                        size="lg"
                        options={[
                          { value: '01', label: '01' },
                          { value: '02', label: '02' },
                          { value: '03', label: '03' },
                          { value: '04', label: '04' },
                          { value: '05', label: '05' },
                          { value: '06', label: '06' },
                          { value: '07', label: '07' },
                          { value: '08', label: '08' },
                          { value: '09', label: '09' },
                          { value: '10', label: '10' },
                          { value: '11', label: '11' },
                          { value: '12', label: '12' },
                        ]}
                        className={'min-h-[38px]'}
                        suffixIcon={
                          <BsChevronDown
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        }
                      />
                    </Form.Item>
                  </div>
                  <div className="col-lg-4">
                    <Form.Item
                      label=" "
                      name="expiration_year"
                      rules={[
                        {
                          required: true,
                          message: 'Expiration year is required',
                        },
                      ]}
                      className="mb-0"
                      // initialValue={formData?.expiration_year}
                    >
                      <Select
                        placeholder="Year"
                        size="large"
                        className={'min-h-[38px]'}
                      >
                        {yearOptions.map((option) => (
                          <Option key={option.value} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div> */}
                  <div className="col-lg-4">
                    <Form.Item
                      label={
                        <>
                          <span>CVV</span>
                          <span className="text-red-2">*</span>
                        </>
                      }
                      // label="CVV"
                      name="cvv"
                      rules={[{ required: true, message: 'CVV is required' }]}
                      className="mb-0"
                      // initialValue={Number(formData?.cvv)}
                    >
                      {/* <div className="relative"> */}
                      <Input
                        placeholder="CVV"
                        name="cvv"
                        className={`${formClass}`}
                        maxLength={4}
                        onKeyDown={handleKeyDown}
                      />
                      {/* <span className="flex absolute top-1/2 right-2 -translate-y-1/2 text-lg text-color-secondary/80">
                          <HiOutlineCreditCard />
                        </span> */}
                      {/* </div> */}
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      </Modal>
    </div>
  );
}

export default React.memo(AddCardModal);
