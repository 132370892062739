import { Form } from 'antd';
import { Card } from 'react-bootstrap';
import { Logo, SpinnerBtn } from '../../assets/Icon';
import { btnPrimaryClass } from '../../components/utils/defaultClasses';
import OtpComponent from '../../components/Common/OtpComponent';
import usetwoFactorAuth from './hooks/useTwoFactorAuth';
import OtpTimer from '../../components/Common/OtpTimer';

function TwoStepVerification() {
  const { form, otpError, udateOTP, submitForm, resendOTP, isLoading } =
    usetwoFactorAuth();

  return (
    <>
      <div className="bg-color-base-2/30 dark:bg-color-base-4">
        <section className="container-xxl">
          <div className="min-h-screen flex justify-center items-center relative z-10">
            <div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
              <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden relative z-10">
                <Card.Body className="p-[2rem]">
                  <div className="flex justify-center mb-4 mt-2">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                  <div className="pt-2 mb-4 flex flex-col">
                    <h2 className="mb-1 text-lg text-color-primary">
                      Two Step Verification 💬
                    </h2>
                    <p class="text-[13px] text-color-secondary/90">
                      {/* We sent a verification code to your mobile. Enter the code
                      from the mobile in the field below. */}
                      Please check your email for the 6-digit authentication
                      code.
                    </p>
                    {/* <p class="text-[13px] text-color-secondary/90">
                      ******1234
                    </p> */}
                  </div>
                  <div>
                    <Form form={form} layout="vertical" onFinish={submitForm}>
                      <div>
                        <p class="text-[14px] text-color-primary mb-0.5">
                          Type your 6 digit security code
                        </p>
                      </div>
                      <div>
                        <OtpComponent props={{ udateOTP }} />
                        {otpError !== '' ? (
                          <div className="ant-form-item-explain ant-form-item-explain-connected mt-1">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: '0px' }}
                            >
                              {otpError}
                            </div>
                          </div>
                        ) : null}{' '}
                      </div>
                      <div className="text-center mt-4 mb-1">
                        {/* <Form.Item> */}
                        <button
                          type="primary"
                          htmlType="submit"
                          className={`${btnPrimaryClass} w-full`}
                        >
                          {isLoading ? (
                            <SpinnerBtn className={'text-lg'} />
                          ) : (
                            'Verify your account'
                          )}
                        </button>
                        {/* </Form.Item> */}
                      </div>

                      <div className="text-center">
                        <OtpTimer
                          minutes={1}
                          seconds={30}
                          text="Resend OTP Link in"
                          ButtonText="Resend OTP"
                          // isButton={false}
                          background="none"
                          textColor="unset"
                          buttonColor="#cc8411"
                          className={`w-full`}
                          // isError={mutation.isError}
                          resend={() => {
                            resendOTP.mutate();
                          }}
                          // darkTheme={darkTheme}
                        />
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default TwoStepVerification;
