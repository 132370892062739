import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openWelcomeModal: false,
  from: '',
};

export const welcomeModalSlice = createSlice({
  name: 'subscribedDetails',
  initialState,
  reducers: {
    setOpenWelcomeModal: (state, { payload }) => {
      state.openWelcomeModal = payload.openWelcomeModal;
      state.from = payload.from;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpenWelcomeModal } = welcomeModalSlice.actions;

const welcomeModalReducer = welcomeModalSlice.reducer;

export default welcomeModalReducer;
