import React, { useEffect, useState } from 'react';
import { WarningIcon } from '../../../assets/Icon';
import { Col, Row } from 'reactstrap';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../../../components/utils/defaultClasses';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { userNewPlan } from '../../../features/update-plan/UpdatePlanSlice';
import { showPlansModal } from '../../../features/profile/profilePlanSlice';

function Subscription() {
  const { userDetails } = useSelector((state) => state.profileDetails);
  const [expireMessage, setExpireMessage] = useState({
    topMessage: '',
    bottomMessage: [],
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { overAllPlansList } = useSelector((state) => state.toUpdatePlan); //all plans

  useEffect(() => {
    let laterReminder = localStorage.getItem('remindMeLater');
    if (
      userDetails?.active_plan?.expired_at &&
      userDetails?.active_plan?.subscriptionPlanData?.plan_type !==
        'free_unlimited'
    ) {
      const expiryDateObj = new Date(userDetails?.active_plan?.expired_at);
      const todayDateObj = new Date();
      const diffTime = expiryDateObj - todayDateObj;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 5 && diffDays >= 0) {
        if (
          userDetails?.active_plan?.subscriptionPlanData?.plan_type ===
          'signup_trial'
        ) {
          setExpireMessage({
            bottomMessage: ['upgrade'],
            topMessage: `Your plan will expire in less than ${diffDays} days!`,
          });
        } else {
          setExpireMessage({
            bottomMessage: ['renew', 'upgrade'],
            topMessage: `Your plan will expire in less than ${diffDays} days!`,
          });
        }
      }
    } else if (
      userDetails?.active_plan?.subscriptionPlanData?.plan_type ===
        'free_unlimited' &&
      !laterReminder
    ) {
      setExpireMessage({
        bottomMessage: [' Upgrade'],
        topMessage: 'You plan is expired!',
      });
    }
  }, [userDetails]);

  const handleNaviagte = (setPlan) => {
    if (setPlan === 'renew') {
      let getPlan = overAllPlansList.find(
        (item) =>
          item._id === userDetails?.active_plan?.subscriptionPlanData?._id,
      );
      navigate('/checkout');
      dispatch(userNewPlan(getPlan));
      localStorage.setItem('new_plan', JSON.stringify(getPlan));
    } else {
      dispatch(showPlansModal(true));
    }
  };

  return (
    <>
      <div className="space-y-4">
        {expireMessage?.topMessage !== '' && (
          <div className="flex items-center border-[1px] border-dashed border-yellow-400 bg-yellow-400/10 rounded-xl overflow-hidden p-3.5">
            <div className="flex gap-2">
              <div>
                <WarningIcon
                  className={'stroke-yellow-600 fill-yellow-500/50 text-3xl'}
                />
              </div>
              <div>
                <div>
                  <p className="text-base text-color-primary font-medium">
                    {expireMessage?.topMessage}
                  </p>
                </div>
                <div>
                  <p className="text-xs text-color-primary">
                    <p className="text-xs text-color-primary ">
                      <span>To enjoy the feature of trading tools, please</span>
                      <span
                        className="text-blue-2 hover:text-blue-3 px-0.5 cursor-pointer"
                        onClick={() =>
                          handleNaviagte(expireMessage?.bottomMessage[0])
                        }
                      >
                        {expireMessage?.bottomMessage[0]}
                      </span>
                      {expireMessage?.bottomMessage?.length > 1 && (
                        <>
                          <span>or</span>
                          <span
                            className="text-blue-2 hover:text-blue-3 px-0.5 cursor-pointer"
                            onClick={() =>
                              handleNaviagte(expireMessage?.bottomMessage[1])
                            }
                          >
                            {expireMessage?.bottomMessage[1]}
                          </span>
                        </>
                      )}
                    </p>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        <div>
          <Row className="gy-4 items-center">
            <Col lg={7}>
              <div className="space-y-6">
                <div className="flex flex-col leading-normal">
                  <>
                    <div className="text-base font-semibold">
                      Active until{' '}
                      {moment(userDetails?.active_plan?.expired_at).format(
                        'DD MMM, YYYY',
                      )}
                    </div>
                    <span className="text-xs text-color-secondary/70 font-medium">
                      We will send you a notification upon Subscription
                      expiration
                    </span>
                  </>
                </div>
              </div>
            </Col>
            <Col lg={5}>
              <div className="space-y-6">
                <div className="flex justify-end items-center gap-2">
                  <button className={`${btnSecondayClass} text-sm`}>
                    Deactivate Account
                  </button>
                  <button
                    className={`${btnPrimaryClass} text-sm`}
                    onClick={() => {
                      dispatch(showPlansModal(true));
                      // setShowSubscription(true);
                    }}
                  >
                    Choose Plan
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default React.memo(Subscription);
