import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { getAdminStrategyList } from '../../API/strategy/startegyMainPage';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Tooltip from '../Common/Tooltip';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Switch } from 'antd';
import { btnSecondayClass } from '../utils/defaultClasses';
import { FaRegEye } from 'react-icons/fa';
import { RiShareLine } from 'react-icons/ri';
import { userPremiumStrategies } from '../../features/UserCredits/userCreditsSlice';
import useSubscribeStrategy from './useSubscribeStrategy';
import PremiumBadge from '../../utils/PremiumBadge';

function useAdminStrategyList() {
  const [categorySearch, setCategorySearch] = useState('all');
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [page, setPage] = useState(1);
  const [adminList, setAdminList] = useState([]);
  let { premium_strategies } = useSelector((state) => state.userCreditsDetails);
  const [changeSubscription, setChangeSubscription] = useState({});
  const dispatch = useDispatch();

  const { data, isFetching } = useQuery({
    queryKey: ['adminList', { page, categorySearch }],
    queryFn: getAdminStrategyList,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    setAdminList(data?.data);
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: page,
      total: data?.count,
    }));
  }, [data, page]);

  // for table pagination changes
  const handleTableChange = useCallback((newPagination) => {
    setPage(newPagination.current);
  }, []);

  //when the toogle button changes
  const subscriptionChange = useCallback((checked, request) => {
    let payload = { st_id: request?._id };
    setChangeSubscription({
      checked,
      request,
    });
    if (checked) {
      add_subscription(payload);
    } else {
      remove_subscription(payload);
    }
  }, []);

  const handleOnSuccess = useCallback(() => {
    let { checked, request } = changeSubscription;
    setAdminList(
      adminList.map((item) => {
        return item._id === request?._id
          ? { ...item, subscribe_by_user: checked } //after subscription to changes the status of the toogle
          : item;
      }),
    );

    //updating  the premium crediets
    if (request.access_type === 'premium') {
      const updatedPremiumStrategies = {
        ...premium_strategies,
        strategies_remaining:
          premium_strategies?.strategies_remaining + (checked ? -1 : 1),
        strategies_used:
          premium_strategies?.strategies_used + (checked ? 1 : -1),
      };
      dispatch(userPremiumStrategies(updatedPremiumStrategies));
    }
  }, [changeSubscription, adminList, premium_strategies]);

  const { remove_subscription, add_subscription } =
    useSubscribeStrategy(handleOnSuccess); // function call to add or remove subscription

  console.log('premium_strategies', premium_strategies);
  //table rows

  const dataSource = useMemo(
    () =>
      adminList?.map((item) => ({
        key: item._id,
        strategy: (
          <>
            <Link
              className="d-flex flex-row align-items-center gap-2 cursor-pointer"
              to={`/strategy/${item?._id}`}
            >
              <p className="">{item?.name}</p>
              <span className="text-primary">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 1024 1024"
                  fillRule="evenodd"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h360c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H184V184h656v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V144c0-17.7-14.3-32-32-32ZM770.87 824.869l-52.2 52.2c-4.7 4.7-1.9 12.8 4.7 13.6l179.4 21c5.1.6 9.5-3.7 8.9-8.9l-21-179.4c-.8-6.6-8.9-9.4-13.6-4.7l-52.4 52.4-256.2-256.2c-3.1-3.1-8.2-3.1-11.3 0l-42.4 42.4c-3.1 3.1-3.1 8.2 0 11.3l256.1 256.3Z"
                    transform="matrix(1 0 0 -1 0 1024)"
                  ></path>
                </svg>
              </span>
            </Link>
          </>
        ),
        CreatedBy: (
          <>
            <div className="flex">
              <span>Winray Labs</span>
            </div>
          </>
        ),
        instrument: (
          <>
            <div className="flex">
              <span>{item?.instrument}</span>
            </div>
          </>
        ),
        category: (
          <>
            <div className="flex">
              {item?.access_type === 'free' ? (
                <span className="flex justify-center items-center px-2.5 py-0.5 bg-blue-2/10 text-blue-2 rounded-lg min-w-[100px]">
                  Free
                </span>
              ) : (
                <span className="flex justify-center items-center px-2.5 py-0.5 bg-green-2/10 text-green-2 rounded-lg min-w-[100px]">
                  Premium
                </span>
              )}
            </div>
          </>
        ),
        subscription: (
          <div className="flex">
            {item?.access_type === 'premium' &&
            !item?.subscribe_by_user &&
            premium_strategies?.strategies_remaining === 0 ? (
              <PremiumBadge />
            ) : (
              // <div className="flex items-center gap-1.5">
              //   <div className="flex items-center gap-1.5 bg-blue-2/15 text-blue-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
              //     Premium Feature
              //   </div>
              //   <Tooltip
              //     title={'Please upgrade your account for premium features.'}
              //   >
              //     <AiOutlineQuestionCircle />
              //   </Tooltip>
              // </div>
              <Switch
                value={item?.subscribe_by_user}
                onChange={(checked) => subscriptionChange(checked, item)}
              />
            )}
          </div>
        ),

        action: (
          <>
            <div className="flex gap-2">
              <div>
                <Link
                  to={`/strategy/${item?._id} `}
                  className={`${btnSecondayClass} w-[30px] h-[30px] relative `}
                >
                  <FaRegEye className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-color-primary" />
                </Link>
              </div>
              {/* <div>
                <button
                  className={`${btnSecondayClass} w-[30px] h-[30px] relative`}
                >
                  <RiShareLine className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
                </button>
              </div> */}
            </div>
          </>
        ),
      })),
    [adminList, premium_strategies],
  );

  //table columns

  const columns = useMemo(
    () => [
      {
        title: 'Strategy',
        dataIndex: 'strategy',
        key: 'strategy',
      },
      {
        title: 'Created By',
        dataIndex: 'CreatedBy',
        key: 'createdBy',
      },
      {
        title: 'Instrument',
        dataIndex: 'instrument',
        key: 'instrument',
      },
      {
        title: 'Strategy Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Subscription',
        dataIndex: 'subscription',
        key: 'subscription',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
      },
    ],
    [],
  );

  const value = useMemo(() => {
    return {
      pagination,
      setCategorySearch,
      handleTableChange,
      categorySearch,
      isFetching,
      premium_strategies,
      dataSource,
      columns,
    };
  }, [
    pagination,
    setCategorySearch,
    handleTableChange,
    categorySearch,
    isFetching,
    premium_strategies,
    dataSource,
    columns,
  ]);

  return value;
}

export default useAdminStrategyList;
