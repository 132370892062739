import React, { useState } from 'react';
import Modal from '../../../components/Common/Modal';
import SubscriptionDetailCompo from '../subscription_upgrade/SubscriptionDetailCompo';
import SubscriptionDetailCompoV2 from '../subscription_upgrade/SubscriptionDetailCompoV2';
import { useDispatch, useSelector } from 'react-redux';
import { showPlansModal } from '../../../features/profile/profilePlanSlice';

export default function SubscriptionUpgradeModal(
  {
    // showSubscription,
    // setShowSubscription,
  },
) {
  const dispatch = useDispatch();
  // const { showSubscription } = useSelector((state) => state.profileDetails);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState('annually');
  const [showDetail, setShowDetail] = useState(false);

  const { showSubscription } = useSelector((state) => state.profileDetails);

  const onClose = () => {
    setShowDetail(false);
    setSubscriptionPeriod('annually');
    dispatch(showPlansModal(false));
    // setShowSubscription(false);
  };

  return (
    <div>
      <Modal
        open={showSubscription}
        setIsOpen={() => {
          onClose();
        }}
        maxWidth={`w-full max-w-[1536px]`}
        header={
          <div className="flex flex-col justify-center py-2">
            <div className="w-full flex items-center">
              <span className="text-color-primary text-base font-medium">
                Choose a Plan
              </span>
            </div>
          </div>
        }
        footer={null}
        bodyHeight={'75vh'}
        bodyClass={
          'w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full max-h-[90vh] p-6'
        }
      >
        <SubscriptionDetailCompoV2
          subscriptionPeriod={subscriptionPeriod}
          setSubscriptionPeriod={setSubscriptionPeriod}
          showDetail={showDetail}
          // setShowSubscription={setShowSubscription}
          setShowDetail={setShowDetail}
        />
      </Modal>
    </div>
  );
}
