import moment from 'moment';
export default function LogsActivity({ logsData }) {
  return (
    <>
      <div>
        <ol className="relative border-l-2 border-gray-200 dark:border-gray-700 space-y-8 leading-5 ml-3">
          {logsData?.map((obj, index) => (
            <li className="ml-7">
              <span className="absolute -left-3 mt-1">
                <span className="w-6 h-6 flex items-center justify-center shrink-0 bg-color-base-2 rounded-full ring-level-4 dark:ring-level-1 border-[1px] border-level-2 ring-8">
                  <svg
                    className={`w-2.5 h-2.5 flex shrink-0 ${
                      //   index % 2 !== 0
                      //     ? 'text-sky-600 dark:text-blue-300'
                      //     : 'text-rose-600 dark:text-blue-300'
                      'text-rose-600 dark:text-blue-300'
                    }
                `}
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                  >
                    <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
                  </svg>
                </span>
              </span>
              <h3 className="flex items-center mb-1 text-base font-semibold text-gray-900 dark:text-white">
                {obj?.activity}
                <span className="bg-sky-100 text-sky-700 text-xs font-medium mr-2 px-2.5 py-0.5 rounded  ml-3">
                  {index === 0 && 'Latest'}
                </span>
              </h3>
              <time className="block mb-2 text-[12px] font-light leading-none text-gray-400 -mt-0.5">
                {moment(obj?.created_at)?.format('DD/MM/YY - HH:mm')}
              </time>
              {/* <p className="mb-4 text-xs font-normal text-gray-700 dark:text-gray-400">
              Text Data
            </p> */}
              {/* <a
                href="#"
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
              >
                <svg
                  className="w-3.5 h-3.5 mr-2.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
                  <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
                </svg>{' '}
                Download ZIP
              </a> */}
            </li>
            // <li className="ml-7">
            //   <span className="absolute -left-3 mt-1">
            //     <span className="w-6 h-6 flex items-center justify-center shrink-0 bg-color-base-2 rounded-full ring-level-4 dark:ring-level-1 border-[1px] border-level-2 ring-8">
            //       <svg
            //         className={`w-2.5 h-2.5 flex shrink-0 ${
            //           //   index % 2 !== 0
            //           //     ? 'text-sky-600 dark:text-blue-300'
            //           //     : 'text-rose-600 dark:text-blue-300'
            //           'text-rose-600 dark:text-blue-300'
            //         }
            //       `}
            //         aria-hidden="true"
            //         xmlns="http://www.w3.org/2000/svg"
            //         fill="currentColor"
            //         viewBox="0 0 20 20"
            //       >
            //         <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
            //       </svg>
            //     </span>
            //   </span>
            //   <h3 className="flex items-center mb-1 text-base font-semibold text-gray-900 dark:text-white">
            //     {'Order Placed'}
            //     <span className="bg-blue-2/10 text-blue-2 text-xs font-medium mr-2 px-2.5 py-0.5 rounded ml-3">
            //       Latest
            //     </span>
            //   </h3>
            //   <time className="block mb-2 text-[12px] font-medium leading-none text-color-secondary -mt-[1px]">
            //     05/02/24 - 18:48
            //   </time>
            //   <p className="mb-4 text-xs text-gray-700 dark:text-gray-400 font-medium">
            //     <>
            //       <p className="flex gap-1.5">
            //         <span className="text-color-secondary">Name:</span> Text
            //       </p>
            //       <p className="flex gap-1.5">
            //         <span className="text-color-secondary">Total Amount:</span>
            //         {<span className="px-2">100.00</span>}
            //       </p>
            //       <p className="flex gap-1.5">
            //         <span className="text-color-secondary">Payment Status:</span>

            //         <span className="px-2">Captured</span>
            //       </p>
            //     </>
            //   </p>
            //   {/* <a
            //       href="#"
            //       className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:outline-none focus:ring-gray-200 focus:text-blue-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-gray-700"
            //     >
            //       <svg
            //         className="w-3.5 h-3.5 mr-2.5"
            //         aria-hidden="true"
            //         xmlns="http://www.w3.org/2000/svg"
            //         fill="currentColor"
            //         viewBox="0 0 20 20"
            //       >
            //         <path d="M14.707 7.793a1 1 0 0 0-1.414 0L11 10.086V1.5a1 1 0 0 0-2 0v8.586L6.707 7.793a1 1 0 1 0-1.414 1.414l4 4a1 1 0 0 0 1.416 0l4-4a1 1 0 0 0-.002-1.414Z" />
            //         <path d="M18 12h-2.55l-2.975 2.975a3.5 3.5 0 0 1-4.95 0L4.55 12H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2Zm-3 5a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
            //       </svg>{' '}
            //       Download ZIP
            //     </a> */}
            // </li>
          ))}
        </ol>
      </div>
    </>
  );
}
