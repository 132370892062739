import React, { useState } from 'react';
import { MdLightbulbOutline } from 'react-icons/md';
import CreateTicketDrawer from '../../Pages/support-ticket/CreateTicketDrawer';

export default function NeedHelpButtonDrawer() {
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <div>
        <button
          className={`text-blue-3 hover:underline flex justify-center items-center gap-0.5 px-1.5 min-w-[100px]`}
          onClick={() => {
            showDrawer();
          }}
        >
          <MdLightbulbOutline />
          Need Help?
        </button>
      </div>
      {open && <CreateTicketDrawer onClose={onClose} open={open} />}
    </div>
  );
}
