import axios from 'axios';
import config from '../../Config/config';
import { apiHeader } from '../../utils/utils';

export async function getUserAddress() {
  const res = await axios.get(
    `${config.base_url}${config.address_list}?page=1&limit=10`,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data.data;
}

export async function createUserAddress(payload) {
  const res = await axios.post(
    `${config.base_url}${config.create_address}`,
    payload,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function updateUserAddress(payload) {
  const { form_header } = apiHeader();
  const res = await axios.put(
    `${config.base_url}${config.update_address}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function deleteUserAddress(id) {
  const res = await axios.delete(
    `${config.base_url}${config.delete_address}/${id}`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}
