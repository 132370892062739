import { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import SwitchedStrategies from '../components/UI/Portfolio/SwitchedStrategies';
import PortfolioDataCards from '../components/UI/Portfolio/PortfolioDataCards';
import DateTime from '../components/Common/DateTime';
import PortfolioReportTabs from '../components/Common/PortfolioReportTabs';
import { DataLoader } from '../components/Common/DataLoader';
import { useDispatch, useSelector } from 'react-redux';
import { formatINR } from '../utils/utils';
import ActionCardUi from './UserComponents/subscription/ActionCardUi';
import { showPlansModal } from '../features/profile/profilePlanSlice';
import GetStartedCard from '../components/Common/GetStartedCard';
import SubscriptionExpireStrip from './UserComponents/subscription_upgrade/SubscriptionExpireStrip';

import usePortfolioTables from '../components/hooks/usePortfolioTables';
import usePortfolioOpenOrder from '../components/hooks/usePortfolioOpenOrder';
import { MdLock } from 'react-icons/md';

// var totalPnL = 0;

const VirtualPortfolio = () => {
  const location = useLocation();
  let tab = location.pathname.split('/portfolio/')[1];
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(true);

  const [overAllStrategies, setOverAllStrategies] = useState([]);

  const { userDetails } = useSelector((state) => state.profileDetails);

  const {
    totalCurrentPnL,
    totalInvestment,
    availableStrategies,
    strategyResult,
    loading,
    previousTrades,
    lastOrder,
    showBottomStrip,
    overAllDateRange,
    totalTradesData,
    selectedDateRange,
    pnlData,
  } = usePortfolioOpenOrder();

  // const { overAllDateRange, totalTradesData, selectedDateRange, pnlData } =
  //   usePortfolioTables(tab);

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    //previous trades stratgies
    if (previousTrades?.stData?.length > 0) {
      let finalStrategies = [];
      previousTrades?.stData?.forEach((item) => {
        let availableIn = availableStrategies?.find(
          (startegy) => startegy?.st_id === item?._id,
        );
        if (!availableIn) {
          finalStrategies.push({
            created_at: item?.strategys?.created_at,
            st_name: item?.strategys?.name,
            st_id: item?.strategys?._id,
            access_type: item?.strategys?.access_type,
          });
        }
      });
      //previous trades stratgies is availble we need to attach with availableStrategies

      setOverAllStrategies([...availableStrategies, ...finalStrategies]);
    } else if (availableStrategies.length > 0) {
      setOverAllStrategies(availableStrategies);
    }
  }, [previousTrades, availableStrategies]);

  const showTrade = useMemo(() => {
    if (
      previousTrades?.orders_data?.length === 0 &&
      tab === 'live' &&
      userDetails?.active_plan?.subscriptionPlanData?.plan_type !== 'month' &&
      userDetails?.active_plan?.subscriptionPlanData?.plan_type !== 'annual'
    ) {
      return (
        <ActionCardUi
          title={'Upgrade your plan'}
          subTitle={'Please upgrade your plan to get access to Live Trades.'}
          actionButton={true}
          buttonName={'Upgrade Now'}
          handleAction={() => dispatch(showPlansModal(true))}
        />
      );
    } else if (
      availableStrategies.length === 0 &&
      previousTrades?.orders_data?.length === 0
    ) {
      return (
        <div className="row px-0">
          <div className="col-12 px-0">
            {showGetStarted && (
              <div className="flex justify-center items-center w-full">
                <GetStartedCard
                  handleClose={() => {
                    setShowGetStarted(false);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          {/* <div className="row strtgy__table__box mt-1 lg-mt-3 bx_shadow sticky"> */}
          <PortfolioReportTabs
            lastOrder={lastOrder}
            availableStrategies={availableStrategies}
            tab={tab}
            isMobile={isMobile}
            strategyResult={strategyResult}
            previousTrades={previousTrades}
            pnlData={pnlData}
            // setTableDate={setTableDate}
          />
        </div>
      );
    }
  }, [
    previousTrades,
    tab,
    userDetails?.active_plan?.subscriptionPlanData?.plan_type,
    availableStrategies,
    dispatch,
    navigate,
    lastOrder,
    isMobile,
    strategyResult,
    pnlData,
    showGetStarted,
  ]);

  const showStrategies = useMemo(() => {
    if (overAllStrategies?.length > 0) {
      return (
        <SwitchedStrategies
          isMobile={isMobile}
          availableStrategies={overAllStrategies}
          tab={tab}
        />
      );
    } else {
      return null;
    }
  }, [overAllStrategies, isMobile, tab]);

  return (
    <>
      <div className={`left__bar`}>
        <SubscriptionExpireStrip
          lastOrder={lastOrder}
          showBottomStrip={showBottomStrip}
        />

        <div className="row off__blue rounded mt-4 mb-2 p-2">
          <div className="col-6 ps-0">
            <div className="flex gap-2">
              <div className="">
                <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                    className="shrink-0"
                  >
                    <polyline points="16 16 12 12 8 16"></polyline>
                    <line x1="12" y1="12" x2="12" y2="21"></line>
                    <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                    <polyline points="16 16 12 12 8 16"></polyline>
                  </svg>
                </span>
              </div>
              <div className="">
                <div className="flex gap-2">
                  <div>
                    <h2 className="strtgy__ttl">Portfolio Overview</h2>
                    <DateTime />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 text-end pe-1 pe-lg-2 d-flex justify-content-end">
            <div
              className={`align-items-end d-flex pb-3 pe-2 ${
                totalCurrentPnL !== 0
                  ? totalCurrentPnL > 0
                    ? 'text-success'
                    : 'text-danger'
                  : ''
              }`}
            >
              {totalCurrentPnL !== 0 &&
                (totalCurrentPnL > 0 ? <BsArrowUp /> : <BsArrowDown />)}
            </div>
            <div>
              <h3 className="pnl__ttl">Current P&L</h3>
              <h4
                className={`pnl__details fw-semibold ${
                  totalCurrentPnL !== 0
                    ? totalCurrentPnL > 0
                      ? 'text-success'
                      : 'text-danger'
                    : ''
                }`}
              >
                {totalCurrentPnL === 0 ? (
                  <span className="dark:text-color-secondary">₹0.00</span>
                ) : (
                  formatINR(totalCurrentPnL.toFixed(2))
                )}
              </h4>
            </div>
          </div>
        </div>

        <PortfolioDataCards
          overAllDateRange={overAllDateRange}
          totalTradesData={totalTradesData}
          selectedDateRange={selectedDateRange}
          totalInvestment={totalInvestment}
        />

        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ textAlign: 'center', minHeight: '50vh' }}
          >
            <DataLoader message={'Loading Data...'} />
          </div>
        ) : (
          <div className="">
            {showStrategies}
            {showTrade}
          </div>
        )}
      </div>
    </>
  );
};

export default VirtualPortfolio;
