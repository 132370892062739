// reducers/index.js
import { combineReducers } from '@reduxjs/toolkit';
import profileDetailsReducer from '../../features/profile/profilePlanSlice';
import subscribedDetailsReducer from '../../features/strategy/userSubscribeListSlice';
import userCreditsDetailsReducer from '../../features/UserCredits/userCreditsSlice';
import toUpdatePlanReducer from '../../features/update-plan/UpdatePlanSlice';
import completeStrategyDetailsReducer from '../../features/strategyReport/strategyDataDetailSlice';
import { completeProfileDetails } from '../../services/profileDetails';
import { completeUserPlanDetails } from '../../services/planDetails';
import { RESET_STORE } from '../reduxReset';
import { strategyReportDetails } from '../../services/strategyReportDetails';
import detailsTradeReducer from '../../features/detailsTradeSlice';
import SelectedTickets from '../../features/Tickets/tickets.slice';
import brokerModalReducer from '../../features/brokers/brokerSlice';
import welcomeModalReducer from '../../features/welcomeModalSlice';
import tradeReportDetails from '../../features/reports/reports.slice';

const appReducer = combineReducers({
  profileDetails: profileDetailsReducer,
  subscribedDetails: subscribedDetailsReducer,
  userCreditsDetails: userCreditsDetailsReducer,
  toUpdatePlan: toUpdatePlanReducer,
  completeStrategyDetails: completeStrategyDetailsReducer,
  tradeReportData: tradeReportDetails,
  detailsTrade: detailsTradeReducer,
  [completeProfileDetails.reducerPath]: completeProfileDetails.reducer,
  [completeUserPlanDetails.reducerPath]: completeUserPlanDetails.reducer,
  [strategyReportDetails.reducerPath]: strategyReportDetails.reducer,
  selectedTickets: SelectedTickets,
  brokerDetails: brokerModalReducer,
  welcomeModal: welcomeModalReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined; // Reset the state to initial state
  }
  return appReducer(state, action);
};

export default rootReducer;
