import { useCallback, useMemo } from 'react';
import { useDisplayImageVideo } from '../Common/useDisplayImageVideo';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { updateProfileImage } from '../helpers/api/Auth/auth';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { useGetUserByIdQuery } from '../../services/profileDetails';

const useProfileImage = () => {
  const UserID = mydecodedTokenFunction(localStorage.getItem('token'));

  const { refetch } = useGetUserByIdQuery(UserID.id);

  const insertNote = useCallback((data) => {
    const { profileImage, userId } = data;
    const formData = new FormData();
    formData.append('user_image', profileImage);
    formData.append('user_id', userId);
    return updateProfileImage(formData);
  }, []);

  const updateProfileIMGMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      refetch();
      return toast.success('updated successfully.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const removeNote = useCallback((data) => {
    const { userId } = data;
    const formData = new FormData();
    formData.append('image_remove', 'yes');
    formData.append('user_id', userId);
    return updateProfileImage(formData);
  }, []);

  const removeProfileIMGMutation = useMutation({
    mutationFn: removeNote,
    onSuccess: (res) => {
      refetch();
      return toast.success('Removed successfully.', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const updateuserData = useCallback(
    (profileImage) => {
      updateProfileIMGMutation?.mutate({ profileImage, userId: UserID.id });
    },
    [updateProfileIMGMutation, UserID],
  );

  const { imgError, profileImage, setProfileImage, uploader, setImgError } =
    useDisplayImageVideo(updateuserData);

  const handleUploadProfileImg = useCallback((e) => {
    console.log('test');
    e.preventDefault();
    document.getElementById('profile-Image').click();
  }, []);

  const handleDeleteProfileImg = useCallback(
    (e) => {
      e.preventDefault();
      setProfileImage();
      removeProfileIMGMutation.mutate({ userId: UserID.id });
    },
    [setProfileImage, removeProfileIMGMutation, UserID.id],
  );

  const value = useMemo(() => {
    return {
      handleUploadProfileImg,
      imgError,
      profileImage,
      isLoading: updateProfileIMGMutation?.isPending,
      setProfileImage,
      uploader,
      setImgError,
      handleDeleteProfileImg,
    };
  }, [
    handleUploadProfileImg,
    imgError,
    profileImage,
    updateProfileIMGMutation?.isPending,
    setProfileImage,
    uploader,
    setImgError,
    handleDeleteProfileImg,
  ]);

  return value;
};

export default useProfileImage;
