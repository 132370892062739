import React, { useEffect, useState } from 'react';
import { BsFillPatchCheckFill } from 'react-icons/bs';
import Tooltip from '../../Common/Tooltip';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Input } from 'antd';
import { formClass } from '../../utils/defaultClasses';
import axios from 'axios';
import config from '../../../Config/config';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useToGetId from '../../hooks/useToGetId';
import { userProfileDetails } from '../../../features/profile/profilePlanSlice';

function PersonalDetailsTab() {
  // const { profileDetailsOfUser } = useSelector((state) => state.profileDetails);
  const { profileDetailsOfUser } = useSelector((state) => state.profileDetails);
  const token = localStorage.getItem('token');
  let { userid } = useToGetId();

  const [isTab, setIsTabed] = useState(true);
  const dispatch = useDispatch();

  const [changeUserName, setChangeUserName] = useState({
    first_name: '',
    last_name: '',
  });

  const handleChangeName = (e) => {
    setChangeUserName({
      ...changeUserName,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (profileDetailsOfUser) {
      //intially setting the to chnage username
      setChangeUserName({
        first_name: profileDetailsOfUser?.first_name,
        last_name: profileDetailsOfUser?.last_name,
      });
    }
  }, [profileDetailsOfUser]);

  const updateProfile = (payload) => {
    axios
      .put(`${config.base_url}${config.update_user}/${userid.id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(userProfileDetails(res?.data?.data));
        if (res.data.message === 'User data update successfully.') {
          return toast.success('updated successfully.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
    // }
  };

  const updateUserName = () => {
    if (
      profileDetailsOfUser?.first_name === changeUserName.first_name &&
      profileDetailsOfUser?.last_name === changeUserName.last_name
    ) {
      return;
    } else {
      updateProfile(changeUserName);
    }
  };

  return (
    <>
      <div className="User__Details__Card">
        <div className="Details__Card__head">
          <h2 className="strtgy__ttl">Personal Details</h2>
          {isTab ? (
            <button className="btn_default" onClick={() => setIsTabed(!isTab)}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 0 0 0-1.41l-2.34-2.34a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
              </svg>
              {/* Edit Profile */}
            </button>
          ) : (
            <div className="d-flex flex-row gap-2">
              <button
                className="btn_default"
                onClick={() => {
                  setIsTabed(!isTab);
                  setChangeUserName({
                    first_name: profileDetailsOfUser?.first_name,
                    last_name: profileDetailsOfUser?.last_name,
                  });
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m289.94 256 95-95A24 24 0 0 0 351 127l-95 95-95-95a24 24 0 0 0-34 34l95 95-95 95a24 24 0 1 0 34 34l95-95 95 95a24 24 0 0 0 34-34z"></path>
                </svg>
                {/* Cancel */}
              </button>
              <button
                className="btn_default_active"
                style={{ padding: '10px' }}
                onClick={() => {
                  setIsTabed(!isTab);
                  updateUserName();
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M272 64h-16c-4.4 0-8 3.6-8 8v72c0 4.4 7.6 8 12 8h12c4.4 0 8-3.6 8-8V72c0-4.4-3.6-8-8-8z"></path>
                  <path d="M433.9 130.1L382 78.2c-9-9-21.3-14.2-34.1-14.2h-28c-8.8 0-16 7.3-16 16.2v80c0 8.8-7.2 16-16 16H160c-8.8 0-16-7.2-16-16v-80c0-8.8-7.2-16.2-16-16.2H96c-17.6 0-32 14.4-32 32v320c0 17.6 14.4 32 32 32h320c17.6 0 32-14.4 32-32V164c0-12.7-5.1-24.9-14.1-33.9zM322 400.1c0 8.8-8 16-17.8 16H143.8c-9.8 0-17.8-7.2-17.8-16v-96c0-8.8 8-16 17.8-16h160.4c9.8 0 17.8 7.2 17.8 16v96z"></path>
                </svg>
              </button>
            </div>
          )}
        </div>
        <div className="Details__Card__Body">
          {isTab ? (
            <div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>First Name</label>
                </div>
                <div className="col-lg-8">
                  <span>
                    {profileDetailsOfUser?.first_name
                      ? profileDetailsOfUser?.first_name
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row separate__between"></div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>Last Name</label>
                </div>
                <div className="col-lg-8">
                  <span>
                    {profileDetailsOfUser?.last_name
                      ? profileDetailsOfUser?.last_name
                      : '-'}
                  </span>
                </div>
              </div>
              <div className="row separate__between"></div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>Email Id</label>
                </div>
                <div className="col-lg-8">
                  <span className="flex items-center justify-between gap-1 w-full">
                    <span>
                      {profileDetailsOfUser?.email
                        ? profileDetailsOfUser?.email
                        : '-'}
                    </span>
                    <span className="flex items-center gap-1 text-xs text-blue-2 bg-blue-2/10 px-1.5 py-0.5 rounded-md">
                      <BsFillPatchCheckFill />
                      Verified
                    </span>
                  </span>
                </div>
              </div>
              <div className="row separate__between"></div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>Phone Number</label>
                </div>
                <div className="col-lg-8">
                  <span className="flex items-center justify-between gap-1 w-full">
                    <span>
                      {profileDetailsOfUser?.phone_no
                        ? profileDetailsOfUser?.phone_no
                        : '-'}
                    </span>
                    <Tooltip title={'Feature coming soon.'}>
                      <span
                        className="flex items-center gap-1 text-xs text-red-2 bg-red-2/10 px-1.5 py-0.5 rounded-md cursor-pointer"
                        onClick={() => {
                          // setIsOtpModalVisible(true);
                        }}
                      >
                        <AiFillCloseCircle />
                        Click to verify
                      </span>
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>First Name</label>
                </div>
                <div className="col-lg-8">
                  <Input
                    placeholder="Enter your first name"
                    value={changeUserName?.first_name}
                    name="first_name"
                    className={`${formClass}`}
                    onChange={(e) => handleChangeName(e)}
                  />
                </div>
              </div>
              <div className="row separate__between"></div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>Last Name</label>
                </div>
                <div className="col-lg-8">
                  <Input
                    placeholder="Enter your last name"
                    value={changeUserName?.last_name}
                    name="last_name"
                    className={`${formClass}`}
                    onChange={(e) => handleChangeName(e)}
                  />
                </div>
              </div>
              <div className="row separate__between"></div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>Email Id</label>
                </div>
                <div className="col-lg-8">
                  <Input
                    placeholder="Enter your Email id"
                    defaultValue={
                      profileDetailsOfUser?.email
                        ? profileDetailsOfUser?.email
                        : '-'
                    }
                    disabled={true}
                    className={`${formClass}`}
                  />
                </div>
              </div>
              <div className="row separate__between"></div>
              <div className="row align-items-center">
                <div className="col-lg-4">
                  <label>Phone Number</label>
                </div>
                <div className="col-lg-8">
                  <Input
                    placeholder="Enter your phone number"
                    defaultValue={
                      profileDetailsOfUser?.phone_no
                        ? profileDetailsOfUser?.phone_no
                        : '-'
                    }
                    className={`${formClass}`}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(PersonalDetailsTab);
