import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../Config/config';
import { btnSecondayClass } from '../utils/defaultClasses';
import { CrossIcon } from '../../assets/Icon';

const DeleteConfirmation = ({
  showModal,
  hideModal,
  id,
  updateReportOnDel,
  from,
  refetch,
  loading,
  type = 'single',
}) => {
  let getuserBots = localStorage.getItem('token');
  // let userid = mydecodedTokenFunction(getuserBots);
  // const tokenexist = localStorage.getItem('token');

  const handleDelete = () => {
    updateReportOnDel(true);
    axios
      .delete(
        from === 'virtual'
          ? `${config.base_url}${config.virtual_trade_delete}/${id}`
          : from === 'backtesting'
          ? `${config.base_url}${config.BT_trade_delete}/${id}`
          : `${config.base_url}${config.live_trade_delete}/${id}`,
        { headers: { Authorization: `Bearer ${getuserBots}` } },
      )
      .then((res) => {
        console.log('Response Data', res.data.message);
        updateReportOnDel(false);
        hideModal();
        refetch(1);
        return toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
        // if (res.data.message === 'Data Delete Successfully.') {
        //   return toast.success('Deleted Successfully', {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     autoClose: 3000,
        //   });
        // } else {
        //   return toast.console.error(res.data.message, {
        //     position: toast.POSITION.BOTTOM_RIGHT,
        //     autoClose: 3000,
        //   });
        // }
      })
      .catch(function (status) {
        console.error('status: ', status);
      });
  };
  const handleDeleteMultiple = () => {
    updateReportOnDel(true);
    axios
      .post(
        from === 'virtual'
          ? `${config.base_url}${config.virtual_trade_delete_multiple}`
          : from === 'backtesting'
          ? `${config.base_url}${config.BT_trade_delete_multiple}`
          : `${config.base_url}${config.live_trade_delete_multiple}`,
        {
          id: id,
        },
        { headers: { Authorization: `Bearer ${getuserBots}` } },
      )
      .then((res) => {
        console.log('Response Data', res.data.message);
        updateReportOnDel(false);
        hideModal();
        refetch(1);
        return toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      })
      .catch(function (status) {
        console.error('status: ', status);
      });
  };
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      style={{ marginTop: '200px' }}
      contentClassName="bg-color-base-1 rounded-lg overflow-hidden"
    >
      <Modal.Header className="flex justify-between items-center">
        <Modal.Title>Delete Confirmation</Modal.Title>
        <div className="text-end">
          <span
            onClick={hideModal}
            className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
          >
            <CrossIcon className="text-base text-color-primary" />
          </span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">
          Are you sure you want to delete?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => {
            if (type === 'single') {
              handleDelete();
            } else {
              handleDeleteMultiple();
            }
          }}
        >
          {loading && <i className={`fas fa-circle-notch fa-spin`}></i>} Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;
