import { IconButton, Menu, Tooltip } from '@mui/material';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import NotificationPopover from './notification_dropdown/NotificationPopover';
import SubscriptionUpgradeModal from '../../Pages/UserComponents/subscription/SubscriptionUpgradeModal';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { useDispatch, useSelector } from 'react-redux';
import useLogout from '../hooks/useLogout';
import AlertModal from '../Common/AlertModal';
import { MdInfoOutline, MdKeyboardArrowDown } from 'react-icons/md';
import PopoverDropdown from '../Common/PopoverDropdown';
import { btnPrimaryClass } from '../utils/defaultClasses';
import SubscriptionExpireStrip from '../../Pages/UserComponents/subscription_upgrade/SubscriptionExpireStrip';
import PortfolioModeStrip from '../Common/PortfolioModeStrip';
import { Dropdown } from 'antd';
import { showPlansModal } from '../../features/profile/profilePlanSlice';
import { setOpenWelcomeModal } from '../../features/welcomeModalSlice';

const Topbar = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const [theme, setTheme] = useState(() => {
    // Check if a theme is stored in local storage, otherwise use default
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  });
  const [logoutModal, setLogoutModal] = useState(false);
  const [planDetails, setPlanDetails] = useState([]);
  const [remainingDays, setRemainingDays] = useState(0);
  const [showStripDropDown, setShowStripDropDown] = useState(true);
  const [showChooseStrategy, setShowChooseStrategy] = useState(false);
  const [tradeDropDown, setTradeDropDown] = useState('');
  const { userDetails } = useSelector((state) => state.profileDetails);

  useEffect(() => {
    setPlanDetails(userDetails?.active_plan);
    if (userDetails?.active_plan?.expired_at) {
      const givenDate = new Date(userDetails?.active_plan?.expired_at);
      const currentDate = new Date();
      const differenceMs = givenDate - currentDate;
      const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
      setRemainingDays(differenceDays);
    }
  }, [userDetails]);

  // Function to toggle between dark and light themes
  const body = document.querySelector('body');
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    // console.log(newTheme);
    setTheme(newTheme);
    if (newTheme === 'dark') {
      body.classList.add('dark');
      body.classList.remove('light');
    } else {
      body.classList.remove('dark');
      body.classList.add('light');
    }
    localStorage.setItem('theme', newTheme);
  };

  // Add/remove 'dark-theme' class to the document body based on the current theme
  const storedDarkMode = localStorage.getItem('theme');
  const [darkMode, setDarkMode] = useState(storedDarkMode);

  useEffect(() => {
    document.body.classList.toggle('dark-theme', theme === 'dark');
    const body = document.querySelector('body');
    if (theme === 'dark') {
      body.classList.add('dark');
      body.classList.remove('light');
    } else {
      body.classList.remove('dark');
      body.classList.add('light');
    }
    // Store the dark mode preference in localStorage
    localStorage.setItem('darkMode', darkMode);
  }, [theme, darkMode]);

  // const [showSubscription, setShowSubscription] = useState(false);

  const { logoutUser, isLoading: logOutLoading } = useLogout();

  const logOut = () => {
    logoutUser();
  };

  const CustomNavLink = ({ to, children }) => {
    return (
      <NavLink
        to={to}
        className={({ isActive }) =>
          isActive
            ? 'bg-blue-2/15 text-blue-2 px-2.5 py-1.5 rounded-md duration-150 ease-in-out'
            : 'hover:bg-color-base-2 px-2.5 py-1.5 rounded-md duration-150 ease-in-out'
        }
      >
        {children}
      </NavLink>
    );
  };

  const showUpdateButtonUI = () => {
    if (
      planDetails?.subscriptionPlanData?.plan_type === ('month' || 'annual')
    ) {
      const expiryDateObj = new Date(planDetails.expired_at);
      const todayDateObj = new Date();
      const diffTime = expiryDateObj - todayDateObj;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 5 && diffDays >= 0) {
        return (
          <span className="text-blue-4 font-semibold">
            <span
              className="text-xs cursor-pointer"
              onClick={() => dispatch(showPlansModal(true))}
            >
              Renew Plan
            </span>
          </span>
        );
      } else {
        return null;
      }
    } else {
      return (
        <span className="text-blue-4 font-semibold">
          <span
            className="text-xs cursor-pointer"
            onClick={() => dispatch(showPlansModal(true))}
          >
            Upgrade Plan
          </span>
        </span>
      );
    }
  };

  const contentPopover = (
    <>
      <div className="flex flex-col max-w-[280px] leading-tight">
        <div>
          <span className="text-sm font-semibold mb-0 text-color-primary">
            Upgrade Plans
          </span>
        </div>
        <div>
          <span className="text-color-secondary text-[10px]">
            Unlock advanced features with our premium upgrade plans.
          </span>
        </div>
        <div className="flex w-full mt-2">
          <button
            className={`${btnPrimaryClass} py-1.5 text-xs`}
            onClick={() => dispatch(showPlansModal(true))}
          >
            Upgrade
          </button>
        </div>
      </div>
    </>
  );
  const location = useLocation();
  let tab = location.pathname.split('/portfolio/')[1];

  useEffect(() => {
    if (tab === 'virtual' || tab === 'live') {
      setShowStripDropDown(true);
    } else {
      setShowStripDropDown(false);
    }
  }, [tab]);

  const handleMenuClick = (key) => {
    if (key === '1') {
      navigate('/portfolio/virtual');
    } else if (key === '2') {
      navigate('/portfolio/live');
    }
  };

  const showTopBar = useMemo(() => {
    return (
      <>
        {/* {remindLater ? null : ( */}

        <div
          className="relative p-[10px] top-0 z-[9] w-full d-none d-lg-flex pe-0"
          style={{ boxShadow: '0px 10px 30px 0px rgba(82,63,105,.05)' }}
        >
          <div className="w-full left__bar mt-0 me-0">
            <div className="w-full">
              <div className="d-flex justify-between w-100 -ml-2">
                <div className="d-flex align-items-center ps-0">
                  <div className="flex gap-3 items-center">
                    <CustomNavLink to="/chart">Charts</CustomNavLink>
                    <CustomNavLink to="/broker">Brokers</CustomNavLink>
                    <CustomNavLink to="/marketplace">Marketplace</CustomNavLink>
                    <CustomNavLink to="/reports">Reports</CustomNavLink>
                  </div>
                </div>
                <div className="pr-[1rem]">
                  <ul className="sidebar__bottom__area d-flex flex-row justify-content-end align-items-center list-unstyled pe-0">
                    <li>
                      {planDetails ? (
                        <div className="flex justify-center items-center gap-1 text-xs bg-blue-2/10 border-[1px] border-blue-2/60 border-dashed py-[6px] px-2.5 rounded-md me-1.5">
                          <span>
                            {planDetails.subscriptionPlanData?.plan_name} :{' '}
                            <strong>{remainingDays}</strong> days Remaining.
                          </span>
                          {showUpdateButtonUI()}
                          <span className="text-blue-4 font-semibold">
                            <PopoverDropdown
                              content={contentPopover}
                              trigger={'hover'}
                              zIndex={99}
                            >
                              <span className="text-xs cursor-pointer">
                                <MdInfoOutline />
                              </span>
                            </PopoverDropdown>
                          </span>
                        </div>
                      ) : null}
                    </li>
                    {showStripDropDown && (
                      <>
                        <li>
                          <span className="divider"></span>
                        </li>

                        <div className="px-1.5">
                          <Dropdown
                            menu={{
                              items: [
                                {
                                  key: '1',
                                  label: (
                                    <div className="flex items-center gap-1.5">
                                      <div
                                        className={`flex w-[10px] h-[10px] rounded-full ${
                                          tab === 'virtual' ? 'bg-green-3' : ''
                                        }`}
                                      ></div>
                                      Virtual Trade
                                    </div>
                                  ),
                                  onClick: () => handleMenuClick('1'),
                                },
                                {
                                  key: '2',
                                  label: (
                                    <div className="flex items-center gap-1.5">
                                      <div
                                        className={`flex w-[10px] h-[10px] rounded-full ${
                                          tab === 'live' ? 'bg-green-3' : ''
                                        }`}
                                      ></div>
                                      Live Trade
                                    </div>
                                  ),
                                  onClick: () => handleMenuClick('2'),
                                },
                              ],
                            }}
                            placement="bottomRight"
                            overlayClassName={'dropdownOverlay dropdownDark'}
                            trigger={['click']}
                            className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-component-gray-2 hover:text-color-primary dark:hover:bg-component-gray-2 gap-1.5 rounded-[6px] text-color-primary px-2 py-[4px] cursor-pointer"
                          >
                            <div onClick={(e) => e.preventDefault()}>
                              {tab === 'virtual'
                                ? 'Virtual Trade'
                                : 'Live Trade'}
                              <MdKeyboardArrowDown />
                            </div>
                          </Dropdown>
                        </div>
                      </>
                    )}

                    <li>
                      <span className="divider"></span>
                    </li>
                    <li className="active">
                      <IconButton className="active" onClick={toggleTheme}>
                        {theme === 'light' ? (
                          <Tooltip title="Light Mode" placement="bottom">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              stroke-width="0"
                              viewBox="0 0 24 24"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path fill="none" d="M0 0h24v24H0z"></path>
                              <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58a.996.996 0 0 0-1.41 0 .996.996 0 0 0 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37a.996.996 0 0 0-1.41 0 .996.996 0 0 0 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0a.996.996 0 0 0 0-1.41l-1.06-1.06zm1.06-10.96a.996.996 0 0 0 0-1.41.996.996 0 0 0-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36a.996.996 0 0 0 0-1.41.996.996 0 0 0-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z"></path>
                            </svg>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Dark Mode" placement="bottom">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              id="Layer_1"
                              data-name="Layer 1"
                              viewBox="0 0 24 24"
                              width="1em"
                              height="1em"
                            >
                              <path d="M15,24A12,12,0,1,1,19.058.71a2.93,2.93,0,0,1,.814,5.067c-5.1,3.92-5.088,8.539,0,12.446a2.93,2.93,0,0,1-.816,5.067A12.2,12.2,0,0,1,15,24ZM15,3a9,9,0,1,0,2.925,17.508c-6.511-5.065-6.489-11.969,0-17.016A9.322,9.322,0,0,0,15,3Z" />
                            </svg>
                          </Tooltip>
                        )}
                      </IconButton>
                    </li>
                    <li>
                      <span className="divider"></span>
                    </li>
                    <li>
                      <Tooltip title="Notification" placement="bottom">
                        <NotificationPopover />
                      </Tooltip>
                      <Menu
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      ></Menu>
                    </li>
                    <li>
                      <span className="divider"></span>
                    </li>
                    <li>
                      <Tooltip title="Profile" placement="bottom">
                        <Link to={'/profile'} exact>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Bold"
                            viewBox="0 0 24 24"
                            width="1em"
                            height="1em"
                          >
                            <path d="M18.5,0H5.5A5.506,5.506,0,0,0,0,5.5v13A5.506,5.506,0,0,0,5.5,24h13A5.506,5.506,0,0,0,24,18.5V5.5A5.506,5.506,0,0,0,18.5,0ZM21,18.5A2.5,2.5,0,0,1,18.5,21H18V20A6,6,0,0,0,6,20v1H5.5A2.5,2.5,0,0,1,3,18.5V5.5A2.5,2.5,0,0,1,5.5,3h13A2.5,2.5,0,0,1,21,5.5Z" />
                            <circle cx="12" cy="8.5" r="3.5" />
                          </svg>
                        </Link>
                      </Tooltip>
                    </li>
                    <li>
                      <span className="divider"></span>
                    </li>
                    <li>
                      <Tooltip title="Logout" placement="bottom">
                        <IconButton
                          className="ms-2"
                          onClick={() => {
                            dispatch(
                              setOpenWelcomeModal({
                                openWelcomeModal: true,
                                from: 'logout',
                              }),
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            version="1.1"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 512.292 512.292"
                            width="1em"
                            height="1em"
                          >
                            <g>
                              <path d="M256.061,0L256.061,0c17.673,0,32,14.327,32,32v106.667c0,17.673-14.327,32-32,32l0,0c-17.673,0-32-14.327-32-32V32   C224.061,14.327,238.387,0,256.061,0z" />
                              <path d="M330.727,105.387L330.727,105.387c-0.157,10.742,5.259,20.8,14.315,26.581c80.432,49.143,105.796,154.185,56.652,234.616   S247.51,472.38,167.078,423.237S61.282,269.052,110.426,188.62c14.042-22.982,33.324-42.315,56.269-56.418   c9.211-5.781,14.773-15.92,14.699-26.795l0,0c0.049-17.673-14.238-32.039-31.911-32.088c-6.07-0.017-12.02,1.693-17.155,4.931   C22.233,146.634-11.58,291.318,56.803,401.412s213.067,143.907,323.161,75.524s143.907-213.067,75.524-323.161   c-19.035-30.645-44.879-56.489-75.524-75.524c-14.997-9.461-34.824-4.973-44.285,10.024   C332.447,93.397,330.731,99.33,330.727,105.387z" />
                            </g>
                          </svg>
                        </IconButton>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <AlertModal
              isAlert={logoutModal}
              setIsAlert={() => setLogoutModal(false)}
              isLoading={logOutLoading}
              secondaryButtonName={'Cancel'}
              primaryButtonName={'Logout'}
              secondaryButtonAction={() => setLogoutModal(false)}
              primaryButtonAction={() => {
                setLogoutModal(false);
                logOut();
              }}
              title={'LogOut'}
              subtitile={'Are you sure want to logout?'}
            />

            <SubscriptionUpgradeModal />
          </div>
          {showStripDropDown && (
            <div className="portfolio_mode absolute bottom-1 left-1/2 -translate-x-1/2 w-full">
              <PortfolioModeStrip tab={tab} />
            </div>
          )}
        </div>
      </>
    );
  }, [
    planDetails,
    showStripDropDown,
    tab,
    logoutModal,
    logOutLoading,
    theme,
    remainingDays,
    contentPopover,
    handleMenuClick,
    logOut,
    showUpdateButtonUI,
    toggleTheme,
  ]);
  return showTopBar;
};

export default React.memo(Topbar);
