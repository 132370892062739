import React, { useState } from 'react';
import { Tab } from 'react-bootstrap';
import Subscription from '../../../Pages/UserComponents/subscription/Subscription';
import StatsUsage from '../../../Pages/UserComponents/stats_usage/StatsUsage';
import PaymentMethod from '../../../Pages/UserComponents/payment_method/PaymentMethod';
import BillingAddress from '../../../Pages/UserComponents/billing_address/BillingAddress';
import useInvoiceDetails from '../../hooks/useInvoiceDetails';
import TableComponent from '../../Common/TableComponent';
import { SubscriptionInvoiceLayout } from '../invoices/SubscriptionInvoice';

function BillingDetailsTab() {
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const {
    handleTableChange,
    renderTrigger,
    columns,
    dataSource,
    isLoading,
    contentToPrint,
    paymentListData,
  } = useInvoiceDetails();
  return (
    <>
      <div className="User__Details__Card">
        <div className="Details__Card__Body my-2.5">
          <div>
            <Subscription />
          </div>
        </div>
        <div className="Details__Card__head mt-2.5 border-t border-level-1">
          <h2 className="strtgy__ttl">Credit Usage</h2>
        </div>
        <div className="Details__Card__Body my-2.5">
          {/* <span className="mt-3 mb-3 d-flex primary__text__blue">
                These settings are helps you keep your account Secure.
              </span> */}
          <div>
            <StatsUsage />
          </div>
        </div>
        <div className="Details__Card__head mt-2.5 border-t border-level-1">
          <h2 className="strtgy__ttl">Payment Methods</h2>
        </div>
        <div className="Details__Card__Body my-2.5">
          {/* <span className="mt-3 mb-3 d-flex primary__text__blue">
                These settings are helps you keep your account Secure.
              </span> */}

          <PaymentMethod />
        </div>

        <div className="Details__Card__head mt-2.5 border-t border-level-1">
          <h2 className="strtgy__ttl">Billing Address</h2>
        </div>
        <div className="Details__Card__Body my-2.5">
          {/* <span className="mt-3 mb-3 d-flex primary__text__blue">
                These settings are helps you keep your account Secure.
              </span> */}
          <div>
            <BillingAddress />
          </div>
        </div>
        <div className="Details__Card__head mt-2.5 border-t border-level-1">
          <h2 className="strtgy__ttl">Billing History</h2>
        </div>
        <div className="Details__Card__Body px-0 pb-0 pt-0">
          <div>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              pagination={pagination}
              total={paymentListData?.totalRecords}
              onChange={handleTableChange}
              loading={isLoading}
            />
          </div>
        </div>
      </div>

      {renderTrigger && (
        <div style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}>
          <SubscriptionInvoiceLayout
            ref={contentToPrint}
            // showBillingDetails={showBillingDetails}
          />
        </div>
      )}
    </>
  );
}

export default React.memo(BillingDetailsTab);
