import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import rootReducer from './reducers';
import { completeProfileDetails } from '../services/profileDetails';
import { completeUserPlanDetails } from '../services/planDetails';
import { strategyReportDetails } from '../services/strategyReportDetails';

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      completeProfileDetails.middleware,
      completeUserPlanDetails.middleware,
      strategyReportDetails.middleware,
    ),
});

setupListeners(store.dispatch);
