import mydecodedTokenFunction from '../../utils/decodetoken';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import { Navigate, Outlet } from 'react-router-dom';
import CopyRightFooter from '../Common/CopyRightFooter';
import Header from '../UI/homepage/header/Header';
import Footer from '../UI/homepage/footer/Footer';

function AuthGuardLoggedOut({ children }) {
  const _decodedToken = mydecodedTokenFunction(localStorage.getItem('token'));
  const history = {
    navigate: null,
    location: null,
  };

  return _decodedToken ? (
    <Navigate to={`/portfolio/virtual`} state={{ from: history.location }} />
  ) : (
    <div>
      {children}
      <div className="shrink-0 flex justify-center 2xl:-mt-6">
        <CopyRightFooter />
      </div>
    </div>
  );
}

export default AuthGuardLoggedOut;

export const AuthGuardLoggedOutLanding = ({ children }) => {
  // const _decodedToken = mydecodedTokenFunction(localStorage.getItem('token'));
  // const history = {
  //   navigate: null,
  //   location: null,
  // };

  return (
    <>
      <div>
        <Header />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};
