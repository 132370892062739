import axios from 'axios';
import React from 'react';
import config from '../../Config/config';
import mydecodedTokenFunction from '../../utils/decodetoken';
let getuserBots = localStorage.getItem('token');

export async function getpnlData(Date, selectedDate, tab) {
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  try {
    const response = await axios.get(
      `${config.base_url}${
        tab === 'VT' ? config.virtual_trade : config.live_trade
      }/${userid.id}?page=1&day=today`,
      {
        headers: { Authorization: `Bearer ${getuserBots}` },
      },
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

export const fetchReportData = async ({ queryKey }) => {
  const [key, { tab, id }] = queryKey;
  const response = await axios.get(
    `${config.base_url}${
      tab === 'VT' ? config.virtual_trade : config.live_trade
    }/${id}?page=1&day=today`,
    {
      headers: { Authorization: `Bearer ${getuserBots}` },
    },
  );
  return response.data;
};

//To Get Closed-Orders Data in Desktop View
export async function getReportClosedOrders({ queryKey }) {
  // console.log('queryKey', queryKey);
  const [key, { closedOrdersDate, page, tab, id }] = queryKey;

  try {
    let apiUrl = '';
    if (closedOrdersDate?.[0] && closedOrdersDate?.[1]) {
      apiUrl = `${config.base_url}${
        tab === 'VT' ? config.virtual_trade : config.live_trade
      }/get-request-placed-orders/${id}?day=custom&page=${page}&start=${
        closedOrdersDate[0]
      }&end=${closedOrdersDate[1]}`;
    } else {
      apiUrl = `${config.base_url}${
        tab === 'VT' ? config.virtual_trade : config.live_trade
      }/get-request-placed-orders/${id}?day=today&page=${page}`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    const data = response.data;
    return data;
    // const finalData = response.data.orders_data;
    // setDesktopClosedOrders(finalData);

    // setPagination({
    //   ...pagination,
    //   current: page,
    //   total: data?.total_orders,
    // });
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

//To Get Trades Data in Desktop View
export async function getReportTrades({ queryKey }) {
  const [key, { tradesOrdersDate, page, tab, id }] = queryKey;
  console.log(queryKey);
  try {
    let apiUrl = '';
    if (tradesOrdersDate?.[0] && tradesOrdersDate?.[1]) {
      apiUrl = `${config.base_url}${
        tab === 'VT' ? config.virtual_trade : config.live_trade
      }/getcloseorders/${id}?page=${page}&day=custom&start=${
        tradesOrdersDate[0]
      }&end=${tradesOrdersDate[1]}`;
    } else {
      apiUrl = `${config.base_url}${
        tab === 'VT' ? config.virtual_trade : config.live_trade
      }/getcloseorders/${id}?page=${page}&day=today`;
    }
    const response = await axios.get(apiUrl, {
      headers: { Authorization: `Bearer ${getuserBots}` },
    });
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
