import React, { useState, useEffect } from 'react';
import { Space, Switch, Table, Tag } from 'antd';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import TableComponent from '../../Common/TableComponent';
import truncate from '../../Truncate';
import TradeDetailDrawer from '../StrategyDetails/TradeDetailDrawer';
import { tradeDetailsInDrawer } from '../../../features/detailsTradeSlice';
import { useDispatch } from 'react-redux';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const PortfolioDesktopTable = ({
  tradeOrder,
  pagination,
  handleTableChange,
  from,
  tab,
  emptyDataText,
  loading,
}) => {
  const [drawerOpen, setDrawerOpen] = useState();
  const [selectedTrade, setSelectedTrade] = useState([]);
  const dispatch = useDispatch();
  const showTradeDetails = (record) => {
    let tradeDetails = {
      'Trade Id': record?._id,
      'Strategy Name': record?.st_name,
      'Report Name': record?.report_name,
      'Trade Date': dayjs.utc(record?.date).format('DD-MMM-YYYY hh:mm:ss a'),
      Day: record?.day,
      Symbol: record?.stock,
      'Trade Type': '-',
      'Position Type': record?.position_type,
      'Lot size': record?.lot_size,
      Quantity: record?.quantity,
      'Expiry ': record?.expiry || '-',
      'Entry Time ': dayjs
        .utc(record?.entry_time)
        .format('DD-MMM-YYYY hh:mm:ss a'),
      'Entry Price ': record?.entry_price,
      'Exit Price ': record?.exit_price,
      'Exit Time': dayjs
        .utc(record?.exit_time)
        .format('DD-MMM-YYYY hh:mm:ss a'),
      'Exit Type': record?.exit_type,
      Points: record?.points,
      'Gross P&L': record?.pnl,
      'Brokeragee (brokerage)': record?.brokerage,
      'Net P&L (net_pnl)': record?.net_pnl || '-',
    };
    dispatch(
      tradeDetailsInDrawer({
        selectedTrade: tradeDetails,
        isOpen: true,
      }),
    );
  };
  const showClosedOrdersDetails = (record) => {
    let orderDetails = {
      'Order Id': record?._id,
      'Strategy Name': record?.strategys,
      'Report Name': record?.vt_requests,
      'Order Date': dayjs.utc(record?.date).format('DD-MMM-YYYY hh:mm:ss a'),
      Day: record?.day,
      'Order Time': dayjs
        .utc(record?.order_timestamp)
        .format('DD-MMM-YYYY hh:mm:ss a'),
      Symbol: record?.tradingsymbol,
      Quantity: record?.quantity,
      'Lot Size': record?.lot_size || '-',
      'Option Type': record?.option_type || '-',
      'Strike Price': record?.strike_price || '-',
      Expiry: record?.expiry || '-',
      'Trigger Price': record?.trigger_price,
      'Exit Type': record?.exit_type,
      'Order Type': record?.order_type,
      'Position Type': record?.position_type,
      'Transaction Type': record?.transaction_type,
      'Trade Action': record?.trade_action || '-',
      'Quantity Left': record?.quantity_left,
    };
    dispatch(
      tradeDetailsInDrawer({
        selectedTrade: orderDetails,
        isOpen: true,
      }),
    );
  };

  const tradeColumns = [
    {
      title: 'Trade Id',
      dataIndex: '_id',
      render: (text, record) => (
        <p
          onClick={() => {
            showTradeDetails(record);

            // setSelectedTrade(record);
            // setDrawerOpen(true);
          }}
          className="text-bluePrimary-1 cursor-pointer"
        >
          {truncate(text, 4)}
        </p>
      ),
      // <Link to={`/strategy/${record.strategy_id}`} className="primary__blue">
      //   {text}
      // </Link>
    },
    {
      title: 'Strategy',
      dataIndex: 'st_name',
      render: (text, record) => (
        <Link to={`/strategy/${record.strategy_id}`} className="primary__blue">
          {text}
        </Link>
      ),
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      render: (text, record) => (
        <Link
          to={`/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
            record.request_id
          }`}
          className="primary__blue"
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'stock',
    },
    {
      title: 'Entry Time',
      dataIndex: 'entry_time',
      render: (text) => (
        <>
          {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
          {
            <p>
              <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
            </p>
          }
        </>
      ),
    },

    {
      title: 'Entry Price',
      dataIndex: 'entry_price',
    },

    {
      title: 'Exit Price',
      dataIndex: 'exit_price',
    },
    {
      title: 'Exit Time',
      dataIndex: 'exit_time',
      render: (text) => (
        <>
          {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
          {
            <p>
              <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
            </p>
          }
        </>
      ),
    },

    // {
    //   title: 'Exit Type',
    //   dataIndex: 'exit_type',
    // },
    // {
    //   title: 'QTY.',
    //   dataIndex: 'quantity',
    // },
    {
      title: 'P&L',
      key: 'pnl',
      dataIndex: 'pnl',
      render: (pnl) => (
        <span>
          {pnl > 0 ? (
            <BsArrowUp className="text-success" />
          ) : (
            <BsArrowDown className="text-danger" />
          )}
          {
            <span className={pnl > 0 ? 'text-success' : 'text-danger'}>
              {pnl}
            </span>
          }
        </span>
      ),
    },
    // {
    //   title: 'Brokerage',
    //   dataIndex: 'brokerage',
    // },
  ];

  const closedColumns = [
    {
      title: 'Order Id',
      dataIndex: '_id',
      render: (text, record) => (
        <p
          onClick={() => {
            showClosedOrdersDetails(record);
          }}
          className="text-bluePrimary-1  cursor-pointer"
        >
          {truncate(text, 4)}
        </p>
      ),
    },
    {
      title: 'Strategy',
      dataIndex: 'strategys',
      render: (text, record) => (
        <Link
          to={`/strategy/${record.strategy_id}`}
          style={{ color: '#99a1b7' }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Report Name',
      dataIndex: 'vt_requests',
      render: (text, record) => (
        <Link
          to={`/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
            record.request_id
          }`}
          style={{ color: '#99a1b7' }}
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'tradingsymbol',
    },
    {
      title: 'Order Time',
      dataIndex: 'order_timestamp',
      render: (text) => dayjs.utc(text).format('DD-MMM-YYYY hh:mm:ss a'),
    },
    {
      title: 'QTY.',
      dataIndex: 'quantity',
    },
    // {
    //   title: 'Lot Size',
    //   dataIndex: 'lot_size',
    // },
    {
      title: 'Trigger Price',
      dataIndex: 'trigger_price',
    },
    {
      title: 'Trade Action',
      dataIndex: 'trade_action',
      render: (text) => (
        <span
          className={`tbl__badge ${
            text === 'ENTRY'
              ? ' primary__bg__ofgreen primary__text__green'
              : 'primary__bg__ofred primary__text__red'
          }`}
        >
          {text}
        </span>
      ),
    },

    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type',
      render: (text) => (
        <span
          className={`tbl__badge ${
            text === 'BUY'
              ? ' primary__bg__ofgreen primary__text__green'
              : 'primary__bg__ofred primary__text__red'
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Exit Type',
      dataIndex: 'exit_type',
      render: (text) => (text ? text : '-'),
    },
  ];

  return (
    <div>
      <TableComponent
        columns={from === 'close' ? closedColumns : tradeColumns}
        emptyDataText={emptyDataText}
        dataSource={tradeOrder}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey={(record) => record?._id}
        total={pagination?.total}
        loading={loading}
      />
      <TradeDetailDrawer />
    </div>
  );
};

export default React.memo(PortfolioDesktopTable);
