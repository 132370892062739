import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import {
  strategyBackTesingDetails,
} from '../../API/strategy/strategyDetail';
import useToGetId from './useToGetId';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { backtestingReportsdataStrategy, setBacktestingReportsDataSeperated } from '../../features/strategyReport/strategyDataDetailSlice';

function useBacktestReport() {
  let { id } = useParams();
  const { userid } = useToGetId();
  const [backTestPagination, setBackTestPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [backTestPage, setBackTestPage] = useState(1);
  const [backTestData, setBackTestData] = useState([]);

  const { data, refetch: backtestRefetch, isLoading, isFetching } = useQuery({
    queryKey: [
      'backtestList',
      {
        backtestingPage: backTestPage,
        strategy_id: id,
        userid: userid,
      },
    ],
    queryFn: strategyBackTesingDetails,
    refetchOnMount: 'true',
    refetchOnWindowFocus: false
  });

  const handleBacktestingTable = useCallback((newPagination) => {
    setBackTestPage(newPagination.current);
  }, []);
  const dispatch = useDispatch()
  useEffect(() => {
    if (data) {
      const updatedData = data?.data?.map((item) => ({
        ...item,
        report_name: item?.bt_inputs?.report_name,
        max_cap: item?.bt_inputs?.investment,
        key: item?._id,

        current_status: item.status,
      }));
      if (updatedData) {
        dispatch(backtestingReportsdataStrategy(updatedData))
        let obj = {
          active: updatedData?.filter((dat) => dat?.status),
          inActive: updatedData?.filter((dat) => !dat?.status),
        }
        dispatch(setBacktestingReportsDataSeperated(obj))
        setBackTestData(updatedData);
      }
      setBackTestPagination((prevPagination) => ({
        ...prevPagination,
        current: backTestPage,
        total: data?.dataCount,
      }));
    }
  }, [data]);

  return {
    handleBacktestingTable,
    backTestPagination,
    backTestData,
    isFetching,
    backtestRefetch
  };
}

export default useBacktestReport;
