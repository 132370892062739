import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStrategyDetails } from '../../API/strategy/strategyDetail';
import {
  userBacktestDeployments,
  userLiveDeployments,
  userPremiumStrategies,
  userVirtualDeployments,
} from '../../features/UserCredits/userCreditsSlice';
import useSubscribeStrategy from './useSubscribeStrategy';
import { useParams } from 'react-router-dom';
import { completeDetailsOfStartegy } from '../../features/strategyReport/strategyDataDetailSlice';
import useLiveStrategyDetails from './useLiveStrategyDetails';
import useVirtualStrategyDetails from './useVirtualStrategyDetails';
import { getActivePlans } from '../../API/profile/billinghistory';

function useReportViewDetails(strategyId = null) {
  let { id } = useParams();
  let dispatch = useDispatch();
  const [toggleState, setToggleState] = useState('overview');
  const [percentTitle, setPercentTitle] = useState({});

  //virtual reports
  const { virtualRefech } = useVirtualStrategyDetails();

  //live reports
  const { liveRefech } = useLiveStrategyDetails();

  const { virtual_requests, live_requests } = useSelector(
    (state) => state.userCreditsDetails, // credits of the virtual and live requests
  );

  // const { refetch, data } =
  //   completeUserPlanDetails.endpoints.getPlanDetails.useQuerySubscription(
  //     undefined,
  //     {
  //       refetchOnMountOrArgChange: 0, // Refetch if more than 60 seconds have passed since the last fetch
  //       refetchOnReconnect: true, // Refetch when the browser reconnects to the network
  //       refetchOnFocus: true, // Refetch when the window regains focus
  //     },
  //   );

  let { premium_strategies } = useSelector((state) => state.userCreditsDetails);

  let stratatergyId = React.useMemo(() => id || strategyId, [id, strategyId])
  const {
    data: strategyData,
    isFetching: loading,
    error,
  } = useQuery({
    queryKey: ['startegyDetailsReport', { stratatergyId }],
    queryFn: getStrategyDetails,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });

  // const { activePlansRefech } = useActivePlanData();

  useEffect(() => {
    if (strategyData) {
      dispatch(completeDetailsOfStartegy(strategyData?.data)); //strategydata updating to redux
    }
  }, [strategyData]);

  const { detailsOfStrategy } = useSelector(
    (state) => state.completeStrategyDetails,
  );

  // const [
  //   trigger,
  //   { data: creditsData, error: creditsError, isLoading: creditsLoading },
  // ] = useLazyGetPlanDetailsQuery();

  // useEffect(() => {
  //   if (error) {
  //     console.error('Error fetching user data:', error);
  //   }
  //   if (creditsData) {
  //     // let virtual_payload = {
  //     //   ...virtual_requests,
  //     //   requests_remaining: creditsData?.virtual_deployments?.remaining,

  //     //   requests_used: creditsData?.virtual_deployments?.used,
  //     // };
  //     // let live_payload = {
  //     //   ...live_requests,
  //     //   requests_remaining:
  //     //     live_requests?.requests_remaining + (status ? -1 : 1),
  //     //   requests_used: live_requests?.requests_used + (status ? 1 : -1),
  //     // };

  //     // //based on the status upadte or removing the requests counts in redux
  //     // toggleState === 'live'
  //     //   ? dispatch(userLiveDeployments(live_payload))
  //     // dispatch(userVirtualDeployments(virtual_payload));
  //     dispatch(
  //       userVirtualDeployments({
  //         requests_remaining: creditsData?.virtual_deployments?.remaining,
  //         requests_total: creditsData?.virtual_deployments?.total,
  //         requests_used: creditsData?.virtual_deployments?.used,
  //       }),
  //     );
  //     // dispatch(
  //     //   userLiveDeployments({
  //     //     requests_remaining: creditsData?.live_deployments?.remaining,
  //     //     requests_total: creditsData?.live_deployments?.total,
  //     //     requests_used: creditsData?.live_deployments?.used,
  //     //   }),
  //     // );
  //   }
  // }, [creditsData, creditsError, creditsLoading]);

  // const handleRefetch = () => {
  //   trigger();
  // };

  const getCreditsAvailable = useMutation({
    mutationFn: getActivePlans,
    onSuccess: (res) => {
      //deploying the virtual and live request according to the plan in redux
      dispatch(
        userVirtualDeployments({
          requests_remaining: res?.virtual_deployments?.remaining,
          requests_total: res?.virtual_deployments?.total,
          requests_used: res?.virtual_deployments?.used,
        }),
      );
      dispatch(
        userLiveDeployments({
          requests_remaining: res?.live_deployments?.remaining,
          requests_total: res?.live_deployments?.total,
          requests_used: res?.live_deployments?.used,
        }),
      );
      dispatch(
        userBacktestDeployments({
          requests_remaining: res?.backtest_deployments?.remaining,
          requests_total: res?.backtest_deployments?.total,
          requests_used: res?.backtest_deployments?.used,
        }),
      );
      // setCreditsData(res);
    },
    onError: (err) => { },
  });

  const handleOnSuccess = (type) => {
    dispatch(
      completeDetailsOfStartegy({
        ...detailsOfStrategy,
        subscribe_by_user: type === 'subscribe' ? true : false,
      }),
    );
    liveRefech();
    virtualRefech();
    type === 'unsubscribe' && getCreditsAvailable.mutate();
    // when the you unsubscribe the startegy the requests in virtual trade and live trade it automatically
    // turnoff all the requests .so we need to fetch the actual plans data
    if (detailsOfStrategy?.access_type === 'premium') {
      const updatedPremiumStrategies = {
        ...premium_strategies,
        strategies_remaining:
          premium_strategies?.strategies_remaining +
          (type === 'subscribe' ? -1 : 1),
        strategies_used:
          premium_strategies?.strategies_used + (type === 'subscribe' ? 1 : -1),
      };
      dispatch(userPremiumStrategies(updatedPremiumStrategies));
    }
  };

  const handleSubscription = useCallback(async (type, id) => {
    const payload = { st_id: id };

    if (type === 'subscribe') {
      add_subscription(payload);
    } else {
      remove_subscription(payload);
    }
  }, []);

  const { remove_subscription, add_subscription } =
    useSubscribeStrategy(handleOnSuccess); // to change the status of the subscription

  //based on type of the trade it will give the inputs to the modal
  const GetInputsData = useCallback(() => {
    if (toggleState === 'virtual') {
      return detailsOfStrategy?.vt_inputs;
    } else if (toggleState === 'live') {
      return detailsOfStrategy?.lt_inputs;
    } else {
      return detailsOfStrategy?.st_inputs;
    }
  }, [toggleState, detailsOfStrategy]);

  // to make the premium credits in the stats usage
  useEffect(() => {
    if (premium_strategies) {
      const { strategies_used, strategies_remaining, strategies_total } =
        premium_strategies;
      if (strategies_total === 0) {
        setPercentTitle({
          percent: 0,
          subtitle: '0/0',
        });
      } else if (strategies_total !== 0 && strategies_used === 0) {
        setPercentTitle({
          percent: 0,
          subtitle: `${strategies_used}/${strategies_total}`,
        });
      } else {
        setPercentTitle({
          percent: (strategies_used / strategies_total) * 100,
          subtitle: `${strategies_used}/${strategies_total}`,
        });
      }
    }
  }, [premium_strategies]);

  return {
    GetInputsData,
    percentTitle,
    premium_strategies,
    toggleState,
    handleSubscription,
    setToggleState,
    loading,
    detailsOfStrategy,
  };
}

export default useReportViewDetails;
