import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getOpenOrders,
  getpnlData,
  getTradeOrders,
  subscribeStratagies,
} from '../../API/portfolio/OrdersApi';
import { valueSocket } from '../../context/socket';
import { calculateBrokerage } from '../../utils/BrokerCharges';
import useToGetId from './useToGetId';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

function usePortfolioOpenOrder() {
  const { userid } = useToGetId();
  const location = useLocation();
  const { userDetails } = useSelector((state) => state.profileDetails);
  let tab = location.pathname.split('/portfolio/')[1];

  const [lastOrder, setLastOrder] = useState([]);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [openOrder, setOpenOrder] = useState([]);
  const [strategyResult, setStrategyResult] = useState([]);
  const [totalCurrentPnL, setTotalCurrentPnL] = useState(0);
  const [livePrice, setLivePrice] = useState(0);
  const [loading, setLoading] = useState(true);
  const [previousTrades, setPreviousTrades] = useState([]);
  const [availableStrategies, setAvailableStrategies] = useState([]);
  const [showBottomStrip, setShowBottomStrip] = useState(false);
  // const [seletedItems, setSelectedItems] = useState([]);

  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [overAllDateRange, setOverAllDateRange] = useState('');
  const [totalTradesData, setTotalTradesData] = useState('');

  const pnlData = useCallback(
    async (selectedDate) => {
      if (
        typeof selectedDate === 'object' &&
        selectedDate[0] &&
        selectedDate[1]
      ) {
        setSelectedDateRange(
          selectedDate
            .map((dateString) => dayjs(dateString).format('DD/MM/YY'))
            .join('-'),
        );
        let data = await getpnlData(selectedDate, tab);
        setTotalTradesData(data);
        setOverAllDateRange(
          `${dayjs(data?.firstTradeDate).format('DD/MM/YY')}` +
            '-' +
            `${dayjs(data?.lastTradeDate).format('DD/MM/YY')}`,
        );
      } else {
        setSelectedDateRange('Today');
        let data = await getpnlData('Today', tab);
        setTotalTradesData(data);
        setOverAllDateRange(
          `${dayjs(data?.firstTradeDate).format('DD/MM/YY')}` +
            '-' +
            `${dayjs(data?.lastTradeDate).format('DD/MM/YY')}`,
        );
      }
    },
    [tab],
  );

  useEffect(() => {
    pnlData('Today');
  }, [pnlData]);

  //Open-orders call
  useEffect(() => {
    async function callOpenOrders() {
      //this is regading the open orders comes from the api
      let { finalOrder } = await getOpenOrders(tab);
      setOpenOrder(
        finalOrder.map((item) => ({
          ...item,
          strategys: item?.strategys[0]?.name,
          vt_requests: item?.vt_requests[0]?.vt_inputs.report_name,
          livePrice: 0,
          pnl: 0,
          key: 0,
          entryPrice: item?.trigger_price,
          charges: 0,
          entryQuantity: item?.quantity,
          entryTime: item?.order_timestamp,
        })),
      );
      valueSocket.connect();
      //to get  open orders we need to emit this
      valueSocket.emit('trade_orders', {
        user_id: [`${userid?.id}_${tab === 'live' ? 'lt' : 'vt'}`],
      });
    }
    callOpenOrders();
    return () => {
      valueSocket.disconnect(); //unmount to disconnect the socket
    };
  }, [tab]);

  useEffect(() => {
    // to get subscribe strategies
    const getStrategies = async () => {
      const response = await subscribeStratagies(userid);
      const data = response?.data?.data || [];

      setAvailableStrategies(
        data.map((item) => ({ ...item, subscribed: true })),
      ); //strategies that are subscribed

      const result = [];
      // let listLength = 0;

      data.forEach((item) => {
        const stName = item.st_name;
        const list = tab === 'live' ? item.ltList : item.vtList;
        result.push(
          ...list.map((listItem) => ({ ...listItem, st_name: stName })),
        );
      });
      setStrategyResult(result); //all reports in the subscribed stratigies
    };

    const previousOrders = async () => {
      const startDate = dayjs(userDetails?.data?.created_at).format(
        'YYYY-MM-DD',
      ); //date whne the account is created
      const endDate = dayjs(new Date()).format('YYYY-MM-DD'); //today's date
      //here we are checking that user having the previous trades  data and cureetly doen't have any subsribe strategies
      const { data } = await getTradeOrders('', [startDate, endDate], 1);

      if (data) {
        setPreviousTrades(data); //it contains previous trades and and its stratgies
      }
    };

    const fetchData = async () => {
      if (userDetails) {
        setLoading(true);
        await Promise.all([getStrategies(), previousOrders()]);
        setLoading(false);
      }
    };

    fetchData();
  }, [userDetails, tab]);

  //To Emit the symbols
  useEffect(() => {
    // we are matching openorder and strategy   to get symbols
    const resultArray = strategyResult
      .map((obj1) => {
        const matchingObj2 = openOrder.find(
          (obj2) => obj2?.request_id === obj1.request_id,
        );
        return matchingObj2
          ? `${
              tab === 'live'
                ? obj1.lt_inputs.symbol.replace(/\s/g, '_')
                : obj1.vt_inputs.symbol.replace(/\s/g, '_') //modifying the symbols according to the backend
            }_${matchingObj2.strike_price}_${matchingObj2.option_type}`
          : null;
      })
      .filter(Boolean);

    if (resultArray.length > 0) {
      valueSocket.emit('option_price', {
        symbol: [...new Set(resultArray)], //to get live price data we need to emit the symbols
      });
      setTotalInvestment(
        openOrder.reduce((accumulator, obj) => {
          const product = obj.entryPrice * obj.lot_size * obj.entryQuantity;
          return accumulator + product;
        }, 0),
      );
    }
  }, [openOrder, strategyResult]);

  useEffect(() => {
    valueSocket.on('get_option_price', (livePriceData) => {
      setLivePrice(livePriceData); //here we are receiving the live price of each trades
    });
  }, []);

  // here we are  assigning live price to the trades. the tables columns are assigning here
  useEffect(() => {
    if (livePrice?.optionData) {
      setLastOrder(
        openOrder.map((item) => {
          if (item.tradingsymbol === livePrice?.optionData?.symbol) {
            const matchingBroker = strategyResult.find(
              (item1) => item1.request_id === item.request_id, // we are matching the broker
            );

            const brokername = matchingBroker?.broker_name || 'Zerodha';
            const calculateResult = calculateBrokerage(
              item.entryPrice,
              livePrice.optionData.price,
              item.lot_size * item.quantity_left,
              item.position_type,
              brokername === 'Kotak' ? 'Kotak' : 'Zerodha', // here we are calculating the broker charges
            );
            item.livePrice = livePrice?.optionData?.price; // assigning live price
            item.pnl = calculateResult[1].toFixed(2); //assigning the pnl
            item.key = new Date().getTime() + item.request_id; //to render the properly we are uisng unique key
            item.charges = calculateResult[0].toFixed(2); //broker charges
          }
          return item;
        }),
      );
    }
  }, [livePrice, openOrder, strategyResult]);

  //new trade order comes from socket
  useEffect(() => {
    valueSocket.on('get_trade_orders', (Data) => {
      setOpenOrder((prevData) => {
        let updatedData = [...prevData];
        const existingObjectIndex = updatedData.findIndex(
          (item) => item?.request_id === Data?.orderDataNew?.request_id,
        );

        if (existingObjectIndex !== -1) {
          // This is an old order
          if (Data?.orderDataNew?.quantity_left !== 0) {
            setShowBottomStrip(true);
            // Update the existing order if quantity left is > 0
            updatedData[existingObjectIndex] = {
              ...updatedData[existingObjectIndex],
              ...Data?.orderDataNew,
            };
          } else {
            // Remove the order if quantity left is 0
            setShowBottomStrip(true);
            updatedData.splice(existingObjectIndex, 1);
            setTimeout(() => {
              pnlData('Today');
            }, 2000); //for every trade exit we need to call the pnl dat api
          }
        } else {
          // Add the new order to the table and update with strategyResult data bcoz in the socket data misses some params we need to match
          const indexToUpdate = strategyResult.findIndex(
            (obj) => obj.request_id === Data?.orderDataNew.request_id,
          );
          if (indexToUpdate !== -1) {
            Data.orderDataNew.vt_requests =
              strategyResult[indexToUpdate]?.report_name;
            Data.orderDataNew.strategys =
              strategyResult[indexToUpdate]?.st_name;
            Data.orderDataNew.entryPrice = Data.orderDataNew?.trigger_price;
            Data.orderDataNew.entryQuantity = Data.orderDataNew?.quantity;
            Data.orderDataNew.entryTime = Data.orderDataNew?.order_timestamp;
          }
          updatedData.push(Data.orderDataNew);
        }

        return updatedData;
      });
    });

    return () => {
      valueSocket.off('get_trade_orders');
    };
  }, [strategyResult]);

  //   //To caluculate the total investment
  useEffect(() => {
    if (lastOrder.length === 0) {
      //if there is no data
      setTotalInvestment(0);
      setTotalCurrentPnL(0);
      valueSocket.emit('option_price', {
        symbol: [], // if there is no data we need to emit empty data
      });
    } else {
      setTotalCurrentPnL(
        lastOrder.reduce((accumulator, currentObj) => {
          return accumulator + parseFloat(currentObj.pnl);
        }, 0),
      );
    }
  }, [lastOrder]);

  const values = useMemo(
    () => ({
      lastOrder,
      totalCurrentPnL,
      totalInvestment,
      availableStrategies,
      strategyResult,
      loading,
      previousTrades,
      showBottomStrip,
      pnlData,
      overAllDateRange,
      totalTradesData,
      selectedDateRange,
    }),
    [
      lastOrder,
      totalCurrentPnL,
      totalInvestment,
      availableStrategies,
      strategyResult,
      loading,
      previousTrades,
      showBottomStrip,
      pnlData,
      overAllDateRange,
      totalTradesData,
      selectedDateRange,
    ],
  );

  return values;
}

export default usePortfolioOpenOrder;
