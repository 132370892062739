import { useEffect, useMemo, useState } from 'react';
import {
  btnPrimaryClass,
  formClass,
} from '../../../components/utils/defaultClasses';
import { Checkbox, Form, Input, Select } from 'antd';
import { BsChevronDown } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import countryList from 'react-select-country-list';
import useRayzorPayment from '../../../components/hooks/useRayzorPayment';
import SubscriptionConfirmationModal from '../subscription/SubscriptionConfirmationModal';
import Modal from '../../../components/Common/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getUserAddress } from '../../../API/profile/AddressApi';
import { useQuery } from '@tanstack/react-query';

const { Option } = Select;

export default function SubscritionBillingPaymentDetail({ userChangedPlan }) {
  const [form] = Form.useForm();
  const [saveAddress, setSaveAddress] = useState(true);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [successLoading, setSuccessLoading] = useState(false);

  const {
    handlePayment,
    showSucessModal,
    setShowSuccessModal,
    startTimer,
    showModalData,
  } = useRayzorPayment(setPaymentLoading, setSuccessLoading);

  const { data: addressList } = useQuery({
    queryKey: ['addresslits'],
    queryFn: getUserAddress,
    refetchOnMount: 'true',
  });

  console.log(addressList);

  useEffect(() => {
    if (addressList?.length > 0) {
      const primaryAddress = addressList?.find(
        (item) => item.is_primary === true,
      );
      if (primaryAddress) {
        const selectedCountry = options.find(
          (country) => country.label === primaryAddress?.country,
        );
        console.log('selectedCountry', selectedCountry);
        form.setFieldsValue({
          ...primaryAddress,
          country: selectedCountry?.value,
        });
      }
    }
  }, [addressList]);

  const options = useMemo(() => countryList().getData(), []);

  const handleData = (values) => {
    if (userChangedPlan.price == 0) {
    } else {
      setPaymentLoading(true);
      const selectedCountry = options.find(
        (country) => country.value === values.country,
      );
      const isPrimaryAddress = addressList?.some(
        (item) => item.is_primary === true,
      );
      // If there is at least one address and any address has is_primary set to true or undefined
      const formData = {
        ...values,
        country: selectedCountry ? selectedCountry.label : '',
        is_primary: isPrimaryAddress ? saveAddress : undefined,
      };

      handlePayment(formData);
    }
    form.resetFields();
  };

  const handleCheckBox = (e) => {
    setSaveAddress(e.target.value);
  };

  return (
    <>
      <div className="w-full">
        <div className="mb-[16px] flex justify-center">
          <div className="w-full max-w-[800px]">
            <h5 className="text-base font-medium text-color-primary flex flex-row items-center">
              Billing Details
            </h5>
          </div>
        </div>
        <div className="flex justify-center">
          <Form
            form={form}
            className="space-y-3"
            layout="vertical"
            onFinish={handleData}
          >
            <div className="row">
              <div className="col-lg-6">
                <Form.Item
                  label="First Name"
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'First name is required',
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter First Name"
                    name="first_name"
                    className={`${formClass}`}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  label="Last Name"
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Last name is required',
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter Last Name"
                    name="last_name"
                    className={`${formClass}`}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-12">
                <Form.Item
                  label="Select Country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: 'Country is required',
                    },
                  ]}
                  className="mb-0"
                >
                  <Select
                    showSearch
                    placeholder="Select a Country..."
                    size="large"
                    options={options}
                    className="min-h-[38px]"
                    suffixIcon={
                      <BsChevronDown
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    }
                    filterOption={(input, option) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
              </div>
              <div className="col-lg-12">
                <Form.Item
                  label="Address Line 1"
                  name="address_1"
                  rules={[
                    {
                      required: true,
                      message: 'Address Line 1 is required',
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter Address Line 1"
                    name="address_1"
                    className={`${formClass}`}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-12">
                <Form.Item
                  label="Address Line 2"
                  name="address_2"
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter Address Line 2"
                    name="address_2"
                    className={`${formClass}`}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  label="State / Province"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'State / Province is required',
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter State / Province"
                    name="state"
                    className={`${formClass}`}
                  />
                </Form.Item>
              </div>
              <div className="col-lg-6">
                <Form.Item
                  label="Post Code"
                  name="post_code"
                  rules={[
                    {
                      required: true,
                      message: 'Post code is required',
                    },
                  ]}
                  className="mb-0"
                >
                  <Input
                    placeholder="Enter Post Code"
                    name="post_code"
                    className={`${formClass}`}
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
        {addressList?.length > 0 &&
        addressList?.some((item) => item.is_primary) ? (
          // If there is at least one address and any address has is_primary set to true, render an empty string (nothing)
          ''
        ) : (
          <div className="mt-3.5 flex justify-between items-center">
            <Checkbox
              defaultChecked={saveAddress}
              className="text-sm"
              onChange={handleCheckBox}
            >
              Do you want to save this address for future billings?
            </Checkbox>
          </div>
        )}

        <div className="w-full py-3.5">
          <span className="flex h-[1px] bg-level-2 w-full"></span>
        </div>

        <div className="mt-3.5 flex justify-between items-center">
          <div>
            <span className="text-sm">
              By proceeding, you agree to our{' '}
              <Link
                // to={'/'}
                className="text-color-primaryColor1 hover:text-color-primaryColor2 underline"
              >
                Privacy Policy
              </Link>
            </span>
          </div>
          <div>
            <button
              className={`${btnPrimaryClass}`}
              htmlType="submit"
              onClick={() => form.submit()}
              // onClick={handlePayment}
            >
              Make Payment
            </button>
          </div>
        </div>
      </div>

      <SubscriptionConfirmationModal
        showSucessModal={showSucessModal}
        setShowSuccessModal={setShowSuccessModal}
        startTimer={startTimer}
        showModalData={showModalData}
        successLoading={successLoading}
      />
      <Modal
        footer={null}
        open={paymentLoading}
        setIsOpen={setPaymentLoading}
        centered
        closeIcon={false}
        title={<h3 className="">{'loading'}</h3>}
      >
        <div>
          <p className="pb-4 text-xl text-center">
            Warning!. Don't refresh the page.{' '}
          </p>
          <div className="d-flex justify-center align-items-center">
            <Box sx={{ display: 'flex' }}>
              <CircularProgress />
            </Box>
          </div>
        </div>
      </Modal>
    </>
  );
}
