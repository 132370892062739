import React, { useCallback, useState } from 'react';
import Button from '@mui/material/Button';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import Drawer from '../../Common/Drawer';
import { btnPrimaryClass, btnSecondayClass } from '../../utils/defaultClasses';
import { toast } from 'react-toastify';
import FileUpload from '../../Common/FileUpload';
import { MdLightbulbOutline, MdOutlineFileDownload } from 'react-icons/md';
import { Form, Input, Radio } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { toastSpecs } from '../../../utils/utils';
import { addCustomStrategy } from '../../../API/strategy/startegyMainPage';
import SuccessCustomStrategy from './SuccessCustomStrategy';
import CreateTicketDrawer from '../../../Pages/support-ticket/CreateTicketDrawer';

function StrategyDrawer({
  show,
  setShow,
  refetch,
  // categorySearch,
  // setLoading,
}) {
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  const [fileType, setFileType] = useState('csv');
  const [form] = Form.useForm();
  const fileList = Form.useWatch('attachments', form);
  const [showSuccessmodal, setShowSuccessModal] = useState(false);
  const [open, setOpen] = useState(false);

  const onClose = useCallback((item) => {
    setOpen(false);
  }, []);

  const handleClose = () => {
    form.resetFields();
    setShowSuccessModal(false);

    setFileType('csv');

    setShow(false);
  };

  const handleSubmit = async () => {
    let { amountRequired, attachments, instrumentName, title } =
      form.getFieldsValue();

    // setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('instrument', instrumentName);
    formData.append('min_amount_required', amountRequired);

    formData.append('user_id', userid.id);
    attachments.map((item) => formData.append('file', item.originFileObj));
    return addCustomStrategy(formData);
  };

  const mutation = useMutation({
    mutationFn: handleSubmit,
    onSuccess: (res) => {
      console.log(res, 'res');
      refetch();
      setShowSuccessModal(true);
      // form.resetFields();
      setFileType('csv');

      return toast.success('Ticket created successfully.', toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const handleFileTypeChange = (e) => {
    setFileType(e.target.value);
  };

  const submitForm = () => {
    mutation.mutate();
  };

  return (
    <>
      <Drawer
        title={showSuccessmodal ? 'Create Strategy' : 'Successfully Created'}
        onClose={handleClose}
        open={show}
        className={`gotix-drawer `}
        rootClassName={'drawer-root-className'}
        width={'540px'}
        footer={
          showSuccessmodal ? null : (
            <div className="d-flex justify-content-end gap-2  ">
              <div className="">
                <button
                  variant="primary"
                  className={`${btnSecondayClass} m-0`}
                  onClick={handleClose}
                >
                  Cancel
                </button>
              </div>
              <div className="">
                <button
                  className={`${btnPrimaryClass} m-0`}
                  type="submit"
                  onClick={() => {
                    form.submit();
                  }}
                  disabled={mutation?.isPending}
                >
                  Submit
                </button>
              </div>
            </div>
          )
        }
      >
        {showSuccessmodal ? (
          <SuccessCustomStrategy handleClose={handleClose} />
        ) : (
          <Form
            layout="vertical"
            className="space-y-3"
            form={form}
            onFinish={submitForm}
            disabled={mutation?.isPending}
          >
            <Form.Item
              label="Strategy Name"
              name="title"
              rules={[
                { required: true, message: 'Please enter Strategy Name' },
              ]}
            >
              <Input
                placeholder="Enter Strategy Name"
                // value={title}
                // onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Instrument Name"
              name="instrumentName"
              rules={[
                { required: true, message: 'Please enter Instrument Name' },
              ]}
            >
              <Input
                placeholder="Enter Instrument Name"
                // value={instrumentName}
                // onChange={(e) => setInstrumentName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Minimum Capital (₹)"
              name="amountRequired"
              rules={[
                {
                  required: true,
                  message: 'Please enter Minimum Capital Required',
                },
              ]}
            >
              <Input
                placeholder="Enter Minimum Capital Required"
                // value={amountRequired}
                // onChange={(e) => setAmountRequired(e.target.value)}
                // type="number"
              />
            </Form.Item>

            <Form.Item label="File Type" name="fileType" className="mb-3 mt-4">
              <Radio.Group onChange={handleFileTypeChange} value={fileType}>
                <Radio value="csv">CSV</Radio>
                <Radio value="doc">Doc</Radio>
                <Radio value="txt">TXT</Radio>
                <Radio value="pdf">PDF</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              name="attachments"
              className="mb-0"
              valuePropName="fileList"
              // getValueFromEvent={normFile}
            >
              <FileUpload form={form} fileList={fileList} />
            </Form.Item>

            <div className="flex flex-row items-center gap-1 my-1.5">
              <Button
                type="link"
                className="text-blue-3 flex justify-center items-center gap-0.5"
              >
                <MdLightbulbOutline />
                Need Help?
              </Button>
              <Button
                type="link"
                className="text-blue-3 flex justify-center items-center gap-0.5"
              >
                <MdOutlineFileDownload />
                <span>Download Sample</span>
              </Button>
            </div>
          </Form>
        )}
      </Drawer>
      {open && <CreateTicketDrawer onClose={onClose} open={open} />}
    </>
  );
}

export default StrategyDrawer;
