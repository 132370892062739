import { Image } from 'antd';
import React, { useState } from 'react';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../../../components/utils/defaultClasses';

export default function BillingAddressCard({
  item,
  index,
  handleEdit,
  handleDelete,
}) {
  // const [isAddAddress, setIsAddAddress] = useState(false);
  // const [addressForm, setAddressForm] = useState({
  //   first_name: '',
  //   last_name: '',
  //   country: '',
  //   address_1: '',
  //   address_2: '',
  //   state: '',
  //   post_code: '',
  //   is_primary: false,
  // });

  // const handleSubmit = () => {};

  return (
    <>
      <div className="flex items-center border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden p-3.5">
        <div className="flex justify-between gap-1.5 w-full">
          <div className="flex flex-col gap-0.5">
            <div className="flex items-center gap-2.5">
              <div className="text-base font-semibold">
                Address {index + 1}{' '}
              </div>
              {item?.is_primary && (
                <span className="flex bg-green-2/20 text-green-3 rounded-md px-1.5 py-0.5 text-xs">
                  <small>Primary</small>
                </span>
              )}
            </div>
            <div className="flexitems-center">
              <div className="text-sm text-color-secondary/70">
                {/* Ap #285-7193 Ullamcorper Avenue Amesbury HI 93373 US */}
                {item?.address_1},&nbsp;
                {item?.state},&nbsp;
                {item?.country}-{item?.post_code}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2 shrink-0">
            <div>
              <button
                className={`${btnSecondayClass}`}
                onClick={() => handleDelete(item)}
              >
                <small>Delete</small>
              </button>
            </div>
            <div>
              <button
                className={`${btnSecondayClass}`}
                onClick={() => {
                  // setIsAddAddress(true);
                  handleEdit(item);
                }}
              >
                <small>Edit</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function BillingAddressAddCard({ setIsAddAddress }) {
  // const [isAddAddress, setIsAddAddress] = useState(false);
  // const [addressForm, setAddressForm] = useState({
  //   first_name: '',
  //   last_name: '',
  //   country: '',
  //   address_1: '',
  //   address_2: '',
  //   state: '',
  //   post_code: '',
  //   is_primary: false,
  // });
  // const handleSubmit = (values) => {
  //   console.log(values);
  // };
  return (
    <>
      <div className="flex items-center border-[1px] border-dashed border-blue-2 bg-blue-2/10 rounded-xl overflow-hidden p-3.5">
        <div className="flex justify-between gap-1.5 items-center w-full">
          <div className="flex flex-col gap-2.5">
            <div className="flex flex-col leading-tight">
              <div className="text-sm font-semibold">
                This is a very important note!
              </div>
              <span className="text-xs text-color-secondary/70">
                Writing headlines for blog posts is much science and probably
                cool audience
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 shrink-0">
            <div>
              <button
                className={`${btnPrimaryClass} text-sm`}
                onClick={() => {
                  setIsAddAddress(true);
                }}
              >
                New Address
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
