import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
// import Tooltip from '../../Common/Tooltip';
import Tooltip from '../../../components/Common/Tooltip';
import { formatINR } from '../../../utils/utils';

export default function StrategyViewCard({
  icon,
  title,
  subtitle,
  tooltipData,
}) {
  return (
    <>
      <div className="flex items-center border-[1px] border-dashed border-level-2 dark:border-level-2 rounded-xl overflow-hidden p-3.5 shadow-md shadow-color-base-2/70 dark:shadow-color-base-2/20 dark:bg-color-base-1 h-full">
        <div className="flex gap-2 w-full items-center">
          <div>{icon}</div>
          <div>
            <div>
              <span className="text-color-primary font-semibold flex items-center gap-1">
                {title}
                <span className='text-zinc-400 text-sm'>
                <Tooltip title={tooltipData} placement="top" arrow>
                  <AiOutlineQuestionCircle />
                </Tooltip>
                </span>
              </span>

              <span className="text-color-secondary text-sm flex items-center gap-1 -mt-1">
                <span className="text-color-secondary/80">
                  <span>{subtitle}</span>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
