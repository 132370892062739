import React from 'react';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import Button from './UI/Button';

import { Form, FormGroup, Label, Input } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const OTP = () => {
  const history = useNavigate();
  return (
    <>
      <div className="login">
        <div className="login-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              history('/update-password');
            }}
            action=""
          >
            <FormGroup>
              <Label for="newPassword">OTP</Label>
              <Input
                className="form-input"
                id="email"
                name="email"
                placeholder="Enter OTP"
                type="text"
              />
            </FormGroup>

            <div className="d-grid gap-2 mt-5">
              <Button variant="success" size="lg" className="form-button">
                SUBMIT
              </Button>
            </div>
          </Form>
          <div className="text-center mt-3">
            <Link to="/">Back To Login</Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default OTP;
