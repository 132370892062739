import { Select, Switch } from 'antd';
import React from 'react';
import { BsChevronDown } from 'react-icons/bs';
import config from '../../../Config/config';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from '../../Common/Modal';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import { useDispatch, useSelector } from 'react-redux';
import { userProfileDetails } from '../../../features/profile/profilePlanSlice';
import useChangeEmail from '../../hooks/useChangeEmail';
import useChangePassword from '../../hooks/useChangePassword';

function SecuritySettingsTabs() {
  const token = localStorage.getItem('token');
  const { profileDetailsOfUser } = useSelector((state) => state.profileDetails);

  const {
    isEmailModalVisible,
    getCloseModal,
    showEmailVerify,
    getEmailmodal,
    setIsEmailModalVisible,
  } = useChangeEmail(profileDetailsOfUser);

  const {
    getPasswordModal,
    isModalVisible,
    closePasswordModal,
    setIsModalVisible,
  } = useChangePassword();

  const userid = mydecodedTokenFunction(localStorage.getItem('token'));

  var dispatch = useDispatch();

  // const [ButtonLoader, setButtonLoader] = useState(false);

  const updateProfile = (payload) => {
    axios
      .put(`${config.base_url}${config.update_user}/${userid.id}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        dispatch(userProfileDetails(res?.data?.data));
        if (res.data.message === 'User data update successfully.') {
          return toast.success('updated successfully.', {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const switchChange = (checked, label) => {
    if (label === 'activeLogs') {
      updateProfile({ save_activity_logs: checked });
    } else if (label === 'unusualLogs') {
      updateProfile({ unusual_activity: checked });
    } else {
      updateProfile({ auth_2fa: checked });
    }
  };

  return (
    <>
      <div className="User__Details__Card">
        <div className="Details__Card__head">
          <h2 className="strtgy__ttl">Security Setting</h2>
        </div>
        <div className="Details__Card__Body">
          <span className="mt-3 mb-3 d-flex primary__text__blue">
            These settings are helps you keep your account Secure.
          </span>

          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex setting_grd">
                <p>Save My Activity Logs</p>
                <span>
                  Save your all activity logs includong unsual activity detected
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <Switch
                // defaultChecked={activeLogs}
                checked={profileDetailsOfUser?.save_activity_logs}
                onChange={(checked) => {
                  return switchChange(checked, 'activeLogs');
                }}
              />
            </div>
          </div>
          <div class="row separate__between"></div>
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex setting_grd">
                <p>Email me if encounter unusual activity</p>
                <span>
                  You will get email notification whenever encounter invalid
                  login activity
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <Switch
                checked={profileDetailsOfUser?.unusual_activity}
                onChange={(checked) => {
                  return switchChange(checked, 'unusualLogs');
                }}
              />
            </div>
          </div>
          <div class="row separate__between"></div>
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex setting_grd">
                <p>Current Email : {profileDetailsOfUser?.email}</p>
                <span>
                  Update your current email address to new email address.
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <button
                className="btn_default"
                onClick={() => setIsEmailModalVisible(true)}
              >
                Change Email
              </button>
            </div>
          </div>

          <div class="row separate__between"></div>
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex setting_grd">
                <p>Change Password</p>
                <span>Set a unique password to protect your account.</span>
              </div>
            </div>
            <div className="col-lg-3">
              <button
                className="btn_default"
                onClick={() => setIsModalVisible(true)}
              >
                Change Password
              </button>
            </div>
          </div>

          <div class="row separate__between"></div>
          <div className="row">
            <div className="col-lg-9">
              <div className="d-flex setting_grd">
                <p>
                  2FA Authentication{' '}
                  <span
                    className={`${
                      profileDetailsOfUser?.auth_2fa
                        ? 'bg-green-2/15 text-green-3'
                        : 'bg-red-2/15 text-red-3'
                    } p-0.5 px-1.5 rounded-md leading-none`}
                  >
                    <small
                      className={`${
                        profileDetailsOfUser?.auth_2fa
                          ? 'text-green-3'
                          : 'text-red-3'
                      }`}
                    >
                      {!profileDetailsOfUser?.auth_2fa === true
                        ? 'Disabled'
                        : 'Enabled'}
                    </small>
                  </span>
                </p>
                <span>
                  Enhance security with an extra login verification step.
                </span>
              </div>
            </div>
            <div className="col-lg-3">
              <Switch
                // defaultChecked={activeLogs}
                checked={profileDetailsOfUser?.auth_2fa}
                onChange={(checked) => {
                  return switchChange(checked, 'Auth');
                }}
              />
            </div>
          </div>

          <div class="row separate__between"></div>
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-9">
                  <div className="d-flex setting_grd">
                    <p>Session Inactivity Preference</p>
                    <span>Set your activity Preference.</span>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="max-w-[350px]">
                    <Select
                      defaultValue="30"
                      placeholder={
                        <>
                          <span className="text-slate-400">
                            Select a Country...
                          </span>
                        </>
                      }
                      size="lg"
                      // onChange={handleChange}
                      options={[
                        {
                          value: '1',
                          label: '1 Day',
                        },
                        {
                          value: '7',
                          label: '7 Week',
                        },
                        {
                          value: '30',
                          label: '1 Month',
                        },
                      ]}
                      className={'min-h-[38px]'}
                      disabled
                      suffixIcon={
                        <>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                            <BsChevronDown
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </>
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={isEmailModalVisible}
        setIsOpen={getCloseModal}
        maxWidth={`w-full max-w-lg`}
        preventClosingOutsideClick
        // fullScreen="pl-0 pe-0 "
        header={
          <div className="flex flex-col justify-center py-2">
            <div className="w-full flex items-center">
              <span className="text-color-primary text-base font-medium">
                {!showEmailVerify
                  ? 'Change Email Address'
                  : 'Verify your Email Address'}
              </span>
            </div>
          </div>
        }
        footer={null}
        // bodyHeight={'70vh'}
        bodyClass={
          'w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full'
        }
      >
        <div className="row justify-content-center">{getEmailmodal()}</div>
      </Modal>
      {/* Change password modal */}
      <Modal
        open={isModalVisible}
        setIsOpen={closePasswordModal}
        maxWidth={`w-full max-w-lg`}
        preventClosingOutsideClick
        // fullScreen="pl-0 pe-0 "
        header={
          <div className="flex flex-col justify-center py-2">
            <div className="w-full flex items-center">
              <span className="text-color-primary text-base font-medium">
                Change Password
              </span>
            </div>
            {/* <small className="d-block text-red-2 text-[10px]">
              {passError ? passError : null}
            </small> */}
          </div>
        }
        footer={null}
        bodyClass={
          'w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full'
        }
      >
        <div className="space-y-4">
          <div>
            <div className="row justify-content-center">
              {getPasswordModal()}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default React.memo(SecuritySettingsTabs);
