import { useQuery } from '@tanstack/react-query';
import { Dropdown, Menu, Space } from 'antd';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { getPaymentList } from '../../API/profile/billinghistory';
import dayjs from 'dayjs';
import { MoreOutlined } from '@ant-design/icons';
import useToGetId from './useToGetId';

function useInvoiceDetails() {
  const [renderTrigger, setRenderTrigger] = useState(false);
  const [page, setPage] = useState(1);
  const {
    userid: { id: id },
  } = useToGetId();

  const readyToPrint = (item, page) => {
    sessionStorage.setItem('printDetails', JSON.stringify(item));
    const url = `${window.location.origin}/invoice/${page}`;
    window.open(url, '_blank');
  };

  const contentToPrint = useRef(null);

  const handleDownload = async (item) => {
    sessionStorage.setItem('printDetails', JSON.stringify(item));

    setRenderTrigger(true);

    setTimeout(() => {
      const input = contentToPrint.current;

      if (input) {
        html2canvas(input).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('invoice.pdf');
          setRenderTrigger(false);
        });
      }
    }, 1000);
  };

  const handleTableChange = useCallback((newPagination) => {
    console.log(newPagination.current);
    setPage(newPagination.current);
  }, []);

  //table actions memu
  const menu = useCallback(
    (record) => (
      <Menu>
        <Menu.Item key="1" onClick={() => readyToPrint(record, 'view')}>
          View
        </Menu.Item>
        <Menu.Item key="2" onClick={() => handleDownload(record)}>
          Download
        </Menu.Item>
        <Menu.Item key="3" onClick={() => readyToPrint(record, 'print')}>
          Print
        </Menu.Item>
      </Menu>
    ),
    [],
  );

  const { data: paymentListData, isLoading } = useQuery({
    queryKey: ['paymentList', { id, page }],
    queryFn: getPaymentList,
    refetchOnWindowFocus: false,
  });

  const dataSource = useMemo(
    () =>
      paymentListData?.data?.map((item) => ({
        key: item._id,
        Date: dayjs(item?.created_at).format('MMM DD,YYYY'),

        PaymentId: <div className="">{item?.payment_details.id}</div>,
        invoice_number: <p className="">{item?.invoice_number}</p>,
        Amount: <div className="">₹{item?.PlanData?.price}</div>,
        PlanName: <div className="">{item?.PlanData?.plan_name}</div>,
        PaymentStatus: <div className="">Success</div>,
        actions: (
          <Space size="middle">
            <Dropdown
              overlay={menu(item)}
              placement="bottomRight"
              overlayClassName={'dropdownOverlay dropdownDark'}
            >
              <MoreOutlined style={{ cursor: 'pointer' }} />
            </Dropdown>
          </Space>
        ),
      })),
    [paymentListData],
  );

  const columns = useMemo(
    () => [
      {
        title: 'Date',
        dataIndex: 'Date',
        key: 'Date',
      },
      {
        title: 'Payment Id',
        dataIndex: 'PaymentId',
        key: 'PaymentId',
      },
      {
        title: 'Invoice number',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
      },
      {
        title: 'Amount',
        dataIndex: 'Amount',
        key: 'Amount',
      },
      {
        title: 'Plan Name',
        dataIndex: 'PlanName',
        key: 'PlanName',
      },
      {
        title: 'Payment Status',
        dataIndex: 'PaymentStatus',
        key: 'PaymentStatus',
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
      },
    ],
    [],
  );

  const value = useMemo(() => {
    return {
      handleTableChange,
      renderTrigger,
      columns,
      dataSource,
      isLoading,
      contentToPrint,
      paymentListData,
    };
  }, [
    handleTableChange,
    renderTrigger,
    columns,
    dataSource,
    isLoading,
    contentToPrint,
    paymentListData,
  ]);

  return value;
}

export default useInvoiceDetails;
