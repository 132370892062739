import React from 'react';
import { btnSecondayClass } from '../../utils/defaultClasses';
import { DeleteOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import Tooltip from '@mui/material/Tooltip';
import { AiOutlineDownload } from 'react-icons/ai';

function BackTestingTabs({
  toggleState,
  toggleTab,
  virtualData,
  liveData,
  setBackTestingModalShow,
  isMobile,
  //   handleDateChange,
  plan_type,
  setShowBacktestDrawer,
  showBacktestDrawer,
  data = {},
  showAccordian,
  setShowAccordian,
}) {
  const { RangePicker } = DatePicker;

  return (
    <div className="">
      <div className="row px-0 strtgy__table__box mt-1.5 lg:mt-3 bx_shadow sticky">
        <div className="col-5 col-lg-6 pl-0">
          <ul className="strtgy__tbl">
            <li
              onClick={() => toggleTab('overview')}
              className={toggleState === 'overview' ? 'active' : 'tabs'}
            >
              <span>Config</span>
            </li>
            {/* <li
            onClick={() => toggleTab('graphs')}
            className={toggleState === 'graphs' ? 'active' : 'tabs'}
          >
            <span>Graphs</span>
          </li> */}
            <li
              onClick={() => toggleTab('reports')}
              className={toggleState === 'reports' ? 'active' : 'tabs'}
            >
              <span>Reports</span>
            </li>
            <li
              onClick={() => toggleTab('trades')}
              className={toggleState === 'trades' ? 'active' : 'tabs'}
            >
              <span>Trades</span>
            </li>
          </ul>
        </div>
        <div className="col-7 col-lg-6 d-flex justify-content-end align-items-center gap-2">
          {/* <div>
            <StatsUsageCardSmall
              icon={
                <>
                  <MdAutoGraph className="text-xl text-color-secondary/80" />
                </>
              }
              percent={75}
              title={'Credits'}
              acquire={0}
              total={'2'}
              infoText={'No credits are available for free accounts'}
              size={30}
              //   strokeColor={'rgb(64 116 249)'}
            />
          </div> */}
          {/* {isMobile === false ? (
          toggleState !== 'overview' && null
        ) : (
          // <span>+ Add Virtual Trade</span>
          <button className="btn__create__strategy align-items-center d-flex gap-1">
            <span className="mb__btn">
              <PlusOutlined />
            </span>
          </button>
        )} */}

          {toggleState !== 'live' &&
            toggleState !== 'overview' &&
            // toggleState !== 'backtesting' &&
            (plan_type !== 'free' || plan_type !== 'Signup Trial') && (
              <>
                {/* <div className="">
                  <CustomDatePicker onChange={() => {}} />
                </div> */}
                {/* <button className={`${btnSecondayClass} py-2.5`}>
                  <Tooltip title="Print" placement="top">
                    <PrinterOutlined />
                  </Tooltip>
                </button> */}
                {data?.dataRequest?.status === 'Generated' && (
                  <>
                    <button className={`${btnSecondayClass} py-2.5`}>
                      <Tooltip title="Print" placement="top">
                        <AiOutlineDownload />
                      </Tooltip>
                    </button>

                    <button className={`${btnSecondayClass} py-2.5`}>
                      <Tooltip title="Delete" placement="top">
                        <DeleteOutlined />
                      </Tooltip>
                    </button>
                  </>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
}

export default React.memo(BackTestingTabs);
