import { useState, useEffect, useCallback } from 'react';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import StrategyDesktopTableV1 from '../components/UI/Strategy/StrategyDesktoptableV1';
import StrategyMobileTableV1 from '../components/UI/Strategy/StrategyMobileTablev1';
import StrategyDrawer from '../components/UI/Strategy/StrategyDrawer';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../components/utils/defaultClasses';
import PopoverDropdown from '../components/Common/PopoverDropdown';
import StrategyFilter from '../components/UI/StrategyDetails/StrategyFilter';
import useStrategyList from '../components/hooks/useStrategyList';
import { Button, Modal } from 'react-bootstrap';
import { CrossIcon } from '../assets/Icon';
import { useMutation } from '@tanstack/react-query';
import { deleteRequest } from '../API/strategy/strategyPagePermissionApi';
import { useNavigate } from 'react-router-dom';
import Tooltip from '../components/Common/Tooltip';
import GetStartedCard from '../components/Common/GetStartedCard';
import StatusStrips from '../components/Common/StatusStrips';
import RefreshButton from '../components/Common/RefreshButton';
import NeedHelpButtonDrawer from '../components/UI/NeedHelpButtonDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { showPlansModal } from '../features/profile/profilePlanSlice';
import { MdLock } from 'react-icons/md';

const StrategyList = () => {
  let { premium_strategies } = useSelector((state) => state.userCreditsDetails);
  const [isMobile, setIsMobile] = useState(false);
  const [showCustomStrategy, setShowCustomStrategy] = useState(false);
  const [deleteID, setDeleteID] = useState('');
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [headerTopTape, setHeaderTopTape] = useState({
    top: false,
    middle: false,
    bottom: false,
  });
  const [showGetStarted, setShowGetStarted] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    strategyRefech,
    strategyDetails,
    pagination,
    categorySearch,
    setCategorySearch,
    handleTableChange,
    isPending,
  } = useStrategyList();

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleDelete = useCallback((id) => {
    setDeleteID(id);
    setConfirmDelete(true);
  }, []);

  const deleteStrategy = useMutation({
    mutationFn: () => deleteRequest(deleteID),
    onSuccess: (res) => {
      strategyRefech();
      setConfirmDelete(false);
    },
    onError: (err) => {},
  });

  const getButtonClass = useCallback(
    (buttonName) => {
      const baseClasses =
        'px-3.5 font-medium duration-150 ease-in-out min-w-[80px] disabled:opacity-60';
      const activeClasses = 'bg-blue-2/15 text-blue-2';
      const inactiveClasses =
        'hover:bg-color-base-2 text-color-secondary hover:text-color-primary ';

      return categorySearch === buttonName
        ? `${baseClasses} ${activeClasses}`
        : `${baseClasses} ${inactiveClasses}`;
    },
    [categorySearch],
  );

  useEffect(() => {
    if (
      strategyDetails?.data?.length > 0 &&
      !localStorage.getItem('StrategyTopStripVisible')
    ) {
      setHeaderTopTape((prevHeaderTopTape) => ({
        ...prevHeaderTopTape,
        top: true,
      }));
    }

    let middleStrap = strategyDetails?.data?.find(
      (item) => !item.added_by && item?.status === 'processing',
    );
    if (middleStrap && !localStorage.getItem('StrategyMiddleStripVisible')) {
      setHeaderTopTape((prevHeaderTopTape) => ({
        ...prevHeaderTopTape,
        middle: true,
      }));
    }
  }, [strategyDetails]);

  useEffect(() => {
    if (
      premium_strategies?.strategies_total === 0 &&
      !localStorage.getItem('StrategybottomStripVisible')
    )
      setHeaderTopTape((prevHeaderTopTape) => ({
        ...prevHeaderTopTape,
        bottom: true,
      }));
  }, [premium_strategies]);

  // console.log(  let { premium_strategies } = useSelector((state) => state.userCreditsDetails););

  return (
    <div className="left__bar">
      <div className="mb-3">
        {headerTopTape?.top && (
          <div className="row px-0 mt-2">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="exclusive"
                  title="Exclusive Features"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        top: false,
                      }));
                      localStorage.setItem('StrategyTopStripVisible', true);
                    },
                  }}
                  description="It's seems you have subscribe an strategy you start your automated trade journey by backtesting data,virtual trade and live trade"
                />
              </div>
            </div>
          </div>
        )}
        {headerTopTape?.middle && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="update"
                  title="Important Updates"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        middle: false,
                      }));
                      localStorage.setItem('StrategyMiddleStripVisible', true);
                    },
                  }}
                  description="Once your created strategy is processed and active then you start your trade for backtest,virtual trade and live trade"
                />
              </div>
            </div>
          </div>
        )}
        {headerTopTape?.bottom && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="warning"
                  title="Alert Warning"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        bottom: false,
                      }));
                      localStorage.setItem('StrategybottomStripVisible', true);
                    },
                  }}
                  description="Your current plan doesn't have any credit to subscribe the premium strategy,you can add credit by clicking.Click to buy"
                  sideBtn={{
                    show: true,
                    click: () => dispatch(showPlansModal(true)),
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="d-flex row" style={{ marginRight: '0px' }}>
        {/* {(strategyDetails?.data?.length > 0 || categorySearch !== 'all') && ( */}
        <>
          <div className="row mb-row">
            <div className="px-0 mb-3.5">
              <div className="flex gap-2">
                <div className="">
                  <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 256 256"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                      className="shrink-0"
                    >
                      <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
                    </svg>
                  </span>
                </div>
                <div className="">
                  <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                    List of Strategy
                  </h4>
                  <span className="flex text-xs text-color-secondary/70">
                    Manage and monitor all your Strategies.
                  </span>
                </div>
              </div>
            </div>
          </div>
          {strategyDetails?.data?.length === 0 && categorySearch === 'all' && (
            <div className="row px-0 mt-3">
              {showGetStarted && (
                <div className="flex justify-center items-center w-full">
                  <GetStartedCard
                    firstBtn={{
                      title: 'Choose a strategy',
                      show: true,
                      onClick: () => {
                        navigate('/marketplace');
                        // setShowChooseStrategy(true);
                      },
                    }}
                    secondBtn={{
                      title: 'Custom strategy',
                      show: true,
                      onClick: () => {
                        setShowCustomStrategy(true);
                      },
                    }}
                    handleClose={() => setShowGetStarted(false)}
                  />
                </div>
              )}

              {/* <div className="col-12 px-0">
                <GetStartedCard
                  firstBtn={{
                    title: 'Choose a strategy',
                    show: true,
                    onClick: () => {
                      navigate('/marketplace');
                      // setShowChooseStrategy(true);
                    },
                  }}
                  secondBtn={{
                    title: 'Custom strategy',
                    show: true,
                    onClick: () => {
                      setShowCustomStrategy(true);
                    },
                  }}
                />
              </div> */}
            </div>
          )}
          <div className="row align-items-center mb-3 mb-row mt-3">
            <div className="col-5 col-lg-6 px-0">
              <div className="flex">
                <div className="flex items-center rounded-md overflow-hidden border-[1px] border-level-2 divide-x divide-level-2 font-normal ">
                  <button
                    className={getButtonClass('all')}
                    disabled={
                      strategyDetails?.data?.length === 0 &&
                      categorySearch === 'all'
                    }
                    onClick={() => {
                      setCategorySearch('all');
                    }}
                  >
                    All
                  </button>
                  <button
                    className={getButtonClass('admin')}
                    disabled={
                      strategyDetails?.data?.length === 0 &&
                      categorySearch === 'all'
                    }
                    onClick={() => {
                      setCategorySearch('admin');
                    }}
                  >
                    Admin
                  </button>
                  <button
                    className={getButtonClass('created')}
                    onClick={() => setCategorySearch('created')}
                    disabled={
                      strategyDetails?.data?.length === 0 &&
                      categorySearch === 'all'
                    }
                  >
                    Created
                  </button>
                </div>
              </div>
            </div>
            <div className="col-7 col-lg-6 d-flex justify-content-end items-center px-0 gap-1.5">
              <div className="flex items-center gap-2">
                <div>
                  <NeedHelpButtonDrawer />
                </div>
                {strategyDetails?.data?.length !== 0 && (
                  <>
                    <Tooltip
                      title={`Explore and subscribe to admin strategies`}
                      placement="top"
                      arrow
                    >
                      <button
                        className={`${btnSecondayClass}`}
                        onClick={() => {
                          navigate('/marketplace');
                        }}
                      >
                        <span className="d-none d-lg-flex">
                          Choose a Strategy
                        </span>
                      </button>
                    </Tooltip>

                    <Tooltip
                      title={`Create your custom trading strategy`}
                      placement="top"
                      arrow
                    >
                      <button
                        className={`${btnPrimaryClass}`}
                        onClick={() => {
                          setShowCustomStrategy(true);
                        }}
                      >
                        <PlusOutlined />
                        <span className="d-none d-lg-flex">
                          Custom Strategy
                        </span>
                      </button>
                    </Tooltip>
                    <PopoverDropdown content={<StrategyFilter />} />
                    <button className={`${btnSecondayClass} py-2.5`}>
                      <DeleteOutlined />
                    </button>
                    <RefreshButton />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="row mb-row">
            <div className="col-lg-12 px-0 strategy__tbl__data">
              {isMobile === false ? (
                <StrategyDesktopTableV1
                  strategyDetails={strategyDetails?.data}
                  deleteRequest={handleDelete}
                  handleTableChange={handleTableChange}
                  pagination={pagination}
                  loading={isPending}
                  categorySearch={categorySearch}
                />
              ) : (
                <StrategyMobileTableV1
                  strategyDetails={strategyDetails?.data}
                  deleteRequest={handleDelete}
                  handleTableChange={handleTableChange}
                  pagination={pagination}
                  loading={isPending}
                />
              )}
            </div>
          </div>
        </>
      </div>
      <Modal
        show={confirmDelete}
        onHide={() => setConfirmDelete(false)}
        backdrop="static"
        keyboard={false}
        centered
        contentClassName="bg-color-base-1 rounded-lg overflow-hidden"
      >
        <Modal.Body className="relative bg-color-base-1 rounded-lg overflow-hidden">
          <div className="d-flex align-items-center justify-content-between">
            <Modal.Title>Delete Confirmation</Modal.Title>
            <div className="text-end mb-4">
              <span
                onClick={() => setConfirmDelete(false)}
                className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
              >
                <CrossIcon className="text-base text-color-primary" />
              </span>
            </div>
          </div>
          <div className="alert alert-danger mt-4">
            Are you sure you want to delete?
          </div>
          <div className="d-flex align-items-center justify-content-end mt-4">
            <Button variant="default" onClick={() => setConfirmDelete(false)}>
              Cancel
            </Button>
            <Button variant="danger" onClick={() => deleteStrategy.mutate()}>
              Delete
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <StrategyDrawer
        show={showCustomStrategy}
        setShow={setShowCustomStrategy}
        refetch={strategyRefech}
      />
    </div>
  );
};
export default StrategyList;
