import React, { useState, useEffect, useCallback } from 'react';
import Tooltip from 'antd/es/tooltip';
import axios from 'axios';
import config from '../Config/config';
import { toast } from 'react-toastify';
import { btnSecondayClass } from './utils/defaultClasses';
import { useDispatch } from 'react-redux';
import {
  openLiveReportFields,
  openVirualReportFields,
} from '../features/strategyReport/strategyDataDetailSlice';
import { LogActivityDrawerWithButton } from './Common/logs/LogActivityDrawer';
import { MdCheck, MdClose } from 'react-icons/md';
import { updateRequest } from '../API/strategy/strategyDetail';
import { useMutation } from '@tanstack/react-query';
import useToGetId from './hooks/useToGetId';

const ReportIcon = ({
  // setTradeSettingSwitch,
  tab,
  reportStatus,
  playPauseID,
  getPauseIcon = () => { },
  setHeaderTopTape = () => { },
  upDateReport,
  strategyData,
  strategy_id,
  id,
}) => {
  const [showPlayButton, setShowPlayButton] = useState(true);
  const dispatch = useDispatch();
  let getuserBots = localStorage.getItem('token');
  const { userid } = useToGetId();
  //To get Status of play-pause
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${config.Kill_trade_base_url}${config.play}?request_id=${playPauseID}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );
        const result = response.data.data.play;
        setShowPlayButton(result);
      } catch (error) {
        console.log(error);
      }
    }
    if (playPauseID) {
      fetchData();
    }
  }, [getuserBots, playPauseID]);

  //Play-Pause call
  const handlePlayPause = async (status) => {
    try {
      const response = await axios.get(
        `${config.Kill_trade_base_url}${config.play}?request_id=${playPauseID}`,
        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      );
      if (response?.data?.data?.play) {
        setShowPlayButton(response?.data?.data?.play);
      }
      if (response.data.data.day_status && response?.data?.data?.market_hours) {
        const payload = {
          request_id: playPauseID,
          play: status,
        };
        axios
          .post(`${config.Kill_trade_base_url}${config.play}`, payload, {
            headers: { Authorization: `Bearer ${getuserBots}` },
          })
          .then((res) => {
            console.log(res);
            if (status) {
              return toast.success('Continue the Trade Successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
            } else {
              !localStorage.getItem('reportTopLastStrip') &&
                setHeaderTopTape({
                  top: false,
                  middle: false,
                  topLast: true,
                  bottom: false,
                });
              return toast.success('Pause the Trade Successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch(function (err) {
            console.log(err);
          });
        setShowPlayButton(status);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //update the toogle switch
  const updateRequestStrategy = useMutation({
    mutationFn: ({ finalPayload, toggleState }) => {
      return updateRequest(finalPayload, toggleState);
    },
    onSuccess: async (res, { finalPayload }) => {
      upDateReport();
      // res.data.message
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
    onError: (error) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      // console.log(error.response.data.message);
    },
  });

  const handleUpdate = useCallback(
    (status) => {
      if (strategyData?.subscribe_by_user) {
        let vtPayload = {
          vt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let ltPayload = {
          lt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let finalPayload = tab === 'LT' ? ltPayload : vtPayload;
        let toggleState = tab === 'LT' ? 'live' : 'virtual';

        updateRequestStrategy.mutate({ finalPayload, toggleState });
      }
    },
    [strategy_id, id, strategyData],
  );

  return (
    <div className="pe-0">
      <ul className="flex justify-end items-center gap-1.5">
        <li>
          <button
            className={`${reportStatus ? ' bg-red-3' : 'bg-green-3 '
              } flex justify-center items-center py-[7px] px-[15px] text-[13px] text-whiteNew-0 rounded-[6px] gap-1 font-normal`}
            onClick={() => {
              handleUpdate(reportStatus ? false : true);
            }}
          >
            {reportStatus ? <MdClose /> : <MdCheck />}

            <span>{reportStatus ? 'InActive' : 'Active'}</span>
          </button>
        </li>
        <li>
          <div className="py-0.5 hidden lg:flex px-1.5">
            <span className="flex w-1 h-7 bg-level-2"></span>
          </div>
        </li>
        <li>
          <Tooltip title="Order Setting" placement="bottom">
            <button
              className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
              onClick={() =>
                tab === 'VT'
                  ? dispatch(openVirualReportFields(true))
                  : dispatch(openLiveReportFields(true))
              }
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0 1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624 502c0 29.9-11.7 58-32.8 79.2z"></path>
              </svg>
            </button>
          </Tooltip>
        </li>
        <li>
          <LogActivityDrawerWithButton button={true} />
        </li>
        <li>
          {reportStatus && (
            <div>
              {showPlayButton ? (
                <Tooltip placement="bottom" title="Pause">
                  <button
                    className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                    disabled={getPauseIcon()}
                    onClick={() => {
                      handlePlayPause(false);
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg text-red-3"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M6 3.5a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5zm4 0a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0V4a.5.5 0 0 1 .5-.5z"></path>
                    </svg>
                  </button>
                </Tooltip>
              ) : (
                <Tooltip placement="bottom" title="Generate Report">
                  <button
                    className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                    onClick={() => {
                      handlePlayPause(true);
                    }}
                    disabled={getPauseIcon()}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 16 16"
                      height="1em"
                      width="1em"
                      className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg text-green-3"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"></path>
                    </svg>
                  </button>
                </Tooltip>
              )}
            </div>
          )}
        </li>
      </ul>
    </div>
  );
};

export default React.memo(ReportIcon);
