import React from 'react';

export default function AccordianSmooth({ showAccordian, children }) {
  return (
    <>
      <div>
        <div
          className={`w-full accordionHeight ${
            showAccordian ? 'accordion-content opacity-100' : ''
          } h-full`}
        >
          {children}
        </div>
      </div>
    </>
  );
}
