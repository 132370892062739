import React from 'react';
import { HiOutlineBellAlert, HiOutlineBellSlash } from 'react-icons/hi2';
import Tooltip from './Tooltip';

export default function SubscribeBellIcon({ item }) {
  return (
    <>
      {item?.subscribed ? (
        <Tooltip title={<>Subscribed Strategy</>}>
          <div className=" bg-blue-3 text-whiteNew-0 rounded-full text-sm p-[6px] w-[25px] h-[25px] relative">
            <HiOutlineBellAlert className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        </Tooltip>
      ) : (
        <Tooltip title={<>Unsubscribed Strategy</>}>
          <div className="bg-gray-400 text-whiteNew-0 rounded-full text-sm p-[6px] w-[25px] h-[25px] relative">
            <HiOutlineBellSlash className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" />
          </div>
        </Tooltip>
      )}
    </>
  );
}
