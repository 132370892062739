import axios from 'axios';
import config from '../../Config/config';
import { apiHeader } from '../../utils/utils';

const { json_header } = apiHeader();

export async function getStrategyDetails({ queryKey }) {
  const [_key, { stratatergyId }] = queryKey;
  const res = await axios.get(
    `${config.base_url}${config.get_strategy}/${stratatergyId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function strategyVirtualDetails({ queryKey }) {
  const [_key, { virtualPage, payload, vstatus }] = queryKey;
  const res = await axios.post(
    `${config.base_url}${config.list_virtual_trade}/list?page=${virtualPage}&limit=10${vstatus ? `&status=${vstatus}` : ""}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function strategyLiveDetails({ queryKey }) {
  const [_key, { livePage, payload, lstatus }] = queryKey;
  const res = await axios.post(
    `${config.base_url}${config.list_live_trade}/list?page=${livePage}&limit=10${lstatus ? `&status=${lstatus}` : ""}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}
export async function userTradeList({ queryKey }) {
  const [_key, { tradePage, payload, tradeOrderDate }] = queryKey;
  const res = await axios.get(
    `${config.base_url}${config.user_trade_list}?user_id=${payload.dashboard_user_id}&page=${tradePage}&limit=10&start=${tradeOrderDate[0] || ""}&end=${tradeOrderDate[1] || ""}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data;
}

export async function strategyBackTesingDetails({ queryKey }) {
  const [_key, { backTestPage, strategy_id, userid, backtestingPage }] = queryKey;
  const res = await axios.get(
    `${config.base_url}${config.get_request_list}/${strategy_id}/${userid.id}?page=${backtestingPage}&limit=10`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function updateRequest(finalPayload, toggleState) {
  const res = await axios.put(
    toggleState === 'live'
      ? `${config.base_url}${config.live_trade}`
      : toggleState === "virtual" ? `${config.base_url}${config.virtual_trade}` : `${config.base_url}${config.update_backtesting_request}/${finalPayload?.st_id}`,
    finalPayload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}
