import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Tabs } from 'antd';
import ModalInputFields from '../components/UI/ModalInputFields';
import axios from 'axios';
import config from '../Config/config';
import mydecodedTokenFunction from './decodetoken';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../components/utils/defaultClasses';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  createLiveStrategyRequest,
  updateLiveStrategyRequest,
} from '../API/strategy/strategyPagePermissionApi';
import useLiveStrategyDetails from '../components/hooks/useLiveStrategyDetails';
import { useNavigate, useParams } from 'react-router-dom';
import Drawer from '../components/Common/Drawer';
import { useDispatch, useSelector } from 'react-redux';
import { openLiveReportFields } from '../features/strategyReport/strategyDataDetailSlice';
import NoDataFoundV2 from '../components/Common/NoDataFoundV2';
import { BrokerCard3 } from './BrokerCard';
import { BsPlus } from 'react-icons/bs';

const LiveReportInputs = ({
  inputs,
  brokerId,
  strategyID,
  upDateData,
  configData,
  setHeaderTopTape,
}) => {
  const [connectedBroker, setConnectedBroker] = useState([]);
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  let location = window.location.pathname.split('/')[1];

  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState('1');
  const liveModalRef = useRef();
  const { liveRefech } = useLiveStrategyDetails();
  const [liveReportDetails, setLiveReportDetails] = useState();
  const [warningMessage, setWarningMessage] = useState({
    message: '',
    show: false,
  });
  const navigate = useNavigate();

  const { showLiveReportDrawer } = useSelector(
    (state) => state.completeStrategyDetails,
  );

  let { id } = useParams();

  useEffect(() => {
    axios
      .get(`${config.base_url}${config.broker_crendientials}/${userid.id}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        if (!brokerId) {
          setConnectedBroker(
            res.data.data.filter((item) => item.status === 'verified'),
          );
        } else {
          setConnectedBroker(
            res.data.data
              .filter((item) => item.status === 'verified')
              .map((item) => {
                return brokerId === item?._id
                  ? { ...item, connected: true }
                  : { ...item };
              }),
          );
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  }, [brokerId]);

  const liveReportSubmission = (values) => {
    let formatValues = { ...values };
    // Find all inputs that are of type 'time'
    let timeInputs = inputs.filter((item) => item.input_type === 'Time');
    // Format each time input value to 'HH:mm'
    timeInputs.forEach((item) => {
      if (values[item.value_name]) {
        formatValues[item.value_name] = values[item.value_name].format('HH:mm');
      }
    });
    setLiveReportDetails(formatValues);
    let emptyString = Object.values(formatValues)
      .filter((item) => typeof item !== 'boolean')
      .every((item) => item);
    if (location === 'view-report') {
      if (
        emptyString &&
        JSON.stringify(formatValues) === JSON.stringify(configData)
      ) {
        setActiveKey('2');
      }
    } else {
      if (emptyString) {
        setActiveKey('2');
      }
    }
  };

  const onChange = (key) => {
    if (key === '2') {
      liveModalRef.current.submit();
    } else {
      setActiveKey(key);
    }
  };

  const handleButtonClick = (id) => {
    setConnectedBroker((prevData) =>
      prevData.map((item) =>
        item._id === id
          ? { ...item, connected: !item.connected }
          : { ...item, connected: false },
      ),
    );
  };

  const mutation = useMutation({
    mutationFn: (payload) => createLiveStrategyRequest(payload),
    onSuccess: (res) => {
      liveRefech();
      dispatch(openLiveReportFields(false));

      setWarningMessage({ ...warningMessage, show: false });
      if (res.data.status) {
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const upDateReport = useMutation({
    mutationFn: (payload) => updateLiveStrategyRequest(payload),
    onSuccess: (res) => {
      dispatch(openLiveReportFields(false));
      setHeaderTopTape({
        top: false,
        middle: false,
        topLast: false,
        bottom: true,
      });
      setWarningMessage({ ...warningMessage, show: false });
      if (res.data.status) {
        upDateData();
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const finalSubmit = () => {
    const selectedBroker = connectedBroker.find((item) => item.connected);
    if (brokerId && selectedBroker && location === 'view-report') {
      console.log(liveReportDetails, configData);

      if (
        JSON.stringify(liveReportDetails) !== JSON.stringify(configData) ||
        brokerId !== selectedBroker?._id
      ) {
        var live_payload = {
          user_id: userid.id,
          strategy_id: strategyID,
          status: true,
          lt_inputs: liveReportDetails,
          broker_name: selectedBroker?.broker_name,
          credential_id: selectedBroker?._id,
          lt_id: id,
        };
        upDateReport.mutate(live_payload);
      }
    } else if (selectedBroker && location !== 'view-report') {
      var payload = {
        user_id: userid.id,
        strategy_id: strategyID,
        status: false,
        current_status: 'pending',
        lt_inputs: liveReportDetails,
        credential_id: selectedBroker?._id,
        broker_name: selectedBroker?.broker_name,
      };

      mutation.mutate(payload);
    } else {
      setWarningMessage({ message: 'Please connect the brokers', show: true });
    }
  };
  // , [
  //   brokerId,
  //   connectedBroker,
  //   id,
  //   liveReportDetails,
  //   mutation,
  //   strategyID,
  //   upDateReport,
  //   userid.id,
  // ]);

  const getSaveButton = () => {
    if (location === 'view-report') {
      let formErrors = liveModalRef.current.getError();
      let getError = formErrors.find((item) => item.errors.length > 0);
      if (getError) {
        liveModalRef.current.submit();
      } else {
        setActiveKey('2');
        liveModalRef.current.submit();
      }
    } else {
      liveModalRef.current.submit();
    }
  };

  const getFooter = useMemo(() => {
    if (inputs && inputs.length === 0) {
      return null;
    } else if (activeKey === '1') {
      return (
        <div className="d-flex gap-2 justify-end">
          <button
            className={`${btnSecondayClass}`}
            onClick={() => {
              dispatch(openLiveReportFields(false));
              liveModalRef.current.resetFields();
              setWarningMessage({ ...warningMessage, show: false });
            }}
          >
            Cancel
          </button>
          <button
            type="primary"
            className={`${btnPrimaryClass}`}
            onClick={() => getSaveButton()}
          >
            Save & Next
          </button>
        </div>
      );
    } else {
      return (
        // <div className="modal-footer justify-content-right mt-4 ps-0 pe-0">
        <div className="d-flex gap-2 justify-end">
          <button
            type="button"
            className={`${btnSecondayClass}`}
            onClick={() => {
              dispatch(openLiveReportFields(false));
              liveModalRef.current.resetFields();
              setWarningMessage({ ...warningMessage, show: false });
              setActiveKey('2');
            }}
          >
            Cancel
          </button>
          <button
            type="button"
            className={`${btnPrimaryClass}`}
            data-dismiss="modal"
            onClick={() => {
              finalSubmit();
              liveModalRef.current.resetFields();
              setActiveKey('2');
            }}
          >
            Save Changes
          </button>
        </div>
        // </div>
      );
    }
  }, [activeKey, dispatch, finalSubmit, inputs]);

  return (
    <>
      <Drawer
        open={showLiveReportDrawer}
        onClose={() => {
          dispatch(openLiveReportFields(false));
          liveModalRef.current.resetFields();
          setWarningMessage({ ...warningMessage, show: false });
        }}
        title={
          <div className="flex justify-between items-center pr-6">
            <div className="flex flex-col -space-y-2">
              <div>
                <h4 className="font-weight-bold mb-0">
                  {configData?.report_name || 'Live Trade Setup'}
                </h4>
              </div>
              <div className="flex gap-1 items-center">
                <div>
                  <span className="text-xs text-color-secondary">
                    Last Updated: 04-Jul-2024 3:41:27 PM
                  </span>
                </div>
                <span className="text-level-2">|</span>
                <div>
                  {warningMessage?.show && (
                    <div className="flex text-xs text-color-secondary">
                      {warningMessage?.message}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              {/* <button
                className="bg-red-3 flex justify-center items-center py-[7px] px-[15px] text-[13px] text-whiteNew-0 rounded-[6px] gap-1 font-normal"
                onClick={() => {}}
              >
                <MdClose />
                <span>Inactive</span>
              </button> */}
              {/* //!ACTIVE AND EDIT FUNCTIONALITY HERE */}
              {/* <button
                className="bg-green-3 flex justify-center items-center py-[7px] px-[15px] text-[13px] text-whiteNew-0 rounded-[6px] gap-1 font-normal"
                onClick={() => {}}
              >
                <MdCheck />
                <span>Active</span>
              </button>
              <button
                className="btn__create__strategy align-items-center d-flex gap-1 items-center font-normal"
                onClick={() => {}}
              >
                <MdOutlineEdit />
                <span>Edit</span>
              </button> */}
            </div>
          </div>
        }
        width={'1200px'}
        footer={getFooter}
      >
        {inputs && inputs.length > 0 ? (
          <div className="position-relative tradesetting_tab -mt-7 -mx-3">
            <Tabs
              activeKey={activeKey}
              onChange={onChange}
              items={[
                {
                  label: 'Setup',
                  key: '1',
                  children: (
                    <>
                      <div className="px-3.5">
                        <ModalInputFields
                          ref={liveModalRef}
                          inputs={inputs}
                          primaryButtonName="Generate"
                          sumbitForm={liveReportSubmission}
                          closeModal={() => {
                            dispatch(openLiveReportFields(false));
                            setWarningMessage({
                              ...warningMessage,
                              show: false,
                            });
                          }}
                        />
                      </div>
                    </>
                  ),
                },
                {
                  label: 'Broker',
                  key: '2',
                  children: (
                    <>
                      <div className="px-3.5">
                        {connectedBroker.length > 0 ? (
                          <>
                            <div className="clearfix mb-3">
                              <div className="">
                                <h5 className="float-left font-medium">
                                  Available Brokers
                                </h5>
                              </div>
                            </div>
                            <div className="d-flex row">
                              {connectedBroker.map((item, index) => (
                                <>
                                  {/* <div
                                  className="col-lg-3"
                                  key={item.broker_name}
                                >
                                  <div className="broker_card d-flex flex-column justify-content-center">
                                    <div className="d-flex justify-content-center">
                                      <img
                                        style={{
                                          objectFit: 'contain',
                                          width: '100%',
                                          height: '70px',
                                        }}
                                        src={item.logo_img}
                                        alt="No-img"
                                      />
                                    </div>
                                    <h3>{item?.broker_name}</h3>
                                    <span className="fsize-13">
                                      {item?.description}
                                    </span>
                                    <p className="py-4">
                                      Avl. Balance:{' '}
                                      <span style={{ color: '#4074F9' }}>
                                        {item?.funds}
                                      </span>
                                      <br />
                                      <span className="fsize-13">
                                        Client ID:{item?.id}
                                      </span>
                                    </p>
                                    <p
                                      className={
                                        item?.connected
                                          ? 'btn_disconnect py-2 cursor-pointer'
                                          : 'btn_connect py-2 cursor-pointer'
                                      }
                                      onClick={() =>
                                        handleButtonClick(item._id)
                                      }
                                    >
                                      {item?.connected
                                        ? 'Disconnect'
                                        : 'Connect'}
                                    </p>
                                  </div>
                                </div> */}
                                  <div className="col-lg-3" key={index}>
                                    <BrokerCard3
                                      item={item}
                                      name={item?.broker_name}
                                      description={item?.description}
                                      image={item.logo_img}
                                      // showModal={showModal}
                                      // getBroker={getBroker}
                                      id={item.broker_name}
                                      button={
                                        <>
                                          <button
                                            className={`${btnPrimaryClass} group-hover:bg-color-primaryColor2 ease-in duration-200 group-hover:text-whiteNew-0 relative w-full`}
                                            // className={`bg-green-2 p-3.5 text-whiteNew-0 w-[35px] h-[35px] relative flex rounded-md`}
                                            //
                                            onClick={() => {
                                              handleButtonClick(item._id);
                                            }}
                                          >
                                            Connect
                                          </button>
                                        </>
                                      }
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="col-12 flex items-center justify-content-center flex-row">
                            <NoDataFoundV2
                              title={
                                'No Brokers Available. please connect the brokers'
                              }
                              className="text-4xl"
                              minHeight={'min-h-[200px] py-2'}
                              onSubmit={() => {
                                navigate('/broker');
                              }}
                              buttonProps={{
                                primaryBtnText: 'Connect Brokers',
                                // secondaryBtnText: 'Select system venue',
                                primaryBtnIcon: (
                                  <>
                                    <span>
                                      <BsPlus className="text-lg" />
                                    </span>
                                  </>
                                ),
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ),
                },
              ]}
            />
          </div>
        ) : (
          <div className="w-full flex justify-center text-center">
            <NoDataFoundV2
              title={'No data found.'}
              className="text-4xl"
              minHeight={'min-h-[200px] py-2'}
            />
          </div>
        )}
      </Drawer>
    </>
  );
};
export default React.memo(LiveReportInputs);
