import React, { useState, useEffect } from 'react';
import '../UI/Header.css';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// import FuturisticWhiteLogoSmall from '../../assets/images/logo/Cliqalogo-03.svg';
// import FuturisticWhiteLogoLarge from '../../assets/images/logo/Cliqalogo-05.svg';

import FuturisticWhiteLogoSmall from '../../assets/images/trigerr/Trigerr-05.svg';
import FuturisticWhiteLogoLarge from '../../assets/images/trigerr/Trigerr-04.svg';

import { NavLink, Link, useLocation } from 'react-router-dom';
import Topbar from './Topbar';
import useLogout from '../hooks/useLogout';
import { useGetUserByIdQuery } from '../../services/profileDetails';
import { useGetPlanDetailsQuery } from '../../services/planDetails';
import {
  userCreditList,
  userLiveDeployments,
  userPremiumStrategies,
  userVirtualDeployments,
} from '../../features/UserCredits/userCreditsSlice';
import {
  completeUserDetails,
  userFeaturesData,
  userPlan,
  userProfileDetails,
  // userToRestrict,
} from '../../features/profile/profilePlanSlice';
import { useDispatch } from 'react-redux';
import useToGetId from '../hooks/useToGetId';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAllPlanDetails } from '../../API/profile/planApi';
import { allPlansList } from '../../features/update-plan/UpdatePlanSlice';
// import useActivePlanData from '../hooks/useActivePlanData';
import { getActivePlans } from '../../API/profile/billinghistory';
import SubscriptionExpireStrip from '../../Pages/UserComponents/subscription_upgrade/SubscriptionExpireStrip';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { setOpenWelcomeModal } from '../../features/welcomeModalSlice';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  var dispatch = useDispatch();
  const collpse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const { logoutUser } = useLogout();
  let { userid } = useToGetId();

  useEffect(() => {
    if (isCollapsed) {
      document.body.classList.add('toggle-on');
    } else {
      document.body.classList.remove('toggle-on');
    }
    return () => {
      document.body.classList.remove('toggle-on');
    };
  }, [isCollapsed]);

  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const matchIncludes = ['/strategies', '/strategy', '/view-report'];

  const isActiveFunc = () => {
    return matchIncludes.some((path) =>
      window.location.pathname.startsWith(path),
    );
  };

  const [theme, setTheme] = useState(() => {
    // Check if a theme is stored in local storage, otherwise use default
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  });

  // Function to toggle between dark and light themes
  const body = document.querySelector('body');
  const toggleTheme = () => {
    const newTheme = theme === 'light' ? 'dark' : 'light';
    console.log(newTheme);
    setTheme(newTheme);
    if (newTheme === 'dark') {
      body.classList.add('dark');
      body.classList.remove('light');
    } else {
      body.classList.remove('dark');
      body.classList.add('light');
    }
    localStorage.setItem('theme', newTheme);
  };

  // Add/remove 'dark-theme' class to the document body based on the current theme
  const storedDarkMode = localStorage.getItem('theme');
  const [darkMode, setDarkMode] = useState(storedDarkMode);
  useEffect(() => {
    document.body.classList.toggle('dark-theme', theme === 'dark');
    const body = document.querySelector('body');
    if (theme === 'dark') {
      body.classList.add('dark');
      body.classList.remove('light');
    } else {
      body.classList.remove('dark');
      body.classList.add('light');
    }
    // Store the dark mode preference in localStorage
    localStorage.setItem('darkMode', darkMode);
  }, [theme, darkMode]);

  // const { data, error, isLoading } = useGetUserByIdQuery(userid.id);

  // const [creditsData, setCreditsData] = useState();

  // const getCreditsAvailable = useMutation({
  //   mutationFn: getActivePlans,
  //   onSuccess: (res) => {
  //     setCreditsData(res);
  //   },
  //   onError: (err) => {},
  // });

  // useEffect(() => {
  //   getCreditsAvailable.mutate();
  // }, []);

  // // console.log(creditsData);
  // // const {
  // //   data: creditsData,
  // //   error: creditsError,
  // //   isLoading: creditsLoading,
  // // } = useGetPlanDetailsQuery();

  // // const {creditsData, creditsError, creditsLoading}=useActivePlanData()

  // useEffect(() => {
  //   if (creditsData) {
  //     dispatch(userCreditList(creditsData));
  //     dispatch(
  //       userPremiumStrategies({
  //         strategies_remaining:
  //           creditsData?.premium_strategies_subscribe?.remaining,
  //         strategies_total: creditsData?.premium_strategies_subscribe?.total,
  //         strategies_used: creditsData?.premium_strategies_subscribe?.used,
  //       }),
  //     );
  //     dispatch(
  //       userVirtualDeployments({
  //         requests_remaining: creditsData?.virtual_deployments?.remaining,
  //         requests_total: creditsData?.virtual_deployments?.total,
  //         requests_used: creditsData?.virtual_deployments?.used,
  //       }),
  //     );
  //     dispatch(
  //       userLiveDeployments({
  //         requests_remaining: creditsData?.live_deployments?.remaining,
  //         requests_total: creditsData?.live_deployments?.total,
  //         requests_used: creditsData?.live_deployments?.used,
  //       }),
  //     );
  //   }
  // }, [creditsData]);

  // useEffect(() => {
  //   if (error) {
  //     console.error('Error fetching user data:', error);
  //   }
  //   if (!isLoading && data) {
  //     dispatch(completeUserDetails(data));
  //     dispatch(userProfileDetails(data?.data));
  //     if (data?.active_plan?.expired_at) {
  //       dispatch(
  //         userFeaturesData(
  //           data?.active_plan?.subscriptionPlanData?.features_data,
  //         ),
  //       );
  //     }
  //   }
  // }, [data, error, isLoading]);

  // const { data: plansList } = useQuery({
  //   queryKey: ['subscribed_plans'],
  //   queryFn: getAllPlanDetails,
  //   refetchOnMount: 'always',
  //   refetchOnWindowFocus: false,
  // });

  // useEffect(() => {
  //   if (plansList) {
  //     const list = plansList.flatMap(({ plans }) => plans);
  //     dispatch(allPlansList(list));
  //   }
  // }, [plansList]);

  const location = useLocation();
  let tab = location.pathname.split('/portfolio/')[1];

  // const [remindLater, setRemindLater] = useState(
  //   localStorage.getItem('remindMeLater'),
  // );

  return (
    <>
      <div className="fixed top-0 w-full z-[9] h-auto bg-whiteNew-0 dark:bg-color-base-4">
        {isMobile === false ? (
          <Topbar tab={tab} />
        ) : (
          <div className="d-flex row mob__top__bar">
            <div className="col-6 mob__top__logo ps-0">
              <Link to={'/'}>
                <img
                  src={
                    !isCollapsed
                      ? FuturisticWhiteLogoSmall
                      : FuturisticWhiteLogoLarge
                  }
                  alt="logo"
                />
              </Link>
            </div>
            <div className="col-6 d-flex align-items-center justify-content-end top__nv__cntrl pe-0">
              <IconButton className="active" onClick={toggleTheme}>
                {theme === 'light' ? (
                  <Tooltip title="Light Mode" placement="bottom">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M12 7c-2.76 0-5 2.24-5 5s2.24 5 5 5 5-2.24 5-5-2.24-5-5-5zM2 13h2c.55 0 1-.45 1-1s-.45-1-1-1H2c-.55 0-1 .45-1 1s.45 1 1 1zm18 0h2c.55 0 1-.45 1-1s-.45-1-1-1h-2c-.55 0-1 .45-1 1s.45 1 1 1zM11 2v2c0 .55.45 1 1 1s1-.45 1-1V2c0-.55-.45-1-1-1s-1 .45-1 1zm0 18v2c0 .55.45 1 1 1s1-.45 1-1v-2c0-.55-.45-1-1-1s-1 .45-1 1zM5.99 4.58a.996.996 0 0 0-1.41 0 .996.996 0 0 0 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0s.39-1.03 0-1.41L5.99 4.58zm12.37 12.37a.996.996 0 0 0-1.41 0 .996.996 0 0 0 0 1.41l1.06 1.06c.39.39 1.03.39 1.41 0a.996.996 0 0 0 0-1.41l-1.06-1.06zm1.06-10.96a.996.996 0 0 0 0-1.41.996.996 0 0 0-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06zM7.05 18.36a.996.996 0 0 0 0-1.41.996.996 0 0 0-1.41 0l-1.06 1.06c-.39.39-.39 1.03 0 1.41s1.03.39 1.41 0l1.06-1.06z"></path>
                    </svg>
                  </Tooltip>
                ) : (
                  <Tooltip title="Dark Mode" placement="bottom">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Layer_1"
                      data-name="Layer 1"
                      viewBox="0 0 24 24"
                      width="1em"
                      height="1em"
                    >
                      <path d="M15,24A12,12,0,1,1,19.058.71a2.93,2.93,0,0,1,.814,5.067c-5.1,3.92-5.088,8.539,0,12.446a2.93,2.93,0,0,1-.816,5.067A12.2,12.2,0,0,1,15,24ZM15,3a9,9,0,1,0,2.925,17.508c-6.511-5.065-6.489-11.969,0-17.016A9.322,9.322,0,0,0,15,3Z" />
                    </svg>
                  </Tooltip>
                )}
              </IconButton>
              <Tooltip title="Profile" placement="right">
                <Link to={'/profile'} exact>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="Bold"
                    viewBox="0 0 24 24"
                    width="1em"
                    height="1em"
                  >
                    <path d="M18.5,0H5.5A5.506,5.506,0,0,0,0,5.5v13A5.506,5.506,0,0,0,5.5,24h13A5.506,5.506,0,0,0,24,18.5V5.5A5.506,5.506,0,0,0,18.5,0ZM21,18.5A2.5,2.5,0,0,1,18.5,21H18V20A6,6,0,0,0,6,20v1H5.5A2.5,2.5,0,0,1,3,18.5V5.5A2.5,2.5,0,0,1,5.5,3h13A2.5,2.5,0,0,1,21,5.5Z" />
                    <circle cx="12" cy="8.5" r="3.5" />
                  </svg>
                </Link>
              </Tooltip>
              <Tooltip title="Logout" placement="right">
                <IconButton onClick={{ logoutUser }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 512.292 512.292"
                    width="1em"
                    height="1em"
                  >
                    <g>
                      <path d="M256.061,0L256.061,0c17.673,0,32,14.327,32,32v106.667c0,17.673-14.327,32-32,32l0,0c-17.673,0-32-14.327-32-32V32   C224.061,14.327,238.387,0,256.061,0z"></path>
                      <path d="M330.727,105.387L330.727,105.387c-0.157,10.742,5.259,20.8,14.315,26.581c80.432,49.143,105.796,154.185,56.652,234.616   S247.51,472.38,167.078,423.237S61.282,269.052,110.426,188.62c14.042-22.982,33.324-42.315,56.269-56.418   c9.211-5.781,14.773-15.92,14.699-26.795l0,0c0.049-17.673-14.238-32.039-31.911-32.088c-6.07-0.017-12.02,1.693-17.155,4.931   C22.233,146.634-11.58,291.318,56.803,401.412s213.067,143.907,323.161,75.524s143.907-213.067,75.524-323.161   c-19.035-30.645-44.879-56.489-75.524-75.524c-14.997-9.461-34.824-4.973-44.285,10.024   C332.447,93.397,330.731,99.33,330.727,105.387z"></path>
                    </g>
                  </svg>
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
        <div className="overflow-hidden">
          {isMobile === false ? (
            <div
              className="absolute sidebar"
              // style={{ top: remindLater ? '0px' : ' 54px' }}
            >
              <button
                className="btn__collapse"
                style={{ top: '16px' }}
                onClick={collpse}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z"></path>
                  <path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path>
                </svg>
              </button>
              <div className="sidebar__area d-flex flex-column justify-content-start h-100 overflow-hidden">
                <Link to={'/'}>
                  <img
                    style={{
                      width: !isCollapsed ? '50px' : '121px',
                      height: '50px',
                    }}
                    src={
                      !isCollapsed
                        ? FuturisticWhiteLogoSmall
                        : FuturisticWhiteLogoLarge
                    }
                    alt=""
                  />
                </Link>

                <ul className="d-flex flex-column justify-content-center align-items-center mt-5">
                  <li>
                    <Tooltip title="Virtual Portfolio" placement="right">
                      <NavLink
                        to={'/portfolio/virtual'}
                        className={'flex items-center px-1.5'}
                        exact
                        sx={{ my: 0, color: 'white', display: 'block' }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="shrink-0"
                        >
                          <polyline points="16 16 12 12 8 16"></polyline>
                          <line x1="12" y1="12" x2="12" y2="21"></line>
                          <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                          <polyline points="16 16 12 12 8 16"></polyline>
                        </svg>
                        {isCollapsed ? (
                          <span className="shrink-0">Virtual Trade</span>
                        ) : null}
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="Live Portfolio" placement="right">
                      <NavLink
                        to={'/portfolio/live'}
                        className={'flex items-center px-1.5'}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          className="bi bi-broadcast shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                        </svg>
                        {isCollapsed ? (
                          <span className="shrink-0">Live Trade</span>
                        ) : null}
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="Strategy" placement="right">
                      <NavLink
                        to={'/strategies'}
                        // isActive={isActiveFunc}
                        className={`flex items-center px-1.5 ${
                          isActiveFunc() ? 'active' : ''
                        }`}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 256 256"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                          className="shrink-0"
                        >
                          <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
                        </svg>

                        {isCollapsed ? (
                          <span className="shrink-0">Strategy</span>
                        ) : null}
                      </NavLink>
                    </Tooltip>
                  </li>
                  <li>
                    <Tooltip title="Tickets" placement="right">
                      <NavLink
                        to={'/tickets'}
                        // isActive={isActiveFunc}
                        // className={`flex items-center px-1.5 ${
                        //   isActiveFunc() ? 'active' : ''
                        // }`}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="none"
                            stroke-miterlimit="10"
                            stroke-width="32"
                            d="M366.05 146a46.7 46.7 0 0 1-2.42-63.42 3.87 3.87 0 0 0-.22-5.26l-44.13-44.18a3.89 3.89 0 0 0-5.5 0l-70.34 70.34a23.62 23.62 0 0 0-5.71 9.24 23.66 23.66 0 0 1-14.95 15 23.7 23.7 0 0 0-9.25 5.71L33.14 313.78a3.89 3.89 0 0 0 0 5.5l44.13 44.13a3.87 3.87 0 0 0 5.26.22 46.69 46.69 0 0 1 65.84 65.84 3.87 3.87 0 0 0 .22 5.26l44.13 44.13a3.89 3.89 0 0 0 5.5 0l180.4-180.39a23.7 23.7 0 0 0 5.71-9.25 23.66 23.66 0 0 1 14.95-15 23.62 23.62 0 0 0 9.24-5.71l70.34-70.34a3.89 3.89 0 0 0 0-5.5l-44.13-44.13a3.87 3.87 0 0 0-5.26-.22 46.7 46.7 0 0 1-63.42-2.32z"
                          ></path>
                          <path
                            fill="none"
                            stroke-linecap="round"
                            stroke-miterlimit="10"
                            stroke-width="32"
                            d="m250.5 140.44-16.51-16.51m60.53 60.53-11.01-11m55.03 55.03-11-11.01m60.53 60.53-16.51-16.51"
                          ></path>
                        </svg>

                        {isCollapsed ? (
                          <span className="shrink-0">Tickets</span>
                        ) : null}
                      </NavLink>
                    </Tooltip>
                  </li>
                  {/* <li>
                  <Tooltip title="Chart" placement="right">
                    <NavLink
                      to={'/chart'}
                      className={'flex items-center px-1.5'}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        className="bi bi-graph-up-arrow shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                        />
                      </svg>
                      {isCollapsed ? (
                        <span className="shrink-0">Chart</span>
                      ) : null}
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <Tooltip title="Broker" placement="right">
                    <NavLink
                      to={'/broker'}
                      className={'flex items-center px-1.5'}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        className="bi bi-person-gear shrink-0"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                      </svg>

                      {isCollapsed ? (
                        <span className="shrink-0">Broker</span>
                      ) : null}
                    </NavLink>
                  </Tooltip>
                </li> */}
                </ul>
              </div>
            </div>
          ) : (
            <div className="mobile__sticky__nav">
              <ul>
                <li>
                  <NavLink to={'/portfolio/virtual'} exact>
                    <svg
                      stroke="currentColor"
                      fill="none"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <polyline points="16 16 12 12 8 16"></polyline>
                      <line x1="12" y1="12" x2="12" y2="21"></line>
                      <path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path>
                      <polyline points="16 16 12 12 8 16"></polyline>
                    </svg>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={'/portfolio/live'} exact>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      className="bi bi-broadcast"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                    </svg>
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={'/strategies'}
                    // isActive={isActiveFunc}
                    className={`${isActiveFunc() ? 'active' : ''}`}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 256 256"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
                    </svg>
                  </NavLink>
                </li>
                <li>
                  <Tooltip title="Chart" placement="right">
                    <NavLink to={'/chart'}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        className="bi bi-graph-up-arrow"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M0 0h1v15h15v1H0V0Zm10 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V4.9l-3.613 4.417a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61L13.445 4H10.5a.5.5 0 0 1-.5-.5Z"
                        />
                      </svg>
                    </NavLink>
                  </Tooltip>
                </li>
                <li>
                  <NavLink to={'/broker'}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      className="bi bi-person-gear"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                    </svg>
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Sidebar);
