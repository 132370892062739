import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { btnPrimaryClass } from '../utils/defaultClasses';

export default class OtpTimer extends Component {
  static get propTypes() {
    return {
      options: PropTypes.object,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      showfield: false,
      minutes: this.props.minutes ? this.props.minutes : 0,
      seconds: this.props.seconds ? this.props.seconds : 30,
    };

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    clearInterval(this.myInterval);
    this.otptimer();
  }

  otptimer() {
    clearInterval(this.myInterval);
    this.myInterval = setInterval(() => {
      const { seconds, minutes } = this.state;

      if (seconds > 0) {
        this.setState(({ seconds }) => ({
          seconds: seconds - 1,
        }));
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(this.myInterval);
        } else {
          this.setState(({ minutes }) => ({
            minutes: minutes - 1,
            seconds: 59,
          }));
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
  }

  handleClick(event) {
    this.props.resend();
    this.otptimer();
    if (this.props.isError) {
      this.setState({
        minutes: 0,
        seconds: 0,
      });
    } else {
      this.setState({
        minutes: this.props.minutes ? this.props.minutes : 0,
        seconds: this.props.seconds ? this.props.seconds : 30,
      });
    }
    event.preventDefault();
  }
  //   wrapperFunction(e){
  //     this.props.resend;
  //     this.handleClick.bind(this);
  // }

  render() {
    return (
      <>
        {(this.state.minutes === 0 && this.state.seconds === 0) ||
        this.props.isError ? (
          <button
            className={` ${
              this.props.buttonType || this.props.darkTheme
                ? `${btnPrimaryClass} ${this.props.className}`
                : `${btnPrimaryClass} ${this.props.className}`
            } `}
            // style={buttonStyling}
            onClick={this.handleClick}
          >
            {this.props.ButtonText ? (
              <span>{this.props.ButtonText} </span>
            ) : (
              <span>Resend</span>
            )}
          </button>
        ) : (
          <span className="text-gray-400 mt-2">
            {this.props.text ? (
              <span>{this.props.text} </span>
            ) : (
              <span>Time left: </span>
            )}
            {this.state.minutes < 10
              ? `0${this.state.minutes}`
              : this.state.minutes}
            :
            {this.state.seconds < 10
              ? `0${this.state.seconds}`
              : this.state.seconds}
          </span>
        )}
      </>
    );
  }
}
