import React from 'react';
import moment from 'moment';
import { afterBorderTop } from '../../utils/defaultClasses';
import { BiSolidChevronRight } from 'react-icons/bi';

function ActiveLogs({ activeLogs }) {
  return (
    <>
      {activeLogs?.map((item) => (
        <div
          className={`px-3.5 max-h-[300px] overflow-y-auto scrollCss divide-y divide-level-2`}
          key={item?._id}
        >
          <div className="flex justify-between items-center gap-2 py-3.5">
            <div className="flex gap-1.5 items-center">
              <div className="">
                <span className="flex bg-yellow-100 text-yellow-500 text-sm px-1.5 rounded-md">
                  Activity
                </span>
              </div>
              <div className="flex flex-col -space-y-1">
                <span
                  className="text-sm font-medium hover:text-blue-2"
                  type="button"
                >
                  {`${item?.activity} ${moment(item.created_at).format(
                    'MM/DD/YY - HH:mm',
                  )}`}
                </span>
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className={`p-3.5 ${afterBorderTop} after:bg-level-2 relative`}>
        <div className="w-full flex justify-center">
          <span
            type={'button'}
            className="flex items-center gap-0.5 text-color-primary text-sm font-medium hover:text-blue-2 duration-100 ease-in"
          >
            View All
            <BiSolidChevronRight />
          </span>
        </div>
      </div>
    </>
  );
}

export default ActiveLogs;
