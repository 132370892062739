import { useQuery } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { strategyVirtualDetails, userTradeList } from '../../../API/strategy/strategyDetail';
import { useDispatch } from 'react-redux';
import { setLiveReportsdata, setTradeData, setVirtualReportsdata } from '../../../features/reports/reports.slice';
import { strategyLiveDetails } from '../../../API/strategy/strategyDetail';
import useToGetId from '../../../components/hooks/useToGetId';

function useReports() {
    var dispatch = useDispatch();
    const [virtualPagination, setVirtualPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [virtualPage, setVirtualPage] = useState(1);
    const [vstatus, setVstatus] = useState("all");
    const [lstatus, setlstatus] = useState("all");
    const [livePagination, setLivePagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [tradeOrderDateRangeValue, setTradeOrderDateRangeValue] = useState([
        null,
        null,
    ]);
    const [tradeOrderDate, setTradeOrdersDate] = useState("");
    console.log('tradeOrderDate: ', tradeOrderDate);
    const handleTradeOrderDateChange = (date, dateString) => {
        onceCallTrade.current = false
        setTradeOrderDateRangeValue(date);
        setTradeOrdersDate(dateString);
    }
    const [tradePagination, setTradePagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0,
    });
    const [livePage, setLivePage] = useState(1);
    const [tradePage, setTradePage] = useState(1);

    const handleVirtualTradeTable = (newPagination) => {
        onceCall.current = false
        setVirtualPage(newPagination.current);
    };
    const handleUserTradeTable = (newPagination) => {
        onceCallTrade.current = false
        setTradePage(newPagination.current);
    };
    const handleLiveTradeTable = (newPagination) => {
        onceCallLive.current = false
        setLivePage(newPagination.current);
    };
    const { userid } = useToGetId();

    const {
        data: virtualData,
        refetch: virtualReportRefetch,
        isFetching: virtualFetching,
        isLoading: virtualLoading,
    } = useQuery({
        queryKey: [
            'virtualReportList',
            {
                virtualPage,
                vstatus,
                payload: {
                    dashboard_user_id: userid.id,
                },
            },
        ],
        queryFn: strategyVirtualDetails,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        staleTime: 0,
    });

    const {
        data: liveData,
        refetch: liveReportRefech,
        isFetching: liveFetching,
        isLoading: liveLoading,
    } = useQuery({
        queryKey: [
            'liveList',
            {
                livePage,
                lstatus,
                payload: {
                    dashboard_user_id: userid.id,
                },
            },
        ],
        queryFn: strategyLiveDetails,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        staleTime: 0,
    });
    const {
        data: tradeData,
        refetch: tradeRefech,
        isFetching: tradeFetching,
        isLoading: tradeLoading
    } = useQuery({
        queryKey: [
            'userTradeList',
            {
                tradePage,
                tradeOrderDate,
                payload: {
                    dashboard_user_id: userid.id,
                },
            },
        ],
        queryFn: userTradeList,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        staleTime: 0,
    });
    const onceCall = useRef(false)
    const onceCallLive = useRef(false)
    const onceCallTrade = useRef(false)
    useEffect(() => {
        if (virtualData && !onceCall.current) {
            onceCall.current = true
            const updatedData = virtualData?.data?.map((item) => ({
                ...item,
                report_name: item?.vt_inputs?.report_name,
                max_cap: item?.vt_inputs?.investment,
                key: item?._id,
            }));
            if (updatedData) {
                dispatch(setVirtualReportsdata(updatedData));
            }
            setVirtualPagination({
                ...virtualPagination,
                current: virtualPage,
                total: virtualData?.dataCount,
            });
        }
        if (liveData && !onceCallLive.current) {
            onceCallLive.current = true
            const updatedData = liveData?.data?.map((item) => ({
                ...item,
                report_name: item?.lt_inputs?.report_name,
                max_cap: item?.lt_inputs?.investment,
                key: item?._id,
            }));
            if (updatedData) {
                dispatch(setLiveReportsdata(updatedData));
            }
            setLivePagination({
                ...livePagination,
                current: livePage,
                total: liveData?.dataCount,
            });
        }
        if (tradeData && !onceCallTrade.current) {
            onceCallTrade.current = true
            dispatch(setTradeData(tradeData?.data));
            setTradePagination({
                ...tradePagination,
                current: tradePage,
                total: tradeData?.count,
            });
        }
    }, [dispatch, liveData, livePage, livePagination, tradeData, tradePage, tradePagination, virtualData, virtualPage, virtualPagination]);

    return {
        virtualPagination,
        livePagination,
        tradePagination,
        virtualPage,
        tradePage,
        liveReportRefech,
        virtualReportRefetch,
        tradeRefech,
        handleVirtualTradeTable,
        handleLiveTradeTable,
        handleUserTradeTable,
        tradeFetching,
        virtualFetching,
        liveFetching,
        virtualData,
        liveData,
        vstatus, setVstatus,
        lstatus, setlstatus, onceCall,
        onceCallLive, onceCallTrade,
        handleTradeOrderDateChange,
        tradeOrderDateRangeValue,
        virtualLoading,
        liveLoading,
        tradeLoading
    };
}

export default useReports;
