import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { strategyVirtualDetails } from '../../API/strategy/strategyDetail';
import useToGetId from './useToGetId';
import { useLocation, useParams } from 'react-router-dom';
import { setVirtualReportsDataSeperated, virtualReportsdataStrategy } from '../../features/strategyReport/strategyDataDetailSlice';
import { useDispatch } from 'react-redux';

function useVirtualStrategyDetails(strategyId = null, tab = "virtual") {
  let { id } = useParams();
  var dispatch = useDispatch();
  const [virtualPagination, setVirtualPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [virtualPage, setVirtualPage] = useState(1);
  const handleVirtualTradeTable = (newPagination) => {
    setVirtualPage(newPagination.current);
  };
  const { userid } = useToGetId();

  const {
    data,
    refetch: virtualRefech,
    isFetching,
  } = useQuery({
    queryKey: [
      'virtualList',
      {
        virtualPage,
        payload: {
          user_id: userid.id,
          strategy_id: id || strategyId,
        },
      },
    ],
    queryFn: strategyVirtualDetails,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  useEffect(() => {
    if (data && tab === "virtual") {
      const updatedData = data?.data?.map((item) => ({
        ...item,
        report_name: item?.vt_inputs?.report_name,
        max_cap: item?.vt_inputs?.investment,
        key: item?._id,
      }));
      if (updatedData) {
        dispatch(virtualReportsdataStrategy(updatedData));
        let obj = {
          active: updatedData?.filter((dat) => dat?.status),
          inActive: updatedData?.filter((dat) => !dat?.status),
        }
        dispatch(setVirtualReportsDataSeperated(obj))

        // setVirtualData(updatedData);
      }
      setVirtualPagination({
        ...virtualPagination,
        current: virtualPage,
        total: data?.dataCount,
      });
    }
  }, [data]);

  return {
    virtualPagination,
    // virtualData,
    virtualPage,
    virtualRefech,
    handleVirtualTradeTable,
    isFetching,
  };
}

export default useVirtualStrategyDetails;
