import axios from 'axios';
import React, { useCallback, useState } from 'react';
import config from '../../Config/config';
import { Form, Input } from 'antd';
import useLogout from './useLogout';

import useToGetId from './useToGetId';
import { checkEmail } from '../helpers/api/Auth/auth';
import { toast } from 'react-toastify';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../utils/defaultClasses';
import OtpComponent from '../Common/OtpComponent';
import OtpTimer from '../Common/OtpTimer';

function useChangeEmail(profileDetailsOfUser) {
  const [form] = Form.useForm();
  const { logoutUser } = useLogout();
  // const { userid } = useToGetId();
  const token = localStorage.getItem('token');
  const [newEmail, setNewEmail] = useState();
  const [otp, setOTP] = useState();
  const [otpError, setOtpError] = useState();
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [showEmailVerify, setShowEmailVerify] = useState(false);
  const [ButtonLoader, setButtonLoader] = useState(false);

  const getCloseModal = (value) => {
    setIsEmailModalVisible(value);
    setShowEmailVerify(value);
  };

  const submitVerifyEmail = () => {
    let payload = {
      new_email: newEmail,
      otp: otp,
    };
    axios
      .post(`${config.base_url}${config.change_email_otp}`, payload, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setIsEmailModalVisible(false);
        setTimeout(() => {
          logoutUser();
        }, 2000);
      })
      .catch((error) => {
        console.log('err', error);
        setOtpError('Invalid OTP');
      });
  };

  const submitForm = useCallback(() => {
    let testOTP = otp ? otp?.length : 0;
    console.log(testOTP);
    if (testOTP < 6) {
      setOtpError('OTP should be of 6 digit code');
    } else {
      submitVerifyEmail();
    }
  }, [otp]);

  const udateOTP = useCallback((value) => {
    if (value.length >= 6) {
      setOtpError();
    }
    setOTP(value);
  }, []);

  // Custom validation rules for old and new emails
  const customValidationRules = {
    currentEmail: {
      validator: (_, value) => {
        if (!value) {
          return Promise.reject('Please enter the current email');
        }
        if (value !== profileDetailsOfUser?.email) {
          return Promise.reject('Email is incorrect!');
        }

        return Promise.resolve();
      },
    },
    newEmail: {
      validator: async (_, value) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!value) {
          return Promise.reject('Please enter the new email');
        }
        if (!emailPattern.test(value)) {
          return Promise.reject('Email format is incorrect!');
        }
        const res = await checkEmail(value);
        if (!res?.data?.status) {
          return Promise.reject('Email id already exists');
        }
        return Promise.resolve();
      },
    },
  };

  const onFinish = (values) => {
    const updateEmail = {
      old_email: values?.currentEmail,
      new_email: values?.newEmail,
    };
    setNewEmail(values?.newEmail);
    setButtonLoader(true);
    axios
      .post(`${config.base_url}${config.change_user_email}`, updateEmail, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setButtonLoader(false);
        setShowEmailVerify(true);
      })
      .catch((error) => {
        setButtonLoader(false);
        console.log(error);
        return toast.error('something went wrong server error', {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  const resendEmailVerify = () => {
    axios
      .get(`${config.base_url}${config.verify_email}/${newEmail}`, {
        headers: { Authorization: `Bearer ${token}` },
      })

      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getEmailmodal = () => {
    if (!showEmailVerify) {
      return (
        <div className="col-12">
          <Form
            className="gap-3 space-y-3"
            layout="vertical"
            onFinish={onFinish}
            validateMessages={customValidationRules}
          >
            <Form.Item
              label={
                <>
                  <span>Current Email Address</span>
                  <span className="text-red-2">*</span>
                </>
              }
              name="currentEmail"
              rules={[
                {
                  validator: customValidationRules.currentEmail.validator,
                },
              ]}
              className="mb-0"
            >
              <Input
                className={`${formClass} form-control`}
                placeholder="Enter Current Email"
                name="old_email"
              />
            </Form.Item>

            <Form.Item
              label={
                <>
                  <span>New Email Address</span>
                  <span className="text-red-2">*</span>
                </>
              }
              name="newEmail"
              rules={[{ validator: customValidationRules.newEmail.validator }]}
              className="mb-0"
            >
              <Input
                className={`${formClass} form-control`}
                placeholder="Enter new email"
                name="new_email"
              />
            </Form.Item>

            <div className="d-flex align-items-center justify-end mt-4 gap-2">
              <button
                onClick={() => setIsEmailModalVisible(false)}
                type="button"
                className={`${btnSecondayClass}`}
              >
                Cancel
              </button>
              <button
                className={`${btnPrimaryClass}`}
                htmlType="submit"
                // onClick={updateEmailID}
                loading={ButtonLoader}
              >
                Send Verification Email
              </button>
            </div>
          </Form>
        </div>
      );
    } else {
      return (
        <>
          <div className="pt-2 mb-4 flex flex-col">
            <p class="text-[13px] text-color-secondary/90">
              Please check your new email for the 6-digit authentication code.
            </p>
          </div>
          <div>
            <Form form={form} layout="vertical" onFinish={submitForm}>
              <div>
                <p class="text-[14px] text-color-primary mb-0.5">
                  Type your 6 digit security code
                </p>
              </div>
              <div>
                <OtpComponent props={{ udateOTP }} />
                {otpError !== '' ? (
                  <>
                    <div className="ant-form-item-explain ant-form-item-explain-connected mt-1">
                      <div
                        className="ant-form-item-explain-error"
                        style={{ marginTop: '0px' }}
                      >
                        {otpError}
                      </div>
                    </div>
                  </>
                ) : null}{' '}
              </div>
              <div className="text-center mt-4 mb-1">
                <button
                  type="primary"
                  htmlType="submit"
                  className={`${btnPrimaryClass} w-full`}
                >
                  Verify your account
                </button>
              </div>
              <div className="text-center">
                <OtpTimer
                  minutes={1}
                  seconds={30}
                  text="Resend OTP Link in"
                  ButtonText="Resend OTP"
                  // isButton={false}
                  background="none"
                  textColor="unset"
                  buttonColor="#cc8411"
                  className={`w-full`}
                  // isError={mutation.isError}
                  resend={() => {
                    resendEmailVerify();
                    // resendOTP.mutate();
                  }}
                  // darkTheme={darkTheme}
                />
              </div>
            </Form>
          </div>
        </>
      );
    }
  };
  return {
    isEmailModalVisible,
    getCloseModal,
    showEmailVerify,
    getEmailmodal,
    setIsEmailModalVisible,
  };
}

export default useChangeEmail;
