import React from 'react';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../utils/defaultClasses';
import { Form, Input, Select } from 'antd';
import { BsChevronDown } from 'react-icons/bs';

export default function StrategyFilter() {
  return (
    <div>
      <div>
        <Form
          //   form={}
          // onFinish={handleSubmit}
          className="w-full"
          autoComplete="off"
          // onFieldsChange={() => dispatch(updateForm(true))}
          // ariaautocomplete="off"
        >
          <div className="space-y-4">
            <div className="relative">
              <div className="flex justify-start items-center gap-2 mb-1 text-color-secondary">
                <div className="font-medium mb-0">Filter by Instrument</div>
              </div>
              <Form.Item
                noStyle
                className="mb-0"
                // key={index}
                // name={`filter${index}`}
              >
                <Select
                  // defaultValue="lucy"

                  placeholder={
                    <>
                      <span className="text-slate-400">Select Instrument</span>
                    </>
                  }
                  size="lg"
                  // onChange={handleChange}
                  options={[
                    {
                      value: 'NIFTY',
                      label: 'NIFTY',
                    },
                    {
                      value: 'NIFTY BANK',
                      label: 'NIFTY BANK',
                    },
                  ]}
                  className={'min-h-[38px]'}
                  suffixIcon={
                    <>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <BsChevronDown
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </>
                  }
                />
              </Form.Item>
            </div>
            <div className="relative">
              <div className="flex justify-start items-center gap-2 mb-1 text-color-secondary">
                <div className="font-medium mb-0">Filter by Status</div>
              </div>
              <Form.Item
                noStyle
                className="mb-0"
                // key={index}
                // name={`filter${index}`}
              >
                <Select
                  // defaultValue="lucy"

                  placeholder={
                    <>
                      <span className="text-slate-400">Select Status</span>
                    </>
                  }
                  size="lg"
                  // onChange={handleChange}
                  options={[
                    {
                      value: 'Pending',
                      label: 'Pending',
                    },
                    {
                      value: 'Active',
                      label: 'Active',
                    },
                  ]}
                  className={'min-h-[38px]'}
                  suffixIcon={
                    <>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <BsChevronDown
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </>
                  }
                />
              </Form.Item>
            </div>
            <div className="relative">
              <div className="flex justify-start items-center gap-2 mb-1 text-color-secondary">
                <div className="font-medium mb-0">Filter by Strategy</div>
              </div>
              <Form.Item
                noStyle
                className="mb-0"
                // key={index}
                // name={`filter${index}`}
              >
                {/* <Input
                  placeholder="Enter Strategy"
                  name="name"
                  className={`${formClass}`}
                  // style={{
                  //   fontSize: '14px',
                  //   fontWeight: '400',
                  //   height: '40px',
                  //   borderRadius: '0.375rem',
                  // }}
                /> */}
                <Select
                  // defaultValue="lucy"

                  placeholder={
                    <>
                      <span className="text-slate-400">Select Strategy</span>
                    </>
                  }
                  size="lg"
                  // onChange={handleChange}
                  options={[
                    {
                      value: 'Strategy 1',
                      label: 'Strategy 1',
                    },
                    {
                      value: 'Strategy 2',
                      label: 'Strategy 2',
                    },
                  ]}
                  className={'min-h-[38px]'}
                  suffixIcon={
                    <>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <BsChevronDown
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </>
                  }
                />
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
      <div className="flex justify-end items-center gap-2 mt-[28px]">
        <div>
          <button
            className={`${btnSecondayClass}`}
            type="button"
            onClick={() => {}}
          >
            Clear
          </button>
        </div>

        <div>
          <button
            className={`${btnPrimaryClass}`}
            type="button"
            // disabled={!form.isFieldsTouched()}
            onClick={() => {}}
          >
            Filter
          </button>
        </div>
      </div>
    </div>
  );
}
