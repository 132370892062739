import { useState } from 'react';

export function useDisplayImageVideo(updateProfilePicture) {
  const [imgError, setImgError] = useState('');
  // const [ImgFiles, setFiles] = useState();
  const [profileImage, setProfileImage] = useState();

  function uploader(e, type = null) {
    // console.log('type', type);
    e.preventDefault();
    const imgFile = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(imgFile);
    reader.addEventListener('load', (e) => {
      e.preventDefault();
      // console.log('imgFile', imgFile);
      // setFiles(imgFile ? imgFile : '');
      setProfileImage(e.target.result);
      if (checkImgValidation(imgFile, setImgError)) {
        updateProfilePicture(imgFile);
      }
    });
  }

  return {
    imgError,
    profileImage,
    setProfileImage,
    uploader,
    setImgError,
  };
}

export const checkImgValidation = (file, setImgError) => {
  if (!file?.url) {
    if (
      file.size > 52428800 ||
      (file?.type?.split('/')[1] !== 'jpeg' &&
        file?.type?.split('/')[1] !== 'jpg' &&
        file?.type?.split('/')[1] !== 'png' &&
        file?.type?.split('/')[1] !== 'gif')
    ) {
      if (file.size > 52428800) {
        setImgError('Profile image should not be greater than 50MB.');
        return false;
      }
      if (
        file?.type?.split('/')[1] !== 'jpeg' &&
        file?.type?.split('/')[1] !== 'jpg' &&
        file?.type?.split('/')[1] !== 'png' &&
        file?.type?.split('/')[1] !== 'gif'
      ) {
        setImgError(
          'Only *png, *jpg, *gif and *jpeg image files are accepted.',
        );

        return false;
      }
    } else {
      setImgError('');
      return true;
    }
  }
};
