import React, { useState, useEffect } from 'react';
import menuBg from '../../../assets/images/menu-header-bg.jpg';
import { Transition } from '@headlessui/react';
import { RiAppsLine } from 'react-icons/ri';
import { afterBorderTop } from '../../utils/defaultClasses';
import { BiSolidChevronRight } from 'react-icons/bi';
import axios from 'axios';
import config from '../../../Config/config';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import moment from 'moment';
import ActiveLogs from './ActiveLogs';
let token = localStorage.getItem('token');
let userid = mydecodedTokenFunction(token);

export default function NotificationContent() {
  const [isToggled, setToggle] = useState('0');
  const [activeLogs, setActiveLogs] = useState([]);

  useEffect(() => {
    async function getLogs() {
      try {
        const response = await axios.get(
          `${config.base_url}${config.active_log}?page=1&limit=10&user_id=${userid.id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const data = response.data;
        setActiveLogs(data?.data);
        console.log(data.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    getLogs();
  }, []);

  const categories = [
    {
      key: 1,
      title: 'Alerts',
      component: (
        <>
          <AlertCompo />
        </>
      ),
    },
    // {
    //   key: 2,
    //   title: 'Updates',
    //   component: (
    //     <>
    //       <AlertCompo />
    //     </>
    //   ),
    // },
    {
      key: 2,
      title: 'Logs',
      component: <ActiveLogs activeLogs={activeLogs} />,
    },
  ];

  const toggleHandle = (key) => {
    setToggle(key);
  };

  return (
    <>
      <div className="dark:text-whiteNew-0 rounded-lg overflow-hidden">
        <div
          className="flex flex-col bg-cover bg-no-repeat w-full h-full"
          style={{ backgroundImage: `url(${menuBg})` }}
        >
          <div className="my-7 px-6 flex items-center gap-3.5 text-whiteNew-0">
            <h3 className="text-lg font-semibold">Notifications</h3>
            <div>
              <span className="text-xs">24 Reports</span>
            </div>
          </div>
          <div className="flex gap-1.5 px-3.5 -mb-[2px]">
            {Object.entries(categories)?.map(([key, value]) => {
              return (
                <button
                  className={`border-b-2  ${
                    isToggled === key
                      ? 'border-blue-2 text-whiteNew-0 font-medium'
                      : 'border-transparent text-whiteNew-0/80 hover:text-whiteNew-0'
                  } px-2.5 py-1.5 group text-sm`}
                  onClick={() => {
                    toggleHandle(key);
                  }}
                >
                  <span className="flex group-hover:-translate-y-[1px] ease-in duration-150">
                    {value?.title}
                  </span>
                </button>
              );
            })}
          </div>
        </div>
        <div>
          {Object.entries(categories)?.map(([key, value]) => {
            return (
              <div className="" key={key}>
                <Transition
                  show={isToggled === key}
                  enter="ease-out duration-150"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-150"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  {isToggled === key ? (
                    <div className="">{value?.component}</div>
                  ) : null}
                </Transition>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
export function AlertCompo() {
  return (
    <>
      <div
        className={`px-3.5 max-h-[300px] overflow-y-auto scrollCss divide-y divide-level-2`}
      >
        <div className="flex justify-between items-center gap-2 py-3.5">
          <div className="flex gap-1.5">
            <div className="">
              <span className="flex bg-yellow-100 text-yellow-500 text-2xl p-1.5 rounded-lg">
                <RiAppsLine />
              </span>
            </div>
            <div className="flex flex-col -space-y-1">
              <span
                className="text-sm font-medium hover:text-blue-2"
                type="button"
              >
                Alert 1
              </span>
              <span className="text-xs text-color-secondary/80">
                Alert Description
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="bg-color-base-2 rounded-md px-1.5 py-0.5 text-sm font-medium">
              1 hr
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-2 py-3.5">
          <div className="flex gap-1.5">
            <div className="">
              <span className="flex bg-green-100 text-green-500 text-2xl p-1.5 rounded-lg">
                <RiAppsLine />
              </span>
            </div>
            <div className="flex flex-col -space-y-1">
              <span
                className="text-sm font-medium hover:text-blue-2"
                type="button"
              >
                Alert 1
              </span>
              <span className="text-xs text-color-secondary/80">
                Alert Description
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="bg-color-base-2 rounded-md px-1.5 py-0.5 text-sm font-medium">
              1 hr
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center gap-2 py-3.5">
          <div className="flex gap-1.5">
            <div className="">
              <span className="flex bg-blue-100 text-blue-500 text-2xl p-1.5 rounded-lg">
                <RiAppsLine />
              </span>
            </div>
            <div className="flex flex-col -space-y-1">
              <span
                className="text-sm font-medium hover:text-blue-2"
                type="button"
              >
                Alert 1
              </span>
              <span className="text-xs text-color-secondary/80">
                Alert Description
              </span>
            </div>
          </div>
          <div className="flex">
            <div className="bg-color-base-2 rounded-md px-1.5 py-0.5 text-sm font-medium">
              1 hr
            </div>
          </div>
        </div>
      </div>
      <div className={`p-3.5 ${afterBorderTop} after:bg-level-2 relative`}>
        <div className="w-full flex justify-center">
          <span
            type={'button'}
            className="flex items-center gap-0.5 text-color-primary text-sm font-medium hover:text-blue-2 duration-100 ease-in"
          >
            View All
            <BiSolidChevronRight />
          </span>
        </div>
      </div>
    </>
  );
}
