import React from 'react';

export default function PortfolioModeStrip({ tab }) {
  console.log(tab);
  return (
    <>
      <div className="w-full flex justify-center relative">
        <div className="bg-color-primaryColor1 h-[3px] w-full flex mb-[-7px]"></div>
        <div className="trapezoid bg-color-primaryColor1 font-medium text-xs text-whiteNew-0">
          {tab === 'virtual' ? 'Virtual' : 'Live'} Trade
        </div>
      </div>
    </>
  );
}
