import React, { useState } from 'react';
import { Popover as AntdPopover } from 'antd';
import { BiSolidMessageRoundedDetail } from 'react-icons/bi';
import { btnSecondayClass } from '../../utils/defaultClasses';
import RequestBrokerContent from './RequestBrokerContent';

export default function BrokerPopover() {
  const [open, setIsOpen] = useState(false);
  const [openNotification, setIsOpenNotification] = useState(false);
  const handleOpenChange = (newOpen) => {
    setIsOpen(newOpen);
  };
  return (
    <AntdPopover
      content={
        <div className="">
          <RequestBrokerContent />
        </div>
      }
      // title="Title"
      trigger="click"
      open={open}
      destroyTooltipOnHide={true}
      arrow={false}
      placement={'bottomLeft'}
      onOpenChange={handleOpenChange}
      overlayClassName="antd-popover"
      overlayStyle={{ paddingTop: '12px' }}
      getPopupContainer={(triggerNode) => triggerNode.parentElement}
      className="mr-2"
    >
      <button
        //   className={`min-h-[33px] min-w-[33px] p-[2px] dark:bg-brown-800 bg-lightTheme-70 hover:bg-lightTheme-80 dark:hover:bg-zinc-700 dark:hover:bg-opacity-90 dark:border-brown-800 rounded-lg dark:text-white flex gap-1 items-center justify-center text-base leading-tight relative`}
        className={`${btnSecondayClass} w-[35px] h-[35px]`}
      >
        <BiSolidMessageRoundedDetail />
      </button>
    </AntdPopover>
  );
}
