import React from 'react';
import { RiTimerLine } from 'react-icons/ri';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { HiMiniAdjustmentsVertical } from 'react-icons/hi2';
import { BiDollarCircle } from 'react-icons/bi';
import { MdOutlineAutoGraph, MdOutlineInstallDesktop } from 'react-icons/md';
import StrategyViewCard from '../StrategyDetails/StrategyViewCard';
import dayjs from 'dayjs';
import { formatINR } from '../../../utils/utils';

function BackTestingDetailCards({ backtestData, strategyData = {} }) {
  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-12 px-0 py-2.5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3">
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Created At'}
                tooltipData={`Creation date of the strategy`}
                subtitle={dayjs(backtestData?.created_at).format(
                  'DD-MMM-YYYY h:mm a',
                )}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
                  </>
                }
                title={'Instrument'}
                tooltipData={`Instrument`}
                // subtitle={backtestData?.config?.symbol}
                subtitle={backtestData?.bt_inputs?.symbol}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <HiMiniAdjustmentsVertical className="text-4xl text-blue-2" />
                  </>
                }
                title={'Min Cap'}
                // subtitle={formatINR(backtestData?.config?.investment)}
                subtitle={formatINR(backtestData?.bt_inputs?.investment)}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Risk'}
                tooltipData={`Risk`}
                subtitle={
                  <>
                    {strategyData?.risk_factor}
                    <BsArrowDownShort className="text-red-2 text-xl" />
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    {strategyData?.growth_factor}
                    <MdOutlineAutoGraph className="text-4xl text-blue-2" />
                  </>
                }
                title={'Growth'}
                tooltipData={`Growth`}
                subtitle={
                  <>
                    <BsArrowUpShort className="text-green-2 text-xl" />
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <BiDollarCircle className="text-4xl text-blue-2" />
                  </>
                }
                title={'Strategy Category'}
                tooltipData={`Strategy Category`}
                subtitle={strategyData?.access_type}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <BiDollarCircle className="text-4xl text-blue-2" />
                  </>
                }
                title={'Strategy Name'}
                tooltipData={`Strategy Name`}
                subtitle={strategyData?.name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(BackTestingDetailCards);
