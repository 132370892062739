import React from 'react';
import StatsUsageCard from './StatsUsageCard';
import { MdOutlineSpeed } from 'react-icons/md';
import { useSelector } from 'react-redux';

export default function StatsUsage() {
  const { creditList, premium_strategies, virtual_requests, live_requests } =
    useSelector((state) => state.userCreditsDetails);

  const { featuresPlanData } = useSelector((state) => state.profileDetails);

  const getPercentage = (item) => {
    if (item?.value === 0) {
      return 0;
    }
    if (item?.key === 'virtual_deployments') {
      return (
        (virtual_requests?.requests_used / virtual_requests?.requests_total) *
        100
      );
    }
    if (item?.key === 'live_deployments') {
      return (
        (live_requests?.requests_used / live_requests?.requests_total) * 100
      );
    }
    if (item?.key === ' premium_strategies_subscribe') {
      return (
        (premium_strategies?.strategies_used /
          premium_strategies?.strategies_total) *
        100
      );
    }

    if (typeof item.value === 'number' && item.value !== 0) {
      return (creditList[item.key]?.used / creditList[item.key]?.total) * 100;
    } else if (item.value === '-' || item.value === 'No') {
      return 0;
    } else {
      return 100;
    }
  };
  const showSubtitile = (item) => {
    if (item.value === 0) {
      return <span className="text-red-3">No credits available</span>;
    }
    if (item?.key === 'virtual_deployments') {
      return ` ${virtual_requests?.requests_used} / ${virtual_requests?.requests_total}  used`;
    }
    if (item?.key === 'live_deployments') {
      return ` ${live_requests?.requests_used} / ${live_requests?.requests_total}`;
    }
    if (item?.key === ' premium_strategies_subscribe') {
      return `${premium_strategies?.strategies_used} /
            ${premium_strategies?.strategies_total}`;
    }

    if (typeof item.value === 'number' && item.value !== 0) {
      return `${creditList[item.key]?.used} / ${
        creditList[item.key]?.total
      } used`;
    } else {
      return item.value;
    }
  };
  const infoText = (item) => {
    switch (item.key) {
      case 'strategy_builder':
        return 'Create your custom trading strategies with our strategy builder tool.';
      case 'broker_setup':
        return 'Setup your broker account seamlessly with our broker setup feature.';
      case 'backtesting_credits':
        return 'Number of backtests you can perform with your current credits.';
      case 'historical_data_duration_for_backtesting':
        return 'Historical data available for backtesting.';
      case 'virtual_deployments':
        return 'Number of virtual trades you can launch or start.';
      case 'live_deployments':
        return 'Number of live trades you can launch or start.';
      case 'premium_strategies_subscribe':
        return 'Number of premium strategies you can subscribe.';
      case 'bulk_trades':
        return 'Trade in larger quantities than standard exchange limits with our bulk trades feature.';
      case 'support':
        return 'Available modes of communication for user interaction.';
      case 'derivatives_trading':
        return 'Trade in derivative instruments such as options and futures.';
      case 'equity_trading':
        return 'Trade in equity (cash segment) with this facility.';
      case 'historical_data_api_credits':
        return 'Access historical candle data using APIs with this feature.';
      case 'historical_data_minimum_granularity':
        return 'Minimum timeframe for historical data available.';
      case 'options_historical_chart':
        return 'View historical candlestick charts for options trading data.';
      default:
        return 'No information available.';
    }
  };

  const showStatus = featuresPlanData?.map((item) => {
    const percentage = getPercentage(item);
    const subTitle = showSubtitile(item);
    const info = infoText(item);
    return (
      <StatsUsageCard
        key={item.key} // Add a unique key for each item
        icon={<MdOutlineSpeed className="text-2xl text-color-secondary/80" />}
        percent={percentage}
        title={item?.name}
        subTitle={subTitle}
        // acquire={50}
        // total={'0'}

        size={42}
        infoText={info}
      />
    );
  });

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        {showStatus}
        {/* <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Strategy Builder'}
          subTitle={'Free'}
          acquire={50}
          total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Historical Chart'}
          subTitle={'Free'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Broker Setup'}
          subTitle={'Unlimited'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Broker Setup'}
          subTitle={'Unlimited'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Backtesting Credits'}
          subTitle={'5/Month'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Data Duration'}
          subTitle={'1/Month'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Virtual Deployments'}
          subTitle={'2'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Live Deployments'}
          subTitle={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Premium Strategies'}
          subTitle={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Bulk Trades'}
          subTitle={
            <>
              <span className="text-red-2">No credits available</span>
            </>
          }
          // total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Support'}
          subTitle={<>Email</>}
          // total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Derivatives Trading'}
          subTitle={<>Yes</>}
          // total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Equity Trading'}
          subTitle={
            <>
              <span className="text-red-2">No credits available</span>
            </>
          }
          // total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Historical Data API Credits'}
          subTitle={<>5/Month</>}
          // total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        />
        <StatsUsageCard
          icon={
            <>
              <MdOutlineSpeed className="text-2xl text-color-secondary/80" />
            </>
          }
          percent={90}
          title={'Historical Data Minimum Granularity'}
          subTitle={<>1 Day</>}
          // total={'0'}
          infoText={'No credits are available for free accounts'}
          //   strokeColor
        /> */}
      </div>
    </>
  );
}
