import mydecodedTokenFunction from '../../utils/decodetoken';
// import CircularProgress from '@mui/material/CircularProgress';
// import Box from '@mui/material/Box';
import { Navigate } from 'react-router-dom';
import Sidebar from '../UI/Sidebar';
import SubscriptionExpireStrip from '../../Pages/UserComponents/subscription_upgrade/SubscriptionExpireStrip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getAllPlanDetails } from '../../API/profile/planApi';
import { allPlansList } from '../../features/update-plan/UpdatePlanSlice';
import { getActivePlans } from '../../API/profile/billinghistory';
import {
  userBacktestDeployments,
  userCreditList,
  userLiveDeployments,
  userPremiumStrategies,
  userVirtualDeployments,
} from '../../features/UserCredits/userCreditsSlice';
import { useGetUserByIdQuery } from '../../services/profileDetails';
import useToGetId from '../hooks/useToGetId';
import {
  completeUserDetails,
  userFeaturesData,
  userProfileDetails,
} from '../../features/profile/profilePlanSlice';
import CopyRightFooter from '../Common/CopyRightFooter';
import WelcomeModal from '../Common/WelcomeModal';

function AuthGuard({ children, noSidebar = false }) {
  const _decodedToken = mydecodedTokenFunction(localStorage.getItem('token'));
  // const { remindLaterStrip } = useSelector((state) => state.profileDetails);
  const history = {
    navigate: null,
    location: null,
  };
  let { userid } = useToGetId();
  const dispatch = useDispatch();

  const { overAllPlansList } = useSelector((state) => state.toUpdatePlan);
  const { data, error, isLoading } = useGetUserByIdQuery(userid.id);

  const [creditsData, setCreditsData] = useState();

  useEffect(() => {
    if (error) {
      console.error('Error fetching user data:', error);
    }
    if (!isLoading && data) {
      dispatch(completeUserDetails(data));
      dispatch(userProfileDetails(data?.data));
      if (data?.active_plan?.expired_at) {
        dispatch(
          userFeaturesData(
            data?.active_plan?.subscriptionPlanData?.features_data,
          ),
        );
      }
    }
  }, [data, error, isLoading]);

  const { data: plansList, refetch } = useQuery({
    queryKey: ['subscribed_plans'],
    queryFn: getAllPlanDetails,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    enabled: false,
  });

  useEffect(() => {
    if (plansList) {
      const list = plansList.flatMap(({ plans }) => plans);
      dispatch(allPlansList(list));
    }
  }, [plansList]);

  useEffect(() => {
    if (overAllPlansList.length === 0) {
      refetch();
    }
  }, [overAllPlansList]);

  const getCreditsAvailable = useMutation({
    mutationFn: getActivePlans,
    onSuccess: (res) => {
      setCreditsData(res);
    },
    onError: (err) => { },
  });

  useEffect(() => {
    if (!creditsData) getCreditsAvailable.mutate();
  }, [creditsData]);

  useEffect(() => {
    if (creditsData) {
      dispatch(userCreditList(creditsData));
      dispatch(
        userPremiumStrategies({
          strategies_remaining:
            creditsData?.premium_strategies_subscribe?.remaining,
          strategies_total: creditsData?.premium_strategies_subscribe?.total,
          strategies_used: creditsData?.premium_strategies_subscribe?.used,
        }),
      );
      dispatch(
        userVirtualDeployments({
          requests_remaining: creditsData?.virtual_deployments?.remaining,
          requests_total: creditsData?.virtual_deployments?.total,
          requests_used: creditsData?.virtual_deployments?.used,
        }),
      );
      dispatch(
        userBacktestDeployments({
          requests_remaining: creditsData?.backtest_deployments?.remaining,
          requests_total: creditsData?.backtest_deployments?.total,
          requests_used: creditsData?.backtest_deployments?.used,
        }),
      );
      dispatch(
        userLiveDeployments({
          requests_remaining: creditsData?.live_deployments?.remaining,
          requests_total: creditsData?.live_deployments?.total,
          requests_used: creditsData?.live_deployments?.used,
        }),
      );
    }
  }, [creditsData]);

  return _decodedToken ? (
    <>
      {noSidebar ? null : <Sidebar />}
      <div className="mt-[0] mb-[55px]">
        {children}
        <div className="shrink-0 flex justify-start fixed bottom-0 z-[8] bg-color-base-1 dark:bg-color-base-1 shadow-base dark:border-t dark:border-level-2 left-0 right-0">
          <div className="left__bar mt-0 py-2.5">
            <CopyRightFooter />
          </div>
        </div>
        <WelcomeModal />
      </div>
    </>
  ) : (
    <Navigate to={`/login`} state={{ from: history.location }} />
  );
}

export default AuthGuard;
