import React from 'react';
import { InboxOutlined, UploadOutlined } from '@ant-design/icons';
import { ConfigProvider, message, Upload } from 'antd';
const { Dragger } = Upload;

const props = {
  name: 'file',
  multiple: true,
  // action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  onChange(info) {
    const { status } = info.file;
    if (status !== 'uploading') {
      console.log('Custom Tooltip');
      //console.log(info.file, info.fileList);
    }
    if (status === 'done') {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log('Dropped files', e.dataTransfer.files);
  },
};
function FileUpload({ form, fileList }) {
  const handleFileChange = (e) => {
    // console.log('e', e);
    form.setFieldValue('attachments', e?.fileList ?? []);
  };

  const handleRemove = (file, fileList) => {
    // console.log('e', file, fileList);
    form.setFieldValue(
      'attachments',
      fileList?.filter((f) => f.uid !== file.uid),
    );
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
        },
      }}
    >
      <div>
        {/* <Dragger {...props}> */}
        <Dragger
          multiple
          customRequest={({ file, onSuccess }) => {
            setTimeout(() => {
              onSuccess('ok');
            }, 1000);
          }}
          fileList={fileList}
          onChange={handleFileChange}
          showUploadList={{ showRemoveIcon: true }}
          onRemove={(file) => handleRemove(file, fileList)}
          className="file__upload [&_.ant-upload-list-item]:bg-zinc-100 [&_.ant-upload-list-item]:border-level-2 [&_.ant-upload-list-item]:!p-2 [&_.ant-upload-list-item]:rounded-lg [&_.ant-upload-list-item]:!h-auto [&_.ant-upload-list-item-name]:!text-[#333]"
        >
          <p className="ant-upload-drag-icon mb-1">
            <UploadOutlined className="!text-[20px]" />
          </p>
          <p className="!text-sm text-color-primary">
            Click or drag files to upload
          </p>
          <p className="!text-[12px] text-color-secondary">
            Only JPEG, PNG, PDF and DOC files with max size of 10mb each.
          </p>
        </Dragger>
      </div>
    </ConfigProvider>
  );
}

export default FileUpload;
