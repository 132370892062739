import React, { useState } from 'react';
import dayjs from 'dayjs';

import { Skeleton } from 'antd';
import Tooltip from '../../../components/Common/Tooltip';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { formatINR } from '../../../utils/utils';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

const PortfolioDataCards = ({
  overAllDateRange,
  totalTradesData,
  selectedDateRange,
  totalInvestment,
}) => {
  const [toggleMore, settoggleMore] = useState('hide');

  const toggleShow = (index) => {
    settoggleMore(index);
  };

  return (
    <>
      <div className="d-flex align-items-end justify-content-end my-0 my-lg-0 ">
        {toggleMore === 'open' ? (
          <span className="show_more" onClick={() => toggleShow('hide')}>
            Show Less{' '}
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 256 256"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M216.49,168.49a12,12,0,0,1-17,0L128,97,56.49,168.49a12,12,0,0,1-17-17l80-80a12,12,0,0,1,17,0l80,80A12,12,0,0,1,216.49,168.49Z"></path>
            </svg>
          </span>
        ) : (
          <span className="show_more" onClick={() => toggleShow('open')}>
            Show More{' '}
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 256 256"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M216.49,104.49l-80,80a12,12,0,0,1-17,0l-80-80a12,12,0,0,1,17-17L128,159l71.51-71.52a12,12,0,0,1,17,17Z"></path>
            </svg>
          </span>
        )}
      </div>
      <div className="row portfolio__list__card mt-0 rounded p-0 p-lg-0">
        {totalTradesData?.totalPnl ? (
          <>
            <div className="col sep__line mob rem__line__tp bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div
                    className={
                      totalTradesData?.totalPnl < 0
                        ? `me-2 primary__red fs-18`
                        : totalTradesData?.totalPnl > 0
                        ? `me-2 primary__green fs-18`
                        : ''
                    }
                  >
                    {totalTradesData?.totalPnl < 0 ? (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M99.4 284.9l134 138.1c5.8 6 13.7 9 22.4 9h.4c8.7 0 16.6-3 22.4-9l134-138.1c12.5-12 12.5-31.3 0-43.2-12.5-11.9-32.7-11.9-45.2 0l-79.4 83v-214c0-16.9-14.3-30.6-32-30.6-18 0-32 13.7-32 30.6v214l-79.4-83c-12.5-11.9-32.7-11.9-45.2 0s-12.5 31.2 0 43.2z"></path>
                      </svg>
                    ) : totalTradesData?.totalPnl > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    ) : null}
                  </div>

                  <div
                    className={
                      totalTradesData?.totalPnl < 0
                        ? `primary__red fw-bold fs-18`
                        : `primary__green fw-bold fs-18`
                    }
                  >
                    {formatINR(totalTradesData?.totalPnl)}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Overall P&L
                    <span>
                      <Tooltip
                        title={`Total net P&L from account creation until today`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>

                  <span className="me-2 px-0 py-0 sub__ttl">
                    {overAllDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/10504/10504063.png" />
              </div>
            </div>
            <div className="col sep__line mob rem_ln rem__line__tp bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div className="fw-bold fs-18 primary__text__blue">
                    {formatINR(totalTradesData?.totalBrokerage)}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Overall Brokerage
                    <span>
                      <Tooltip
                        title={`Cumulative brokerage paid`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">
                    {overAllDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/9011/9011634.png" />
              </div>
            </div>
            <div className="col sep__line mob rem__line__tp bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div className="primary__red fw-bold fs-18">
                    {formatINR(totalInvestment.toFixed(2))}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Total Investment
                    <span>
                      <Tooltip
                        title={`Total invested amount in running trades`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">Today</span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/4449/4449895.png" />
              </div>
            </div>

            <div className="col sep__line mob mob__hide bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2 ps-0">
                  <div
                    className={
                      totalTradesData?.filterPnl < 0
                        ? `me-2 primary__red fs-18`
                        : totalTradesData?.filterPnl > 0
                        ? `me-2 primary__green fs-18`
                        : ''
                    }
                  >
                    {totalTradesData?.filterPnl < 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        fill="currentColor"
                        className="bi bi-arrow-down"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                        />
                      </svg>
                    ) : totalTradesData?.filterPnl > 0 ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-arrow-up"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                        />
                      </svg>
                    ) : null}
                  </div>
                  <div
                    className={
                      totalTradesData?.filterPnlL < 0
                        ? `primary__red fw-bold fs-18`
                        : `primary__green fw-bold fs-18`
                    }
                  >
                    {formatINR(totalTradesData?.filterPnl)}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Total P&L
                    <span>
                      <Tooltip
                        title={`Net P&L for the specified dates`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">
                    {selectedDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/6059/6059893.png" />
              </div>
            </div>

            <div className="col sep__line mob rem_ln rem__line__tp bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div className="fw-bold fs-18 primary__green">
                    {formatINR(totalTradesData?.filterBrokerage)}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Total Brokerage
                    <span>
                      <Tooltip
                        title={`Brokerage paid for the specified dates`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">
                    {selectedDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/15586/15586945.png" />
              </div>
            </div>
            <div className="col sep__line mob mob__hide bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div className="fw-bold fs-18 primary__text__blue">
                    {totalTradesData?.totalTrades}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Trades
                    <span>
                      <Tooltip
                        title={`Total trades executed till today`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">
                    {overAllDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/14277/14277902.png" />
              </div>
            </div>
            <div className="col sep__line mob rem_ln mob__hide bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div className="primary__green fw-bold fs-18 flex items-center gap-2">
                    {totalTradesData?.profitTrade === '0' ? null : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 512 512"
                        stroke="currentColor"
                        stroke-width="0"
                      >
                        <path d="M412.6 227.1L278.6 89c-5.8-6-13.7-9-22.4-9h-.4c-8.7 0-16.6 3-22.4 9l-134 138.1c-12.5 12-12.5 31.3 0 43.2 12.5 11.9 32.7 11.9 45.2 0l79.4-83v214c0 16.9 14.3 30.6 32 30.6 18 0 32-13.7 32-30.6v-214l79.4 83c12.5 11.9 32.7 11.9 45.2 0s12.5-31.2 0-43.2z"></path>
                      </svg>
                    )}
                    {totalTradesData?.profitTrade === '0' ? (
                      <>
                        <div className="dark:text-color-secondary">0.00</div>
                      </>
                    ) : (
                      totalTradesData?.profitTrade
                    )}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Profit Trades
                    <span>
                      <Tooltip
                        title={`Profitable trades out of total trades`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">
                    {overAllDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/15186/15186531.png" />
              </div>
            </div>
            <div className="col sep__line mob__hide bx_shadow">
              <div>
                <div className="d-flex d-flex align-items-center mb-2">
                  <div className="primary__red fw-bold fs-18 flex items-center gap-2">
                    {totalTradesData?.lossTrade === '0' ? null : (
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M99.4 284.9l134 138.1c5.8 6 13.7 9 22.4 9h.4c8.7 0 16.6-3 22.4-9l134-138.1c12.5-12 12.5-31.3 0-43.2-12.5-11.9-32.7-11.9-45.2 0l-79.4 83v-214c0-16.9-14.3-30.6-32-30.6-18 0-32 13.7-32 30.6v214l-79.4-83c-12.5-11.9-32.7-11.9-45.2 0s-12.5 31.2 0 43.2z"></path>
                      </svg>
                    )}
                    {totalTradesData?.lossTrade === '0' ? (
                      <>
                        <div className="dark:text-color-secondary">0.00</div>
                      </>
                    ) : (
                      totalTradesData?.lossTrade
                    )}
                  </div>
                </div>
                <div>
                  <h3 className="flex flex-row gap-1 items-center">
                    Loss Trades
                    <span>
                      <Tooltip
                        title={`Losing trades out of total trades`}
                        placement="right"
                        arrow
                      >
                        <AiOutlineQuestionCircle />
                      </Tooltip>
                    </span>
                  </h3>
                  <span className="me-2 px-0 py-0 sub__ttl">
                    {overAllDateRange}
                  </span>
                </div>
              </div>
              <div className="bg__icon">
                <img src="https://cdn-icons-png.flaticon.com/512/9144/9144423.png" />
              </div>
            </div>
          </>
        ) : (
          <>
            {[...Array(8)].map((_, idx) => (
              <div
                className="col sep__line mob rem__line__tp bx_shadow max-h-[95px] overflow-hidden"
                key={idx}
              >
                <div className="w-full">
                  <div className="d-flex align-items-center mb-2 w-full">
                    <div className="primary__red fw-bold fs-18 flex items-center gap-2 w-full max-w-[88%] primary_skeleton">
                      <Skeleton paragraph={{ rows: 2 }} size={'small'} />
                    </div>
                  </div>
                </div>
                <div className="relative p-2.5 w-[45px] h-[45px]">
                  <Skeleton.Input
                    active={true}
                    size={'small'}
                    style={{
                      width: '45px',
                      height: '45px',
                      minWidth: '45px',
                    }}
                    className="absolute inset-0 w-full h-full bg-color-base-2 rounded-md overflow-hidden"
                  />
                </div>
              </div>
            ))}
          </>
        )}

        {toggleMore === 'open' ? (
          <div className="mob_sect">
            <div className="d-flex flex-column">
              <div className="col sep__line mob mb-3">
                <div>
                  <div className="d-flex d-flex align-items-center mb-2 ps-0">
                    <div
                      className={
                        totalTradesData?.filterPnl < 0
                          ? `me-2 primary__red fs-18`
                          : totalTradesData?.filterPnl > 0
                          ? `me-2 primary__green fs-18`
                          : ''
                      }
                    >
                      {totalTradesData?.filterPnl < 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          fill="currentColor"
                          className="bi bi-arrow-down"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1a.5.5 0 0 1 .5.5v11.793l3.146-3.147a.5.5 0 0 1 .708.708l-4 4a.5.5 0 0 1-.708 0l-4-4a.5.5 0 0 1 .708-.708L7.5 13.293V1.5A.5.5 0 0 1 8 1z"
                          />
                        </svg>
                      ) : totalTradesData?.filterPnl > 0 ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-arrow-up"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
                          />
                        </svg>
                      ) : null}
                    </div>
                    <div
                      className={
                        totalTradesData?.filterPnl < 0
                          ? `primary__red fw-bold fs-18`
                          : `primary__green fw-bold fs-18`
                      }
                    >
                      {totalTradesData?.filterPnl}
                    </div>
                  </div>
                  <div>
                    <h3>Total P&L</h3>
                    <span className="me-2 px-0 py-0 sub__ttl">
                      {selectedDateRange}
                    </span>
                  </div>
                </div>
                <div className="bg__icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/6059/6059893.png" />
                </div>
              </div>
              <div className="col sep__line mob bx_shadow mb-3">
                <div>
                  <div className="d-flex d-flex align-items-center mb-2">
                    <div className="fw-bold fs-18 primary__text__blue">
                      {totalTradesData?.totalTrades}
                    </div>
                  </div>
                  <div>
                    <h3>Trades</h3>
                    <span className="me-2 px-0 py-0 sub__ttl">
                      {overAllDateRange}
                    </span>
                  </div>
                </div>
                <div className="bg__icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/14277/14277902.png" />
                </div>
              </div>
              <div className="col sep__line mob rem_ln bx_shadow mb-3">
                <div>
                  <div className="d-flex d-flex align-items-center mb-2">
                    <div className="primary__green fw-bold fs-18">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        fill="currentColor"
                        viewBox="0 0 512 512"
                        stroke="currentColor"
                        stroke-width="0"
                      >
                        <path d="M412.6 227.1L278.6 89c-5.8-6-13.7-9-22.4-9h-.4c-8.7 0-16.6 3-22.4 9l-134 138.1c-12.5 12-12.5 31.3 0 43.2 12.5 11.9 32.7 11.9 45.2 0l79.4-83v214c0 16.9 14.3 30.6 32 30.6 18 0 32-13.7 32-30.6v-214l79.4 83c12.5 11.9 32.7 11.9 45.2 0s12.5-31.2 0-43.2z"></path>
                      </svg>
                      {totalTradesData?.profitTrade === '0' ? (
                        <>
                          <div className="text-color-secondary">0.00</div>
                        </>
                      ) : (
                        totalTradesData?.profitTrade
                      )}
                    </div>
                  </div>
                  <div>
                    <h3>Profit Trades</h3>
                    <span className="me-2 px-0 py-0 sub__ttl">
                      {overAllDateRange}
                    </span>
                  </div>
                </div>
                <div className="bg__icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/15186/15186531.png" />
                </div>
              </div>
              <div className="col sep__line bx_shadow">
                <div>
                  <div className="d-flex d-flex align-items-center mb-2">
                    <div className="primary__red fw-bold fs-18">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 512 512"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M99.4 284.9l134 138.1c5.8 6 13.7 9 22.4 9h.4c8.7 0 16.6-3 22.4-9l134-138.1c12.5-12 12.5-31.3 0-43.2-12.5-11.9-32.7-11.9-45.2 0l-79.4 83v-214c0-16.9-14.3-30.6-32-30.6-18 0-32 13.7-32 30.6v214l-79.4-83c-12.5-11.9-32.7-11.9-45.2 0s-12.5 31.2 0 43.2z"></path>
                      </svg>
                      {totalTradesData?.lossTrade}
                    </div>
                  </div>
                  <div>
                    <h3>Loss Trades</h3>
                    <span className="me-2 px-0 py-0 sub__ttl">
                      {overAllDateRange}
                    </span>
                  </div>
                </div>
                <div className="bg__icon">
                  <img src="https://cdn-icons-png.flaticon.com/512/9144/9144423.png" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default React.memo(PortfolioDataCards);
