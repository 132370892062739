import React from 'react';
import { btnPrimaryClass, btnSecondayClass } from '../utils/defaultClasses';
import Modal from './Modal';

function AlertModal({
  isAlert,
  setIsAlert,
  isLoading = false,
  secondaryButtonName,
  primaryButtonName,
  secondaryButtonAction,
  primaryButtonAction,
  title,
  subtitile,
}) {
  return (
    <Modal
      footer={null}
      open={isAlert}
      setIsOpen={setIsAlert}
      centered
      title={<h3 className="">{title}</h3>}
    >
      <div>
        <p className="pb-4">{subtitile}</p>
        <div className="d-flex justify-start gap-2 align-items-center">
          <button
            onClick={secondaryButtonAction}
            className={`${btnSecondayClass}`}
          >
            {secondaryButtonName}
          </button>
          <button
            className={`${btnPrimaryClass}`}
            onClick={primaryButtonAction}
            disabled={isLoading}
          >
            {primaryButtonName}
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default AlertModal;
