import React, { useState } from 'react';
import { Popover as AntdPopover } from 'antd';
import { useSelector } from 'react-redux';
import { BsFunnel } from 'react-icons/bs';
import { btnSecondayClass } from '../utils/defaultClasses';

export default function PopoverDropdown({
  content,
  isSorting = false,
  children,
  trigger = 'click',
  zIndex,
}) {
  //   const popover = useSelector((state) => state?.Popover);
  //   const [isOpen, setIsOpen] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  //   const {
  //     // open,
  //     // openSort,
  //     // isFiltered = false,
  //     // isSorted,
  //     // filterCount = 0,
  //     // defaultSortCount,
  //   } = popover;
  return (
    <AntdPopover
      content={<div className="p-1.5 min-w-[280px]">{content}</div>}
      // title="Title"
      trigger={trigger}
      //   open={isOpen}
      arrow={false}
      placement={'bottomRight'}
      zIndex={zIndex}
      //   onOpenChange={handleOpenChange}
    >
      {children ? (
        children
      ) : (
        <button
          className={`${btnSecondayClass} min-h-[38px] min-auto rounded-md relative font-normal max-w-[38px] min-w-[38px] mb-0 
         ${isFiltered ? 'border-green-700 border-1' : ''}`}
          //  dark:text-whiteNew-0 text-zinc-800 dark:bg-color-base-3 bg-zinc-200 backdrop-blur-lg dark:hover:bg-brown-700 hover:bg-brown-600 hover:bg-opacity-90 rounded-lg flex gap-1 items-center justify-center w-[34px] h-[34px] text-base leading-tight duration-200 ease-in max-w-[34px] min-w-[34px] relative
        >
          {isFiltered && (
            <span className="flex w-[14px] h-[14px] bg-green-600 rounded-full -top-1 -right-1 absolute text-[10px] justify-center items-center text-whiteNew-0">
              2
            </span>
          )}
          <BsFunnel className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-sm" />
        </button>
      )}
    </AntdPopover>
  );
}
