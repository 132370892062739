import { Checkbox, Form, Input, Radio, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { btnPrimaryClass, formClass } from '../utils/defaultClasses';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { Logo, SpinnerBtn } from '../../assets/Icon';
import useSignUp from './hooks/useSignUp';
import { EMAIL_REG } from '../../utils/utils';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { checkEmail } from '../helpers/api/Auth/auth';

function SignUpV2() {
  // const [value, setValue] = useState('');
  // const [signUpData, setSignUpData] = useState({
  //   firstName: '',
  //   lastName: '',
  //   email: '',
  //   mobileNum: '',
  //   password: '',
  //   confirmPassword: '',
  // });
  // const [emailError, setEmailError] = useState('');
  // const [passwordError, setPasswordError] = useState('');
  // const [agree, setAgree] = useState(false);

  // const handelChange = (e) => {
  //   let list = { ...signUpData, [e.target.name]: e.target.value };
  //   //checkPassword(list);
  //   emailError !== '' && setEmailError('');
  //   setSignUpData({ ...signUpData, [e.target.name]: e.target.value });
  // };
  //console.log(setSignUpData);

  // const onChange = (e) => {
  //   //console.log("radio checked", e.target.value);
  //   setValue(e.target.value);
  // };

  //Signup Function

  const { form, isAgreed, SignUp, isLoading } = useSignUp();

  return (
    <>
      <div className="bg-color-base-2/30 dark:bg-color-base-4">
        <section className="container-xxl">
          <div className="min-h-screen flex justify-center items-center relative z-10">
            <div className="auth-wrapper relative w-full max-w-[540px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
              <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden">
                <Card.Body className="p-[2rem]">
                  <div className="flex justify-center mb-4 mt-2">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                  <div className="pt-2 mb-4 flex flex-col">
                    <h2 className="mb-1 text-lg text-color-primary">
                      Signup Here 🚀
                    </h2>
                    <p class="text-[13px] text-color-secondary/90">
                      Please create your account.
                    </p>
                  </div>
                  <div>
                    <Form
                      className=""
                      layout="vertical"
                      form={form}
                      initialValues={{
                        agree_terms_conditions: false, // Default value for checkbox
                      }}
                      onFinish={SignUp}
                      disabled={isLoading}
                    >
                      <div className="mb-3">
                        <Form.Item
                          // label=""
                          name="role"
                          rules={[
                            {
                              required: true,
                              message: 'Please mention your role.',
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio
                              value={'Business'}
                              className="text-color-primary"
                            >
                              Business
                            </Radio>
                            <Radio
                              value={'Enterprise'}
                              className="text-color-primary"
                            >
                              Enterprise
                            </Radio>
                            <Radio
                              value={'Invidual'}
                              className="text-color-primary"
                            >
                              Invidual
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <Form.Item
                            label={
                              <>
                                <span className="flex items-center gap-0.5">
                                  First Name
                                  <span className="text-red-2">*</span>
                                </span>
                              </>
                            }
                            name="first_name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your first name.',
                              },
                            ]}
                          >
                            <Input
                              className={`${formClass}`}
                              placeholder="First Name"
                              name="firstName"
                              // value={setSignUpData.firstName}
                              // onChange={handelChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-md-6">
                          <Form.Item
                            label={
                              <>
                                <span className="flex items-center gap-0.5">
                                  Last Name
                                  <span className="text-red-2">*</span>
                                </span>
                              </>
                            }
                            name="last_name"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your last name.',
                              },
                            ]}
                          >
                            <Input
                              placeholder="Last Name"
                              name="lastName"
                              className={`${formClass}`}
                              // value={setSignUpData.lastName}
                              // onChange={handelChange}
                            />
                          </Form.Item>
                        </div>

                        <div className="col-12 col-md-6">
                          <Form.Item
                            label={
                              <>
                                <span className="flex items-center gap-0.5">
                                  Email Address
                                  <span className="text-red-2">*</span>
                                </span>
                              </>
                            }
                            name="email"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your email.',
                              },
                              {
                                pattern: EMAIL_REG,
                                message: 'Invalid email.',
                              },
                              ({ getFieldValue }) => ({
                                validator: async (_, value) => {
                                  if (!value || !EMAIL_REG.test(value))
                                    return Promise.resolve(); // Initial condition

                                  // const res = await check_email_while_login(value);
                                  const res = await checkEmail(value);
                                  if (!res?.data?.status) {
                                    return Promise.reject(
                                      new Error(res?.data?.message),
                                    );
                                  } // Email registered as both
                                },
                              }),
                            ]}
                          >
                            <Input
                              className={`${formClass}`}
                              placeholder="Email Address"
                              name="email"
                              // value={setSignUpData.email}
                              // onChange={handelChange}
                            />
                          </Form.Item>
                          {/* {emailError !== '' ? (
                            <>
                              <div className="ant-form-item-explain ant-form-item-explain-connected">
                                <div
                                  className="ant-form-item-explain-error"
                                  style={{ marginTop: '0px' }}
                                >
                                  {emailError}
                                </div>
                              </div>
                            </>
                          ) : null} */}
                        </div>
                        <div className="col-12 col-md-6">
                          <Form.Item
                            label={
                              <>
                                <span className="flex items-center gap-0.5">
                                  Phone No.
                                  <span className="text-red-2">*</span>
                                </span>
                              </>
                            }
                            name="phone"
                            rules={[
                              // {
                              //   required: true,
                              //   message: 'Please enter your Phone Number.',
                              // },
                              {
                                max: 15,
                                message: 'Max. 15 characters allowed.',
                              },
                              // ({ getFieldValue }) => ({
                              //   validator: async (_, value) => {
                              //     if (!value) return Promise.resolve();
                              //     if (
                              //       isValidPhoneNumber(
                              //         value,
                              //         String(getFieldValue('phone_code')).split(
                              //           '+',
                              //         )[0],
                              //       )
                              //     ) {
                              //       const res = await check_phone(
                              //         value,
                              //         getFieldValue('phone_code'),
                              //       );
                              //       if (!res) {
                              //         return Promise.resolve();
                              //       }
                              //       return Promise.reject(
                              //         new Error(
                              //           'Phone number is already associated with other account.',
                              //         ),
                              //       );
                              //     }
                              //     return Promise.reject(
                              //       new Error(
                              //         'Invalid phone number for the selected country code.',
                              //       ),
                              //     );
                              //   },
                              // }),
                            ]}
                          >
                            <Input
                              className={`${formClass}`}
                              placeholder="phone Number"
                              name="mobileNum"
                              // value={setSignUpData.mobileNum}
                              // onChange={handelChange}
                            />
                          </Form.Item>
                        </div>
                        <div className="col-12 col-md-6">
                          <Form.Item
                            label={
                              <span className="flex gap-2 items-center">
                                <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                                  <span className="flex items-center gap-0.5">
                                    Password
                                    <span className="text-red-2">*</span>
                                  </span>
                                </span>

                                <Tooltip
                                  title="Please make sure your password must have 8 characters."
                                  //  meeting the following criteria: [(A-Z),(a-z),(0-9),(!@#$%^&*)]"
                                  placement={'right'}
                                  overlayStyle={{
                                    maxWidth: '400px',
                                  }}
                                >
                                  <span className="pe-auto">
                                    <AiOutlineInfoCircle className="text-zinc-800 dark:text-whiteNew-0" />
                                  </span>
                                </Tooltip>
                              </span>
                            }
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: 'Please enter your password.',
                              },
                              {
                                min: 8,
                                message:
                                  'Please make sure your password must have 8 characters.',
                              },
                            ]}
                            hasFeedback
                          >
                            <Input.Password
                              //className="form-control"
                              placeholder="Create Password"
                              name="password"
                              // value={setSignUpData.password}
                              // onChange={handelChange}
                              className={`${formClass}`}
                              iconRender={(visible) =>
                                visible ? (
                                  <span>
                                    <FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                                  </span>
                                ) : (
                                  <span>
                                    <FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                                  </span>
                                )
                              }
                            />
                          </Form.Item>
                          {/* {passwordError ? (
                            <>
                              <div className="ant-form-item-explain ant-form-item-explain-connected">
                                <div
                                  className="ant-form-item-explain-error"
                                  style={{ marginTop: '0px' }}
                                >
                                  {passwordError}
                                </div>
                              </div>
                            </>
                          ) : null} */}
                        </div>
                        <div className="col-12 col-md-6">
                          <Form.Item
                            type="password"
                            label={
                              <>
                                <span className="flex items-center gap-0.5">
                                  Confirm Password
                                  <span className="text-red-2">*</span>
                                </span>
                              </>
                            }
                            name="verfiy_password"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Please confirm your password.',
                              },
                              ({ getFieldValue }) => ({
                                validator: (_, value) => {
                                  if (
                                    getFieldValue('password') === value ||
                                    !value
                                  )
                                    return Promise.resolve();
                                  return Promise.reject(
                                    new Error('Your password does not match.'),
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input.Password
                              //className="form-control"
                              placeholder="Confirm Password"
                              name="confirmPassword"
                              // value={setSignUpData.confirmPassword}
                              // onChange={handelChange}
                              className={`${formClass}`}
                              iconRender={(visible) =>
                                visible ? (
                                  <span>
                                    <FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                                  </span>
                                ) : (
                                  <span>
                                    <FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                                  </span>
                                )
                              }
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-12 flex items-center gap-2">
                        <Form.Item
                          name="agree_terms_conditions"
                          valuePropName="checked"
                          className="mb-0"
                        >
                          <Checkbox className="text-xs text-color-secondary" />
                          {/* </Checkbox> */}
                        </Form.Item>
                        <span className="text-xs text-color-secondary">
                          I agree to all the{' '}
                          <Link
                            to="#"
                            style={{ color: '#4074f9', fontWeight: '500' }}
                          >
                            Terms, Privacy Policy
                          </Link>{' '}
                          and{' '}
                          <Link
                            to="#"
                            style={{ color: '#4074f9', fontWeight: '500' }}
                          >
                            Fees
                          </Link>
                        </span>
                      </div>
                      <div className="text-left mt-3">
                        <Form.Item>
                          <button
                            type="primary"
                            htmlType="submit"
                            className={`${btnPrimaryClass} w-full`}
                            // onClick={SignUp}
                            disabled={!isAgreed || isLoading}
                          >
                            {isLoading ? (
                              <SpinnerBtn className={'text-lg'} />
                            ) : (
                              'SIGN UP'
                            )}
                          </button>
                        </Form.Item>
                      </div>
                      <div className="text-color-primary text-center">
                        Already have an Account?{' '}
                        <Link
                          className={`text-blue-2 hover:text-blue-2`}
                          to={'/'}
                        >
                          Log in
                        </Link>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <div className="absolute bottom-3 right-3">
            <a href="#" className="text-color-primary hover:text-blue-3">
              Need Help?
            </a>
          </div> */}
          </div>
        </section>
      </div>
      {/* <section className="loginSection">
        <div className="logoOverlay d-none d-lg-block">
          <img src={logo} />
        </div>
        <div className="container">
          <div className="loginPage">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center justify-content-end">
                  <a href="#" className="ndHl me-3">
                    Need Help?
                  </a>
                </div>
              </div>
              <div className="col-12 col-lg-4 col-xl-3">
                <div className="d-lg-none text-center">
                  <img className="mobile_logo" src={dark_logo} />
                </div>
              </div>
              <div className="col-12 col-lg-7 col-xl-5">
                <div className="mt-5">
                  <h1>Signup</h1>
                </div>
                <div>
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={'Business'}>BUSINESS</Radio>
                    <Radio value={'Enterprise'}>ENTERPRISE</Radio>
                    <Radio value={'Invidual'}>INDIVIDUAL</Radio>
                  </Radio.Group>
                </div>

                <Form className="mt-5" layout="vertical">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="FIRST NAME"
                        name="first_name"
                        rules={[
                          {
                            required: true,
                            message: 'Name field required',
                          },
                        ]}
                      >
                        <Input
                          className="form-control"
                          placeholder="Enter your first name"
                          name="firstName"
                          value={setSignUpData.firstName}
                          onChange={handelChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="LAST NAME"
                        name="last_name"
                        rules={[
                          {
                            required: true,
                            message: 'Last name field required',
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter your last name"
                          name="lastName"
                          value={setSignUpData.lastName}
                          onChange={handelChange}
                        />
                      </Form.Item>
                    </div>

                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="EMAIL ID"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Email field required',
                          },
                        ]}
                      >
                        <Input
                          className="form-control"
                          placeholder="Enter your email"
                          name="email"
                          value={setSignUpData.email}
                          onChange={handelChange}
                        />
                      </Form.Item>
                      {emailError !== '' ? (
                        <>
                          <div className="ant-form-item-explain ant-form-item-explain-connected">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: '0px' }}
                            >
                              {emailError}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="PHONE NO."
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: 'Phone field required',
                          },
                        ]}
                      >
                        <Input
                          className="form-control"
                          placeholder="Enter your phone number"
                          name="mobileNum"
                          value={setSignUpData.mobileNum}
                          onChange={handelChange}
                        />
                      </Form.Item>
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        label="CREATE PASSWORD"
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: 'Password field required',
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          //className="form-control"
                          placeholder="Enter password"
                          name="password"
                          value={setSignUpData.password}
                          onChange={handelChange}
                          style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            height: '40px',
                            borderRadius: '0.375rem',
                          }}
                        />
                      </Form.Item>
                      {passwordError ? (
                        <>
                          <div className="ant-form-item-explain ant-form-item-explain-connected">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: '0px' }}
                            >
                              {passwordError}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="col-12 col-md-6">
                      <Form.Item
                        type="password"
                        label="CONFIRM PASSWORD"
                        name="verfiy_password"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: 'Password field required',
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue('password') === value
                              ) {
                                return Promise.resolve();
                              }

                              return Promise.reject(
                                new Error(
                                  'The two passwords that you entered do not match!',
                                ),
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          //className="form-control"
                          placeholder="Verify password"
                          name="confirmPassword"
                          value={setSignUpData.confirmPassword}
                          onChange={handelChange}
                          style={{
                            fontSize: '14px',
                            fontWeight: '400',
                            height: '40px',
                            borderRadius: '0.375rem',
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="col-12">
                    <Form.Item
                      name="remember"
                      valuePropName="checked"
                      className="mb-0"
                    >
                      <Checkbox
                        className="rememberMe"
                        onClick={() => {
                          agree ? setAgree(false) : setAgree(true);
                        }}
                      >
                        I agree to all the{' '}
                        <a style={{ color: '#4074f9', fontWeight: '500' }}>
                          Terms, Privacy Policy
                        </a>{' '}
                        and{' '}
                        <a style={{ color: '#4074f9', fontWeight: '500' }}>
                          Fees
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </div>
                  <div className="text-left mt-3">
                    {agree ? (
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="allBtns"
                          onClick={SignUp}
                        >
                          SIGN UP
                        </Button>
                      </Form.Item>
                    ) : (
                      <Form.Item>
                        <Button
                          htmlType="submit"
                          className="allBtns"
                          onClick={SignUp}
                          disabled
                          style={{ backgroundColor: 'rgb(141,129,242)' }}
                        >
                          SIGN UP
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                  <div className="text-center">
                    Already have an Account?{' '}
                    <Link
                      style={{ color: '#4074f9', fontWeight: '500' }}
                      to={'/'}
                    >
                      Log in
                    </Link>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default SignUpV2;
