import React, { useCallback, useMemo, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Tabs } from 'antd';
import NoDataFoundV2 from './NoDataFoundV2';
import { btnPrimaryClass } from '../utils/defaultClasses';
import ClosedOrders from '../UI/Portfolio/ClosedOrders';
import PortfolioMobileCards from '../../utils/PortfolioMobileCards';
import PortfolioOpenCards from '../../utils/PortfolioOpenCards';
import TradeOrders from '../UI/Portfolio/TradeOrders';
import Heatmap from '../UI/Heatmap';
import { useNavigate } from 'react-router-dom';
import NeedHelpButtonDrawer from '../UI/NeedHelpButtonDrawer';
import RefreshButton from './RefreshButton';
import { getTradeExit } from '../../API/OrderExit';
import CustomDatePicker from './CustomDatePicker';
import usePortfolioTables from '../hooks/usePortfolioTables';
import Tooltip from './Tooltip';

const { TabPane } = Tabs;

const PortfolioReportTabs = ({
  availableStrategies,
  tab,
  isMobile = false,
  strategyResult,
  previousTrades,
  lastOrder,
  pnlData,
}) => {
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState('openOrder');
  const [isChecked, setIsChecked] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [closedOrdersDate, setClosedOrdersDate] = useState([]);
  const [tradesOrdersDate, setTradesOrdersDate] = useState([]);

  const [tradesPagination, setTradesPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [closedOrdersPagination, setClosedOrdersPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [closedOrderDateRangeValue, setClosedOrderDateRangeValue] = useState([
    null,
    null,
  ]);
  const [tradeOrderDateRangeValue, setTradeOrderDateRangeValue] = useState([
    null,
    null,
  ]);

  // const { pnlData } = usePortfolioTables();
  //selected checkbox
  const handleCheckboxChange = useCallback(
    (request_id) => {
      setSelectedItems((prevSelected) => {
        if (prevSelected.includes(request_id)) {
          return prevSelected.filter((item) => item !== request_id);
        } else {
          return [...prevSelected, request_id];
        }
      });
    },
    [selectedItems],
  );

  const handleKillSwitch = useCallback(
    (status) => {
      const checkedReportNames = lastOrder.filter((obj) =>
        selectedItems.includes(obj.request_id),
      );

      const credentialData = lastOrder
        .filter((obj) => selectedItems?.includes(obj.request_id))
        .map((obj) => ({
          ...obj,
          credential_id: availableStrategies
            .flatMap((obj) => obj.ltList || [])
            .find((item) => item.request_id === obj.request_id)?.credential_id,
        }));

      if (status !== 'day-exit') {
        setIsChecked(true);
        setTimeout(() => setIsChecked(false), 500);
      }
      const itemsToProcess =
        tab === 'live' ? credentialData : checkedReportNames;

      itemsToProcess.forEach((obj) => getTradeExit(obj, status, tab));
      setSelectedItems([]);
    },
    [availableStrategies, selectedItems, tab, lastOrder.length],
  );

  const handleTradesDateChange = useCallback(
    (date, dateString) => {
      setTradeOrderDateRangeValue(date);
      setTradesOrdersDate(dateString);
      pnlData(dateString);
    },

    [pnlData],
  );

  const handleClosedOrderDateChange = useCallback(
    (date, dateString) => {
      setClosedOrderDateRangeValue(date);
      setClosedOrdersDate(dateString);
      pnlData(dateString);
    },
    [pnlData],
  );

  const onTabChange = useCallback(
    (key) => {
      if (tradesOrdersDate[0] || closedOrdersDate[0]) {
        pnlData('Today');
      }
      if (tradesOrdersDate[0]) {
        setTradesOrdersDate([]);
        setTradesPagination({ ...tradesPagination, current: 1 });
        setTradeOrderDateRangeValue([null, null]);
      }
      if (closedOrdersDate[0]) {
        setClosedOrdersDate([]);
        setClosedOrdersPagination({ ...closedOrdersPagination, current: 1 });
        setClosedOrderDateRangeValue([null, null]);
      }
      setToggleState(key);
    },
    [
      closedOrdersDate,
      closedOrdersPagination,
      pnlData,
      tradesOrdersDate,
      tradesPagination,
    ],
  );

  const showCalender = useMemo(() => {
    if (
      (strategyResult?.length !== 0 && availableStrategies?.length > 0) ||
      previousTrades?.orders_data?.length !== 0
    ) {
      if (toggleState === 'openOrder') {
        return (
          <>
            <div
              style={{
                display: `${selectedItems?.length === 0 ? 'none' : 'block'}`,
              }}
            >
              <div className="d-flex btn_swipe_exit mx-2">
                <div className="button" id="button-1">
                  <input
                    type="checkbox"
                    className="checkbox"
                    checked={isChecked}
                    disabled={selectedItems?.length === 0}
                    onClick={() => handleKillSwitch('order-exit')}
                  />
                  <div className="knobs"></div>
                  <div className="layer"></div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: `${selectedItems?.length === 0 ? 'none' : 'block'}`,
              }}
            >
              <button
                className="d-flex btn__exit mx-2"
                onClick={() => handleKillSwitch('day-exit')}
              >
                <Tooltip title="Kill Switch" placement="top">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-power"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7.5 1v7h1V1h-1z" />
                    <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                  </svg>
                </Tooltip>
              </button>
            </div>
          </>
        );
      } else if (toggleState === 'closeOrder') {
        return (
          <CustomDatePicker
            onChange={handleClosedOrderDateChange}
            value={closedOrderDateRangeValue}
          />
        );
      } else if (toggleState === 'trades') {
        return (
          <CustomDatePicker
            onChange={handleTradesDateChange}
            value={tradeOrderDateRangeValue}
          />
        );
      }
    } else {
      return null;
    }
  }, [
    availableStrategies,
    closedOrderDateRangeValue,
    handleClosedOrderDateChange,
    handleKillSwitch,
    handleTradesDateChange,
    isChecked,
    previousTrades,
    selectedItems,
    strategyResult,
    toggleState,
    tradeOrderDateRangeValue,
  ]);
  const showTable = useMemo(() => {
    const renderNoDataFound = () => (
      <NoDataFoundV2
        // icon={
        //   <></>
        //   // <div className="-my-7">
        //   //   <img
        //   //     src={strategy_img}
        //   //     alt="upgrade plan"
        //   //     className="w-full h-full object-contain"
        //   //   />
        //   // </div>
        // }
        title={
          <div className="max-w-[450px]">
            <h1 className="text-xl text-center font-semibold">
              {availableStrategies?.length > 0
                ? `No ${tab === 'live' ? 'Live' : 'Virtual'} trade is running`
                : `Oops! you haven't subscribed with any strategy yet.`}
            </h1>
            <div className="mt-0">
              <div className="flex flex-col items-center justify-center space-y-4">
                <p className="">
                  To
                  {availableStrategies?.length > 0
                    ? ` run a ${tab === 'live' ? 'Live' : 'Virtual'} trade`
                    : ' subscribe a strategy'}
                  please click on the below button to get started with.
                </p>
                <button
                  onClick={() =>
                    navigate(
                      availableStrategies?.length > 0
                        ? '/strategies'
                        : '/marketplace',
                    )
                  }
                  className={btnPrimaryClass}
                >
                  {availableStrategies?.length > 0
                    ? `Setup ${tab === 'live' ? 'Live' : 'Virtual'} Request`
                    : 'Subscribe Strategy'}
                </button>
              </div>
            </div>
          </div>
        }
        className="text-4xl"
        minHeight={'py-2'}
      />
    );

    const renderPortfolioCards = () =>
      !isMobile ? (
        <PortfolioOpenCards
          tab={tab}
          lastOrder={lastOrder}
          selectedItems={selectedItems}
          handleCheckboxChange={handleCheckboxChange}
          availableStrategies={availableStrategies}
          previousTrades={previousTrades}
          strategyTrades={strategyResult}

          // userDetails={userDetails}
        />
      ) : (
        <PortfolioMobileCards
          // lastOrder={lastOrder}
          // selectedItems={selectedItems}
          // handleCheckboxChange={handleCheckboxChange}
          tab={tab}
        />
      );

    const operations = (
      <>
        <div className="w-auto d-flex justify-content-end align-items-center pe-2 pe-lg-0 gap-2">
          <div>
            <NeedHelpButtonDrawer />
          </div>
          {showCalender}

          <div>
            <RefreshButton />
          </div>
        </div>
      </>
    );

    return (
      <div className="">
        <Tabs
          activeKey={toggleState}
          onChange={onTabChange}
          className="strtgy__tbl"
          tabBarExtraContent={operations}
        >
          <TabPane tab="Open Order" key="openOrder">
            {strategyResult.length === 0 &&
            availableStrategies?.length > 0 &&
            previousTrades?.orders_data?.length === 0
              ? renderNoDataFound()
              : renderPortfolioCards()}
          </TabPane>
          <TabPane tab="Closed Order" key="closeOrder">
            <>
              {/* <div className="col ps-0 pe-0 mt-2"> */}
              <ClosedOrders
                // isMobile={isMobile}
                pagination={closedOrdersPagination}
                setPagination={setClosedOrdersPagination}
                tab={tab}
                closedOrdersDate={closedOrdersDate}
              />
            </>
            {/* </div> */}
          </TabPane>
          <TabPane tab="Trades" key="trades">
            {/* <div className="col ps-0 pe-0 mt-2"> */}
            <>
              <TradeOrders
                isMobile={false}
                tradesOrdersDate={tradesOrdersDate}
                tab={tab}
                pagination={tradesPagination}
                setPagination={setTradesPagination}
              />
            </>
            {/* </div> */}
          </TabPane>
          <TabPane tab="P&L" key="p&l">
            <div className="">
              <div className="heatmap__bg">
                <Heatmap from="portfolio" tab={tab} />
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }, [
    showCalender,
    toggleState,
    onTabChange,
    strategyResult,
    availableStrategies,
    previousTrades,
    closedOrdersPagination,
    tab,
    closedOrdersDate,
    tradesOrdersDate,
    tradesPagination,
    navigate,
    isMobile,
    lastOrder,
    selectedItems,
    handleCheckboxChange,
  ]);

  return (
    <>
      <div className="row ">
        <div className="strtgy__table__box mt-1.5 lg:mt-3 bx_shadow sticky">
          {showTable}
          {/* <div className="strtgy__table__drpdwn">
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                {dropDownCall()}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => setToggleState('openOrder')}
                  className={toggleState === 'openOrder' ? 'active' : 'tabs'}
                >
                  Open Order
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setToggleState('closeOrder')}
                  className={toggleState === 'closeOrder' ? 'active' : 'tabs'}
                >
                  Closed Order
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setToggleState('trades')}
                  className={toggleState === 'trades' ? 'active' : 'tabs'}
                >
                  Trades
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => setToggleState('p&l')}
                  className={toggleState === 'p&l' ? 'active' : 'tabs'}
                >
                  PNL
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default React.memo(PortfolioReportTabs);
