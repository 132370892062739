import { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Routers from './Routers';

function App() {
  const [theme, setTheme] = useState(() => {
    // Check if a theme is stored in local storage, otherwise use default
    const savedTheme = localStorage.getItem('theme');
    return savedTheme ? savedTheme : 'light';
  });

  const storedDarkMode = localStorage.getItem('theme');
  const [darkMode, setDarkMode] = useState(storedDarkMode);

  useEffect(() => {
    document.body.classList.toggle('dark-theme', theme === 'dark');
    const body = document.querySelector('body');
    if (theme === 'dark') {
      body.classList.add('dark');
      body.classList.remove('light');
    } else {
      body.classList.remove('dark');
      body.classList.add('light');
    }
    // Store the dark mode preference in localStorage
    localStorage.setItem('darkMode', darkMode);
  }, [theme, darkMode]);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        // staleTime: 1000 * 60 * 60 * 5,
        staleTime: 0,
        // suspense: true,
      },
    },
  });

  return (
    <>
      <main className="card_table">
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <BrowserRouter>
              <Routers />
            </BrowserRouter>
          </Provider>
        </QueryClientProvider>
      </main>
    </>
  );
}

export default App;
