import React, { useMemo, useState } from 'react';
import { ConfigProvider, Dropdown } from 'antd';
import {
  DeleteOutlined,
  DownloadOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  PaperClipOutlined,
} from '@ant-design/icons';
// import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
import { MdEdit, MdKeyboardArrowDown } from 'react-icons/md';
import TableComponent from '../../Common/TableComponent';
import { formatINR } from '../../../utils/utils';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Drawer from '../../Common/Drawer';
import UnderProcessDrawer from './UnderProcessDrawer';
import { FaRegEye } from 'react-icons/fa';
import Tooltip from '../../Common/Tooltip';

const StrategyDesktopTableV1 = ({
  strategyDetails,
  deleteRequest,
  handleTableChange,
  pagination,
  loading,
  categorySearch,
}) => {
  // const [open, setOpen] = useState(false);
  // const showStrategyDrawer = () => {
  //   setOpen(true);
  // };
  // const onClose = () => {
  //   setOpen(false);
  // };

  const [underProgress, setUnderProgress] = useState(false);
  const [progressDetails, setProgressDetails] = useState({});
  let history = useNavigate();
  const [selectionType, setSelectionType] = useState('checkbox');

  const columns = [
    {
      title: 'Strategy',
      dataIndex: 'strategy',
      render: (item) => (
        <div
          className="d-flex flex-row align-items-center gap-2"
          onClick={() => {
            if (item?.statusType === 'active') {
              history(`/strategy/${item._id}`);
            } else {
              setProgressDetails(item);
              setUnderProgress(true);
            }
          }}

          // onClick={showStrategyDrawer}
        >
          <p
            className={
              item?.statusType === 'active'
                ? 'cursor-pointer text-bluePrimary-1'
                : 'cursor-pointer text-bluePrimary-1'
            }
          >
            {item.name}
          </p>
          <span
            // className={
            //   item?.statusType === 'active' ? 'text-primary cursor-pointer' : ''
            // }
            className={'text-primary cursor-pointer'}
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 1024 1024"
              fillRule="evenodd"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h360c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H184V184h656v320c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V144c0-17.7-14.3-32-32-32ZM770.87 824.869l-52.2 52.2c-4.7 4.7-1.9 12.8 4.7 13.6l179.4 21c5.1.6 9.5-3.7 8.9-8.9l-21-179.4c-.8-6.6-8.9-9.4-13.6-4.7l-52.4 52.4-256.2-256.2c-3.1-3.1-8.2-3.1-11.3 0l-42.4 42.4c-3.1 3.1-3.1 8.2 0 11.3l256.1 256.3Z"
                transform="matrix(1 0 0 -1 0 1024)"
              ></path>
            </svg>
          </span>

          {item?.document_link && (
            <span>
              <PaperClipOutlined className="text-zinc-950 mt-2 text-primary cursor-pointer" />
            </span>
          )}
        </div>
      ),
    },
    {
      title: 'Instrument',
      dataIndex: 'instrument',
    },
    {
      title: <span>Min. Capital Rqd.</span>,
      dataIndex: 'mincap',
      render: (text) => <div className="">{text}</div>,
    },
    {
      title: 'Risk',
      dataIndex: 'risk',
    },
    {
      title: 'Reports',
      dataIndex: 'report',
      render: (item) => (
        <div className="d-flex flex-row">
          <span className="col px-0 d-flex align-items-center gap-1">
            <span>Virtual Trade :</span>
            <span className="vrtl__trd__badge">
              {item?.vtList.length > 0 ? item?.vtList[0]?.count : '0'}
            </span>
          </span>
          <span className="col px-0 d-flex align-items-center gap-1 py-1">
            <span>Live Trade :</span>
            <span className="lv__trd__badge">
              {item?.ltList.length > 0 ? item?.ltList[0]?.count : '0'}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: 'Strategy Category',
      dataIndex: 'subscribed',
      render: (item) => {
        if (item?.access_type === 'free') {
          return (
            <div className="flex">
              <div className="flex items-center gap-1.5 bg-green-2/15 text-green-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
                <Tooltip title="Free" placement="bottom">
                  <>Free</>
                </Tooltip>
              </div>
            </div>
          );
        } else if (item?.access_type === 'premium') {
          return (
            <div className="flex">
              <div className="flex items-center gap-1.5 bg-blue-2/15 text-blue-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
                <Tooltip title="Premium" placement="bottom">
                  <>Premium</>
                </Tooltip>
              </div>
            </div>
          );
        } else {
          return (
            <div className="flex">
              <div className="flex items-center gap-1.5 bg-red-2/15 text-red-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
                <Tooltip title="Created" placement="bottom">
                  <>Created</>
                </Tooltip>
              </div>
            </div>
          );
        }
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      render: (text) => (
        <>
          <div className={text}>
            <span>{text}</span>
          </div>
        </>
      ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
    },
  ];

  const deleteIconDisabled = (item) => {
    if (item.added_by === 'admin' || item?.statusType === 'processing') {
      return true;
    } else {
      return false;
    }
    //  item?.statusType === 'processing',
  };

  const data = useMemo(
    () =>
      strategyDetails?.map((item) => ({
        key: item?._id,
        strategy: item,

        instrument: (
          <div className="d-flex flex-column">
            <span>{item?.instrument ? item?.instrument : '-'}</span>
          </div>
        ),
        mincap: item?.min_amount_required
          ? formatINR(item?.min_amount_required)
          : '-',
        risk: item?.risk_factor ? item?.risk_factor : '-',
        report: item,
        subscribed: item,
        status:
          item?.statusType.charAt(0).toUpperCase() + item?.statusType.slice(1),
        action: (
          <div className="d-flex gap-2">
            {/* <div className="">
              <Dropdown
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <div className="flex items-center gap-1.5">
                          <EyeOutlined />
                          View
                        </div>
                      ),
                      onClick: () => {
                        history(`/strategy/${item._id}`);
                      },
                      disabled:
                        item?.statusType === 'processing' ||
                        item?.statusType === 'rejected',
                    },
                    {
                      key: '2',
                      label: (
                        <div className="flex items-center gap-1.5">
                          <DeleteOutlined />
                          Delete
                        </div>
                      ),
                      onClick: () => {
                        deleteRequest(item?._id);
                      },
                      disabled: deleteIconDisabled(item),
                    },
                  ],
                }}
                placement="bottomRight"
                overlayClassName={'dropdownOverlay dowpdownDark'}
                trigger={['click']}
                className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
              >
                <div onClick={(e) => e.preventDefault()}>
                  Action
                  <MdKeyboardArrowDown />
                </div>
              </Dropdown>
            </div> */}
            <div className="flex items-center gap-2">
              <Tooltip title={<>View</>}>
                <button
                  onClick={() => {
                    history(`/strategy/${item._id}`);
                  }}
                  disabled={
                    item?.statusType === 'processing' ||
                    item?.statusType === 'rejected'
                  }
                  className="border-[1px] border-solid border-level-2 text-color-primary hover:border-blue-3 hover:bg-blue-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center disabled:opacity-50"
                >
                  <FaRegEye />
                </button>
              </Tooltip>
              <Tooltip title={<>Edit</>}>
                <button
                  onClick={() => {
                    history(`/strategy/${item._id}`);
                  }}
                  disabled={
                    item?.statusType === 'processing' ||
                    item?.statusType === 'rejected'
                  }
                  className="border-[1px] border-solid border-level-2 text-color-primary hover:border-yellow-500 hover:bg-yellow-500 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center disabled:opacity-50"
                >
                  <MdEdit />
                </button>
              </Tooltip>
              <Tooltip title={<>Delete</>}>
                <button
                  onClick={() => {
                    deleteRequest(item?._id);
                  }}
                  disabled={deleteIconDisabled(item)}
                  className="border-[1px] border-solid border-level-2 hover:border-red-3 text-color-primary hover:bg-red-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center disabled:opacity-50"
                >
                  <DeleteOutlined />
                </button>
              </Tooltip>
            </div>
          </div>
        ),
        disabled: item?.added_by === 'admin',
      })),
    [strategyDetails],
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        'selectedRows: ',
        selectedRows,
      );
    },
    getCheckboxProps: (record) => ({
      disabled: record.disabled, // Column configuration not to be checked
    }),
  };

  return (
    <div className="strategy__tbl__data">
      <TableComponent
        className="strategy_tbl"
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        onChange={handleTableChange}
        pagination={pagination}
        loading={loading}
        total={pagination?.total}
        scroll={{ x: '1380px' }}
        emptyDataText={
          strategyDetails?.length === 0 && categorySearch === 'all' ? (
            <h1>No Strategy subscribe yet</h1>
          ) : (
            ''
          )
        }
      />
      <UnderProcessDrawer
        underProgress={underProgress}
        setUnderProgress={setUnderProgress}
        progressDetails={progressDetails}
      />
    </div>
  );
};
export default React.memo(StrategyDesktopTableV1);
