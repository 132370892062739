import { decodeToken, isExpired } from 'react-jwt';
// const userInfo = localStorage.getItem("userInfo");
// const token = userInfo ? JSON.parse(userInfo).token : "";

// console.log(isExpired(token));
//let mydecodedToken = isExpired(token) ? false : decodeToken(token);

export const mydecodedTokenFunction = (token) => {
  const decodedToken = isExpired(token) ? false : decodeToken(token);
  // mydecodedToken = decodedToken;
  return decodedToken;
};
export const checkTokenExpired = (token) => {
  return isExpired(token) ? false : mydecodedTokenFunction(token);
};


//console.log(mydecodedToken);

// export { token, mydecodedToken };
export default mydecodedTokenFunction;
