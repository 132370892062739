import React, { useEffect, useState } from 'react';
import BillingAddressCard, {
  BillingAddressAddCard,
} from './BillingAddressCard';
import {
  createUserAddress,
  getUserAddress,
  updateUserAddress,
  deleteUserAddress,
} from '../../../API/profile/AddressApi';
import { useMutation, useQuery } from '@tanstack/react-query';
import NoAddressAvailable from './NoAddressAvailable';
import NoDataFoundV2 from '../../../components/Common/NoDataFoundV2';
import { FaAddressCard } from 'react-icons/fa';
import { BsPlus } from 'react-icons/bs';
import AddAddressModal from './AddAddressModal';
import AlertModal from '../../../components/Common/AlertModal';

export default function BillingAddress() {
  // const [userAddressList, setUserAddressList] = useState([]);
  const [isAddAddress, setIsAddAddress] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editAddressId, setEditAddressId] = useState('');
  const [deleteAddressId, setDeleteAddressId] = useState('');
  const [addressForm, setAddressForm] = useState({
    first_name: '',
    last_name: '',
    country: null,
    address_1: '',
    address_2: '',
    state: '',
    post_code: '',
    is_primary: false,
  });
  const [deleteModal, setDeleteModal] = useState(false);

  const { data: userAddressList, refetch: addressRefech } = useQuery({
    queryKey: ['addressList'],
    queryFn: getUserAddress,
    refetchOnWindowFocus: false,
  });

  const createAddressList = useMutation({
    mutationFn: (values) => createUserAddress(values),
    onSuccess: (res) => {
      addressRefech();
    },
    onError: (err) => {},
  });

  const updateAddress = useMutation({
    mutationFn: (values) => updateUserAddress(values),
    onSuccess: (res) => {
      addressRefech();
    },
    onError: (err) => {},
  });

  function handleSubmit(values) {
    console.log('values', values);
    // console.log('isEdit', isEdit);
    if (isEdit) {
      let payload = {
        ...values,
        address_id: editAddressId,
      };
      updateAddress.mutate(payload);
    } else {
      createAddressList.mutate(values);
    }
    setIsEdit(false);
  }

  const handleEdit = (item) => {
    setIsEdit(true);
    setEditAddressId(item?._id);
    setAddressForm({
      first_name: item?.first_name,
      last_name: item?.last_name,
      country: item?.country,
      address_1: item?.address_1,
      address_2: item?.address_2,
      state: item?.state,
      post_code: item?.post_code,
      is_primary: item?.is_primary,
    });

    setIsAddAddress(true);
    // console.log('item', item);
  };

  const deleteAddress = useMutation({
    mutationFn: (id) => deleteUserAddress(id),
    onSuccess: (res) => {
      addressRefech();
      // getAdressList.mutate();
    },
    onError: (err) => {},
  });

  const handleDelete = (item) => {
    setDeleteAddressId(item._id);
    setDeleteModal(true);

    // deleteAddress.mutate(item._id);
  };

  return (
    <>
      <div className="space-y-3">
        {userAddressList && userAddressList?.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {userAddressList.map((item, index) => (
              <BillingAddressCard
                key={item?._id}
                index={index}
                item={item}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
              />
            ))}
            <BillingAddressAddCard setIsAddAddress={setIsAddAddress} />
          </div>
        ) : (
          <NoAddressAvailable setIsAddAddress={setIsAddAddress} />
        )}
      </div>
      <AddAddressModal
        isAddAddress={isAddAddress}
        setIsAddAddress={setIsAddAddress}
        handleSubmit={handleSubmit}
        addressForm={addressForm}
        setAddressForm={setAddressForm}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <AlertModal
        isAlert={deleteModal}
        setIsAlert={() => setDeleteModal(false)}
        secondaryButtonName={'Cancel'}
        primaryButtonName={'Delete'}
        secondaryButtonAction={() => setDeleteModal(false)}
        primaryButtonAction={() => {
          setDeleteModal(false);
          deleteAddress.mutate(deleteAddressId);
        }}
        title={null}
        subtitile={'Are you sure want to remove the address??'}
      />
    </>
  );
}
