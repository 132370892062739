import moment from 'moment';
import { HiOutlineDownload } from 'react-icons/hi';
import { FileDownloader, getFileSize, truncate2 } from '../../../utils/utils';
import DOMPurify from 'dompurify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import NoDataFoundV2 from '../../../components/Common/NoDataFoundV2';
import { DataLoader } from '../../../components/Common/DataLoader';
import { useEffect, useMemo, useState } from 'react';
// import FuturisticWhiteLogoSmall from '../../../assets/images/logo/Cliqalogo-04.png';
import FuturisticWhiteLogoSmall from '../../../assets/images/trigerr/Trigerr-05.svg';

export default function SupportChatBody({ props }) {
  const { Pagination, isLoading } = props;
  const { ticketMessageList: ticketList, totalCount } = useSelector(
    (state) => state.selectedTickets,
  );
  // const containerRef = useRef(null);
  // useEffect(() => {
  //   if (containerRef.current) {
  //     containerRef.current.scrollTop = containerRef.current.scrollHeight;
  //   }
  // }, [ticketList]);

  // console.log('Pagination', Pagination);
  // console.log('totalCount', totalCount);

  const infiniteScroll = useMemo(() => {
    if (isLoading && !totalCount)
      return (
        <div className=" w-full h-full min-w-[300px] flex justify-center items-center">
          <DataLoader message={'Loading Conversations...'} />
        </div>
      );
    if (totalCount === 0)
      return (
        <NoDataFoundV2
          title={'No conversations have been started yet.'}
          className="text-4xl"
          minHeight={'min-h-[200px] py-2'}
        />
      );
    return (
      <InfiniteScroll
        dataLength={ticketList?.length}
        next={() => {
          Pagination?.setPage(Pagination?.page + 1);
        }}
        hasMore={Pagination?.hasMore}
        inverse={true} // This makes it scroll upwards
        loader={<h4>Loading...</h4>}
        scrollableTarget="scrollableDiv"
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        <div className="space-y-2">
          {ticketList?.map((item) => (
            <SupportChatMessage item={item} key={item._id} />
          ))}
        </div>
      </InfiniteScroll>
    );
  }, [Pagination, isLoading, ticketList, totalCount]);

  return (
    <>
      <div
        // className={`min-h-[calc(100svh-511px)] max-h-[calc(100svh-201px)] h-full p-3.5 px-6 my-auto flex flex-col space-y-4 overflow-x-hidden overflow-y-auto scrollCss`}
        className={`p-3.5 px-6 my-auto flex flex-col-reverse space-y-4 overflow-x-hidden overflow-y-auto scrollCss`}
        id="scrollableDiv"
        // ref={containerRef}
        style={{
          height: '400px',
        }}
        // max-h-[calc(100svh-511px)]
      >
        {infiniteScroll}
      </div>
    </>
  );
}

export function SupportChatMessage({ item }) {
  const rawHtmlString = item?.description ?? '-';
  const sanitizedHtmlString = DOMPurify.sanitize(rawHtmlString);
  return (
    <>
      {/* <div className="mt-6"> */}
      <div className="space-y-2">
        <div className="flex items-center gap-2.5">
          <div className="relative">
            <div className="h-[35px] w-[35px] rounded-md bg-color-base-2 overflow-hidden ">
              <img
                src={
                  item?.replies_by === 'admin'
                    ? FuturisticWhiteLogoSmall
                    : `https://eu.ui-avatars.com/api/?name=${
                        item?.[
                          item?.replies_by === 'admin'
                            ? 'adminData'
                            : 'userData'
                        ]?.first_name
                      }+${
                        item?.[
                          item?.replies_by === 'admin'
                            ? 'adminData'
                            : 'userData'
                        ]?.last_name
                      }&size=250`
                }
                className="object-contain w-full h-full "
                alt="avatar"
              />
            </div>
          </div>

          <div className="flex flex-col gap-0 -space-y-1">
            <div>
              <span className="flex font-medium text-color-primary">
                <span
                  className={`${
                    item?.replies_by === 'admin' ? 'text-red-3' : ''
                  } flex items-center gap-1.5`}
                >
                  {
                    item?.[
                      item?.replies_by === 'admin' ? 'adminData' : 'userData'
                    ]?.first_name
                  }{' '}
                  {
                    item?.[
                      item?.replies_by === 'admin' ? 'adminData' : 'userData'
                    ]?.last_name
                  }
                </span>
              </span>
            </div>
            <div>
              <span className="flex text-xs text-color-secondary">
                {moment(item?.created_at).format('DD-MM-YYYY HH-MM')}
              </span>
            </div>
          </div>
        </div>
        <div className="pl-[45px] space-y-6">
          <div>
            {<div dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }} />}
          </div>
          <div className="flex items-center gap-3">
            {item?.attachments?.map((docs) => (
              <AttachmentCard doc={docs} key={docs.key} />
            ))}
          </div>
        </div>
      </div>
      {/* <div></div>
      </div> */}
    </>
  );
}

export function AttachmentCard({ doc }) {
  const [fileSize, setFileSize] = useState(0);
  useEffect(() => {
    getFileSize(doc?.url).then((fileSize) => {
      if (fileSize !== undefined) {
        setFileSize(fileSize);
      }
    });
  }, [doc]);

  return (
    <>
      <div className="p-2.5 bg-color-primaryColor1/10 rounded-lg border-[1px] border-color-primaryColor1 flex justify-between gap-3">
        <div className="flex gap-2">
          <div className="capitalize h-[35px] w-[35px] rounded-md overflow-hidden bg-color-primaryColor1 flex justify-center items-center p-1.5 text-xs text-whiteNew-0">
            {doc?.url?.split('.')?.pop() || '-'}
          </div>
          <div className="flex flex-col gap-0 -space-y-1">
            <span className="flex font-medium text-color-primary text-sm">
              <span className="flex items-center gap-1.5">
                {' '}
                {truncate2(doc?.url?.split('/')?.pop(), 6) || '-'}{' '}
              </span>
            </span>
            <span className="flex text-xs text-color-secondary">
              {parseInt(Math.floor(fileSize / 1024))} Kb
              {/* 29 Kb */}
            </span>
          </div>
        </div>
        <div>
          <button
            className="p-1.5 text-blue-2"
            onClick={() => {
              FileDownloader(doc?.url);
            }}
          >
            <HiOutlineDownload />
          </button>
        </div>
      </div>
    </>
  );
}
