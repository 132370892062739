import React, { useMemo } from 'react';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../Common/TableComponent';

const StrategyMobileTableV1 = ({
  strategyDetails,
  deleteRequest,
  handleTableChange,
  pagination,
  loading,
}) => {
  let history = useNavigate();

  const columns = useMemo(
    () => [
      {
        title: 'Strategy',
        dataIndex: 'strategy',
        render: (item) => (
          <div>
            <p
              className={
                item?.statusType === 'active'
                  ? 'cursor-pointer text-bluePrimary-1'
                  : ''
              }
              onClick={() => {
                if (item?.statusType === 'active') {
                  history(`/strategy/${item._id}`);
                }
              }}
            >
              {item.name}
            </p>
          </div>
        ),
      },
      {
        title: 'Instrument',
        dataIndex: 'instrument',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (statustext) => (
          <div>
            <div className={statustext}>
              <span>{statustext}</span>
            </div>
          </div>
        ),
      },
      {
        title: 'Action',
        dataIndex: 'action',
        render: (text) => (
          <div>
            <div className={text}>
              <span>{text}</span>
            </div>
          </div>
        ),
      },
    ],
    [strategyDetails],
  );

  const dataSource = useMemo(
    () =>
      strategyDetails?.map((item) => ({
        key: item?._id,
        strategy: item,

        instrument: (
          <div className="d-flex flex-column">
            <span>{item?.instrument ? item?.instrument : '-'}</span>
          </div>
        ),
        mincap: item?.min_amount_required
          ? `₹${item?.min_amount_required}`
          : '-',
        risk: item?.risk_factor ? item?.risk_factor : '-',
        report: item,
        subscribed: item,
        status:
          item?.statusType.charAt(0).toUpperCase() + item?.statusType.slice(1),
        action: (
          <div className="d-flex gap-2">
            <a className="btn-action act__btn__view">
              <Tooltip title="View" placement="bottom">
                <IconButton
                  disabled={
                    item?.statusType === 'processing' ||
                    item?.statusType === 'rejected'
                  }
                  style={
                    item?.statusType === 'processing' ||
                    item?.statusType === 'rejected'
                      ? { opacity: 0.3 }
                      : {}
                  }
                  onClick={() => {
                    if (item?.statusType === 'active') {
                      history(`/strategy/${item._id}`);
                    }
                  }}
                >
                  <EyeOutlined />
                </IconButton>
              </Tooltip>
            </a>

            <a className="btn-action act__btn__delete">
              <Tooltip title="Delete" placement="bottom">
                <IconButton
                  disabled={item?.statusType === 'processing'}
                  style={
                    item?.statusType === 'processing' ? { opacity: 0.3 } : {}
                  }
                  onClick={() => deleteRequest(item?._id)}
                >
                  <DeleteOutlined />
                </IconButton>
              </Tooltip>
            </a>
          </div>
        ),
      })),
    [],
  );

  const nestedColumns = useMemo(
    () => [
      {
        title: 'Min. Cap',
        dataIndex: 'mincap',
      },
      {
        title: 'Risk',
        dataIndex: 'risk',
      },
      {
        title: 'Subscribed',
        dataIndex: 'subscribed',
      },

      {
        title: 'Reports',
        dataIndex: 'report',
        render: (item) => (
          <div className="d-flex flex-column">
            <span className="col px-0">
              <span>Virtual :</span>
              <span className="vrtl__trd__badge">
                {item?.vtList.length > 0 ? item?.vtList[0].count : '0'}
              </span>
            </span>
            <span className="col px-0 d-flex align-items-center py-1">
              <span>Live &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</span>
              <span className="lv__trd__badge">
                {item?.ltList.length > 0 ? item?.ltList[0].count : '0'}
              </span>
            </span>
          </div>
        ),
      },
    ],
    [strategyDetails],
  );

  return (
    <div>
      <TableComponent
        className="strategy_tbl"
        columns={columns}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <TableComponent
                columns={nestedColumns}
                dataSource={[record]}
                pagination={false}
                loading={loading}
              />
            );
          },
          rowExpandable: (record) => record.name !== 'Not Expandable',
        }}
        dataSource={dataSource}
        onChange={handleTableChange}
        pagination={pagination}
        loading={loading}
        total={pagination?.total}
      />
    </div>
  );
};
export default React.memo(StrategyMobileTableV1);
