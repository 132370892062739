import React, { useEffect, useMemo, useState } from 'react';
import { StatsUsageCardSmall } from '../../../Pages/UserComponents/stats_usage/StatsUsageCard';
import { btnPrimaryClass, btnSecondayClass } from '../../utils/defaultClasses';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import {
  openBacktestingReportFields,
  openLiveReportFields,
  openVirualReportFields,
  setSelectedKeys,
} from '../../../features/strategyReport/strategyDataDetailSlice';
import RefreshButton from '../../Common/RefreshButton';
import NeedHelpButtonDrawer from '../NeedHelpButtonDrawer';
import Tooltip from '../../Common/Tooltip';
import ModelDelete from '../../Common/DeleteConfirm';

function StrategyDetailsTabs({
  toggleState,
  setToggleState,
  setBackTestingModalShow,
  isMobile,
  refetchFunction,
  refreshView,
}) {
  var dispatch = useDispatch();
  const { virtual_requests, live_requests, backtest_requests } = useSelector(
    (state) => state.userCreditsDetails,
  );
  const [percentTitle, setPercentTitle] = useState({});
  const [showTabs, setShowTabs] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const {
    detailsOfStrategy,
    liveReportsData,
    virtualReportsData,
    backtestingReportsData,
  } = useSelector((state) => state.completeStrategyDetails);
  const [removeModel, setRemoveModel] = useState(false);
  //we have to check it the virtualData and livedata and subscribed
  useEffect(() => {
    if (
      virtualReportsData?.length > 0 ||
      liveReportsData?.length > 0 ||
      detailsOfStrategy?.subscribe_by_user
    ) {
      setShowTabs(true);
    } else {
      setShowTabs(false);
    }
  }, [virtualReportsData, liveReportsData, detailsOfStrategy]);

  const getIconButton = useMemo(() => {
    if (toggleState === 'virtual' && virtualReportsData?.length > 0) {
      return (
        <button
          className={`${btnPrimaryClass}`}
          onClick={() => {
            dispatch(openVirualReportFields(true));
            // setBackTestingModalShow(true);
          }}
        >
          <span>+ Add Virtual Trade</span>
        </button>
      );
    }
    if (toggleState === 'live' && liveReportsData?.length > 0) {
      return (
        <button
          className={`${btnPrimaryClass}`}
          onClick={() => {
            dispatch(openLiveReportFields(true));
            // setBackTestingModalShow(true);
          }}
        >
          <span>
            <span className="font-medium">+</span> Add Live Trade
          </span>
        </button>
      );
    }
    if (toggleState === 'backtesting' && backtestingReportsData?.length > 0) {
      return (
        <button
          className="btn__create__strategy align-items-center d-flex gap-1"
          onClick={() => {
            dispatch(openBacktestingReportFields(true));
          }}
        >
          <span>+ Add Backtesting</span>
        </button>
      );
    }
  }, [
    toggleState,
    virtualReportsData?.length,
    liveReportsData?.length,
    backtestingReportsData?.length,
    dispatch,
  ]);

  useEffect(() => {
    //to show percentage and credits of the stats usage for the live and virtual trades
    const setCreditsInfo = (deployments) => {
      const total = deployments?.requests_total || 0;
      const used = deployments?.requests_used || 0;
      const remaining = deployments?.remaining || 0;

      if (total === 0) {
        setPercentTitle({
          percent: 0,
          subtitle: '0/0',
        });
      } else if (total !== 0 && used === 0) {
        setPercentTitle({
          percent: 0,
          subtitle: `${used}/${total}`,
        });
      } else {
        setPercentTitle({
          percent: (used / total) * 100,
          subtitle: `${used}/${total}`,
        });
      }
    };

    if (toggleState === 'live') {
      setCreditsInfo(live_requests);
    } else if (toggleState === 'virtual') {
      setCreditsInfo(virtual_requests);
    } else {
      setCreditsInfo(backtest_requests);
    }
  }, [toggleState, virtual_requests, live_requests, backtest_requests]);

  const toGetIcons = useMemo(() => {
    if (toggleState === 'overview') {
      return null;
    } else if (toggleState === 'virtual' && virtualReportsData?.length === 0) {
      return null;
    } else if (toggleState === 'live' && liveReportsData?.length === 0) {
      return null;
    } else if (
      toggleState === 'backtesting' &&
      backtestingReportsData?.length === 0
    ) {
      return null;
    } else {
      return (
        <>
          {/* <Tooltip title={<>Print</>}>
            <button className={`${btnSecondayClass} py-2.5`}>
              <PrinterOutlined />
            </button>
          </Tooltip> */}

          <Tooltip title={<>Delete</>}>
            <button
              className={`${btnSecondayClass} py-2.5`}
              onClick={() => {
                setRemoveModel(true);
              }}
            >
              <DeleteOutlined />
            </button>
          </Tooltip>
        </>
      );
    }
  }, [
    backtestingReportsData?.length,
    liveReportsData?.length,
    toggleState,
    virtualReportsData?.length,
  ]);

  const { selectedKeys } = useSelector(
    (state) => state.completeStrategyDetails,
  );
  const hideConfirmationModal = () => {
    setRemoveModel(false);
  };
  const updateReportOnDel = (value) => {
    setDeleteLoading(value);
  };

  return (
    <div className="row strtgy__table__box mt-3">
      <div className="col-5 col-lg-6 ps-0">
        <ul className="strtgy__tbl">
          <li
            onClick={() => setToggleState('overview')}
            className={toggleState === 'overview' ? 'active' : 'tabs'}
          >
            <span>Overview</span>
          </li>
          {showTabs && (
            <>
              <li
                onClick={() => {
                  dispatch(setSelectedKeys([]));
                  setToggleState('backtesting');
                }}
                className={toggleState === 'backtesting' ? 'active' : 'tabs'}
              >
                <span>Back Testing</span>
              </li>
              <li
                onClick={() => {
                  dispatch(setSelectedKeys([]));
                  setToggleState('virtual');
                }}
                className={toggleState === 'virtual' ? 'active' : 'tabs'}
              >
                <span>Virtual Trade</span>
              </li>
              <li
                onClick={() => {
                  dispatch(setSelectedKeys([]));
                  setToggleState('live');
                }}
                className={toggleState === 'live' ? 'active' : 'tabs'}
              >
                <span>Live Trade</span>
              </li>
            </>
          )}
        </ul>
        <div className="strtgy__table__drpdwn">
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic">
              {toggleState === 'virtual' ? (
                <span>Virtual Trade</span>
              ) : (
                <span>Live Trade</span>
              )}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                href=""
                onClick={() => setToggleState('virtual')}
                className={toggleState === 'virtual' ? 'active' : 'tabs'}
              >
                Virtual Trade
              </Dropdown.Item>
              <Dropdown.Item
                href=""
                onClick={() => setToggleState('live')}
                className={toggleState === 'live' ? 'active' : 'tabs'}
              >
                Live Trade
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="col-7 col-lg-6 d-flex justify-content-end align-items-center gap-2">
        <div>
          <NeedHelpButtonDrawer />
        </div>
        {(toggleState === 'live' ||
          toggleState === 'virtual' ||
          toggleState === 'backtesting') && (
          <>
            <div>
              <StatsUsageCardSmall
                icon={
                  <>
                    <span className="text-color-primary">
                      {percentTitle?.subtitle}
                    </span>
                  </>
                }
                percent={percentTitle?.percent}
                title={
                  toggleState === 'live'
                    ? 'Live Credits'
                    : toggleState === 'virtual'
                    ? 'Virtual Credits'
                    : 'Backtest Credits'
                }
                infoText={'No credits are available for free accounts'}
                size={35}
              />
            </div>
            <div className="py-0.5 hidden lg:flex">
              <span className="flex w-1 h-7 bg-level-2"></span>
            </div>
          </>
        )}

        {isMobile === false ? (
          toggleState !== 'overview' && getIconButton
        ) : (
          <Tooltip title={<>Add Virtual Trade</>}>
            <button className="btn__create__strategy align-items-center d-flex gap-1">
              <span className="mb__btn">
                <PlusOutlined />
              </span>
            </button>
          </Tooltip>
          // <span>+ Add Virtual Trade</span>
        )}
        {toGetIcons}
        {refreshView && (
          <div>
            <RefreshButton onClick={refetchFunction} />
          </div>
        )}
      </div>
      <ModelDelete
        from={
          toggleState === 'live'
            ? 'live'
            : toggleState === 'backtesting'
            ? 'backtesting'
            : 'virtual'
        }
        showModal={removeModel}
        hideModal={hideConfirmationModal}
        id={selectedKeys}
        updateReportOnDel={updateReportOnDel}
        refetch={refetchFunction}
        loading={deleteLoading}
        type="multiple"
      />
    </div>
  );
}

export default React.memo(StrategyDetailsTabs);
