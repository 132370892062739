import { useCallback, useEffect, useMemo, useState } from 'react';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { useQuery } from '@tanstack/react-query';
import { getStrategyList } from '../../API/strategy/strategyPagePermissionApi';

function useStrategyList() {
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [page, setPage] = useState(1);

  const [categorySearch, setCategorySearch] = useState('all');

  const {
    data: strategyDetails,
    isPending,
    refetch: strategyRefech,
  } = useQuery({
    queryKey: ['stragies-list', { page, categorySearch, userid }],
    queryFn: getStrategyList,
    refetchOnWindowFocus: false,
    refetchOnMount: 'always',
  });

  useEffect(() => {
    if (strategyDetails) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: page,
        total: strategyDetails.count,
      }));
    }
  }, [strategyDetails, page]);

  //table pagination changes

  const handleTableChange = useCallback((newPagination) => {
    setPage(newPagination.current);
  }, []);

  const value = useMemo(() => {
    return {
      strategyRefech,
      strategyDetails,
      pagination,
      categorySearch,
      setCategorySearch,
      handleTableChange,
      isPending,
    };
  }, [
    strategyRefech,
    strategyDetails,
    pagination,
    categorySearch,
    setCategorySearch,
    handleTableChange,
    isPending,
  ]);

  return value;
}

export default useStrategyList;
