import React from 'react';
import { AiOutlineCheck, AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsBoxes } from 'react-icons/bs';
import { afterBorder } from '../../../components/utils/defaultClasses';
import { useSelector } from 'react-redux';
import Tooltip from '../../../components/Common/Tooltip';

export default function SunscriptionInfoCard({ data, showDetail }) {
  const { featuresPlanData } = useSelector((state) => state.profileDetails);
  const infoText = (item) => {
    switch (item.key) {
      case 'strategy_builder':
        return 'Create your custom trading strategies with our strategy builder tool.';
      case 'broker_setup':
        return 'Setup your broker account seamlessly with our broker setup feature.';
      case 'backtesting_credits':
        return 'Number of backtests you can perform with your current credits.';
      case 'historical_data_duration_for_backtesting':
        return 'Historical data available for backtesting.';
      case 'virtual_deployments':
        return 'Number of virtual trades you can launch or start.';
      case 'live_deployments':
        return 'Number of live trades you can launch or start.';
      case 'premium_strategies_subscribe':
        return 'Number of premium strategies you can subscribe.';
      case 'bulk_trades':
        return 'Trade in larger quantities than standard exchange limits with our bulk trades feature.';
      case 'support':
        return 'Available modes of communication for user interaction.';
      case 'derivatives_trading':
        return 'Trade in derivative instruments such as options and futures.';
      case 'equity_trading':
        return 'Trade in equity (cash segment) with this facility.';
      case 'historical_data_api_credits':
        return 'Access historical candle data using APIs with this feature.';
      case 'historical_data_minimum_granularity':
        return 'Minimum timeframe for historical data available.';
      case 'options_historical_chart':
        return 'View historical candlestick charts for options trading data.';
      default:
        return 'No information available.';
    }
  };

  return (
    <>
      <div className="h-full">
        <div
          className={`border-[1px] border-transparent p-[15px] rounded-xl group-hover:border-blue-3/50 bg-color-base-4 h-full relative overflow-hidden group-hover:-translate-y-1 duration-300 ease-in-out shadow-md`}
        >
          <div className="space-y-5">
            <div
              className={`min-h-[188px] relative border-[1px] border-level-2 rounded-lg p-3.5`}
            >
              <div className="absolute top-1/2 -translate-y-1/2">
                <div className="space-y-1">
                  <div className="flex">
                    <span>
                      <BsBoxes className="text-6xl text-zinc-700 dark:text-color-secondary" />
                    </span>
                  </div>
                  <div>
                    <h1 className="text-lg font-bold">Features / Plans</h1>
                  </div>
                </div>
                <div>
                  <div>
                    <h2 className="text-2xl font-bold mb-0 flex items-center gap-1.5">
                      Price
                      {/* <span className="flex px-1.5 py-1 bg-blue-3/15 text-color-primary rounded-lg text-sm font-medium">
                    -15% Discount
                  </span> */}
                    </h2>
                    {/* <p className="mb-0 text-color-secondary font-medium">
                  {data?.subtitle}
                </p> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="w-full">
              <Link
                // to={'/upgrade-plan'}
                target="_blank"
                className={`${
                  data?.selected
                    ? `${btnSuccessClass} hover:bg-green-3/100`
                    : btnPrimaryClass
                } w-full text-lg py-2.5 rounded-[12px]`}
              >
                {data?.selected ? 'Active Plan' : data?.btnText}
              </Link>
            </div> */}
            <div>
              {/* <p className="font-semibold mb-1.5">{data?.plan_list_title}</p> */}

              <div
                className={`${
                  showDetail ? 'accordion-content' : ''
                } showDetail overflow-hidden`}
              >
                <div className="divide-y divide-level-2">
                  {featuresPlanData?.map((items, index) => (
                    <>
                      <div
                        className="text-color-primary py-3.5 min-h-[34px] flex flex-row items-center"
                        key={index}
                      >
                        <span className="text-color-secondary font-medium">
                          {items.name}
                        </span>
                        <span className="pl-1">
                          <Tooltip title={() => infoText(items)}>
                            <AiOutlineQuestionCircle />
                          </Tooltip>
                        </span>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
