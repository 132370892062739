import React from 'react';
import { Card } from 'react-bootstrap';
import { Logo } from '../../assets/Icon';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import OtpTimer from '../../components/Common/OtpTimer';

export default function EmailConfirmation() {
  return (
    <>
      <div className="bg-color-base-2/30 dark:bg-color-base-4">
        <section className="container-xxl">
          <div className="min-h-screen flex justify-center items-center relative z-10">
            <div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
              <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden relative z-10">
                <Card.Body className="p-[2rem]">
                  <div className="flex justify-center mb-4 mt-2">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                  <div className="pt-2 mb-4 flex flex-col">
                    <h2 className="mb-1 text-lg text-color-primary">
                      Check Your Email ✉️
                      {/* Email link expired ❗ */}
                    </h2>
                    <p class="text-[13px] text-color-secondary/90">
                      Email confirmation link has been sent on your register
                      email.
                      {/*  */}
                    </p>
                  </div>
                  <div>
                    <Form layout="vertical">
                      <div className="flex justify-center mb-3">
                        <OtpTimer
                          minutes={0}
                          seconds={10}
                          text="Resend Email Link in"
                          ButtonText="Resend Email"
                          background="none"
                          textColor="unset"
                          buttonColor="#cc8411"
                          classNames="w-full"
                            // isError={mutation.isError}
                            // // resend={() => {
                            // //   onSubmit();
                            // // }}
                        />
                      </div>
                      {/* <div>
                        <OtpComponent />
                      </div> */}
                      <div className="d-flex justify-content-center">
                        <Link
                          className={`text-blue-2 hover:text-blue-2`}
                          to="/"
                        >
                          <i
                            className="fa fa-unlock me-1"
                            aria-hidden="true"
                          ></i>{' '}
                          Back To Login
                        </Link>
                      </div>{' '}
                    </Form>
                    <div className="mt-3">
                      <p className="text-xs text-gray-400 text-center">
                        <i>
                          In case the email is not recieved in the Inbox, please
                          check the Spam folder.
                        </i>
                      </p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <div className="absolute bottom-3 right-3">
            <a href="#" className="text-color-primary hover:text-blue-3">
              Need Help?
            </a>
          </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
