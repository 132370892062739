import { useQuery } from '@tanstack/react-query';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import { useParams } from 'react-router-dom';
import { getTicketSupportReplies } from '../../../components/helpers/api/TicketSupport/ticket';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from 'reactstrap';
import { setTicketMessageList } from '../../../features/Tickets/tickets.slice';
import { removeDuplicates } from '../../../utils/utils';

const useTicketSupportDetail = () => {
  const { id } = useParams();
  const decodedToken = localStorage.getItem('token');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const dispatch = useDispatch();

  const { selectedTicket, ticketMessageList } = useSelector(
    (state) => state.selectedTickets,
  );

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ['ticketSupportDetail', decodedToken, page, limit, id],
    queryFn: async () => {
      if (decodedToken) {
        return await getTicketSupportReplies(page, limit, id);
      } else {
        return null;
      }
    },
  });

  useEffect(() => {
    if (ticketMessageList?.length < data?.data?.count) {
      dispatch(
        setTicketMessageList({
          data: removeDuplicates([...ticketMessageList, ...data?.data?.data]),
          count: data?.data?.count,
        }),
      );
    }
    if (ticketMessageList?.length === 0 && data?.data?.count === 0) {
      dispatch(
        setTicketMessageList({
          data: [],
          count: data?.data?.count,
        }),
      );
    }
  }, [data]);

  const hasMore = useMemo(() => {
    return ticketMessageList?.length < data?.data?.count;
  }, [ticketMessageList, data?.data?.count]);

  const values = useMemo(() => {
    return {
      selectedTicket,
      ticketList: data?.data?.data ?? [],
      refetch,
      Pagination: {
        page,
        setPage,
        limit,
        setLimit,
        total: data?.data?.count,
        hasMore,
      },
      isLoading,
    };
  }, [selectedTicket, data, refetch, page, limit, hasMore, isLoading]);

  return values;
};

export default useTicketSupportDetail;
