import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../components/Common/Modal';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../../components/utils/defaultClasses';
import { Form, Input, Select, Switch } from 'antd';
import { BsChevronDown } from 'react-icons/bs';
import countryList from 'react-select-country-list';

export default function AddAddressModal({
  isAddAddress,
  setIsAddAddress,
  handleSubmit = () => {},
  addressForm,
  setAddressForm,
  isEdit,
  setIsEdit,
}) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const options = useMemo(() => countryList().getData(), []);

  const [primary, setPrimary] = useState(false);

  const handleData = (values) => {
    const selectedCountry = options.find(
      (country) => country.value === values.country,
    );
    const formData = {
      ...values,
      country: selectedCountry ? selectedCountry.label : '',
      is_primary: primary,
    };
    handleSubmit(formData);
    setAddressForm({
      first_name: '',
      last_name: '',
      country: '',
      address_1: '',
      address_2: '',
      state: '',
      post_code: '',
      is_primary: false,
    });

    form.resetFields();
    setPrimary(false);
    setIsAddAddress(false);
    setIsEdit(false);
  };

  const onChange = (checked) => {
    setPrimary(checked);
  };

  useEffect(() => {
    if (isEdit) {
      setPrimary(addressForm?.is_primary);
      const selectedCountry = options.find(
        (country) => country.label === addressForm?.country,
      );
      form.setFieldsValue({
        ...addressForm,
        country: selectedCountry?.value,
      });
    } else {
      form.setFieldsValue({ ...addressForm });
    }
  }, [addressForm]);

  const closeAddressModal = () => {
    setIsAddAddress(false);
    setIsEdit(false);
    setAddressForm({
      first_name: '',
      last_name: '',
      country: null,
      address_1: '',
      address_2: '',
      state: '',
      post_code: '',
      is_primary: false,
    });
  };

  return (
    <div>
      <Modal
        open={isAddAddress}
        setIsOpen={closeAddressModal}
        maxWidth={`w-full max-w-lg`}
        preventClosingOutsideClick
        header={
          <div className="flex flex-col justify-center py-2">
            <div className="w-full flex items-center">
              <span className="text-color-primary text-base font-medium">
                {isEdit ? 'Update Address' : 'Add New Address'}
              </span>
            </div>
          </div>
        }
        footer={
          <div className="d-flex justify-end items-center gap-2">
            <button
              onClick={closeAddressModal}
              type="button"
              className={`${btnSecondayClass} text-sm`}
            >
              Discard
            </button>
            <button
              type="primary"
              htmlType="submit"
              className={`${btnPrimaryClass} text-sm`}
              onClick={() => form.submit()}
            >
              {isEdit ? 'Update' : 'Submit'}
            </button>
          </div>
        }
        bodyClass={
          'w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full'
        }
      >
        <div className="row justify-content-center">
          <div className="col-12">
            <Form
              form={form}
              className="space-y-3"
              layout="vertical"
              onFinish={handleData}
            >
              <div className="row">
                <div className="col-lg-6">
                  <Form.Item
                    label={
                      <>
                        <span>First Name</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="First Name"
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'First name is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      placeholder="Enter First Name"
                      name="first_name"
                      className={`${formClass}`}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                    label={
                      <>
                        <span>Last Name</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="Last Name"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Last name is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      placeholder="Enter Last Name"
                      name="last_name"
                      className={`${formClass}`}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-12">
                  <Form.Item
                    label={
                      <>
                        <span>Select Country</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="Select Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                        message: 'Country is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Select
                      showSearch
                      placeholder="Select a Country..."
                      size="large"
                      options={options}
                      className="min-h-[38px]"
                      suffixIcon={
                        <BsChevronDown
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      }
                      filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </div>

                <div className="col-lg-12">
                  <Form.Item
                    label={
                      <>
                        <span>Address Line 1</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="Address Line 1"
                    name="address_1"
                    rules={[
                      {
                        required: true,
                        message: 'Address Line 1 is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      placeholder="Enter Address Line 1"
                      name="address_1"
                      className={`${formClass}`}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-12">
                  <Form.Item
                    label={
                      <>
                        <span>Address Line 2</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="Address Line 2"
                    name="address_2"
                    className="mb-0"
                  >
                    <Input
                      placeholder="Enter Address Line 2"
                      name="address_2"
                      className={`${formClass}`}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                    label={
                      <>
                        <span>State / Province</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="State / Province"
                    name="state"
                    rules={[
                      {
                        required: true,
                        message: 'State / Province is required',
                      },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      placeholder="Enter State / Province"
                      name="state"
                      className={`${formClass}`}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-6">
                  <Form.Item
                    label={
                      <>
                        <span>Post Code</span>
                        <span className="text-red-2">*</span>
                      </>
                    }
                    // label="Post Code"
                    name="post_code"
                    rules={[
                      {
                        required: true,
                        message: 'Post code is required',
                      },
                      // {
                      //   pattern: /^\d+$/,
                      //   message: 'Post code must be a number',
                      // },
                    ]}
                    className="mb-0"
                  >
                    <Input
                      placeholder="Enter Post Code"
                      name="post_code"
                      className={`${formClass}`}
                    />
                  </Form.Item>
                </div>
                <div className="col-lg-12">
                  <div className="flex justify-between items-center gap-3 mt-7">
                    <div className="flex flex-col -space-y-1">
                      <span className="text-sm text-color-primary">
                        Use as a billing address?
                      </span>
                      <span className="text-color-secondary/70 text-xs">
                        If you need more info, please check budget planning
                      </span>
                    </div>
                    <Switch defaultValue={primary} onChange={onChange} />
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </div>
  );
}
