import { Routes, Route, Navigate } from 'react-router-dom';
import OTP from './components/opt';
import AuthGuard from './components/hoc/AuthGuard';
import ChartPage from './components/UI/ChartPage';
import HeatMap from '../src/components/UI/Heatmap';
import Broker from './Pages/Broker';
import UserLogin2 from './Pages/user-Login/LoginV2';
import Signup from './components/SignUP/SignUpV2';
// import SignupSuccess from './components/SignUP/SignUPSuccess';
import ForgotPassword from './Pages/user-Login/ForgotPassword';
import ExpiredLink from './Pages/user-Login/ExpiredLink_page';
// import ResetPassword from './Pages/user-Login/ResetPassword';
import SetupInstruction from './components/UI/Broker/Instructions/SetupInstruction';
import ZerodhaInstructions from './components/UI/Broker/Instructions/ZerodhaInstructions';
import AliceInstructions from './components/UI/Broker/Instructions/AliceInstructions';
import AngelInstructions from './components/UI/Broker/Instructions/AngelOneInstructions';
import FlatTradeInstructions from './components/UI/Broker/Instructions/FlatTradeInstructions';
import FyresInstructions from './components/UI/Broker/Instructions/FyresInstructions';
import PaiseInstructions from './components/UI/Broker/Instructions/5PaisaInstructions';
import DhanInstructions from './components/UI/Broker/Instructions/DhanInstructions';
import ViewVTReport from './Pages/ViewVTReport';
import VirtualPortfolio from './Pages/VirtualPortfolio';
import StrategyList from './Pages/StrategyList';
import ProfileView from './Pages/ProfileView';
import VerifyEmail from './Pages/user-Login/VerifyEmail';
import TwoStepVerification from './Pages/user-Login/TwoStepVerification';
import ResetPasswordV2 from './Pages/user-Login/ResetPasswordV2';
import EmailConfirmation from './Pages/user-Login/EmailConfirmation';
import SubscriptionUpgrade from './Pages/UserComponents/subscription_upgrade/SubscriptionUpgrade';
import AuthGuardLoggedOut, {
  AuthGuardLoggedOutLanding,
} from './components/hoc/AuthGuardLoggedOut';
import StrategyReportView from './components/UI/StrategyDetails/StrategyReportView';
import BackTestingReport from './components/UI/back_testing/BackTestingReport';
import StrategyMarketplaceList from './Pages/StrategyMarketplaceList';
import SubscriptionInvoice from './components/UI/invoices/SubscriptionInvoice';
import TicketList from './Pages/support-ticket/TicketList';
import TicketDetail from './Pages/support-ticket/TicketDetail';
import HomePage from './Pages/homepage/HomePage';
import Reports from './Pages/support-ticket/report_ui/Reports';

function Routers() {
  const history = {
    navigate: null,
    location: null,
  };

  return (
    <>
      <Routes>
        <Route
          path="/broker/instructions/zerodha"
          element={
            <AuthGuard>
              <ZerodhaInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions/aliceBlue"
          element={
            <AuthGuard>
              <AliceInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions/angelone"
          element={
            <AuthGuard>
              <AngelInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions/flatTrade"
          element={
            <AuthGuard>
              <FlatTradeInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions/fyres"
          element={
            <AuthGuard>
              <FyresInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions/paisa"
          element={
            <AuthGuard>
              <PaiseInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions/dhan"
          element={
            <AuthGuard>
              <DhanInstructions />
            </AuthGuard>
          }
        />
        <Route
          path="/broker/instructions"
          element={
            <AuthGuard>
              <SetupInstruction />
            </AuthGuard>
          }
        />
        {/* <Route
                  path="/backtesting-report/:strategy_id"
                  element={
                    <AuthGuard>
                      <BacktestingReport />
                    </AuthGuard>
                  }
                  // component={AuthGuard(BacktestingReport)}
                /> */}
        <Route
          path="/view-report/:tab/:id"
          element={
            <AuthGuard>
              <ViewVTReport />
            </AuthGuard>
          }
        />
        <Route
          path="/invoice/:page"
          // component={AuthGuard(StrategyList)}
          element={
            <AuthGuard noSidebar={true}>
              <SubscriptionInvoice />
            </AuthGuard>
          }
        />
        {/* <Route
                    path="/backtesting"
                    component={AuthGuard(BackTesting)}
                    // element={
                    //   <AuthGuard>
                    //     <BackTesting />
                    //   </AuthGuard>
                    // }
                  /> */}
        <Route
          path="/profit-n-loss"
          // component={AuthGuard(HeatMap)}
          element={
            <AuthGuard>
              <HeatMap />
            </AuthGuard>
          }
        />
        <Route
          path="/chart"
          // component={AuthGuard(ChartPage)}
          element={
            <AuthGuard>
              <ChartPage />
            </AuthGuard>
          }
        />
        {/* <Route path="/engulfing" component={Engulfing} /> */}
        <Route
          path="/strategies"
          // component={AuthGuard(StrategyList)}
          element={
            <AuthGuard>
              <StrategyList />
            </AuthGuard>
          }
        />
        <Route
          path="/tickets"
          // component={AuthGuard(StrategyList)}
          element={
            <AuthGuard>
              <TicketList />
            </AuthGuard>
          }
        />
        <Route
          path="/ticket/:id"
          // component={AuthGuard(StrategyList)}
          element={
            <AuthGuard>
              <TicketDetail />
            </AuthGuard>
          }
        />
        <Route
          path="/marketplace"
          // component={AuthGuard(StrategyList)}
          element={
            <AuthGuard>
              <StrategyMarketplaceList />
            </AuthGuard>
          }
        />
        <Route
          path="/profile"
          // component={AuthGuard(ProfileView)}
          element={
            <AuthGuard>
              <ProfileView />
            </AuthGuard>
          }
        />
        {/* <Route path="/strategy/:id" component={AuthGuard(StrategyList)} /> */}
        <Route
          path="/strategy/:id"
          // component={AuthGuard(StrategyReport)}
          element={
            <AuthGuard>
              <StrategyReportView />
              {/* <StrategyReport /> */}
            </AuthGuard>
          }
        />
        {/* <Route
                  path="/strategy2/:id"
                  // component={AuthGuard(StrategyReport)}
                  element={
                    <AuthGuard>
                      <StrategyReportView />
                    </AuthGuard>
                  }
                /> */}
        <Route
          path="/back-testing/:id"
          // component={AuthGuard(StrategyReport)}
          element={
            <AuthGuard>
              <BackTestingReport />
            </AuthGuard>
          }
        />
        <Route
          path="/broker"
          // component={AuthGuard(Broker)}
          element={
            <AuthGuard>
              <Broker />
            </AuthGuard>
          }
        />
        <Route
          path="/reports"
          // component={AuthGuard(Broker)}
          element={
            <AuthGuard>
              <Reports />
            </AuthGuard>
          }
        />
        <Route
          path="/portfolio/live"
          // component={AuthGuard(VirtualPortfolio)}
          element={
            <AuthGuard>
              <VirtualPortfolio key={'Virtual'} />
            </AuthGuard>
          }
        />
        <Route
          path="/portfolio/virtual"
          // component={AuthGuard(VirtualPortfolio)}
          element={
            <AuthGuard>
              <VirtualPortfolio key={'Live'} />
            </AuthGuard>
          }
        />
        <Route
          path="/checkout"
          // component={AuthGuard(VirtualPortfolio)}
          element={
            <AuthGuard noSidebar={true}>
              <SubscriptionUpgrade />
            </AuthGuard>
          }
        />
        {/* </Route> */}
        {/* <Route path="/liveportfolio" component={AuthGuard(LivePortfolio)} /> */}
        {/* <Route path="/reportsnew" component={AuthGuard(ReportsNew)} /> */}
        {/* <Route path="/strategynew" component={AuthGuard(StrategyNew)} /> */}
        {/* <Route
                  path="/user-reset-password/:token"
                  // component={ResetPassword}
                  element={<ResetPassword />}
                /> */}
        <Route
          path="/otp"
          element={
            <AuthGuardLoggedOut>
              <OTP />
            </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/login"
          element={
            <AuthGuardLoggedOut>
              <UserLogin2 />
            </AuthGuardLoggedOut>
          }
        />

        {/* <Route element={<AuthGuardLoggedOutLanding />}>
          <Route path="/" element={<HomePage />} />
        </Route> */}
        {/* <Route path="/login2" component={UserLogin2} /> */}
        <Route
          path="/signup"
          element={
            <AuthGuardLoggedOut>
              <Signup />
            </AuthGuardLoggedOut>
          }
        />
        {/* <Route
                  path="/success"
                  // component={SignupSuccess}
                  element={<SignupSuccess />}
                /> */}
        <Route
          path="/user-forgot-password"
          // component={ForgotPassword}
          element={
            <AuthGuardLoggedOut>
              <ForgotPassword />
            </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/user-expired_link"
          // component={ExpiredLink}
          element={
            <AuthGuardLoggedOut>
              <ExpiredLink />
            </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/auth-verify-email"
          element={
            // <AuthGuardLoggedOut>
            <VerifyEmail />
            // </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/auth-verify-otp"
          // component={TwoStepVerification}
          element={
            <AuthGuardLoggedOut>
              <TwoStepVerification />
            </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/user-reset-password"
          // component={ResetPasswordV2}
          element={
            // <AuthGuardLoggedOut>
            <ResetPasswordV2 type={'reset'} />
            // </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/user-set-password"
          // component={ResetPasswordV2}
          element={
            // <AuthGuardLoggedOut>
            <ResetPasswordV2 type={'Set'} />
            // </AuthGuardLoggedOut>
          }
        />
        <Route
          path="/email-verify"
          // component={EmailConfirmation}
          element={
            // <AuthGuardLoggedOut>
            <EmailConfirmation />
            // </AuthGuardLoggedOut>
          }
        />

        <Route
          path="/"
          element={
            <AuthGuard>
              <Navigate
                to={`/portfolio/virtual`}
                state={{ from: history.location }}
              />
            </AuthGuard>
          }
        />
      </Routes>
    </>
  );
}

export default Routers;
