import React, { useEffect, useState } from 'react';
import { Space, Table, Dropdown, Switch, Menu } from 'antd';
import { MoreOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import axios from 'axios';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import config from '../../../Config/config';
import { toast } from 'react-toastify';
import ModelDelete from '../../Common/DeleteConfirm';
import { Link } from 'react-router-dom';
import TableComponent from '../../Common/TableComponent';
import { useMutation } from '@tanstack/react-query';
import { updateRequest } from '../../../API/strategy/strategyDetail';
import {
  userBacktestDeployments,
  userLiveDeployments,
  userVirtualDeployments,
} from '../../../features/UserCredits/userCreditsSlice';
import { useDispatch, useSelector } from 'react-redux';
import Tooltip from '../../Common/Tooltip';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import PremiumBadge from '../../../utils/PremiumBadge';

const StrategyMobileTable = ({
  tradeOrder,
  toggleState,
  loading,
  pagination,
  handleTableChange,
  refetch,
}) => {
  const onChange = (checked) => {
    console.log(`switch to ${checked}`);
  };
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  let token = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(token);
  const [tableData, setTableData] = useState([]);
  const { virtual_requests, live_requests, backtest_requests } = useSelector(
    (state) => state.userCreditsDetails,
  );

  const handleCheckboxChange = (id, isChecked) => {
    if (isChecked) {
      setSelectedKeys([...selectedKeys, id]);
    } else {
      setSelectedKeys(selectedKeys.filter((selectedId) => selectedId !== id));
    }
  };

  useEffect(() => {
    setTableData(tradeOrder);
  }, [tradeOrder]);

  const menu = (record) => (
    <Menu>
      <Menu.Item key="1">
        <Link
          to={`/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${record._id
            }`}
        >
          View
        </Link>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => Link}>
        Print
      </Menu.Item>
      <Menu.Item key="3" onClick={() => deleteRequestReports(record._id)}>
        Delete
      </Menu.Item>
    </Menu>
  );
  const disptach = useDispatch();

  const [selectedKeys, setSelectedKeys] = useState([]);
  const [removeModel, setRemoveModel] = useState(false);
  const [reportId, setReportId] = useState('');
  const [deleteLoading, setDeleteLoading] = useState(false);
  const updateReportOnDel = (value) => {
    setDeleteLoading(value);
  };
  const deleteRequestReports = (value) => {
    // console.log(value);
    setRemoveModel(true);
    setReportId(value);
  };

  // Hide the modal
  const hideConfirmationModal = () => {
    setRemoveModel(false);
  };

  const updateRequestStrategy = useMutation({
    mutationFn: ({ finalPayload, toggleState }) => {
      return updateRequest(finalPayload, toggleState);
    },
    onSuccess: async (res, { finalPayload }) => {
      console.log(finalPayload);
      const idToUpdate =
        toggleState === 'live' ? finalPayload.lt_id : finalPayload.vt_id;
      const { status } = finalPayload;

      setTableData(
        tableData.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        }),
      );
      let virtual_payload = {
        ...virtual_requests,
        requests_remaining:
          virtual_requests?.requests_remaining === 'Unlimited'
            ? virtual_requests?.requests_remaining
            : virtual_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: virtual_requests?.requests_used + (status ? 1 : -1),
      };
      let live_payload = {
        ...live_requests,
        requests_remaining:
          live_requests?.requests_remaining === 'Unlimited'
            ? live_requests?.requests_remaining
            : live_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: live_requests?.requests_used + (status ? 1 : -1),
      };
      let backtest_payload = {
        ...backtest_requests,
        requests_remaining:
          backtest_requests?.requests_remaining === 'Unlimited'
            ? backtest_requests?.requests_remaining
            : backtest_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: backtest_requests?.requests_used + (status ? 1 : -1),
      };
      toggleState === 'live'
        ? disptach(userLiveDeployments(live_payload))
        : toggleState === 'virtual' ? disptach(userVirtualDeployments(virtual_payload)) : disptach(userBacktestDeployments(backtest_payload));
      //       virtual_requests({
      //   strategies_remaining: creditsData?.virtual_deployments?.remaining,
      //   strategies_total: creditsData?.virtual_deployments?.total,
      //   strategies_used: creditsData?.virtual_deployments?.used,
      // }),
    },
    onError: (error) => {
      console.log(error.response.data.message);
    },
  });

  console.log('tableData', tableData);
  //Update VT trade
  const handleUpdate = (status, id, strategy_id) => {
    console.log(status, id);
    let payload = {
      vt_id: id,
      user_id: userid.id,
      strategy_id: strategy_id,
      status: status,
    };
    let ltPayload = {
      lt_id: id,
      user_id: userid.id,
      strategy_id: strategy_id,
      status: status,
    };
    let finalPayload = toggleState === 'live' ? ltPayload : payload;

    updateRequestStrategy.mutate({ finalPayload, toggleState });
  };

  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      render: (text, record) => (
        <Link
          to={
            toggleState === 'backtesting'
              ? `/back-testing/${record._id}`
              : `/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${record._id
              }`
          }
          className="primary__blue"
        >
          {text}
        </Link>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      render: (text) => dayjs(text).format('DD MMM YY'),
    },
    {
      title: 'Max Cap',
      dataIndex: 'max_cap',
    },
  ];

  const nestedColumns = [
    {
      title: 'No of order',
      dataIndex: 'Order',
    },
    {
      title: 'Status',
      key: 'tag',
      dataIndex: 'current_status',
    },

    {
      title: 'Mode',
      dataIndex: 'Mode',
      render: (text, record) => (
        <div className="flex items-center gap-1.5">
          {!record.status &&
            (toggleState === 'live'
              ? live_requests?.requests_remaining === 0
              : toggleState === 'virtual' ? virtual_requests?.requests_remaining === 0 : backtest_requests?.requests_remaining === 0) ? (
            <PremiumBadge />
          ) : (
            // <div className="flex items-center gap-1.5">
            //   <div className="flex items-center gap-1.5 bg-blue-2/15 text-blue-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
            //     Premium Feature
            //   </div>
            //   <Tooltip
            //     title={'Please upgrade your account for premium features.'}
            //   >
            //     <AiOutlineQuestionCircle />
            //   </Tooltip>
            // </div>
            <Switch
              value={record?.status}
              onChange={(checked) =>
                handleUpdate(checked, record?._id, record?.strategy_id)
              }
            />
          )}
        </div>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            overlay={menu(record)}
            placement="bottomRight"
            overlayClassName={'dropdownOverlay dowpdownDark'}
          >
            <MoreOutlined style={{ cursor: 'pointer' }} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <div className="strategy__tbl__data">
      <TableComponent
        columns={columns}
        dataSource={tableData}
        pagination={pagination}
        onChange={handleTableChange}
        rowKey={tradeOrder.request_id}
        total={pagination?.total}
        loading={loading}
        expandable={{
          rowExpandable: (record) => window.innerWidth < 720,
          expandedRowRender: (record) => {
            return (
              <TableComponent
                columns={nestedColumns}
                dataSource={[record]}
                loading={loading}
                pagination={false}
              />
            );
          },
        }}
        size="middle"
      />
      <ModelDelete
        from={toggleState === 'live' ? 'live' : 'virtual'}
        showModal={removeModel}
        hideModal={hideConfirmationModal}
        id={reportId}
        updateReportOnDel={updateReportOnDel}
        refetch={refetch}
        loading={deleteLoading}
      />
    </div>
  );
};

export default StrategyMobileTable;
