import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  //   overAllPlansList: [],
  selectedTrade: {},
  isOpen: false,
};

export const detailsTrade = createSlice({
  name: 'detailsTrade',
  initialState,
  reducers: {
    tradeDetailsInDrawer: (state, { payload }) => {
      console.log('payload', payload);
      state.selectedTrade = payload.selectedTrade;
      state.isOpen = payload.isOpen;
    },
    // allPlansList: (state, { payload }) => {
    //   state.overAllPlansList = payload;
    // },
  },
});

// Action creators are generated for each case reducer function
export const { tradeDetailsInDrawer } = detailsTrade.actions;

export default detailsTrade.reducer;
