import React from 'react';
import UserDetailsHead from '../components/UI/Profile/UserDetailsHead';
import { Tab, Tabs } from 'react-bootstrap';
import PersonalDetailsTab from '../components/UI/Profile/PersonalDetailsTab';
import SecuritySettingsTabs from '../components/UI/Profile/SecuritySettingsTabs';
import BillingDetailsTab from '../components/UI/Profile/BillingDetailsTab';

function ProfileView() {
  return (
    <>
      <div className="left__bar">
        <div className="small__container mb-5">
          <UserDetailsHead />
          <Tabs
            defaultActiveKey="PersonalDetails"
            id="uncontrolled-tab-example"
            className="mb-2 overflow-x-auto overflow-y-hidden scrollCss scrollHide w-full flex-nowrap scrollTab pb-[1px]"
          >
            <Tab
              eventKey="PersonalDetails"
              title="Personal Details"
              className="shrink-0"
            >
              <PersonalDetailsTab />
            </Tab>
            <Tab
              eventKey="SecuritySetting"
              title="Security & Privacy"
              className="shrink-0"
            >
              <SecuritySettingsTabs />
            </Tab>
            <Tab eventKey="billing" title="Billing" className="shrink-0">
              <BillingDetailsTab />
            </Tab>
          </Tabs>
        </div>
        <div className="clearfix"></div>
      </div>
    </>
  );
}

export default React.memo(ProfileView);
