import { Input, Select, TimePicker, DatePicker, Form, Switch } from 'antd';
import moment from 'moment';
import {
  formClass,
} from '../utils/defaultClasses';
import { BsChevronDown } from 'react-icons/bs';
import { forwardRef, useImperativeHandle, useMemo } from 'react';
import dayjs from 'dayjs';

const { RangePicker } = DatePicker;

const ModalInputFields = forwardRef(
  ({ inputs, sumbitForm, fieldsDisable = false, data = {}, type = "other" }, ref) => {
    console.log('data: ', data);
    // let data_duration_for_backtesting = useMemo(() => data?.active_plan?.subscriptionPlanData?.features_data?.[4]?.value, [data?.active_plan?.subscriptionPlanData?.features_data])
    const [form] = Form.useForm();
    const timeFormat = 'HH:mm';

    useImperativeHandle(ref, () => ({
      resetFields: () => form.resetFields(),
      validateFields: () => form.validateFields(),
      getFields: () => form.getFieldValue(),
      getError: () => form.getFieldsError(),
      submit: () => form.submit(),
    }));

    // const getyearOrMonth = (dateDuration) => {
    //   console.log('dateDuration: ', dateDuration);
    //   let splitValue = dateDuration?.split(" ")?.[1]?.trim()
    //   console.log('splitValue: ', splitValue);
    //   if (splitValue === "Month" || splitValue === "month") {
    //     return "months"
    //   } else {
    //     return "years"
    //   }
    // }
    const renderFormItem = (el) => {
      switch (el.input_type) {
        case 'select':
          return (
            <Form.Item
              label={el.name}
              name={el.value_name}
              initialValue={el.value_default}
              rules={[{ required: true, message: `Please select ${el.name}` }]}
            >
              <Select
                size="large"
                suffixIcon={
                  <BsChevronDown
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                }
                mode={el.value_type === 'array' ? 'multiple' : undefined}
                allowClear={el.value_type === 'array'}
              >
                {el.select_options &&
                  el.select_options.map((option, index) => (
                    <Select.Option key={index} value={option}>
                      {option}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          );
        case 'Date':
          return (
            <Form.Item
              label={el.name}
              name={el.value_name}
              rules={[{ required: true, message: `Please select ${el.name}` }]}
            >
              <RangePicker
                popupClassName="cust_popup dark-ant-picker-dropdown"
                className={`dark-ant-picker text-sm rounded-md cursor-pointer ${formClass}`}
                disabledDate={(current) => {
                  // if (type === "backtest") {
                  // return current && (current < moment().subtract(6, 'months') || current > moment().endOf('day'))
                  // } else {
                  return current && current?.isAfter(dayjs(), "date")
                  // }
                }}
              />
            </Form.Item>
          );
        case 'Toggle':
          return (
            <Form.Item
              label={el.name}
              name={el.value_name}
              valuePropName="checked"
              initialValue={el.value_default}
            >
              <Switch />
            </Form.Item>
          );
        case 'Time':
          return (
            <Form.Item
              label={el.name}
              name={el.value_name}
              initialValue={moment(el.value_default, timeFormat)}
              rules={[{ required: true, message: `Please select ${el.name}` }]}
            >
              <TimePicker
                defaultValue={moment(el.value_default, timeFormat)}
                format={timeFormat}
                popupClassName="cust_popup dark-ant-picker-dropdown"
                className={`dark-ant-picker text-sm rounded-md cursor-pointer ${formClass}`}
              />
            </Form.Item>
          );
        default:
          return (
            <Form.Item
              label={el.name}
              name={el.value_name}
              initialValue={el.value_default}
              min={el.min ? el.min : ''}
              max={el.max ? el.max : ''}
              rules={[{ required: true, message: `Please input ${el.name}` }]}
            >
              <Input
                type={el.input_type}
                step={el.step ? el.step : ''}
                className={`${formClass}`}
              />
            </Form.Item>
          );
      }
    };

    const onFinish = (values) => {
      sumbitForm(values);
    };

    return (
      <Form
        name="basic"
        form={form}
        // labelCol={{ span: 8 }}
        // wrapperCol={{ span: 16 }}
        onFinish={onFinish}
        autoComplete="off"
        layout="vertical"
        disabled={fieldsDisable}
      // className="flex flex-col content-between"
      // style={{ height: '80vh' }}
      >
        <div className="row">
          {inputs.map((el, index) => (
            <div className="col-12 col-md-4 col-lg-3" key={index}>
              {renderFormItem(el)}
            </div>
          ))}
        </div>
      </Form>
    );
  },
);

export default ModalInputFields;
