import React, { useEffect, useState } from 'react';
import StrategyDesktopTable from './StrategyDesktopTable';
import StrategyMobileTable from './StrategyMobileTable';
import useLiveStrategyDetails from '../../hooks/useLiveStrategyDetails';
import { DataLoader } from '../../Common/DataLoader';
import { useDispatch, useSelector } from 'react-redux';
import ActionCardUi from '../../../Pages/UserComponents/subscription/ActionCardUi';
import { openLiveReportFields } from '../../../features/strategyReport/strategyDataDetailSlice';

function ShowLiveTrade({
  toggleState,
  isMobile,
  // setBackTestingModalShow,
  livePagination,
  // liveData,
  handleliveTradeTable,
  liveRefech,
  isFetching,
}) {
  // const {
  //   livePagination,
  //   liveData,
  //   handleliveTradeTable,
  //   liveRefech,
  //   isFetching,
  // } = useLiveStrategyDetails();

  const [loading, setLoading] = useState(false);
  var dispatch = useDispatch();

  const { liveReportsData } = useSelector(
    (state) => state.completeStrategyDetails,
  );

  return liveReportsData.length === 0 ? (
    <div className="flex items-center flex-col justify-center min-h-[calc(100svh-400px)]">
      <ActionCardUi
        title={'You don’t have Live Running'}
        subTitle={'Click on the below button to setup Live Trade'}
        actionButton={true}
        buttonName={'Add Live Trade'}
        handleAction={() => dispatch(openLiveReportFields(true))}
      />
    </div>
  ) : (
    // <div className="empty__bx bg-color-base-1">
    //   <h3>You don’t have Live Running</h3>
    //   <button
    //     onClick={() => setBackTestingModalShow(true)}
    //     className="btn__add__strategy flex justify-center items-center"
    //   >
    //     <svg
    //       stroke="currentColor"
    //       fill="#4074F9"
    //       strokeWidth="0"
    //       viewBox="0 0 16 16"
    //       height="1em"
    //       width="1em"
    //       xmlns="http://www.w3.org/2000/svg"
    //     >
    //       <path
    //         fillRule="evenodd"
    //         d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
    //       ></path>
    //     </svg>
    //   </button>
    //   <h4>Click on the + button to setup Live</h4>
    // </div>
    <div className="col ps-0 pe-0 mt-3">
      <>
        {isMobile ? (
          <StrategyMobileTable
            tradeOrder={liveReportsData}
            toggleState={toggleState}
            refetch={liveRefech}
            pagination={livePagination}
            handleTableChange={handleliveTradeTable}
            loading={isFetching}
          />
        ) : (
          <StrategyDesktopTable
            tradeOrder={liveReportsData}
            toggleState={toggleState}
            refetch={liveRefech}
            pagination={livePagination}
            handleTableChange={handleliveTradeTable}
            loading={isFetching}

            // setLoading={setLoading}
          />
        )}
      </>
    </div>
  );
}

export default ShowLiveTrade;
