import React from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import {
  btnPrimaryClass,
  btnSuccessClass,
} from '../../../components/utils/defaultClasses';
import { AiOutlineCheck } from 'react-icons/ai';
import { formatINR } from '../../../utils/utils';

export default function SubscriptionUpgradeCardV2({
  data,
  showDetail,
  handleButtonClick,
  onPlanSelected,
}) {
  return (
    <>
      <div className="h-full group" onClick={() => onPlanSelected(data)}>
        <div
          className={`border-[2px] text-center ${
            data?.selected
              ? 'border-green-3 hover:border-green-3'
              : 'border-transparent'
          } p-[15px] rounded-xl hover:border-blue-3/50 bg-color-base-4 h-full relative overflow-hidden group-hover:-translate-y-1 duration-300 ease-in-out`}
        >
          {data?.selected ? (
            <div className="absolute top-5 right-5 text-green-3 text-2xl">
              <BsCheckCircleFill />
            </div>
          ) : null}

          <div className="space-y-5">
            <div className="space-y-1 flex flex-col items-center">
              <div>
                <span>{data?.icon ? data?.icon : null}</span>
              </div>
              <div>
                <h1 className="text-2xl font-bold">{data?.title}</h1>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-center">
                <h2 className="text-2xl font-bold mb-0 flex items-center gap-1.5">
                  {formatINR(data?.price)}
                  {/* <span className="flex px-1.5 py-1 bg-blue-3/15 text-color-primary rounded-lg text-sm font-medium">
                    -15% Discount
                  </span> */}
                </h2>
                <p className="mb-0 text-color-secondary font-medium">
                  {data?.subtitle}
                </p>
              </div>
            </div>
            <div>
              <p className="font-semibold mb-1.5">{data?.plan_list_title}</p>

              <div
                className={`${
                  showDetail ? 'accordion-content' : ''
                } showDetail overflow-hidden`}
              >
                <div className="flex flex-col items-center divide-y divide-level-2 w-full">
                  {data?.plan_list.map(
                    (items, index) =>
                      index <= data?.plan_list.length - 1 && (
                        <div
                          className="text-color-primary flex justify-center items-center gap-1.5 py-3.5 w-full text-center min-h-[34px]"
                          key={index}
                        >
                          {/* {console.log(items, 'hello2')}
                          <span>
                            <AiOutlineCheck className="text-green-3 font-medium" />
                          </span> */}
                          <span className="text-color-secondary font-medium flex min-h-[21px]">
                            {items}
                          </span>
                        </div>
                      ),
                  )}
                </div>
                {/* {!showDetail ? ( */}
                <div
                  // className="absolute bg-color-base-2 dark:bg-color-base-1 bg-opacity-10 backdrop-blur-sm h-[60px] w-full bottom-0 left-0 rounded-b-xl rounded-t-[90px]"
                  className={`absolute bg-color-base-2/10 dark:bg-color-base-1/10 backdrop-blur-sm h-[70px] w-full bottom-0 left-0 rounded-b-2xl rounded-t-[35px] ${
                    !showDetail ? 'accordion-content' : ''
                  } showDetail overlay overflow-hidden`}
                ></div>
                {/* ) : null} */}
              </div>
            </div>
            <div className="w-full">
              <button
                // to={'/upgrade-plan'}
                // target="_blank"
                onClick={() => handleButtonClick(data)}
                disabled={data?.price === 0}
                className={`${
                  data?.selected
                    ? `${btnSuccessClass} hover:bg-green-3/100`
                    : btnPrimaryClass
                } w-full text-lg py-2.5 rounded-[12px]`}
              >
                {data?.btnText}
                {/* {data?.plan_active ? 'Active Plan' : data?.btnText} */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
