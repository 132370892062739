import React, { useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import { AiOutlineCaretRight, AiOutlineClose } from 'react-icons/ai';
import { Popover as AntdPopover } from 'antd';
import NotificationContent from './NotificationContent';

export default function NotificationPopover() {
  const [open, setIsOpen] = useState(false);
  const [openNotification, setIsOpenNotification] = useState(false);

  const handleOpenChange = (newOpen) => {
    setIsOpen(newOpen);
  };
  return (
    <>
      <AntdPopover
        content={
          <div className="min-w-[420px]">
            <NotificationContent />
          </div>
        }
        // title="Title"
        trigger="click"
        open={open}
        destroyTooltipOnHide={true}
        arrow={false}
        placement={'bottomLeft'}
        onOpenChange={handleOpenChange}
        overlayClassName="antd-popover"
        overlayStyle={{ paddingTop: '12px' }}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        className="mr-2"
      >
        <button
          //   className={`min-h-[33px] min-w-[33px] p-[2px] dark:bg-brown-800 bg-lightTheme-70 hover:bg-lightTheme-80 dark:hover:bg-zinc-700 dark:hover:bg-opacity-90 dark:border-brown-800 rounded-lg dark:text-white flex gap-1 items-center justify-center text-base leading-tight relative`}
          className={`min-h-[33px] min-w-[33px] p-[2px]  flex gap-1 items-center justify-center text-base leading-tight relative`}
        >
          {/* <span className="flex w-3 h-3 rounded-full -top-1 -right-1 absolute">
            <span className="relative flex h-[8px] w-[8px]">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-2 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-[8px] w-[8px] bg-green-2"></span>
            </span>
          </span> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="Isolation_Mode"
            data-name="Isolation Mode"
            viewBox="0 0 24 24"
            width="1em"
            height="1em"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 pulse-button"
          >
            <path d="M23.608,17.013l-2.8-10.1A9.443,9.443,0,0,0,2.486,7.4L.321,17.14a2.5,2.5,0,0,0,2.441,3.042H6.905a5.285,5.285,0,0,0,10.154,0H21.2a2.5,2.5,0,0,0,2.409-3.169Zm-20.223.169,2.03-9.137a6.443,6.443,0,0,1,12.5-.326l2.628,9.463Z" />
          </svg>
          <span className="notifucation__badge">1</span>
        </button>
      </AntdPopover>
    </>
  );
}
