import { useState, useEffect } from 'react';
import { BrokerCard2 } from '../utils/BrokerCard';
// import Correct from '../../../assets/images/correct.png';
import Correct from '../assets/images/correct.png';
import { Col, Modal, Row, Badge } from 'react-bootstrap';
import { Switch, Tooltip, Dropdown } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import mydecodedTokenFunction from '../utils/decodetoken';
import { CrossIcon } from '../assets/Icon';
import moment from 'moment';
import Skeleton from '@mui/material/Skeleton';
import {
  MdConnectWithoutContact,
  MdEdit,
  MdInfoOutline,
  MdKeyboardArrowDown,
  MdLock,
  MdOutlineClose,
  MdOutlineLinkOff,
  MdPlayArrow,
} from 'react-icons/md';
import config from '../Config/config';
import Accordion from 'react-bootstrap/Accordion';
import TableComponent from '../components/Common/TableComponent';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../components/utils/defaultClasses';
import NoDataFoundV2 from '../components/Common/NoDataFoundV2';
import BrokerPopover from '../components/UI/Broker/BrokerPopover';
import { formatINR } from '../utils/utils';
import BrokerModal from '../components/Common/brokers/brokerModal';
import { useDispatch, useSelector } from 'react-redux';
import StatusStrips from '../components/Common/StatusStrips';
import GetStartedCard from '../components/Common/GetStartedCard';
import {
  setOpenBrokerModal,
  setSelectedBroker,
  setSelectedType,
} from '../features/brokers/brokerSlice';
import { Link } from 'react-router-dom';
import { FaRegEye } from 'react-icons/fa';

let message = '';
let deleteMessage = '';
var modalData = '';
const Broker = () => {
  const [isMobile, setIsMobile] = useState(false);
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  const dispatch = useDispatch();
  //console.log(userid);
  const [loading, setLoading] = useState(true);
  const [openModel, setOpenModel] = useState(false);
  //Broker data Hooks
  const [brokerDatas, setBrokerDatas] = useState([]);
  const [connectedBroker, setConnectedBroker] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [successModalShow, setSuccessModalShow] = useState(false);
  const [showGetStarted, setShowGetStarted] = useState(true);
  //console.log("open: ", open);

  const { openBrokerModal } = useSelector((state) => state.brokerDetails);

  // const showModal = () => {
  //   setOpen(true);
  // };
  const handleModelOk = () => {
    setOpenModel(false);
  };
  const [headerTopTape, setHeaderTopTape] = useState({
    top: true,
    middle: true,
  });
  // const handleOk = () => {
  //   setLoading(true);
  //   setOpenModel(true);
  //   setTimeout(() => {
  //     setLoading(false);
  //     setOpen(false);
  //   }, 3000);
  // };

  // const handleCancel = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    getBrokerList();
    getconnectedBrokers();
  }, []);

  //API to get all brokers list

  const getBrokerList = () => {
    //console.log("here");
    axios
      .get(`${config.base_url}${config.broker}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        // let extraData = [
        //   {
        //     category_name: 'Collections',
        //   },
        //   { category_name: 'Creators' },
        // ];
        setBrokerDatas(res.data.data);
        // setBrokerDatas([...res.data.data, ...extraData]);
        // console.log('Response Data', res.data.data);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const header = [
    {
      key: '1',
      title: 'Broker Name',
      dataIndex: 'broker_name',
      render: (name) => {
        return name && name !== '' ? name : 'Brocker Name';
      },
    },
    {
      title: 'Verified',
      dataIndex: 'input',
      key: '2',
      render: (input, record) => {
        //console.log(input);
        for (let i = 0; i < input.length; i++) {
          //console.log(input[i].key_name);

          return (
            <div className="d-flex gap-2 align-items-center">
              {/* {input[i].key_name === 'client_id' ? (
                <span className="d-flex">{input[i].key_value}</span>
              ) : (
                <span className="d-flex">-</span>
              )} */}

              <span className="d-flex">
                <span>
                  <span
                    className={`flex justify-center items-center min-w-[100px] rounded-md px-1.5 ${
                      record.status === 'verified'
                        ? 'bg-green-2/15 text-green-2'
                        : 'bg-red-2/15 text-red-2'
                    }`}
                  >
                    {record.status === 'verified' ? 'Verified' : 'Not Verified'}
                  </span>
                </span>
              </span>
            </div>
          );
        }
        //return status === true ? "Connect On" : "Connect Off";
      },
    },

    {
      title: 'Funds',
      dataIndex: 'funds',
      key: '3',
      render: (funds) => {
        if (
          funds !== undefined &&
          funds !== null &&
          funds !== '' &&
          funds !== 'invalid'
        ) {
          return formatINR(funds);
        } else {
          return '-';
        }
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: '4',
      render: (status, record) => {
        const onToggle = (checked) => {
          status = checked;
          onActiveUser(status, record);
        };
        return (
          <Switch
            defaultChecked={status === 'verified' ? true : false}
            onChange={onToggle}
          />
        );
      },
    },

    {
      title: 'Added On',
      dataIndex: 'updated_at',
      key: '5',
      render: (updated_at) => {
        return moment(updated_at).format('DD MMM, YYYY h:mm a');
      },
    },

    {
      title: 'Actions',
      dataIndex: '_id',
      key: '6',
      render: (status, record) => {
        //console.log(status, record);
        return (
          <>
            <div className="d-flex gap-2">
              {/* <div className="">
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <div className="flex items-center gap-1.5">
                            <EyeOutlined />
                            View
                          </div>
                        ),
                        onClick: () => {
                          // console.log('recore', record);
                          viewModal(status, record);
                        },
                      },
                      {
                        key: '2',
                        label: (
                          <div className="flex items-center gap-1.5">
                            <DeleteOutlined />
                            Delete
                          </div>
                        ),
                        onClick: () => {
                          modalData = record;
                          setConfirmDelete(true);
                        },
                      },
                    ],
                  }}
                  placement="bottomRight"
                  overlayClassName={'dropdownOverlay dowpdownDark'}
                  trigger={['click']}
                  className="flex items-center justify-center border-[1px] border-component-gray-1 whitespace-nowrap transition duration-200 text-md font-normal bg-component-gray-1 dark:bg-component-gray-1 hover:bg-blue-3/20 hover:text-blue-2 dark:hover:bg-blue-3/20 gap-1.5 rounded-[6px] text-color-primary px-2 py-0.5 cursor-pointer"
                >
                  <div onClick={(e) => e.preventDefault()}>
                    Action
                    <MdKeyboardArrowDown />
                  </div>
                </Dropdown>
              </div> */}
              <div className="flex items-center gap-2">
                <Tooltip title={<>View</>}>
                  <button
                    onClick={() => {
                      viewModal(status, record);
                    }}
                    className="border-[1px] border-solid border-level-2 text-color-primary hover:border-blue-3 hover:bg-blue-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
                  >
                    <FaRegEye />
                  </button>
                </Tooltip>
                <Tooltip title={<>Edit</>}>
                  <button
                    onClick={() => Link}
                    className="border-[1px] border-solid border-level-2 text-color-primary hover:border-yellow-500 hover:bg-yellow-500 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
                  >
                    <MdEdit />
                  </button>
                </Tooltip>
                <Tooltip title={<>Delete</>}>
                  <button
                    onClick={() => {
                      modalData = record;
                      setConfirmDelete(true);
                    }}
                    className="border-[1px] border-solid border-level-2 hover:border-red-3 text-color-primary hover:bg-red-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
                  >
                    <DeleteOutlined />
                  </button>
                </Tooltip>
                <Tooltip title={<>Disconnect</>}>
                  <button className="border-[1px] border-solid border-level-2 hover:border-red-3 text-color-primary hover:bg-red-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center">
                    <MdOutlineLinkOff />
                  </button>
                </Tooltip>
              </div>
            </div>
            {/* <div className="d-flex">
              <button
                onClick={() => viewModal(status, record)}
                className="view__button"
              >
                View
              </button>
              <button
                onClick={() => {
                  modalData = record;
                  setConfirmDelete(true);
                }}
                className="view__button ml-2"
              >
                Delete
              </button>
            </div> */}
          </>
        );
      },
    },
  ];

  // Function to view particular broker information in the view modal

  const viewModal = (status, record) => {
    console.log(brokerDatas, 'brokerDatas');
    modalData = record;
    let demo = brokerDatas.filter((el) => el._id === modalData.broker_id);
    modalData = { ...record, logo_img: demo[0]?.logo_img };

    // console.log(modalData, 'modalData');
    dispatch(setSelectedBroker(modalData));
    dispatch(setSelectedType('view'));
    dispatch(setOpenBrokerModal(true));
    // setUpdateBroker(Tempinput);
    // setOpenViewModal(true);
    //console.log(modalData);
    //console.log(modalData);
  };

  //API request made when we update the status

  const onActiveUser = (status, record) => {
    // console.log(status, record);
    let payload = {
      id: record._id,
      user_id: record.user_id,
      broker_id: record.broker_id,
      broker_name: record.broker_name,
      input: record.input,
      funds: record.funds ? record.funds : 0,
      status: status === true ? 'verified' : 'not verified',
    };
    //console.log(payload);
    axios
      .put(`${config.base_url}${config.broker_crendientials}`, payload, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        //console.log("updated Data", res);
        getconnectedBrokers();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // Connected Brokers list
  const getconnectedBrokers = () => {
    //console.log("here");
    axios
      .get(`${config.base_url}${config.broker_crendientials}/${userid.id}`, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      })
      .then((res) => {
        // console.log("Connected Brokers ", res);
        setConnectedBroker(res.data.data);
        setLoading(false);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // Connect to Brokers

  // const handelChange = (e) => {
  //   setConnectBroker({ ...connectBroker, [e.target.name]: e.target.value });
  // };

  // const handelChangeUpdateBroker = (e) => {
  //   setUpdateBroker({ ...updateBroker, [e.target.name]: e.target.value });
  // };

  // API to update the brokers.

  // const updateBrokerData = (payload) => {
  //   axios
  //     .put(`${config.base_url}${config.broker_crendientials}`, payload, {
  //       headers: { Authorization: `Bearer ${getuserBots}` },
  //     })
  //     .then((res) => {
  //       //console.log("added Data", res);
  //       if (res.data.message === 'Insert successfully.') {
  //         message = res.data.message;
  //       } else {
  //         message = res.data.message;
  //       }
  //       setOpenViewModal(false);
  //       setOpenModel(true);
  //       getconnectedBrokers();
  //     })
  //     .catch(function (err) {
  //       console.log(err);
  //     });
  // };

  // const handelUpdate = (e) => {
  //   e.preventDefault();
  //   //console.log(updateBroker);
  //   let inputValue = [];
  //   for (let x in updateBroker) {
  //     inputValue.push({ key_name: x, key_value: updateBroker[x] });
  //   }
  //   let payload = {
  //     id: modalData._id,
  //     user_id: modalData.user_id,
  //     broker_id: modalData.broker_id,
  //     broker_name: modalData.broker_name,
  //     funds: modalData.funds ? modalData.funds : 0,
  //     input: inputValue,
  //     status: modalData.status,
  //   };
  //   //console.log(payload);
  //   updateBrokerData(payload);
  // };

  // const Keyname = (data) => {
  //   let name = '';
  //   if (data !== '' && data !== null && data !== undefined) {
  //     let temp = data.split('_').join(' ');
  //     name = temp.charAt(0).toUpperCase() + temp.slice(1);
  //   }
  //   //console.log(name);
  //   return name;
  // };

  // Delete the connected brokers.
  const handelDelete = () => {
    let getID = modalData !== '' ? modalData._id : '';
    //console.log(getID);
    axios
      .delete(
        `${config.base_url}${config.broker_crendientials}/${getID}`,

        {
          headers: { Authorization: `Bearer ${getuserBots}` },
        },
      )
      .then((res) => {
        //console.log("Response", res);
        deleteMessage = res.data.message;
        getconnectedBrokers();
        setConfirmDelete(false);
        setSuccessModalShow(true);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  // const iconRenders = (visible) => {
  //   visible ? (
  //     <span>
  //       <EyeTwoTone className="text-color-primary" />
  //     </span>
  //   ) : (
  //     <span>
  //       <EyeInvisibleOutlined className="text-color-primary" />
  //     </span>
  //   );
  // };

  const slides = [
    {
      icon: (
        <div className="w-[40px] h-[40px] border-[10px] border-blue-3 rounded-full"></div>
      ),
      title: 'Select/Choose',
      subtitle:
        'User can choose from the list of available broker to place the order in live market as per their live setup',
    },
    {
      icon: (
        <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
          <MdLock />
        </div>
      ),
      title: 'Credentials',
      subtitle:
        'This allow you to enter the required credentials in the form to create a connection request to connect the selected broker',
    },
    {
      icon: (
        <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
          <MdLock />
        </div>
      ),
      title: 'Processing',
      subtitle: `Submitted request will be reviewed and processed,once details are confirmed it will be available for trade`,
    },
    {
      icon: (
        <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
          <MdLock />
        </div>
      ),
      title: 'Ready to trade',
      subtitle:
        'Connected broker allows you to trade in the live market witrh your strategy',
    },
  ];

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <div className="broker_page left__bar">
        {showGetStarted && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="flex justify-center items-center w-full">
                <GetStartedCard
                  customSlides={slides}
                  handleClose={() => setShowGetStarted(false)}
                />
              </div>
            </div>
          </div>
        )}
        {headerTopTape?.middle &&
          !localStorage.getItem('brokerMiddleStrip') && (
            <div className="row mt-2 px-0">
              <div className="col-12 px-0">
                <div className="mb-2">
                  <StatusStrips
                    status="exclusive"
                    title="Exclusive Feature"
                    closeIcon={{
                      show: true,
                      click: () => {
                        setHeaderTopTape((prevHeaderTopTape) => ({
                          ...prevHeaderTopTape,
                          middle: false,
                        }));
                        localStorage.setItem('brokerMiddleStrip', true);
                      },
                    }}
                    description="List of available broker allows you to connect with your respective broker account,read the documentation to know how to connect."
                  />
                </div>
              </div>
            </div>
          )}
        {headerTopTape?.top && !localStorage.getItem('brokerTopStrip') && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="update"
                  title="Important Updates"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        top: false,
                      }));
                      localStorage.setItem('brokerTopStrip', true);
                    },
                  }}
                  description="Platform allows you connect multiple account of the same broker,during your live trade you may switch and choose your credentials for live order"
                />
              </div>
            </div>
          </div>
        )}

        <div className="pt-2.5">
          <Row>
            <Col xl={6}>
              <div>
                <div className="mb-6 flex justify-between items-center">
                  <div className="flex gap-2">
                    <div className="">
                      <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          className="bi bi-person-gear shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                        </svg>
                      </span>
                    </div>
                    <div className="">
                      <div className="flex gap-2 items-center">
                        <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                          Suggested Broker
                        </h4>
                        <span>
                          <Tooltip
                            title="Curated list of brokers we trust for quality service."
                            placement="right"
                          >
                            <MdInfoOutline />
                          </Tooltip>
                        </span>
                      </div>

                      <span className="flex text-xs text-color-secondary/70">
                        Manage and monitor all your Brokers.
                      </span>
                    </div>
                  </div>
                  <div>
                    <BrokerPopover />
                  </div>
                </div>
                {brokerDatas && brokerDatas.length > 0 ? (
                  <section className="mt__spc">
                    <div>
                      <Row className="row-cols-1 row-cols-md-2 row-cols-xl-2 row-cols-xxl-2">
                        {brokerDatas.map((el) => {
                          return (
                            <Col key={el._id} className="mb-3">
                              <BrokerCard2
                                name={el.name}
                                description={el.description}
                                image={el.logo_img}
                                // showModal={showModal}
                                // getBroker={getBroker}
                                // id={el._id}
                                broker={el}
                              />
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </section>
                ) : (
                  <section className="mbPx-100 mb__spc">
                    <div>
                      <Row className="row-cols-2 row-cols-md-3 row-cols-xl-2 row-cols-xxl-2">
                        <Col>
                          <Skeleton
                            height="150px"
                            variant="rectangular"
                            style={{ borderRadius: '10px', marginTop: '0px' }}
                          />
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Col>
                        <Col>
                          <Skeleton
                            height="150px"
                            variant="rectangular"
                            style={{ borderRadius: '10px', marginTop: '0px' }}
                          />
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Col>
                        <Col>
                          <Skeleton
                            height="150px"
                            variant="rectangular"
                            style={{ borderRadius: '10px', marginTop: '0px' }}
                          />
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Col>
                        <Col>
                          <Skeleton
                            height="150px"
                            variant="rectangular"
                            style={{ borderRadius: '10px', marginTop: '0px' }}
                          />
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Col>
                        <Col>
                          <Skeleton
                            height="150px"
                            variant="rectangular"
                            style={{ borderRadius: '10px', marginTop: '0px' }}
                          />
                          <Skeleton />
                          <Skeleton width="60%" />
                        </Col>
                      </Row>
                    </div>
                  </section>
                )}
              </div>
            </Col>
            <Col xl={6}>
              <section className="mt-0 cstm__btm__spc">
                <div>
                  <div className="mb-6">
                    <div className="flex gap-2">
                      <div className="">
                        <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                          <MdConnectWithoutContact />
                        </span>
                      </div>
                      <div className="">
                        <div className="flex gap-2 items-center">
                          <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                            Connected Brokers
                          </h4>
                          <span>
                            <Tooltip
                              title="Manage and view your connected brokers here."
                              placement="right"
                            >
                              <MdInfoOutline />
                            </Tooltip>
                          </span>
                        </div>
                        <span className="flex text-xs text-color-secondary/70">
                          Manage and monitor all your Connected Brokers.
                        </span>
                      </div>
                    </div>
                  </div>
                  {isMobile === false ? (
                    <Row>
                      <Col className="">
                        {connectedBroker && connectedBroker.length === 0 ? (
                          <div className="border-[1px] border-level-2 mbPx-24 min-h-[200px] py-5 d-flex align-items-center justify-content-center bg-color-base-1 rounded-lg">
                            <div className="px-4">
                              <NoDataFoundV2 title={'No connected Broker'} />
                              {/* <div className="mb-4 fw-normal fsize-18 text-capitalize">
                                No connected Broker
                              </div> */}
                            </div>
                          </div>
                        ) : (
                          <div className="mb-4">
                            <div className="border-[1px] border-level-1 shadow-md shadow-level-4 dark:shadow-level-4 w-full rounded-lg overflow-hidden card_table">
                              <TableComponent
                                columns={header}
                                dataSource={connectedBroker}
                                pagination={false}
                                loading={loading}
                                scroll={{ x: '680px' }}
                              />
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <div>
                      <div className="tbl__hd mobile broker">
                        <div className="coll">Broker Name</div>
                        <div className="coll">Verified</div>
                        <div className="coll">Funds</div>
                      </div>

                      <Accordion className="cstm__accordion">
                        {connectedBroker?.map((item) => (
                          <Accordion.Item eventKey={item._id}>
                            <Accordion.Header>
                              <div className="tbl__body broker">
                                <div className="coll text-truncate">
                                  {item.broker_name}
                                </div>
                                <div className="coll">
                                  {item.status === 'verified' ? (
                                    <Badge bg="success" className="pt-1">
                                      Verified
                                    </Badge>
                                  ) : (
                                    <Badge bg="danger" className="pt-1">
                                      Not Verified
                                    </Badge>
                                  )}
                                  {/* <span className="pt-1 badge bg-success">
                              Verified
                            </span> */}
                                </div>
                                <div className="coll">
                                  {item.funds ? item.funds : '-'}
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body className="text-color-primary">
                              <div className="tbl__body__inner broker text-color-secondary">
                                <div className="coll text-truncate">
                                  Added On
                                </div>
                                <div className="coll">Status</div>
                                <div className="coll">Actions</div>
                              </div>

                              <div className="tbl__body__inner__data broker">
                                <div className="coll text-truncate">
                                  {moment(item.updated_at).format(
                                    'DD MMM, YYYY h:mm a',
                                  )}
                                </div>
                                <div className="coll">
                                  <Switch
                                    defaultChecked={
                                      item.status === 'verified' ? true : false
                                    }
                                    onChange={(checked) => {
                                      // console.log(checked, item);
                                      onActiveUser(checked, item);
                                    }}
                                  />
                                  {/* <button
                              type="button"
                              role="switch"
                              aria-checked="true"
                              class="ant-switch ant-switch-checked"
                            >
                              <div class="ant-switch-handle"></div>
                              <span class="ant-switch-inner"></span>
                            </button> */}
                                </div>
                                <div className="coll">
                                  <button
                                    className="view__button"
                                    onClick={() => {
                                      viewModal('', item);
                                    }}
                                  >
                                    View
                                  </button>
                                  <button
                                    className="view__button ml-1"
                                    onClick={() => {
                                      modalData = item;
                                      setConfirmDelete(true);
                                    }}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                      </Accordion>
                    </div>
                    // <ConnectedBroker connectedBroker={connectedBroker} />
                  )}
                </div>
              </section>
            </Col>
          </Row>
        </div>

        {openBrokerModal && <BrokerModal refetch={getconnectedBrokers} />}

        <Modal
          show={openModel}
          onHide={handleModelOk}
          backdrop="static"
          keyboard={false}
          centered
          contentClassName="bg-color-base-1 rounded-lg overflow-hidden"
        >
          <div className="position-absolute topPx-10 rightPx-10 zindex-2">
            <button
              onClick={() => {
                handleModelOk();
              }}
              className="btn btnPrimary max-content wpx-40 hpx-40 position-relative"
            >
              <MdOutlineClose className="m-0 position-absolute top-percent-50 left-percent-50 translate-neg-50-50" />
            </button>
          </div>
          <Modal.Body className="bg-color-base-1 rounded-lg overflow-hidden">
            <div className="d-flex align-items-center justify-content-end">
              <span
                onClick={handleModelOk}
                className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
              >
                <CrossIcon className="text-base text-color-primary" />
              </span>
            </div>
            {message === 'Insert successfully.' ||
            message === 'Update successfully.' ? (
              <div className="display__container mt-4">
                <img src={Correct} width="100px" height="100px" alt="img" />
                <h6 className="display__title">
                  {message === 'Insert successfully.'
                    ? 'Connected Successfully.'
                    : 'Updated Successfully.'}
                </h6>
              </div>
            ) : (
              <div className="display__container mt-4">
                <h6 className="display__title">{message}</h6>
              </div>
            )}
            <div className="d-flex justify-content-end mt-4">
              <button className={`${btnPrimaryClass}`} onClick={handleModelOk}>
                Ok, Got it!
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* {deletemodal} */}

        <Modal
          show={confirmDelete}
          onHide={() => setConfirmDelete(false)}
          backdrop="static"
          keyboard={false}
          centered
          contentClassName="bg-color-base-1 rounded-lg overflow-hidden"
        >
          {/* <div className="position-absolute topPx-10 rightPx-10 zindex-2">
            <button
              onClick={() => {
                setConfirmDelete(false);
              }}
              className="btn btnPrimary max-content wpx-40 hpx-40 position-relative"
            >
              <MdOutlineClose className="m-0 position-absolute top-percent-50 left-percent-50 translate-neg-50-50" />
            </button>
          </div> */}
          <Modal.Body className="bg-color-base-1 rounded-lg overflow-hidden">
            <div className="d-flex align-items-center justify-content-between">
              <Modal.Title>Delete Confirmation</Modal.Title>
              <div className="text-end">
                <span
                  onClick={() => setConfirmDelete(false)}
                  className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
                >
                  <CrossIcon className="text-base text-color-primary" />
                </span>
              </div>
            </div>
            <div className="alert alert-danger mt-4">
              Are you sure you want to delete?
            </div>
            {/* {console.log(modalData)} */}
            <div className="d-flex align-items-center justify-content-end mt-4 gap-2">
              <button
                className={`${btnSecondayClass}`}
                onClick={() => {
                  modalData = '';
                  setConfirmDelete(false);
                }}
              >
                Cancel
              </button>
              <button
                className={`btn btn-danger py-1.5 border-0`}
                onClick={handelDelete}
              >
                Delete
              </button>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={successModalShow}
          size={'lg'}
          backdrop="static"
          keyboard={false}
          centered
          contentClassName="bg-color-base-1 rounded-lg overflow-hidden"
        >
          <Modal.Body className="bg-color-base-1 rounded-lg overflow-hidden">
            <div className="text-end mb-4">
              <span
                onClick={() => setSuccessModalShow(false)}
                className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
              >
                <CrossIcon className="text-base text-color-primary" />
              </span>
            </div>
            {deleteMessage === 'Delete Successfully.' ? (
              <div className="text-center">
                <p>Deleted Successfully</p>
                <button
                  onClick={() => {
                    setSuccessModalShow(false);
                  }}
                  className="mt-4 mb-4 textBColor w-25 ms-auto btn py-2 round-50 hpx-40 fsize-16 fw-semibold secondaryBttn"
                >
                  Okay
                </button>
              </div>
            ) : (
              <p>{deleteMessage}</p>
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Broker;
