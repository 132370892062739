import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  openBrokerModal: false,
  selectedBroker: {},
  selectedType: 'create',
};

export const brokerModalSlice = createSlice({
  name: 'subscribedDetails',
  initialState,
  reducers: {
    setOpenBrokerModal: (state, { payload }) => {
      state.openBrokerModal = payload;
    },
    setSelectedBroker: (state, { payload }) => {
      state.selectedBroker = payload;
    },
    setSelectedType: (state, { payload }) => {
      state.selectedType = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setOpenBrokerModal, setSelectedBroker, setSelectedType } =
  brokerModalSlice.actions;

const brokerModalReducer = brokerModalSlice.reducer;

export default brokerModalReducer;
