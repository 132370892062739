import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from '../Config/config';
import { valueSocket } from '../context/socket';
import dayjs from 'dayjs';
import DateTime from '../components/Common/DateTime';
import ReportIcons from '../components/ReportIcons';
import ReportTabs from '../components/ReportTabs';
import ReportCardsData from '../components/ReportCardsData';
import { btnSecondayClass } from '../components/utils/defaultClasses';
import ReportDrawerInputs from '../utils/ReportDrawerInputs';
import useReportOpenOrder from '../components/hooks/useReportOpenOrder';
import ReportShowStrip from '../components/UI/ReportPage/ReportShowStrip';
import moment from 'moment';

const ViewVTReport = () => {
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  let getuserBots = localStorage.getItem('token');
  let { id, tab } = useParams();
  let history = useNavigate();
  const [headerTopTape, setHeaderTopTape] = useState({
    firstTop: false,
    top: false,
    middle: false,
    topLast: false,
    bottom: false,
  });

  const [isMobile, setIsMobile] = useState(false);

  const [reportStatus, setReportStatus] = useState(false);

  const {
    reportData,
    overAllPnL,
    lastOrder,
    inputs,
    upDateReport,
    fundBalance,
    strategyData,
  } = useReportOpenOrder();

  //Responsiveness
  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //beforeunload event
  useEffect(() => {
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  //beforeunload function
  const handleBeforeUnload = (event) => {
    // event.preventDefault();
    valueSocket.disconnect();
  };

  //Tooltip of VT&LT modal
  const getTooltip = () => {
    if (tab === 'VT') {
      if (reportStatus === true) return 'Virtual Trade is Active';
      else return 'Virtual Trade is Deactive';
    }

    if (tab === 'LT') {
      if (reportStatus === true) return 'Live Trade is Active';
      else return 'Live Trade is Deactive';
    }
  };

  //function to make play-pause to be disbaled
  const getPauseIcon = useCallback(() => {
    if (lastOrder.length === 0) {
      return false;
    } else {
      return true;
    }
  }, [lastOrder.length]);

  //function of the Active status
  function getActiveStatus() {
    if (reportData?.request_data?.status === true) {
      return (
        <span className="badge__green flex m-0">
          <small>Active</small>
        </span>
      );
    }
    if (reportData?.request_data?.status === false) {
      return (
        <span className="bg-red-3 text-whiteNew-0 px-[8px] py-[4px] rounded-[4px] text-[12px] flex m-0 ">
          <small>Inactive</small>
        </span>
      );
    }
  }

  // console.log(headerTopTape);

  return (
    <>
      {/* <Sidebar /> */}
      <div className="left__bar">
        <ReportShowStrip
          tab={tab}
          reportData={reportData}
          headerTopTape={headerTopTape}
          setHeaderTopTape={setHeaderTopTape}
          strategyData={strategyData}
        />
        <div className="row bg__white p-2 mt-3 rounded justify-between items-center">
          <div className="col-lg-3 order-1 col-6 ps-0 ps-lg-0">
            <div className="flex items-center gap-2">
              <div className="">
                <div className="flex">
                  <Link
                    onClick={() => {
                      valueSocket.disconnect();
                      history(-1);
                    }}
                    className={`${btnSecondayClass} rounded-full py-2.5`}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="">
                <div>
                  <div className="flex items-center gap-1.5">
                    <h2 className="strtgy__ttl">
                      {tab === 'VT'
                        ? reportData?.request_data?.vt_inputs?.report_name
                        : reportData?.request_data?.lt_inputs?.report_name}
                      {/* {`${reportData?.request_data?(tab==="VT"?vt_inputs?.report_name)}`} */}
                    </h2>
                    {getActiveStatus()}
                  </div>
                  <span className='primary__text__mute fs-7 time_update m-0 flex'>
                    {/* <DateTime /> */}
                    {moment(reportData?.request_data?.updated_at)?.format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-7 order-3 order-lg-2 px-0">
            <ReportIcons
              tab={tab}
              reportStatus={reportData?.request_data?.status}
              playPauseID={reportData?.request_data?._id}
              getPauseIcon={getPauseIcon}
              setHeaderTopTape={setHeaderTopTape}
              strategyData={strategyData}
              upDateReport={upDateReport}
              strategy_id={reportData?.request_data?.strategy_id}
              id={id}
            />
          </div>
        </div>

        <ReportCardsData
          tab={tab}
          brokerID={reportData?.request_data?.credential_id}
          lastOrder={lastOrder}
          configData={
            tab === 'LT'
              ? reportData?.request_data?.lt_inputs
              : reportData?.request_data?.vt_inputs
          }
          todayPnL={overAllPnL?.filterPnl}
          createdDate={reportData?.request_data?.created_at}
          overAllPnL={overAllPnL?.totalPnl}
          fundBalance={fundBalance}
          strategyName={reportData?.request_data?.strategyData?.name}
        />

        <div className="row">
          <div className="col-12 px-0">
            <div className="strtgy__table__box mt-1.5 lg:mt-3 bx_shadow sticky pl-3.5">
              <ReportTabs
                lastOrder={lastOrder}
                tab={tab}
                brokerID={reportData?.request_data?.credential_id}
                reportStatus={reportStatus}
                reportData={reportData}
              />
            </div>
          </div>
        </div>
      </div>

      <ReportDrawerInputs
        inputs={inputs}
        brokerId={reportData?.request_data?.credential_id}
        reportName={
          tab === 'VT'
            ? reportData?.request_data?.vt_inputs?.report_name
            : tab === "LT" ? reportData?.request_data?.lt_inputs?.report_name : reportData?.request_data?.st_inputs?.report_name
        }
        iT_id={reportData?.request_data?._id}
        modalData={tab === 'LT' ? 'live' : tab === 'VT' ? 'virtual' : "backtesting"}
        configData={
          tab === 'LT'
            ? reportData?.request_data?.lt_inputs
            : tab === 'VT' ? reportData?.request_data?.vt_inputs : reportData?.request_data?.st_inputs
        }
        strategyID={reportData?.request_data?.strategy_id}
        upDateData={upDateReport}
        reportData={reportData}
        setHeaderTopTape={setHeaderTopTape}
        strategyData={strategyData}
      />
    </>
  );
};
export default ViewVTReport;
