import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  detailsOfStrategy: {},
  liveReportsData: [],
  virtualReportsData: [],
  backtestingReportsData: [],
  selectedBacktestingData: [],
  virtualReportsDataSeperated: {
    active: [],
    inActive: []
  },
  liveReportsDataSeperated: {
    active: [],
    inActive: []
  },
  backtestingReportsDataSeperated: {
    active: [],
    inActive: []
  },
  selectedKeys: [],
  showLiveReportDrawer: false,
  showBacktestingReportDrawer: false,
  showVirtualReportDrawer: false,
};

export const completeStrategyDetails = createSlice({
  name: 'completeStrategyDetails',
  initialState,
  reducers: {
    completeDetailsOfStartegy: (state, { payload }) => {
      state.detailsOfStrategy = { ...payload };
    },
    virtualReportsdataStrategy: (state, { payload }) => {
      // console.log('payload', payload);
      state.virtualReportsData = payload;
    },
    backtestingReportsdataStrategy: (state, { payload }) => {
      // console.log('payload', payload);
      state.backtestingReportsData = payload;
    },
    setVirtualReportsDataSeperated: (state, { payload }) => {
      // console.log('payload', payload);
      state.virtualReportsDataSeperated = payload;
    },
    setLiveReportsDataSeperated: (state, { payload }) => {
      // console.log('payload', payload);
      state.liveReportsDataSeperated = payload;
    },
    setBacktestingReportsDataSeperated: (state, { payload }) => {
      // console.log('payload', payload);
      state.backtestingReportsDataSeperated = payload;
    },
    liveReportsdataStrategy: (state, { payload }) => {
      state.liveReportsData = payload;
    },
    openLiveReportFields: (state, { payload }) => {
      state.showLiveReportDrawer = payload;
    },
    openVirualReportFields: (state, { payload }) => {
      state.showVirtualReportDrawer = payload;
    },
    openBacktestingReportFields: (state, { payload }) => {
      state.showBacktestingReportDrawer = payload;
    },
    setSelectedBacktestingData: (state, { payload }) => {
      state.selectedBacktestingData = payload;
    },
    setSelectedKeys: (state, { payload }) => {
      state.selectedKeys = payload;
    },
  },
});

// Action creators are generated for each case reducer function changed
export const {
  completeDetailsOfStartegy,
  virtualReportsdataStrategy,
  setLiveReportsDataSeperated, backtestingReportsdataStrategy, setBacktestingReportsDataSeperated,
  liveReportsdataStrategy,
  openLiveReportFields, setSelectedKeys,
  openVirualReportFields, setVirtualReportsDataSeperated, openBacktestingReportFields, setSelectedBacktestingData
} = completeStrategyDetails.actions;

export default completeStrategyDetails.reducer;
