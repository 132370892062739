import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineCloudUpload, AiOutlineDelete } from 'react-icons/ai';
import useUploadImage from '../../hooks/useProfileImage';
import { SpinnerBtn } from '../../../assets/Icon';
import { useSelector } from 'react-redux';
import useToGetId from '../../hooks/useToGetId';

function UserDetailsHead() {
  let history = useNavigate();

  const {
    handleUploadProfileImg,
    imgError,
    profileImage,
    uploader,
    isLoading,
    handleDeleteProfileImg,
  } = useUploadImage();

  let { userid } = useToGetId();

  const { profileDetailsOfUser } = useSelector((state) => state.profileDetails);

  return (
    <>
      <div className="user__profile mb-3">
        <h2 className="strtgy__ttl">Account Overview</h2>
        <span className="sub__ttl d-flex gap-2 flex align-items-center mt-1">
          <Link
            onClick={() => {
              history(-1);
            }}
            className="cursor-pointer"
          >
            <svg
              stroke="currentColor"
              fill="currentColor"
              stroke-width="0"
              viewBox="0 0 24 24"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path fill="none" d="M0 0h24v24H0z"></path>
              <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
            </svg>
          </Link>
          <Link
            onClick={() => {
              history(-1);
            }}
            className="cursor-pointer"
          >
            Go Back
          </Link>
        </span>
      </div>

      <div className="user__info__box">
        <div className="row">
          <div className="col-lg-2 ps-lg-0">
            <div className="user__dp relative h-full w-full">
              <div className="bg-color-base-2 w-[100px] h-[100px] lg:h-full lg:w-full inset-0 border-[1px] border-dashed border-level-2 rounded-lg cursor-pointer absolute">
                <div className="w-full h-0 pb-[100%]">
                  <div className="absolute inset-0 overflow-hidden rounded-md rounded-b-none object-cover w-full h-full"></div>
                  {!profileDetailsOfUser?.user_image?.url && !profileImage && (
                    <div
                      className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-2xl z-10"
                      onClick={handleUploadProfileImg}
                    >
                      <AiOutlineCloudUpload />
                    </div>
                  )}
                </div>
              </div>
              <div className="relative group w-[100px] h-[100px] lg:h-full lg:w-full">
                <input
                  hidden
                  id="profile-Image"
                  onChange={(e) => uploader(e, 'banner')}
                  onClick={(e) => {
                    e.target.value = null;
                  }}
                  accept="image/*"
                  type={'file'}
                  // disabled={loading}
                />

                {(profileDetailsOfUser?.user_image?.url || profileImage) && (
                  <>
                    <div className="w-full h-0 pb-[100%]">
                      <div className="absolute inset-0 w-full h-full overflow-hidden rounded-md rounded-b-none object-cover">
                        <img
                          src={
                            profileImage ||
                            profileDetailsOfUser?.user_image?.url
                          }
                          className="object-cover h-full w-full"
                          alt="img"
                        />
                      </div>
                    </div>
                    <button
                      disabled={isLoading}
                      className="bg-color-base-2 w-full h-full absolute inset-0 border-[1px] border-dashed border-level-2 rounded-lg cursor-pointer opacity-[0] group-hover:opacity-100 duration-200 ease-in z-100 bg-opacity-10"
                    >
                      <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 flex items-center gap-1.5">
                        <div
                          className="text-2xl bg-color-base-2 rounded-full w-[35px] h-[35px] flex justify-center items-center"
                          onClick={handleUploadProfileImg}
                        >
                          {isLoading ? (
                            <>
                              <SpinnerBtn />
                            </>
                          ) : (
                            <>
                              <AiOutlineCloudUpload />
                            </>
                          )}
                        </div>
                        {(profileDetailsOfUser?.user_image?.url ||
                          profileImage) && (
                          <div
                            className="text-2xl bg-color-base-2 rounded-full w-[35px] h-[35px] flex justify-center items-center"
                            onClick={handleDeleteProfileImg}
                          >
                            {isLoading ? (
                              <>
                                <SpinnerBtn />
                              </>
                            ) : (
                              <>
                                <AiOutlineDelete />
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    </button>
                  </>
                )}
              </div>
            </div>
            {imgError !== '' ? (
              <>
                <div
                  className="ant-form-item-explain-error"
                  style={{ marginTop: '0px' }}
                >
                  {imgError}
                </div>
              </>
            ) : null}
          </div>
          <div className="col-lg-10">
            <div className="user__info">
              <div>
                <h2 className="strtgy__ttl mt-2">
                  {' '}
                  {profileDetailsOfUser?.first_name !== undefined
                    ? profileDetailsOfUser?.name
                      ? profileDetailsOfUser?.name
                      : `${profileDetailsOfUser?.first_name} ${profileDetailsOfUser?.last_name}`
                    : '-'}
                </h2>
                <span>
                  {profileDetailsOfUser?.email
                    ? profileDetailsOfUser?.email
                    : '-'}
                </span>
              </div>
            </div>
            <div className="dash_separate"></div>
            <div className="additional__info row">
              <div className="col-lg-4 sml__grd ps-0 pe-0">
                <div className="grd__ico bg__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm-2 14-4-4 1.41-1.41L10 14.17l6.59-6.59L18 9l-8 8z"></path>
                  </svg>
                </div>
                <div className="grd__info">
                  <h4>Register At:</h4>
                  <span>
                    {moment(profileDetailsOfUser?.created_at).format(
                      'DD MMM, YYYY',
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 sml__grd ps-0 pe-0">
                <div className="grd__ico bg__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm4 12h-4v3l-5-5 5-5v3h4v4z"></path>
                  </svg>
                </div>
                <div className="grd__info">
                  <h4>Last Login:</h4>
                  <span>
                    {moment(profileDetailsOfUser?.last_login).format(
                      'DD MMM, YYYY',
                    )}
                  </span>
                </div>
              </div>

              <div className="col-lg-4 sml__grd ps-0 pe-0">
                <div className="grd__ico bg__icon">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    stroke-width="0"
                    viewBox="0 0 24 24"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"></path>
                  </svg>
                </div>
                <div className="grd__info">
                  <h4>User Id:</h4>
                  <span>{userid?.id}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserDetailsHead;
