import { Checkbox, Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { useState } from 'react';
import {
  btnPrimaryClass,
  formClass,
} from '../../components/utils/defaultClasses';
import { Logo, SpinnerBtn } from '../../assets/Icon';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import useLogin from './hooks/useLogin';
import { debounce } from 'lodash';
import { checkemailpassword } from '../../components/helpers/api/Auth/auth';
import { EMAIL_REG } from '../../utils/utils';

function LoginV2() {
  const {
    form,
    userDetails,
    isLoading,
    loginHandle,
    setShowPassword,
    showPassword,
    setEmailErrors,
  } = useLogin();

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleEmail = debounce(async (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    if (emailValue) {
      try {
        const response = await checkemailpassword(emailValue);
        if (response.data.status !== true) {
          setEmailError(response.data.message);
        } else {
          setEmailError('');
        }
      } catch (error) {
        // setEmailError('Password is not set please set password first.');
      }
    } else {
      setEmailError('');
    }
  }, 300);

  console.log(userDetails, 'details');

  return (
    <>
      <div className="bg-color-base-2/30 dark:bg-color-base-4">
        <section className="container-xxl">
          <div className="min-h-screen flex justify-center items-center relative z-10">
            <div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
              <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden">
                <Card.Body className="p-[2rem]">
                  <div className="flex justify-center mb-4 mt-2">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                  <div className="pt-2 mb-4 flex flex-col">
                    <h2 className="mb-1 text-lg text-color-primary">
                      Hello! Welcome Back 👋
                    </h2>
                    <p class="text-[13px] text-color-secondary/90">
                      Please sign-in to your account and start the adventure.
                    </p>
                  </div>
                  <div>
                    <Form
                      className=""
                      layout="vertical"
                      form={form}
                      onFinish={loginHandle}
                      disabled={isLoading}
                      initialValues={{
                        email: userDetails?.email || undefined,
                        password: userDetails?.password || undefined,
                      }}
                    >
                      <Form.Item
                        // label="Email *"
                        label={
                          <>
                            <span className="flex items-center gap-0.5">
                              Email
                              <span className="text-red-2">*</span>
                            </span>
                          </>
                        }
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Email is Required.',
                          },
                          ({ getFieldValue }) => ({
                            validator: async (_, value) => {
                              if (!value || !EMAIL_REG.test(value))
                                return Promise.resolve(); // Initial condition

                              // const res = await check_email_while_login(value);

                              const res = await checkemailpassword(value);
                              if (!res?.data?.status) {
                                return Promise.reject([
                                  setEmailErrors(res?.data?.message),
                                ]);
                              } // Email registered as both
                            },
                          }),
                        ]}
                      >
                        <Input
                          className={`${formClass}`}
                          placeholder="Email"
                          name="email"
                          type="email"
                        />
                        {/* {EmailError ? (
                          <>
                            <div className="ant-form-item-explain ant-form-item-explain-connected">
                              <div
                                className="ant-form-item-explain-error"
                                style={{ marginTop: "0px" }}
                              >
                                {EmailError}
                              </div>
                            </div>
                          </>
                        ) : null} */}
                      </Form.Item>

                      <div className="relative">
                        <Form.Item
                          // label="Password *"
                          label={
                            <>
                              <span className="flex items-center gap-0.5">
                                Password
                                <span className="text-red-2">*</span>
                              </span>
                            </>
                          }
                          name="password"
                          className="relative mb-1"
                          rules={[
                            {
                              required: true,
                              message: 'Password is Required.',
                            },
                          ]}
                        >
                          {/* <span className="d-flex position-relative"> */}

                          <Input.Password
                            className={`${formClass} pr-7`}
                            placeholder="Password"
                            name="password"
                            // value={password}
                            // onChange={handlePassword}
                            // defaultValue={value ? value.password : ''}
                            iconRender={(visible) =>
                              visible ? (
                                <span>
                                  <FaRegEyeSlash className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                                </span>
                              ) : (
                                <span>
                                  <FaRegEye className="cursor-pointer dark:text-whiteNew-0 text-zinc-900" />
                                </span>
                              )
                            }
                          />

                          {/* <i
                            className={`fa text-color-primary ${
                              showpassword ? 'fa-eye-slash' : 'fa-eye'
                            } eyeIcon`}
                            onClick={(e) => {
                              setShowPassword(!showpassword);
                            }}
                          /> */}
                          {/* </span> */}
                        </Form.Item>
                        <div className="d-flex align-items-center justify-content-between">
                          <Form.Item
                            name="remember"
                            valuePropName="checked"
                            className="mb-0"
                          >
                            <Checkbox className="rememberMe">
                              Remember me
                            </Checkbox>
                          </Form.Item>
                          <Link
                            className="forgotPassword"
                            to="/user-forgot-password"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </div>

                      <div className="text-center mt-3.5">
                        <Form.Item>
                          <button
                            type="primary"
                            htmlType="submit"
                            className={`${btnPrimaryClass} w-full`}
                            disabled={isLoading}
                            // onClick={loginHandle}
                            // loading={loading}
                          >
                            {isLoading ? (
                              <SpinnerBtn className={'text-lg'} />
                            ) : (
                              'LOG IN'
                            )}
                          </button>
                        </Form.Item>
                        <div className="text-color-primary">
                          Don't have an account?{' '}
                          <Link
                            to={'/signup'}
                            className={`text-blue-2 hover:text-blue-2`}
                          >
                            Signup
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {/* <div className="absolute bottom-3 right-3">
            <a href="#" className="text-color-primary hover:text-blue-3">
              Need Help?
            </a>
          </div> */}
          </div>
        </section>
      </div>
    </>
  );
}

export default LoginV2;
