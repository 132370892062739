import React, { useState } from 'react';
import Drawer from '../Drawer';
import { btnSecondayClass } from '../../utils/defaultClasses';
import LogsActivity from './LogsActivity';
import { BsClockHistory } from 'react-icons/bs';
import Tooltip from '../Tooltip';

export default function LogActivityDrawer({ onClose, open, logsData }) {
  return (
    <Drawer title="Logs & Activity" onClose={onClose} open={open} footer={null}>
      <div>
        <LogsActivity logsData={logsData} />
      </div>
    </Drawer>
  );
}
export function LogActivityDrawerWithButton({ button = false }) {
  const [openLogsActivity, setOpenLogsActivity] = useState(false);
  const showLogsActivityDrawer = () => {
    setOpenLogsActivity(true);
  };
  const onClose = () => {
    setOpenLogsActivity(false);
  };
  return (
    <>
      <Tooltip title={'Logs & Activities'} placement={'bottom'}>
        {button ? (
          <>
            <div className="flex items-center justify-center">
              <button
                className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                onClick={() => {
                  showLogsActivityDrawer();
                }}
              >
                <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg">
                  <BsClockHistory />
                </span>
              </button>
            </div>
          </>
        ) : (
          <>
            <button
              className={`text-blue-3 hover:underline relative flex items-center gap-1.5 font-normal`}
              onClick={() => {
                showLogsActivityDrawer();
              }}
            >
              <BsClockHistory /> Logs & Activities
            </button>
          </>
        )}
      </Tooltip>
      <Drawer
        title="Logs & Activity"
        onClose={onClose}
        open={openLogsActivity}
        footer={null}
      >
        <div>
          <LogsActivity />
        </div>
      </Drawer>
    </>
  );
}
