import React, { useMemo } from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import NoDataFoundV2 from '../components/Common/NoDataFoundV2';
import { btnPrimaryClass } from '../components/utils/defaultClasses';
import { MdOutlineArrowUpward } from 'react-icons/md';
import { showPlansModal } from '../features/profile/profilePlanSlice';
import { useDispatch } from 'react-redux';
import ActionCardUi from '../Pages/UserComponents/subscription/ActionCardUi';
import strategy_img from '../assets/images/strategy.svg';
import useOpenOrderHook from '../components/hooks/usePortfolioOpenOrder';

const PortfolioOpenCards = ({
  lastOrder = [],
  handleCheckboxChange,
  selectedItems,
  tab,
  availableStrategies = [],
  previousTrades,
  strategyTrades,
  userDetails,
  // displayContent,
}) => {
  let history = useNavigate();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);
  const dispatch = useDispatch();

  // const { lastOrder } = useOpenOrderHook();
  // console.log(lastOrder);

  const openOrdersTitle = useMemo(() => {
    if (
      tab === 'live' &&
      previousTrades?.orders_data?.length === 0 &&
      (userDetails?.active_plan?.subscriptionPlanData?.plan_type !== 'month' ||
        userDetails?.active_plan?.subscriptionPlanData?.plan_type !== 'annual')
    ) {
      return (
        <ActionCardUi
          title={'You don’t have Live Running'}
          subTitle={'Please upgrade your plan to get access to Live Trades.'}
          actionButton={true}
          buttonName={' Upgrade Now'}
          handleAction={() => dispatch(showPlansModal(true))}
        />
      );
    } else if (availableStrategies.length === 0) {
      return (
        <NoDataFoundV2
          icon={
            <>
              <div className="-my-7">
                <img
                  src={strategy_img}
                  alt="upgrade plan"
                  className="w-full h-full object-contain"
                />
              </div>
            </>
          }
          title={
            <div className="max-w-[450px]">
              <h1 className="text-xl text-center font-semibold">
                Oops! you haven't subscribed with any strategy yet.
              </h1>
              <div className="mt-0">
                <div className="flex flex-col items-center justify-center space-y-4">
                  <p className="">
                    To subscribe a strategy please click on the bellow button to
                    get started with.
                  </p>

                  <button
                    onClick={() => history('/marketplace')}
                    className={`${btnPrimaryClass}`}
                  >
                    Subscribe strategy
                  </button>
                </div>
              </div>
            </div>
          }
          className="text-4xl"
          minHeight={' py-2'}
        />
      );
    } else if (strategyTrades.length === 0) {
      return (
        <NoDataFoundV2
          title={
            <div>
              <h1 className="text-2xl text-center mb-0 ">
                {`No ${tab === 'live' ? 'Live' : 'Virtual'} trade is running.`}
              </h1>
              <div className="mt-0">
                <div className="flex flex-col items-center justify-center space-y-3">
                  <p className="">
                    Click below button to run{' '}
                    {tab === 'live' ? 'Live' : 'Virtual'}
                    Trade
                  </p>

                  <button
                    onClick={() => history('/strategies')}
                    className={`${btnPrimaryClass}`}
                  >
                    {`Setup ${tab === 'live' ? 'Live' : 'Virtual'} Trade`}
                  </button>
                </div>
              </div>
            </div>
          }
          className="text-4xl"
          minHeight={' py-2'}
        />
      );
    } else {
      return (
        <NoDataFoundV2
          title={
            <p>
              "We haven't received any order yet, once the order is placed it
              will be listed here."
            </p>
          }
          className="text-4xl"
          minHeight={' py-2'}
        />
      );
    }
  }, [
    availableStrategies,
    dispatch,
    history,
    previousTrades,
    strategyTrades,
    tab,
    userDetails,
  ]);

  return (
    <>
      <div className="rounded-md overflow-hidden">
        <div className="bg-color-base-2 dark:bg-color-base-1">
          <div className="trd__data__head">
            <div className="coll">Select</div>
            <div className="coll">Strategy</div>
            <div className="coll">Report Name</div>
            <div className="coll">Symbol</div>
            <div className="coll">Order Time</div>
            <div className="coll">Lot Size</div>
            <div className="coll">QTY.</div>
            <div className="coll">Avg.</div>
            <div className="coll">LTP</div>
            <div className="coll">P&L</div>
            <div className="coll">Charge</div>
          </div>
        </div>
        {lastOrder?.length > 0 ? (
          <div className="card-body">
            {lastOrder
              .filter((item) => item?.livePrice > 0)
              .map((item) => (
                <div key={item.request_id} className="card crd__row">
                  <div className="trd__data__body">
                    <div className="coll">
                      <input
                        type="checkbox"
                        onChange={() => handleCheckboxChange(item.request_id)}
                        checked={selectedItems?.includes(item.request_id)}
                      />
                    </div>
                    <div
                      className="coll"
                      onClick={() => history(`/strategy/${item?.strategy_id}`)}
                    >
                      <span className="text-primary">{item?.strategys}</span>
                    </div>

                    <div
                      className="coll"
                      onClick={() =>
                        history(
                          `/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
                            item?.request_id
                          }`,
                        )
                      }
                    >
                      <span className="text-primary">{item?.vt_requests}</span>
                    </div>
                    <div className="coll text-truncate">
                      {item?.tradingsymbol}
                    </div>
                    <div className="coll text-truncate">
                      {dayjs
                        .utc(item?.entryTime)
                        .format('DD-MMM-YYYY hh:mm:ss a')}
                    </div>
                    <div className="coll">{item?.lot_size}</div>
                    <div className="coll">{item?.quantity_left}</div>
                    <div className="coll">{item?.entryPrice}</div>
                    <div className="coll">{item?.livePrice}</div>
                    <div className="coll arrow">
                      <span>
                        {item?.pnl > 0 ? (
                          <BsArrowUp className="text-success" />
                        ) : (
                          <BsArrowDown className="text-danger" />
                        )}
                      </span>
                      <span
                        className={
                          item?.pnl > 0 ? 'text-success' : 'text-danger'
                        }
                      >
                        {item?.pnl}
                      </span>
                    </div>
                    <div className="coll">{item.charges}</div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="close__order__data w-full">
            <div className="w-full flex justify-center text-center">
              {openOrdersTitle}
              {/* <NoDataFoundV2
                title={openOrdersTitle}
                className="text-4xl"
                minHeight={' py-2'}
                icon={}
              /> */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(PortfolioOpenCards);
