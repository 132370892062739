import axios from 'axios';
import config from '../../Config/config';
import { apiHeader } from '../../utils/utils';

export async function getAdminStrategyList({ queryKey }) {
  // const { json_header } = apiHeader();

  const [_key, { page, categorySearch }] = queryKey;
  // let strategyType = category.charAt(0).toLowerCase() + category.slice(1);
  const res = await axios.get(
    `${config.base_url}${config.get_admin_List}?page=${page}&limit=10&type=${categorySearch}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res?.data;
}

export async function removeStrategySubscription(payload) {
  const { json_header } = apiHeader();
  const res = await axios.post(
    `${config.base_url}${config.remove_subscription}`,
    payload,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}

export async function addStrategySubscription(payload) {
  const { json_header } = apiHeader();

  const res = await axios.post(
    `${config.base_url}${config.add_subscription}`,
    payload,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}

export async function addCustomStrategy(payload) {
  const submission = axios.post(
    `${config.base_url}/admin/create-strategy-request`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return submission;
}
