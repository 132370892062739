import React, { useState, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import axios from 'axios';
import config from '../../../Config/config';
import Drawer from '../../Common/Drawer';
import TableComponent from '../../Common/TableComponent';
import { MdPause, MdPlayArrow } from 'react-icons/md';
import PremiumIcon from '../../Common/PremiumIcon';
import useToGetId from '../../hooks/useToGetId';
import { toast } from 'react-toastify';
import SubscribeBellIcon from '../../Common/SubscribeBellIcon';
import ActionCardUi from '../../../Pages/UserComponents/subscription/ActionCardUi';
import { LogActivityDrawerWithButton } from '../../Common/logs/LogActivityDrawer';
import useVirtualStrategyDetails from '../../hooks/useVirtualStrategyDetails';
import { useSelector } from 'react-redux';
import useDesktopStrategyTable from '../../hooks/useDesktopStrategyTable';
import useReportViewDetails from '../../hooks/useReportViewDetails';
import { Switch } from 'antd';
import { formatINR } from '../../../utils/utils';
import PremiumBadge from '../../../utils/PremiumBadge';
import { FaRegEye } from 'react-icons/fa';
import Tooltip from '../../Common/Tooltip';
import useLiveStrategyDetails from '../../hooks/useLiveStrategyDetails';
const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);

function SwitchedStrategies({ isMobile, availableStrategies, tab }) {
  let history = useNavigate();
  const [toggleState, setToggleState] = useState('Active');
  let getuserBots = localStorage.getItem('token');
  const { userid } = useToGetId();

  const [showTradeDrawer, setShowTradeDrawer] = useState(false);
  const [listOfRequests, setListOfRequests] = useState([]);
  const [strategyId, setStrategyId] = useState('');
  const [toggle, setToggle] = useState('');
  const location = useLocation();
  let tradeToggle = location.pathname.split('/portfolio/')[1];
  const { toggleState: TradeToggleState } = useReportViewDetails(strategyId)
  const { virtualRefech } = useVirtualStrategyDetails(strategyId, tradeToggle) //iske jagha live wala use karn padega
  const { liveFetching } = useLiveStrategyDetails(strategyId, tradeToggle) //iske jagha live wala use karn padega

  const { virtualReportsDataSeperated, liveReportsDataSeperated } = useSelector(
    (state) => state.completeStrategyDetails,
  );



  let finalData = useMemo(() => toggleState === "Active" && tradeToggle === "virtual" ? virtualReportsDataSeperated.active :
    toggleState === "Inactive" && tradeToggle === "virtual" ?
      virtualReportsDataSeperated.inActive :
      toggleState === "Active" && tradeToggle === "live" ?
        liveReportsDataSeperated.active :
        liveReportsDataSeperated.inActive,
    [liveReportsDataSeperated.active, liveReportsDataSeperated.inActive, toggleState, tradeToggle, virtualReportsDataSeperated.active, virtualReportsDataSeperated.inActive])


  //handleUpdate same
  //datasource same
  //toggleFeature same

  const {
    tableData, toggleFeature, handleUpdate,
  } = useDesktopStrategyTable(finalData, tradeToggle); //need to pass virtual
  // } = useDesktopStrategyTable(finalData, toggleState); //previously

  const handleClose = () => {
    setShowTradeDrawer(false);
  };
  const dataSourceDrawer = useMemo(
    () =>
      tableData?.map((item) => ({
        key: item._id,
        report_name: (
          <>
            <Link
              to={
                toggleState === 'backtesting'
                  ? `/back-testing/${item._id}`
                  : `/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${item._id
                  }`
              }
              className="primary__blue text-blue-2 hover:underline"
            >
              {item?.report_name}
            </Link>
          </>
        ),
        created_at: <>{dayjs(item.created_at).format('DD MMM YY')}</>,
        max_cap: (
          <div className="flex">
            <span>{formatINR(item?.max_cap)}</span>
          </div>
        ),
        current_status: item,

        mode: item,

        orderCount: <p>{item?.orderCount ? item?.orderCount : 0}</p>,

        actions: item,
      })),
    [tableData, toggleState],
  );

  const columns = [
    {
      title: 'Report Name',
      dataIndex: 'report_name',
      key: 'name',
    },
    {
      title: 'Current Status',
      dataIndex: 'current_status',
      key: 'current_status',
      render: (item) => {
        let request_item = listOfRequests?.filter((elem) => elem?.request_id === item?._id)?.[0]

        return (<div div className={`flex`}>
          {toggleState !== "Active" ? <span className={`px-1 min-w-[70px] flex justify-center items-center rounded-md bg-red-3/15 text-red-3`}>Hold</span>
            : <span className={`px-1 min-w-[70px] flex justify-center items-center rounded-md  ${request_item?.current_status === "Hold" ? "bg-red-3/15 text-red-3"
              : request_item?.current_status === "Paused" ? "bg-yellow-600/15 text-yellow-600"
                : request_item?.current_status === "Running" ? "bg-green-3/15 text-green-3"
                  : "bg-red-3/15 text-red-3"}`}>{request_item?.current_status || item?.current_status}</span>}

        </div >)
      }
    },
    {
      title: 'Mode',
      dataIndex: 'mode',
      key: 'mode',
      render: (item) => {
        let request_item = listOfRequests?.filter((elem) => elem?.request_id === item?._id)?.[0]
        return (
          <div className="flex items-center gap-1.5">
            {toggleFeature(item) ? (
              <PremiumBadge />
            ) : (
              <Switch
                disabled={toggleState === "Active" ? request_item?.current_status !== 'Hold' : false}
                value={item?.status}
                onChange={async (checked) => {
                  await handleUpdate(checked, item?._id, item?.strategy_id)
                }
                }
              />
            )}
          </div>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'actions',
      key: 'actions',
      render: (item) => {
        let request_item = listOfRequests?.filter((elem) => elem?.request_id === item?._id)?.[0]
        return (
          <>
            <div className="flex ite gap-2">
              <Link
                to={
                  toggleState === 'backtesting'
                    ? `/ back - testing / ${item._id}`
                    : `/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'
                    }/${item._id}`
                }
                className="border-[1px] border-solid border-blue-3 text-blue-3 hover:bg-blue-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
              >
                <FaRegEye />
              </Link>
              {
                toggleState === "Active" &&
                <button className={`border-[1px] border-solid text-green-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center
                 ${request_item?.current_status === 'Hold' ? "cursor-not-allowed" : "cursor-pointer"} ${request_item?.current_status === 'Paused' ? "hover:bg-yellow-500 text-yellow-500 border-yellow-500" : request_item?.current_status === 'Hold' ? "hover:bg-red-3 text-red-3  border-red-3" : "hover:bg-green-3 text-green-3 border-green-3"}`}>
                  {request_item?.current_status === 'Paused' ? (
                    <div onClick={() => getAction(request_item?.request_id, true)}>
                      <MdPlayArrow />
                    </div>
                  ) : request_item?.current_status === 'Hold' ? (
                    <div className='cursor-not-allowed'>
                      <MdPlayArrow className='cursor-not-allowed' />
                    </div>
                  ) : (
                    <div onClick={() => getAction(request_item?.request_id, false)}>
                      <MdPause className="" />
                    </div>
                  )}
                </button>
              }
            </div >
          </>
        )
      }
    },
  ];

  const playPauseResponse = async (id, tab) => {
    const response = await axios.get(
      `${config.Kill_trade_base_url}${config.play}?strategy_id=${id}&user_id=${userid.id}&request_type=${tab}`,
      {
        headers: { Authorization: `Bearer ${getuserBots}` },
      },
    );
    return response?.data?.data; // Return the data property of the response
  };

  const activeToggleList = async (id, tab) => {
    setToggle(tab);
    setStrategyId(id);

    try {
      const data = await playPauseResponse(id, tab);
      setListOfRequests(data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAction = async (id, status) => {
    try {
      const data = await playPauseResponse(strategyId, toggle);
      let checkMarketHours = data?.find((item) => item.request_id === id);
      if (checkMarketHours?.market_hours) {
        const payload = {
          request_id: id,
          play: status,
        };
        axios
          .post(`${config.Kill_trade_base_url}${config.play}`, payload, {
            headers: { Authorization: `Bearer ${getuserBots}` },
          })
          .then((res) => {
            activeToggleList(strategyId, toggle);
            if (TradeToggleState === "virtual") {
              virtualRefech()
            } else {
              liveFetching()
            }
            if (status) {
              return toast.success('Continue the Trade Successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
            } else {
              return toast.success('Pause the Trade Successfully', {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 1000,
              });
            }
          })
          .catch(function (err) {
            console.error(err);
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {isMobile === false ? (
        <div className="row mob_spc">
          <div className="col-12 px-0 mt-3 d-flex justify-content-between sep__ln align-items-center">
            <h2 className="mb-0 strtgy__ttl">Strategy</h2>
            <span
              className="strgy_ttl_box me-3 text-primary cursor-pointer"
              onClick={() => history('/strategies')}
            >
              <p>View All</p>
            </span>
          </div>
          <div>
            <div className="row mx-0 px-0 mt-0 pb-2 mb-2 cstm__scroll pt-2 scrollCss">
              {availableStrategies?.map((item) => (
                <div
                  key={item?.st_id}
                  className="strtgy__card d-flex col flex-column ms-lg-2 me-lg-2 relative group"
                >
                  <div className="absolute -top-2 -left-2 z-[2]">
                    <SubscribeBellIcon item={item} />
                  </div>
                  <div className="d-flex row flex-row px-2 position-relative">
                    <div
                      className={`${item?.subscribed
                        ? 'bg-blue-2/15 text-blue-3 border-blue-3'
                        : 'bg-red-2/15 text-red-3 border-red-3'
                        }  px-[15px] py-[10px] flex justify-center items-center border-[1px] border-dashed rounded-[4px] font-semibold col-2`}
                    //strtgy__thumb
                    >
                      {item?.st_name[0].toUpperCase()}
                    </div>
                    <div className="strtgy__card__details col-10 pe-0">
                      <div className="d-flex">
                        <div className="d-flex flex-row col pe-2 ps-2 justify-content-between align-items-center">
                          <div className="flex items-center mt-1 mb-1">
                            <Link to={`/strategy/${item?.st_id}`} className="">
                              {item?.st_name}
                            </Link>
                            {item?.access_type === 'premium' && <PremiumIcon />}
                          </div>
                          <span class="d-flex flex-row gap-1 align-items-center">
                            <svg
                              stroke="currentColor"
                              fill="none"
                              stroke-width="2"
                              viewBox="0 0 24 24"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                width="18"
                                height="18"
                                x="3"
                                y="4"
                                rx="2"
                                ry="2"
                              ></rect>
                              <line x1="16" x2="16" y1="2" y2="6"></line>
                              <line x1="8" x2="8" y1="2" y2="6"></line>
                              <line x1="3" x2="21" y1="10" y2="10"></line>
                            </svg>
                            {dayjs.utc(item?.created_at).format('DD MMM YYYY')}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex sep__top strtgy__card__sub__details">
                        <div className="d-flex">
                          {tab === 'live' ? (
                            <div className="col flex">
                              <div
                                className=" px-0 d-flex gap-1 align-items-center cursor-pointer"
                                onClick={() => {
                                  activeToggleList(item?.st_id, 'live');
                                  setTimeout(() => {
                                    setShowTradeDrawer(true);
                                  }, 500);
                                }}
                              >
                                <svg
                                  className="sub__ttl"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-width="2"
                                  viewBox="0 0 24 24"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="19" cy="6" r="3"></circle>
                                  <path d="M22 12v3a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h9"></path>
                                  <path d="M12 17v4"></path>
                                  <path d="M8 21h8"></path>
                                </svg>
                                <span>Live Trade</span>
                                <span className="lv__trd__badge" type="button">
                                  {item?.ltList?.length || 0}
                                </span>
                              </div>
                            </div>
                          ) : (
                            <div className="col flex">
                              <div
                                className=" px-0 d-flex gap-1 align-items-center cursor-pointer"
                                onClick={() => {
                                  activeToggleList(item?.st_id, 'virtual');
                                  setTimeout(() => {
                                    setShowTradeDrawer(true);
                                  }, 500);
                                }}
                              >
                                <svg
                                  className="sub__ttl"
                                  stroke="currentColor"
                                  fill="none"
                                  stroke-width="2"
                                  viewBox="0 0 24 24"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="m9 10 2 2 4-4"></path>
                                  <rect
                                    width="20"
                                    height="14"
                                    x="2"
                                    y="3"
                                    rx="2"
                                  ></rect>
                                  <path d="M12 17v4"></path>
                                  <path d="M8 21h8"></path>
                                </svg>
                                <span>Virtual Trade</span>
                                <span
                                  className="vrtl__trd__badge"
                                  type="button"
                                >
                                  {item?.vtList?.length || 0}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="col px-0 d-flex align-items-center justify-content-end d-flex gap-1 align-items-center ">
                            <div>
                              <Tooltip title={<>Click to View</>}>
                                <Link
                                  className="flex bg-blue-2/10 group-hover:bg-blue-2 text-blue-3 text-sm group-hover:text-whiteNew-0 duration-300 ease-in-out w-[20px] h-[20px] rounded-full relative p-0.5"
                                  to={`/strategy/${item?.st_id}`}
                                >
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="1em"
                                    width="1em"
                                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                                  </svg>
                                </Link>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card_hidden_btn">
                      <Link className="" to={`/strategy/${item?.st_id}`}>
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          stroke-width="0"
                          viewBox="0 0 448 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z"></path>
                        </svg>
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="row mt-3 mob_rem_margin">
          <div className="strgy_ttl_box">
            <h2 className="strgy_ttl">Strategy</h2>
            <a onClick={() => history('/strategies')}>View All</a>
          </div>
          <div className="cstm__mob__scroll">
            {availableStrategies?.map((item) => (
              <div
                key={item?.st_id}
                className="strtgy__card d-flex col-12 flex-column mb-2"
              >
                <div className="d-flex row flex-row px-2">
                  <div className="strtgy__thumb col-2">
                    {item?.st_name[0].toUpperCase()}
                  </div>
                  <div className="strtgy__card__details col-10 pe-0">
                    <div className="d-flex">
                      <div className="d-flex flex-row col pe-0 ps-2 justify-content-between">
                        <h3>{item?.st_name}</h3>
                        <span>
                          {dayjs.utc(item?.created_at).format('DD MMM YYYY')}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-end float-end">
                        <Link
                          className="strtgy__card__more"
                          to={`/strategy/${item?.st_id}`}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                            />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex sep__top strtgy__card__sub__details">
                  <div className="d-flex">
                    <div className="col px-0">
                      <span>Virtual Trade</span>
                      <span className="vrtl__trd__badge">
                        {item?.vtList?.length}
                      </span>
                    </div>
                    <div className="col px-0 d-flex align-items-center justify-content-end">
                      <span>Live Trade</span>
                      <span className="lv__trd__badge">
                        {item?.ltList?.length}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Drawer
        title={
          <>
            <div className="flex justify-between items-center pr-6">
              <span>{toggle === 'virtual' ? 'Virtual' : 'Live'} Trades</span>
              <div>
                <LogActivityDrawerWithButton />
              </div>
            </div>
          </>
        }
        onClose={handleClose}
        open={showTradeDrawer}
        className={`gotix-drawer `}
        rootClassName={'drawer-root-className'}
        width={'768px'}
        footer={null}
      >
        <div className="-mx-2">
          <div className="strtgy__table__box border-0 -mt-6 mb-2">
            <ul className="d-flex strtgy__tbl gap-1.5 items-center">
              {/* {reportStatus && ( */}
              <li
                onClick={() => {
                  activeToggleList(strategyId, toggle);
                  setToggleState('Active')
                }}
                className={`${toggleState === 'Active' ? 'active' : 'tabs'
                  } py-2.5 border-b-[3px] border-b-transparent`}
              >
                Active
              </li>

              <li
                onClick={() => setToggleState('Inactive')}
                className={`${toggleState === 'Inactive' ? 'active' : 'tabs'
                  } py-2.5 border-b-[3px] border-b-transparent`}
              >
                Inactive
              </li>
            </ul>
          </div>
          <TableComponent
            dataSource={dataSourceDrawer}
            columns={columns}
            loading={false}
            dynamicNoDataFound={
              <>
                <ActionCardUi
                  title={`No ${toggle === 'virtual' ? 'Virtual' : 'Live'
                    } Trades is Active`}
                  // subTitle={'You don’t have back testing running'}
                  actionButton={false}
                />
              </>
            }
          />
        </div>
      </Drawer>
    </>
  );
}

export default React.memo(SwitchedStrategies);
