import React, { useState } from 'react';
import NoDataFoundV2 from '../../../components/Common/NoDataFoundV2';
import { FaAddressCard } from 'react-icons/fa';
import { BsPlus } from 'react-icons/bs';
import { truncateHTML } from '../../../components/Truncate';

function NoAddressAvailable({ setIsAddAddress }) {
  // const [isAddAddress, setIsAddAddress] = useState(false);
  // const [addressForm, setAddressForm] = useState({
  //   first_name: '',
  //   last_name: '',
  //   country: '',
  //   address_1: '',
  //   address_2: '',
  //   state: '',
  //   post_code: '',
  //   is_primary: false,
  // });

  return (
    <>
      <div className="flex justify-center">
        <NoDataFoundV2
          icon={
            <>
              <FaAddressCard className="text-4xl text-color-secondary" />
            </>
          }
          title={
            <>You currently don't have any billing address in your list.</>
          }
          subTitle={<>Click 'Add Billing Address' to add a new address.</>}
          minHeight={'min-h-[200px]'}
          onSubmit={() => {
            setIsAddAddress(true);
            //   setIsAddCard(true);
          }}
          onCancel={() => {}}
          buttonProps={{
            primaryBtnText: 'Add Billing Address',
            // secondaryBtnText: 'Select system venue',
            primaryBtnIcon: (
              <>
                <span>
                  <BsPlus className="text-lg" />
                </span>
              </>
            ),
          }}
        />
      </div>
    </>
  );
}

export default NoAddressAvailable;
