import axios from 'axios';
import config from '../../Config/config';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { apiHeader } from '../../utils/utils';

export async function getUserpaymentCards() {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${config.base_url}${config.payment_cards}?page=1&limit=10`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  // console.log(res);

  return res?.data?.data;
}

export async function addpaymentsCards(payload) {
  const { json_header } = apiHeader();
  const res = await axios.post(
    `${config.base_url}${config.add_cards}`,
    payload,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  // console.log(res);

  return res;
}

export async function deleteUserpaymentCard(id) {
  const { json_header } = apiHeader();
  const res = await axios.delete(
    `${config.base_url}${config.delete_cards}/${id}`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}

export async function updateUserpaymentCard(payload) {
  const { json_header } = apiHeader();
  const res = await axios.put(
    `${config.base_url}${config.update_card}`,
    payload,
    {
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res;
}
