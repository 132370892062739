import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { strategyLiveDetails } from '../../API/strategy/strategyDetail';
import useToGetId from './useToGetId';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { liveReportsdataStrategy, setLiveReportsDataSeperated } from '../../features/strategyReport/strategyDataDetailSlice';

function useLiveStrategyDetails(strategyId = null, tab = "live") {

  let { id } = useParams();
  var dispatch = useDispatch();
  const [livePagination, setLivePagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [livePage, setLivePage] = useState(1);

  const { userid } = useToGetId();

  const {
    data,
    refetch: liveRefech,
    isFetching: liveFetching,
  } = useQuery({
    queryKey: [
      'liveList',
      {
        livePage,
        payload: {
          user_id: userid.id,
          strategy_id: id || strategyId,
        },
      },
    ],
    queryFn: strategyLiveDetails,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    staleTime: 0,
  });
  // console.log(data);

  useEffect(() => {
    if (data && tab === "live") {
      const updatedData = data?.data?.map((item) => ({
        ...item,
        report_name: item?.lt_inputs?.report_name,
        max_cap: item?.lt_inputs?.investment,
        key: item?._id,
      }));
      if (updatedData) {
        dispatch(liveReportsdataStrategy(updatedData));

        // setLiveData(updatedData);
        let obj = {
          active: updatedData?.filter((dat) => dat?.status),
          inActive: updatedData?.filter((dat) => !dat?.status),
        }
        dispatch(setLiveReportsDataSeperated(obj))
      }
      setLivePagination((prevPagination) => ({
        ...prevPagination,
        current: livePage,
        total: data?.dataCount,
      }));
    }
  }, [data]);

  //table pagination this fun will call

  const handleLiveTradeTable = useCallback((newPagination) => {
    setLivePage(newPagination.current);
  }, []);

  return {
    livePagination,
    // livePage,
    // liveData,
    liveRefech,
    handleLiveTradeTable,
    liveFetching,
  };
}

export default useLiveStrategyDetails;
