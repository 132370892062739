import { Form, Input } from 'antd';
import React, { useState } from 'react';
import {
  btnPrimaryClass,
  formClass,
} from '../../../components/utils/defaultClasses';
import { RadioGroup } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { userNewPlan } from '../../../features/update-plan/UpdatePlanSlice';

export default function SubcriptionUpgradePlanDetailAddons({
  userChangedPlan,
  toggleHandle,
}) {
  const dispatch = useDispatch();
  const { overAllPlansList } = useSelector((state) => state.toUpdatePlan); //all plans

  const [selected, setSelected] = useState(
    userChangedPlan?.plan_type === 'annual' ? 'annually' : 'monthly',
  );

  const handleButtonChange = (e) => {
    if (e === 'annually' && selected === 'monthly') {
      const finalPlan = overAllPlansList.find(
        (item) =>
          item.plan_name === userChangedPlan?.plan_name &&
          item.plan_type === 'annual',
      );
      if (finalPlan) {
        dispatch(userNewPlan(finalPlan));
        localStorage.setItem('new_plan', JSON.stringify(finalPlan));
      }
    } else if (e === 'monthly' && selected === 'annually') {
      const finalPlan = overAllPlansList.find(
        (item) =>
          item.plan_name === userChangedPlan?.plan_name &&
          item.plan_type === 'month',
      );
      if (finalPlan) {
        dispatch(userNewPlan(finalPlan));
        localStorage.setItem('new_plan', JSON.stringify(finalPlan));
      }
    }
    setSelected(e);
  };

  return (
    <>
      <div className="divide-y divide-level-2">
        <div className="space-y-0 px-3.5">
          <div className="row gy-3">
            <div className="col-md-5 col-lg-4">
              <div className="d-flex setting_grd">
                <p>Payment Frequency</p>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="">
                <RadioSelect
                  userChangedPlan={userChangedPlan}
                  handleButtonChange={handleButtonChange}
                  selected={selected}
                />
              </div>
            </div>
          </div>
          <div className="row gy-3">
            <div className="col-md-5 col-lg-4">
              <div className="d-flex setting_grd">
                <p>Selected Plan</p>
              </div>
            </div>
            <div className="col-6 col-md-4 col-lg-5">
              <div className="">{userChangedPlan?.plan_name}</div>
            </div>
            <div className="col-6 col-md-3 col-lg-3">
              <div className="flex justify-end">
                ₹ {userChangedPlan?.price}.00
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6">
          <div className="">
            <div className="row items-center gx-0">
              <div className="col-md-5 col-lg-4 py-2.5 bg-color-base-2 hidden md:flex">
                &nbsp;
              </div>
              <div className="col-6 col-md-4 col-lg-5 bg-color-base-2 h-full">
                <div className="flex justify-end text-sm font-semibold py-[11.5px] px-3.5">
                  {`Amount to be paid per ${
                    userChangedPlan?.plan_type === 'annual' ? 'year' : 'month'
                  } `}
                </div>
              </div>
              <div className="col-6 col-md-3 col-lg-3 bg-component-gray-2 dark:bg-color-base-4">
                <div className="flex justify-end font-semibold py-2.5 px-3.5">
                  ₹ {userChangedPlan?.price}.00
                </div>
              </div>
            </div>
          </div>
          <div className="mt-3.5 flex justify-end">
            <button
              className={`${btnPrimaryClass}`}
              onClick={() => {
                toggleHandle('2');
              }}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export function RadioSelect({ handleButtonChange, selected }) {
  const plans = [
    {
      name: 'Annually',
      value: 'annually',
    },
    {
      name: 'Monthly',
      value: 'monthly',
    },
  ];

  return (
    <>
      <div className="flex">
        <RadioGroup
          defaultValue={selected}
          value={selected}
          onChange={handleButtonChange}
        >
          <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
          <div className="flex flex-wrap flex-md-nowrap gap-3">
            {plans.map((plan, idx) => (
              <RadioGroup.Option
                key={idx}
                value={plan.value}
                className={({ active, checked }) =>
                  `${
                    active
                      ? 'bg-transparent border-blue-3 bg-opacity-75'
                      : 'bg-color-base-1 border-level-2'
                  } ${
                    checked
                      ? 'bg-transparent border-blue-3 bg-opacity-75'
                      : 'bg-color-base-1 border-level-2'
                  } relative flex cursor-pointer rounded-lg px-0 py-0 duration-150 ease-in`
                }
              >
                {({ active, checked }) => (
                  <>
                    <div className="flex w-full items-start justify-between">
                      <div className="flex items-center gap-2">
                        <div className="">
                          <span
                            className={`flex w-5 h-5 border-[1px] rounded-full p-[3px] duration-150 ease-in ${
                              checked ? 'border-blue-3' : ' border-level-2'
                            }`}
                          >
                            <span
                              className={`flex w-full h-full rounded-full duration-150 ease-in ${
                                checked ? 'bg-blue-3' : ''
                              }`}
                            ></span>
                          </span>
                        </div>
                        <div className="text-sm">
                          <RadioGroup.Label
                            as="div"
                            className={`font-medium duration-150 ease-in ${
                              checked ? 'text-blue-3' : 'bg-color-base-1'
                            }`}
                          >
                            {plan.name}
                          </RadioGroup.Label>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
}
