import React from 'react';
import mydecodedTokenFunction from '../../utils/decodetoken';

function useToGetId() {
  let token = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(token);
  return { userid };
}

export default useToGetId;
