import { Image } from 'antd';
import React, { useCallback, useState } from 'react';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../../../components/utils/defaultClasses';
import AddCardModal from './AddCardModal';
import { useMutation } from '@tanstack/react-query';
import { addpaymentsCards } from '../../../API/profile/CardsApi';
import { toast } from 'react-toastify';
import useSaveUpdateCard from '../../../components/hooks/useSaveUpdateCard';
import useSaveCard from '../../../components/hooks/useSaveCard';

export default function PaymentCard({ item, handleDelete, handleEdit }) {
  return (
    <>
      <div className="flex items-center border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden p-3.5">
        <div className="flex justify-between gap-1.5 w-full">
          <div className="flex flex-col gap-3.5">
            <div className="flex items-center gap-1.5">
              <div className="text-base font-semibold">{item?.card_name}</div>
              {/* <span className="flex bg-green-2/20 text-green-3 rounded-md px-1.5 py-0.5 text-xs">
                <small>Primary</small>
              </span> */}
            </div>
            <div className="flex gap-2.5 items-center">
              <div>
                <Image
                  src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/card-logos/visa.svg"
                  preview={false}
                />
              </div>
              <div className="flex flex-col leading-tight">
                <div className="text-base font-semibold">
                  {item?.card_type} ****{' '}
                  {item?.card_number?.slice(
                    item?.card_number.length - 4,
                    item?.card_number.length,
                  )}
                </div>
                <span className="text-xs text-color-secondary/70 font-normal">
                  Card expires at {item?.expiration_date}
                </span>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-2 shrink-0">
            <div>
              <button
                className={`${btnSecondayClass}`}
                onClick={() => handleDelete(item._id)}
              >
                <small>Delete</small>
              </button>
            </div>
            <div>
              <button
                className={`${btnSecondayClass}`}
                onClick={() => handleEdit(item)}
              >
                <small>Edit</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export function PaymentAddCard({ setIsAddCard }) {
  return (
    <>
      <div className="flex items-center border-[1px] border-dashed border-blue-2 bg-blue-2/10 rounded-xl overflow-hidden p-3.5">
        <div className="flex justify-between gap-1.5 items-center w-full">
          <div className="flex flex-col gap-2.5">
            <div className="flex flex-col leading-tight">
              <div className="text-sm font-semibold">Important Note!</div>
              <span className="text-xs text-color-secondary/70">
                Please carefully read Product Terms adding your new payment card
              </span>
            </div>
          </div>
          <div className="flex items-center gap-2 shrink-0">
            <div>
              <button
                className={`${btnPrimaryClass} text-sm`}
                onClick={() => {
                  setIsAddCard(true);
                }}
              >
                Add Card
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
