import { useQuery } from '@tanstack/react-query';
import useToGetId from './useToGetId';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getStrategyLogs } from '../../API/profile/ActivityLogsApi';
import { useEffect, useState } from 'react';

function useActivityLogs() {

    let { id } = useParams();
    var dispatch = useDispatch();
    const { userid } = useToGetId();
    const [logsData, setLogsData] = useState([])

    const {
        data,
        refetch,
        isFetching,
    } = useQuery({
        queryKey: ['strategy-activity-logs', { userid, id }],
        queryFn: getStrategyLogs,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        staleTime: 0,
    });
    console.log("activity logs data", data);
    useEffect(() => {
        if (data) {
            setLogsData(data?.data)
        }
    }, [data])


    return {
        logsData,
        refetch
    };
}

export default useActivityLogs;
