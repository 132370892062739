import React, { useState, useEffect } from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import StrategyViewCard from './UI/StrategyDetails/StrategyViewCard';
import { HiMiniAdjustmentsVertical } from 'react-icons/hi2';
import { MdOutlineAutoGraph } from 'react-icons/md';
import { RiTimerLine } from 'react-icons/ri';
import { BiDollarCircle } from 'react-icons/bi';
import dayjs from 'dayjs';
import { formatINR } from '../utils/utils';

const ReportCardsData = (
  {
    tab,
    lastOrder = [],
    configData,
    todayPnL,
    // brokerID,
    // strategyName,
    createdDate,
    overAllPnL,
    fundBalance,
    strategyName,
  },
  ref,
) => {
  const [amountInvested, setAmountInvested] = useState(0);
  let getuserBots = localStorage.getItem('token');

  // useImperativeHandle(ref, () => ({
  //   getBrokerFund,
  // }));

  //To get fund balance

  useEffect(() => {
    if (lastOrder.length === 0) {
      setAmountInvested(0);
    }
    if (
      lastOrder.length !== 0 &&
      lastOrder[0]?.entryPrice !== null &&
      lastOrder[0]?.entryPrice !== undefined &&
      lastOrder[0]?.entryPrice !== ''
    ) {
      setAmountInvested(
        lastOrder.map(
          (item) => item.entryPrice * item.entryQuantity * item.lot_size,
        ),
      );
    }
  }, [lastOrder]); //for Amount Invested

  return (
    <>
      <div className="row">
        <div className="col-12 px-0 py-2.5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3">
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Created at'}
                subtitle={
                  <>{dayjs.utc(createdDate).format('DD MMM, YYYY h:mm a')}</>
                }
                tooltipData={`Creation date of this trade report`}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Strategy Name'}
                subtitle={<>{strategyName}</>}
                tooltipData={`Name of the Strategy`}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <HiMiniAdjustmentsVertical className="text-4xl text-blue-2" />
                  </>
                }
                title={'Overall P&L'}
                subtitle={
                  <>
                    {overAllPnL < 0 ? (
                      <span className="text-danger">
                        {/* {Number(overAllPnL).toFixed(2)} */}
                        {formatINR(Number(overAllPnL).toFixed(2))}
                      </span>
                    ) : (
                      <span className="text-success">
                        {formatINR(Number(overAllPnL).toFixed(2))}
                      </span>
                    )}
                  </>
                }
                tooltipData={`Total net P&L from account creation until today`}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Avl. Margin'}
                tooltipData={`Remaining available margin after running positions`}
                subtitle={
                  <>
                    {tab === 'LT'
                      ? fundBalance
                      : lastOrder.length > 0
                      ? Number(configData?.investment - amountInvested).toFixed(
                          2,
                        )
                      : 'N.A'}
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <MdOutlineAutoGraph className="text-4xl text-blue-2" />
                  </>
                }
                title={'Amount Invested'}
                tooltipData={`Total invested amount in running trades`}
                subtitle={
                  <>
                    {/* {Number(amountInvested).toFixed(2)} */}
                    {formatINR(Number(amountInvested).toFixed(2))}
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <BiDollarCircle className="text-4xl text-blue-2" />
                  </>
                }
                title={'Current P&L'}
                tooltipData={`The unrealized gain or loss on your open positions.`}
                subtitle={
                  <>
                    {lastOrder.length > 0 ? (
                      lastOrder[0]?.pnl >= 0 ? (
                        <span className="text-success ml-2">
                          <BsArrowUp />
                          {/* {Number(lastOrder[0]?.pnl).toFixed(2)} */}
                          {formatINR(Number(lastOrder[0]?.pnl).toFixed(2))}
                        </span>
                      ) : (
                        <span className="text-danger ml-2">
                          <BsArrowDown />
                          {formatINR(Number(lastOrder[0]?.pnl).toFixed(2))}
                        </span>
                      )
                    ) : (
                      <span>N.A</span>
                    )}
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <BiDollarCircle className="text-4xl text-blue-2" />
                  </>
                }
                title={'Max Cap'}
                tooltipData={`Total Capital allocated for this trade report`}
                subtitle={
                  <>
                    {/* {Number(configData?.investment).toFixed(2)} */}
                    {formatINR(Number(configData?.investment).toFixed(2))}
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <BiDollarCircle className="text-4xl text-blue-2" />
                  </>
                }
                title={'Total P&L'}
                tooltipData={`Net P&L for the specified dates`}
                subtitle={
                  <>
                    {todayPnL >= 0 ? (
                      <span className="text-success">
                        {formatINR(Number(todayPnL).toFixed(2))}
                      </span>
                    ) : (
                      <span className="text-danger">
                        {formatINR(Number(todayPnL).toFixed(2))}
                      </span>
                    )}
                  </>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(ReportCardsData);
