import { Transition } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { FaCheck, FaPencilAlt } from 'react-icons/fa';
import SubcriptionUpgradePlanDetailAddons from './SubcriptionUpgradePlanDetailAddons';
import SubscriptionOrderSummary from './SubscriptionOrderSummary';
import SubscritionBillingPaymentDetail from './SubscritionBillingPaymentDetail';
import SubscriptionDetailCompoV2 from './SubscriptionDetailCompoV2';
import { useDispatch, useSelector } from 'react-redux';
import { userNewPlan } from '../../../features/update-plan/UpdatePlanSlice';

export default function SubscriptionUpgradeSteps() {
  const [isToggled, setIsToggled] = useState('1');
  const [showDetail, setShowDetail] = useState(false);

  const { userChangedPlan } = useSelector((state) => state.toUpdatePlan);

  const toggleHandle = (key) => {
    setIsToggled(key);
  };

  const steps = [
    {
      key: 1,
      content: (
        <>
          <div className="">
            <SubscriptionDetailCompoV2
              showDetail={showDetail}
              setShowDetail={setShowDetail}
              noAddons={true}
              toggleHandle={toggleHandle}
            />
          </div>
        </>
      ),
      heading: 'Choose a plan based on your business needs',
      headingSelected: (
        <span className="flex items-center gap-3.5">
          Selected Plan
          <span className="flex flex-col text-sm gap-0.5 bg-blue-3/10 text-blue-3 px-2.5 py-0.5 rounded-md">
            <span>{userChangedPlan?.plan_name}</span>
          </span>
        </span>
      ),
    },
    {
      key: 2,
      content: (
        <>
          <div className="flex justify-center">
            <div className="w-full max-w-[840px] px-3.5">
              <SubcriptionUpgradePlanDetailAddons
                userChangedPlan={userChangedPlan}
                toggleHandle={toggleHandle}
              />
            </div>
          </div>
        </>
      ),
      heading: 'Plan Details',
      headingSelected: (
        <span className="flex items-center gap-3.5">
          Plan Details
          {Number(isToggled) >= 1 && (
            <span className="flex flex-col text-xs gap-0.5">
              <span>
                {userChangedPlan?.plan_type &&
                  (userChangedPlan?.plan_type === 'annual'
                    ? 'Yearly'
                    : 'Monthly')}
              </span>
              <span>₹ {userChangedPlan?.price}</span>
            </span>
          )}
        </span>
      ),
    },
    {
      key: 3,
      content: (
        <>
          <div className="flex justify-center">
            <div className="w-full max-w-[840px] px-3.5">
              <SubscriptionOrderSummary
                userChangedPlan={userChangedPlan}
                handleReviewOrder={toggleHandle}
              />
            </div>
          </div>
        </>
      ),
      heading: 'Review Order',
      headingSelected: (
        <>
          <span className="flex items-center gap-1.5">
            Review Order
            {Number(isToggled) >= 3 && (
              <span className="text-green-3">
                <FaCheck />
              </span>
            )}
          </span>
        </>
      ),
    },
    {
      key: 4,
      content: (
        <>
          <div className="flex justify-center">
            <div className="w-full max-w-[840px]">
              <SubscritionBillingPaymentDetail
                userChangedPlan={userChangedPlan}
              />
            </div>
          </div>
        </>
      ),
      heading: 'Billing Address & Payment Details',
      headingSelected: (
        <span className="flex items-center gap-1.5">
          Billing Address & Payment Details
          {/* {Number(isToggled) > 3 && (
            <span className="text-green-3">
              <FaCheck />
            </span>
          )} */}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="space-y-4 mt-6 w-full">
        {Object.entries(steps)?.map(([key, value, index]) => {
          return (
            <div
              key={index}
              className="flex flex-col rounded-xl overflow-hidden px-0 border-[1px] shadow-md border-level-4 shadow-level-4 bg-color-base-3 dark:bg-color-base-1 w-full"
            >
              <div
                className="bg-color-base-2/60 dark:bg-color-base-3 px-3 py-3.5 rounded-t-xl flex justify-between items-center relative group min-h-[50px]"
                key={key}
              >
                <div
                  className={`flex absolute top-0 left-0 h-full border-r border-level-1`}
                >
                  <div className="w-[45px] h-full flex justify-center items-center">
                    {Number(key) + 1}
                  </div>
                </div>
                <h2 className="text-base font-medium text-color-primary flex flex-row items-center ml-12">
                  {isToggled === key ? (
                    <> {value?.heading}</>
                  ) : (
                    <>{value?.headingSelected}</>
                  )}
                </h2>
                {Number(isToggled) >= Number(key) && (
                  <button
                    className="text-xs shrink-0 overflow-hidden"
                    onClick={() => {
                      toggleHandle(key);
                    }}
                  >
                    {isToggled === key ? (
                      <></>
                    ) : (
                      <>
                        <span className="flex gap-1.5 items-center text-color-primary/30 group-hover:text-color-primary group-hover:translate-x-0 translate-x-12 duration-300 ease-in">
                          <FaPencilAlt />
                          Change
                        </span>
                      </>
                    )}
                  </button>
                )}
              </div>
              <div className="" key={key}>
                <Transition
                  show={isToggled === key}
                  enter="duration-200 ease-out"
                  enterFrom="opacity-0 -translate-y-6"
                  enterTo="opacity-100 translate-y-0"
                  leave="duration-300 ease-out"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 -translate-y-6"
                >
                  <div className="p-6">{value?.content}</div>
                </Transition>
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-6 rounded-xl border-[1px] shadow-md border-level-4 shadow-level-4 px-3 py-3.5 bg-color-base-3 dark:bg-color-base-1">
        <div className="flex gap-2.5">
          <div>
            <MdOutlineCalendarMonth className="text-5xl text-blue-4" />
          </div>
          <div className="flex flex-col gap-1.5">
            <h5 className="text-color-primary text-sm">
              About your subscription
            </h5>
            <p className="text-xs text-color-secondary/90">
              All subscriptions will be automatically renewed from your selected
              payment method on a recurring basis and we'll send you a receipt
              each time. We do not store your card details. You can upgrade,
              downgrade or cancel anytime. If the subscription is canceled,
              refunds and termination of access will follow the Terms of
              Service. Prices are in Indian Rupee and subject to change. Other
              restrictions and taxes may apply.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
