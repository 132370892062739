import React from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import Accordion from 'react-bootstrap/Accordion';
import dayjs from 'dayjs';
import Tooltip from '@mui/material/Tooltip';
const PortfolioMobileCards = ({
  lastOrder,
  handleCheckboxChange,
  selectedItems,
  tab,
}) => {
  let history = useNavigate();
  const utc = require('dayjs/plugin/utc');
  dayjs.extend(utc);

  return (
    <div>
      <div className="tbl__hd mobile">
        <div className="coll"></div>
        <div className="coll text-truncate">Symbol</div>
        <div className="coll text-center">Qty</div>
        <div className="coll">P&L</div>
        <div className="coll">Charges</div>
      </div>
      <Accordion className="cstm__accordion">
        {lastOrder
          ?.filter((item) => item?.livePrice > 0)
          .map((item) => (
            <Accordion.Item eventKey={item?.request_id}>
              <Accordion.Header>
                <div className="tbl__body">
                  <div className="coll text-truncate">
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(item.request_id)}
                      checked={selectedItems.includes(item.request_id)}
                    />
                  </div>
                  <Tooltip title={item?.tradingsymbol} placement="top">
                    <div className="coll text-truncate">
                      {item?.tradingsymbol}
                    </div>
                  </Tooltip>
                  <div className="coll text-center">{item?.quantity_left}</div>
                  <div className="coll pnl text-left">
                    <span>
                      {item?.pnl > 0 ? (
                        <BsArrowUp className="text-success" />
                      ) : (
                        <BsArrowDown className="text-danger" />
                      )}
                    </span>
                    <span
                      className={item?.pnl > 0 ? 'text-success' : 'text-danger'}
                    >
                      {item?.pnl}
                    </span>
                  </div>
                  <div className="coll">{item?.charges}</div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tbl__body__inner">
                  <div className="coll"></div>
                  <div className="coll text-truncate">Strategy</div>
                  <div className="coll text-truncate">Report Name</div>
                  <div className="coll text-center text-truncate">
                    Order Time
                  </div>
                  <div className="coll text-center">Lot</div>
                  <div className="coll text-center">Avg.</div>
                  <div className="coll text-center">LTP</div>
                </div>

                <div className="tbl__body__inner__data">
                  <div className="coll"></div>
                  <Tooltip title={item?.strategys} placement="top">
                    <div
                      className="coll text-truncate"
                      onClick={() => history(`/strategy/${item?.strategy_id}`)}
                    >
                      <span className="text-primary">{item?.strategys}</span>
                    </div>
                  </Tooltip>
                  <Tooltip title={item?.vt_requests} placement="top">
                    <div
                      className="coll text-truncate"
                      onClick={() =>
                        history(
                          `/view-report/${tab === 'live' ? 'LT' : 'VT'}/${
                            item?.request_id
                          }`,
                        )
                      }
                    >
                      <span className="text-primary">{item?.vt_requests}</span>
                    </div>
                  </Tooltip>
                  <Tooltip
                    title={dayjs
                      .utc(item?.entryTime)
                      .format('DD-MMM-YYYY hh:mm:ss a')}
                    placement="top"
                  >
                    <div className="coll text-center text-truncate">
                      {dayjs
                        .utc(item?.entryTime)
                        .format('DD-MMM-YYYY hh:mm:ss a')}
                    </div>
                  </Tooltip>
                  <div className="coll text-center">{item?.lot_size}</div>

                  <div className="coll text-center">{item?.entryPrice}</div>
                  <div className="coll text-center">{item?.livePrice}</div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
      </Accordion>
    </div>
  );
};

export default React.memo(PortfolioMobileCards);
