import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Logo, SpinnerBtn } from '../../assets/Icon';
import { Form, Input, Tooltip, Skeleton } from 'antd';
import {
  btnPrimaryClass,
  formClass,
} from '../../components/utils/defaultClasses';
import { Card } from 'react-bootstrap';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import useResetPassword from './hooks/useResetPassword';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import OtpTimer from '../../components/Common/OtpTimer';

export default function ResetPasswordV2({ type }) {
  const navigate = useNavigate();
  const {
    form,
    query,
    message,
    onSubmit,
    isLoading,
    isLoadingData,
    tokenExpired,
    isExpired,
    queryClient,
    setIsExpired,
    ResendSetPasswordLink,
    ResendResetPasswordLink,
    backToLogin,
  } = useResetPassword(type);

  useEffect(() => {
    // Cleanup function to remove data on unmount
    return () => {
      queryClient.removeQueries(['emailVerify']); // Remove the specific query data
    };
  }, []);

  return (
    <>
      <div className="bg-color-base-2/30 dark:bg-color-base-4">
        <section className="container-xxl">
          <div className="min-h-screen flex justify-center items-center relative z-10">
            <div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
              <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden relative z-10">
                <Card.Body className="p-[2rem]">
                  <div className="flex justify-center mb-4 mt-2">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                  <div className="pt-2 flex flex-col">
                    {isLoadingData ? (
                      <Skeleton active />
                    ) : (
                      <>
                        <h2 className="mb-1 text-lg text-color-primary">
                          {message?.title}
                        </h2>
                        <p class="text-[13px] text-color-secondary/90">
                          {message?.Description}
                        </p>
                      </>
                    )}
                  </div>
                  {isLoadingData ? (
                    <Skeleton.Input
                      active={true}
                      size={'default'}
                      shape={'default'}
                      block={true}
                    />
                  ) : (
                    <>
                      {message?.button === 'form' ? (
                        <>
                          {tokenExpired !== '' ? (
                            <>
                              <div className="ant-form-item-explain ant-form-item-explain-connected mb-4">
                                <div
                                  className="ant-form-item-explain-error"
                                  style={{ marginTop: '0px' }}
                                >
                                  {tokenExpired}
                                </div>
                              </div>
                            </>
                          ) : null}{' '}
                          <div>
                            <Form
                              form={form}
                              layout="vertical"
                              onFinish={onSubmit}
                              disabled={isLoading}
                            >
                              <div>
                                <Form.Item
                                  label={
                                    <span className="flex gap-2 items-center">
                                      <span className="flex justify-start text-center items-center text-zinc-800 dark:text-whiteNew-0">
                                        <span>Enter New Password</span>
                                      </span>

                                      <Tooltip
                                        title="Please make sure your password must have 8 characters."
                                        //  meeting the following criteria: [(A-Z),(a-z),(0-9),(!@#$%^&*)]"
                                        placement={'right'}
                                        overlayStyle={{
                                          maxWidth: '400px',
                                        }}
                                      >
                                        <span className="pe-auto">
                                          <AiOutlineInfoCircle className="text-zinc-800 dark:text-whiteNew-0" />
                                        </span>
                                      </Tooltip>
                                    </span>
                                  }
                                  name="new_password"
                                  className="mb-3"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please enter your password.',
                                    },
                                    {
                                      min: 8,
                                      message:
                                        'Please make sure your password must have 8 characters.',
                                    },
                                  ]}
                                >
                                  <Input.Password
                                    placeholder="Enter New Password"
                                    name="new_password"
                                    //   onChange={handelChange}

                                    className={`${formClass}`}
                                  />
                                </Form.Item>
                                {/* {passwordError ? (
                        <>
                          <div className="ant-form-item-explain ant-form-item-explain-connected">
                            <div
                              className="ant-form-item-explain-error"
                              style={{ marginTop: '0px' }}
                            >
                              {passwordError}
                            </div>
                          </div>
                        </>
                      ) : null} */}
                                <Form.Item
                                  label="Confirm Password"
                                  name="confirm_password"
                                  dependencies={['new_password']}
                                  className="mb-3"
                                  //   hasFeedback
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Please confirm your password.',
                                    },
                                    ({ getFieldValue }) => ({
                                      validator: (_, value) => {
                                        if (
                                          getFieldValue('new_password') ===
                                            value ||
                                          !value
                                        )
                                          return Promise.resolve();
                                        return Promise.reject(
                                          new Error(
                                            'Your password does not match.',
                                          ),
                                        );
                                      },
                                    }),
                                  ]}
                                >
                                  <Input.Password
                                    placeholder="Confirm Password"
                                    name="confirm_password"
                                    className={`${formClass}`}
                                  />
                                </Form.Item>
                              </div>
                              <div className="text-center mt-6">
                                <Form.Item className="">
                                  <button
                                    type="primary"
                                    htmlType="submit"
                                    className={`${btnPrimaryClass} w-full`}
                                  >
                                    {isLoading ? (
                                      <SpinnerBtn className={'text-lg'} />
                                    ) : type === 'Set' ? (
                                      'Set Password'
                                    ) : (
                                      'Set new password'
                                    )}
                                  </button>
                                </Form.Item>
                              </div>
                              {type !== 'Set' && (
                                <div className="d-flex justify-content-center gap-0.5">
                                  <Link
                                    className={`text-blue-2 hover:text-blue-2 flex items-center justify-center`}
                                    to="/"
                                  >
                                    <MdKeyboardArrowLeft /> Back to login
                                  </Link>
                                </div>
                              )}
                            </Form>
                          </div>
                        </>
                      ) : message?.button === 'login' ? (
                        <Form.Item>
                          <button
                            type="primary"
                            htmlType="submit"
                            className={`${btnPrimaryClass} w-full mt-3`}
                            onClick={() => navigate('/')}
                          >
                            Login
                          </button>
                        </Form.Item>
                      ) : message?.button === 'resend_email' && !isExpired ? (
                        <>
                          <div className="flex flex-col">
                            <Form.Item>
                              <button
                                type="primary"
                                htmlType="submit"
                                className={`${btnPrimaryClass} w-full mt-3`}
                                onClick={() => {
                                  type === 'Set'
                                    ? ResendSetPasswordLink(query.get('email'))
                                    : ResendResetPasswordLink(
                                        query.get('email'),
                                      );
                                  setIsExpired(true);
                                }}
                              >
                                Resend Link
                              </button>
                            </Form.Item>
                            {backToLogin()}
                          </div>
                        </>
                      ) : (
                        (message?.button === 'counter' || isExpired) && (
                          <>
                            <div className="flex justify-center w-full min-h-[34px] mt-3">
                              <OtpTimer
                                minutes={1}
                                seconds={30}
                                text="Resend Email Link in"
                                ButtonText="Resend Email"
                                // isButton={false}
                                background="none"
                                textColor="unset"
                                buttonColor="#cc8411"
                                className={`w-full`}
                                // isError={mutation.isError}
                                resend={() => {
                                  type === 'Set'
                                    ? ResendSetPasswordLink(query.get('email'))
                                    : ResendResetPasswordLink(
                                        query.get('email'),
                                      );
                                }}
                                // darkTheme={darkTheme}
                              />
                            </div>
                            {backToLogin()}
                          </>
                        )
                      )}
                      {message?.button !== 'login' &&
                        message?.button !== 'form' && (
                          <div className="mt-3">
                            <p className="text-xs text-gray-400 text-center">
                              <i>
                                In case the email is not recieved in the Inbox,
                                please check the Spam folder.
                              </i>
                            </p>
                          </div>
                        )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
            {/* <div className="absolute bottom-3 right-3">
            <a href="#" className="text-color-primary hover:text-blue-3">
              Need Help?
            </a>
          </div> */}
          </div>
        </section>
      </div>
    </>
  );
}
