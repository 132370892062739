import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { getpnlData } from '../../API/portfolio/OrdersApi';

function usePortfolioTables(tab) {
  const [selectedDateRange, setSelectedDateRange] = useState('');
  const [overAllDateRange, setOverAllDateRange] = useState('');
  const [totalTradesData, setTotalTradesData] = useState('');

  const pnlData = useCallback(
    async (selectedDate) => {
      if (
        typeof selectedDate === 'object' &&
        selectedDate[0] &&
        selectedDate[1]
      ) {
        setSelectedDateRange(
          selectedDate
            .map((dateString) => dayjs(dateString).format('DD/MM/YY'))
            .join('-'),
        );
        let data = await getpnlData(selectedDate, tab);
        setTotalTradesData(data);
        setOverAllDateRange(
          `${dayjs(data?.firstTradeDate).format('DD/MM/YY')}` +
            '-' +
            `${dayjs(data?.lastTradeDate).format('DD/MM/YY')}`,
        );
      } else {
        setSelectedDateRange('Today');
        let data = await getpnlData('Today', tab);
        setTotalTradesData(data);
        setOverAllDateRange(
          `${dayjs(data?.firstTradeDate).format('DD/MM/YY')}` +
            '-' +
            `${dayjs(data?.lastTradeDate).format('DD/MM/YY')}`,
        );
      }
    },
    [tab],
  );

  useEffect(() => {
    pnlData('Today');
  }, [pnlData]);

  const values = useMemo(
    () => ({
      pnlData,
      overAllDateRange,
      totalTradesData,
      selectedDateRange,
    }),
    [pnlData, overAllDateRange, totalTradesData, selectedDateRange],
  );
  return values;
}

export default usePortfolioTables;
