import React, { useEffect, useState } from 'react';
import '../Backtesting.css';
import { Button, Tooltip } from 'antd';
import axios from 'axios';
import moment from 'moment';
import CalendarHeatmap from 'react-calendar-heatmap';
import 'react-calendar-heatmap/dist/styles.css';
import './Heatmap.css';
import ReactTooltip from 'react-tooltip';
import BarChartIcon from '@mui/icons-material/BarChart';
import config from '../../Config/config';
import { getpnlData } from '../../API/portfolio/OrdersApi';
import { afterBorder } from '../utils/defaultClasses';

const Heatmap = ({ from, tab }) => {
  let getuserBots = localStorage.getItem('token');
  const [data, setData] = useState();
  let startDate = new Date();
  let endDate = new Date();
  const [mapData, setMapData] = useState([]);
  const [totalTrades, setTotalTrades] = useState(0);
  const [profitTrades, setProfitTrades] = useState(0);
  const [lossTrades, setLossTrades] = useState(0);

  useEffect(() => {
    async function pnlData(Date, selectedDate) {
      let data = await getpnlData(Date, selectedDate, tab);
      setMapData(data?.TotalDayWisePnl);
      if (from !== 'portfolio') {
        setTotalTrades(data?.totalTrades);
        setProfitTrades(data?.profitPercentage);
        setLossTrades(data?.lossPercentage);
      }
    }
    pnlData('today', '');
  }, []);

  // let ordersData = originalData;

  startDate.setFullYear(startDate.getFullYear() - 1);

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        let payload = {
          start_date: moment(startDate).format('YYYY-MM-DD'),
          end_date: moment(endDate).format('YYYY-MM-DD'),
        };

        const response = await axios.post(
          `${config.base_url}${config.heatmap}`,
          payload,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );

        if (isMounted) {
          setData(response.data.data);
        }
      } catch (error) {
        // Handle errors if needed
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, []);

  var getDaysArray = function (start, end) {
    for (
      var arr = [], dt = new Date(start);
      dt <= new Date(end);
      dt.setDate(dt.getDate() + 1)
    ) {
      arr.push({ date: new Date(dt), count: 0 });
    }
    return arr;
  };

  const updatedData =
    mapData?.map((item) => ({
      date: item.date,
      count: item.pnl,
    })) || [];

  //console.log(getDaysArray(startDate, endDate));
  if (data) {
    //console.log(ordersData);
    var sum = 0;
    var Profit_trades = 0;
    var loss_trades = 0;
    var max = 0;
    var min = -1;
    var PnL = [...getDaysArray(startDate, endDate), ...updatedData];
  }
  const pnlValues = updatedData?.map((item) => item.count) || [];

  // Find the minimum and maximum values
  min = Math.min(...pnlValues);
  max = Math.max(...pnlValues);

  var total_trades = Profit_trades + loss_trades;

  // Setting colors
  const colors = [];
  colors.push({ count: 1, color: '#66ff33' });
  colors.push({ count: 2, color: '#ff0000' });

  return (
    <>
      {/* {from !== 'strategy' && <Header />} */}

      <div>
        <div className="primary__bg__dark heat__map__bg">
          {from !== 'portfolio' && (
            <div
              className={`flex justify-between items-center gap-2 pt-1.5 pb-3.5 px-1.5 relative ${afterBorder}`}
            >
              <div>
                <BarChartIcon />
                P&L
              </div>
              <div className="flex gap-2 items-center">
                <button className="bg-color-base-2 rounded-lg border-[1px] border-level-1 py-1 px-2.5 text-sm">
                  Total Trades:
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    <span className="text-success ml-2">{totalTrades}</span>
                  ) : (
                    0
                  )}
                </button>
                <button className="bg-color-base-2 rounded-lg border-[1px] border-level-1 py-1 px-2.5 text-sm">
                  Profit Trades:{' '}
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    <span className="text-success ml-2">
                      {profitTrades}

                      {'%'}
                    </span>
                  ) : (
                    0
                  )}
                </button>
                <button className="bg-color-base-2 rounded-lg border-[1px] border-level-1 py-1 px-2.5 text-sm">
                  Loss Trades:{' '}
                  {PnL && PnL.length !== 0 && PnL !== undefined ? (
                    <span className="text-danger ml-2">
                      {lossTrades}
                      {/* {(() => Math.round((loss_trades / total_trades) * 100))()} */}
                      {'%'}
                    </span>
                  ) : (
                    0
                  )}
                </button>
              </div>
            </div>
          )}

          <div className="mt-4">
            {PnL && PnL.length !== 0 && PnL !== undefined ? (
              <>
                <CalendarHeatmap
                  startDate={startDate}
                  endDate={endDate}
                  values={PnL}
                  classForValue={(value) => {
                    //console.log(value);
                    if (value) {
                      return value.count > 0
                        ? value.count == 0
                          ? `color-empty`
                          : value.count < max / 4
                          ? 'color-scale-1'
                          : value.count > max / 4 &&
                            value.count < max / 4 + max / 4
                          ? 'color-scale-2'
                          : value.count > max / 4 + max / 4 &&
                            value.count < max / 4 + max / 4 + max / 4
                          ? 'color-scale-3'
                          : value.count > max / 4 + max / 4 + max / 4 &&
                            value.count <= max &&
                            'color-scale-4'
                        : value.count == 0
                        ? `color-empty`
                        : Math.abs(value.count) < Math.abs(min) / 4
                        ? 'color-scale-5'
                        : Math.abs(value.count) > Math.abs(min) / 4 &&
                          Math.abs(value.count) <
                            Math.abs(min) / 4 + Math.abs(min) / 4
                        ? 'color-scale-6'
                        : Math.abs(value.count) >
                            Math.abs(min) / 4 + Math.abs(min) / 4 &&
                          Math.abs(value.count) <
                            Math.abs(min) / 4 +
                              Math.abs(min) / 4 +
                              Math.abs(min) / 4
                        ? 'color-scale-7'
                        : Math.abs(value.count) >
                            Math.abs(min) / 4 +
                              Math.abs(min) / 4 +
                              Math.abs(min) / 4 &&
                          Math.abs(value.count) <= Math.abs(min) &&
                          'color-scale-8';
                    }
                  }}
                  tooltipDataAttrs={(value) => {
                    return {
                      'data-tip': `${moment(value.date).format(
                        'DD/MM',
                      )} PnL : ${value.count.toFixed(2)}`,
                    };
                  }}
                  //showOutOfRangeDays={true}
                  showWeekdayLabels={true}
                  addGap={true}
                ></CalendarHeatmap>
                <ReactTooltip />
              </>
            ) : (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <div className="d-flex">
              <h6 className="mr-4 d-flex align-items-center">
                Profit:{' '}
                <Tooltip title={'Profit : 1'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#9fc891',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Profit : 2'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#71c655',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Profit : 3'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#4cac29',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Profit : 4'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#329b0c',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
              </h6>
              <h6 className="mr-4 d-flex align-items-center">
                Loss:{' '}
                <Tooltip title={'Loss : 1'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#cf8c8c',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Loss : 2'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#db5757',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Loss : 3'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#dd3434',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
                <Tooltip title={'Loss : 4'}>
                  <div
                    style={{
                      width: '12px',
                      height: '12px',
                      border: '1px solid transparent',
                      marginLeft: '5px',
                      backgroundColor: '#c60707',
                    }}
                  >
                    {' '}
                  </div>{' '}
                </Tooltip>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default React.memo(Heatmap);
