import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { NavLink } from 'react-bootstrap';
import { BsInfoCircle } from '../assets/Icon';
import { Tooltip } from '@mui/material';
import {
  afterBorder,
  btnPrimaryClass,
  btnSecondayClass,
} from '../components/utils/defaultClasses';
import { Avatar, Image } from 'antd';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import {
  setOpenBrokerModal,
  setSelectedBroker,
} from '../features/brokers/brokerSlice';

function BrokerCard({
  image,
  name,
  description,
  showModal,
  id,
  getBroker,
  instruct,
}) {
  // console.log(name, description);
  let history = useNavigate();
  return (
    <>
      <div
        className={`flex items-center border-[1px] border-level-2 border-dashed rounded-xl overflow-hidden p-3.5 relative`}
        //  border-green-3
      >
        <div className="flex justify-between gap-1.5 w-full">
          <div className="flex flex-col gap-3.5 w-full">
            <div className="max-w-[40%] w-[100%] h-[40px] bg-color-base-2 dark:bg-transparent flex justify-center items-center p-1.5 px-3.5">
              <img
                src={image}
                preview={false}
                alt="Broker"
                className="w-full h-full object-contain"
              />
            </div>

            <div className="flex gap-2.5 items-center justify-between w-full">
              {/* <div>
                <div className="flex items-center gap-1.5">
                  <div className="text-base font-semibold">Marcus Morris</div>
                  <span className="flex bg-green-2/20 text-green-3 rounded-md px-1.5 py-0.5 text-xs">
                    <small>Primary</small>
                  </span>
                </div>
              </div> */}
              <div className="flex flex-col leading-tight">
                <div className="text-base font-semibold">
                  <span>{name}</span>
                </div>
                <span className="text-xs text-color-secondary/70 font-normal">
                  {description}
                </span>
              </div>
              <div className="flex items-center gap-2 shrink-0">
                <div>
                  <button
                    className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                    // className={`bg-green-2 p-3.5 text-whiteNew-0 w-[35px] h-[35px] relative flex rounded-md`}
                    //
                    onClick={() => {
                      getBroker(id, name);
                      showModal();
                    }}
                  >
                    <Tooltip
                      title={`Submit your broker credentials here.`}
                      placement="top"
                      arrow
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z"></path>
                        <path d="M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z"></path>
                        <path d="M3 21l2.5 -2.5"></path>
                        <path d="M18.5 5.5l2.5 -2.5"></path>
                        <path d="M10 11l-2 2"></path>
                        <path d="M13 14l-2 2"></path>
                      </svg>
                      {/* <MdInsertLink /> */}
                    </Tooltip>
                  </button>
                </div>
                <div>
                  <Tooltip
                    title={`Get help with finding your broker login details.`}
                    placement="top"
                    arrow
                  >
                    <Link
                      className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                      to={
                        name.toUpperCase() === 'Alice Blue'.toUpperCase()
                          ? '/broker/instructions/aliceBlue'
                          : name.toUpperCase() === 'Angle One'.toUpperCase()
                          ? '/broker/instructions/angelone'
                          : name.toUpperCase() === '5Paisa'.toUpperCase()
                          ? '/broker/instructions/paisa'
                          : name.toUpperCase() === 'Zerodha'.toUpperCase()
                          ? '/broker/instructions/zerodha'
                          : name.toUpperCase() === 'Flat Trade'.toUpperCase()
                          ? '/broker/instructions/flatTrade'
                          : name.toUpperCase() === 'Dhan'.toUpperCase()
                          ? '/broker/instructions/dhan'
                          : name.toUpperCase() === 'Fyers'.toUpperCase()
                          ? '/broker/instructions/fyres'
                          : ''
                      }
                    >
                      <svg
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 14H4V5h16v12z"></path>
                        <path d="M6 8.25h8v1.5H6zM16.5 9.75H18v-1.5h-1.5V7H15v4h1.5zM10 12.25h8v1.5h-8zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5z"></path>
                      </svg>
                    </Link>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="absolute top-2 right-3">
          <Avatar.Group>
            <Tooltip title={'Broker Name'}>
              <Avatar
                src={`https://eu.ui-avatars.com/api/?name=${'Broker Name'}&size=250`}
              />
            </Tooltip>
            <Tooltip title={'Broker Name'}>
              <Avatar
                src={`https://eu.ui-avatars.com/api/?name=${'Broker Name'}&size=250`}
              />
            </Tooltip>
          </Avatar.Group>
        </div>
        {/* <div className="absolute top-5 right-5 text-green-3 text-2xl">
          <BsCheckCircleFill />
        </div> */}
      </div>
    </>
  );
}

export default BrokerCard;

export function BrokerCard2({
  image,
  name,
  description,
  showModal,
  id,
  getBroker,
  broker,
}) {
  const getInitials = function (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  const dispatch = useDispatch();
  return (
    <>
      <div
        className={`flex items-center rounded-md overflow-hidden relative group`}
        style={{
          boxShadow:
            '2px 4px 9px 1px rgba(0,0,0,0.05),0 1px 1px rgba(0,0,0,0.04)',
        }}
        //  border-green-3
      >
        <div className="flex justify-between gap-1.5 w-full">
          <div className="flex flex-col w-full ">
            <div className="flex justify-between items-center p-2.5 px-3.5 group-hover:bg-color-primaryColor2 relative ease-in duration-200">
              <div className="flex gap-2 shrink-0">
                <div className="w-[50px] h-[50px] bg-color-base-1 border-[0.625px] rounded-[4px] border-level-4 flex justify-center items-center p-[6px] shrink-0">
                  <img
                    src={image}
                    preview={false}
                    alt="Broker"
                    className="w-full h-full object-contain"
                  />
                </div>
                <div>
                  <div className="flex flex-col">
                    <span className="text-base font-semibold group-hover:text-whiteNew-0 ease-in duration-200">
                      {name}
                    </span>
                    <span className="text-sm text-color-secondary group-hover:text-whiteNew-0 ease-in duration-200">
                      Brokers
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <Avatar.Group>
                  <Tooltip title={'Broker Name'}>
                    <Avatar
                      // style={{
                      //   backgroundColor: '#fde3cf',
                      //   color: '#f56a00',
                      // }}
                      className="bg-blue-3 border-[2px]"
                    >
                      {getInitials('b')}
                    </Avatar>
                  </Tooltip>
                  <Tooltip title={'Broker Name'}>
                    <Avatar
                      // style={{
                      //   backgroundColor: '#fde3cf',
                      //   color: '#f56a00',
                      // }}
                      className="bg-blue-3 border-[2px]"
                    >
                      {getInitials('A B C')}
                    </Avatar>
                  </Tooltip>
                </Avatar.Group>
              </div>
              <div className="left-strip absolute left-0 h-[51px] w-[5px] rounded-md rounded-tl-none rounded-bl-none bg-blue-2/20 group-hover:bg-transparent ease-in duration-200"></div>
            </div>

            <div className="flex gap-2.5 items-center justify-between w-full p-2.5 px-3.5">
              {/* <div>
                <div className="flex items-center gap-1.5">
                  <div className="text-base font-semibold">Marcus Morris</div>
                  <span className="flex bg-green-2/20 text-green-3 rounded-md px-1.5 py-0.5 text-xs">
                    <small>Primary</small>
                  </span>
                </div>
              </div> */}
              <div className="flex flex-col leading-tight">
                {/* <div className="text-base font-semibold">
                  <span>{name}</span>
                </div> */}
                <span className="text-xs text-color-secondary/70 font-normal">
                  {description}
                </span>
                <Link
                  to={
                    name.toUpperCase() === 'Alice Blue'.toUpperCase()
                      ? '/broker/instructions/aliceBlue'
                      : name.toUpperCase() === 'Angle One'.toUpperCase()
                      ? '/broker/instructions/angelone'
                      : name.toUpperCase() === '5Paisa'.toUpperCase()
                      ? '/broker/instructions/paisa'
                      : name.toUpperCase() === 'Zerodha'.toUpperCase()
                      ? '/broker/instructions/zerodha'
                      : name.toUpperCase() === 'Flat Trade'.toUpperCase()
                      ? '/broker/instructions/flatTrade'
                      : name.toUpperCase() === 'Dhan'.toUpperCase()
                      ? '/broker/instructions/dhan'
                      : name.toUpperCase() === 'Fyers'.toUpperCase()
                      ? '/broker/instructions/fyres'
                      : ''
                  }
                  className="text-blue-3 hover:underline flex items-center justify-center gap-0.5 text-sm"
                >
                  Documentation <FiExternalLink />
                </Link>
              </div>
              <div className="flex items-center gap-2 shrink-0">
                <div>
                  <button
                    className={`${btnSecondayClass} group-hover:bg-color-primaryColor2 ease-in duration-200 group-hover:text-whiteNew-0 w-[35px] h-[35px] relative`}
                    // className={`bg-green-2 p-3.5 text-whiteNew-0 w-[35px] h-[35px] relative flex rounded-md`}
                    //
                    onClick={() => {
                      // getBroker(id, name);
                      // showModal();
                      dispatch(setSelectedBroker(broker));
                      dispatch(setOpenBrokerModal(true));
                    }}
                  >
                    <Tooltip
                      title={`Submit your broker credentials here.`}
                      placement="top"
                      arrow
                    >
                      <svg
                        stroke="currentColor"
                        fill="none"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z"></path>
                        <path d="M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z"></path>
                        <path d="M3 21l2.5 -2.5"></path>
                        <path d="M18.5 5.5l2.5 -2.5"></path>
                        <path d="M10 11l-2 2"></path>
                        <path d="M13 14l-2 2"></path>
                      </svg>
                      {/* <MdInsertLink /> */}
                    </Tooltip>
                  </button>
                </div>
                {/* <div>
                  <Tooltip
                    title={`Get help with finding your broker login details.`}
                    placement="top"
                    arrow
                  >
                    <Link
                      className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                      to={
                        name.toUpperCase() === 'Alice Blue'.toUpperCase()
                          ? '/broker/instructions/aliceBlue'
                          : name.toUpperCase() === 'Angle One'.toUpperCase()
                          ? '/broker/instructions/angelone'
                          : name.toUpperCase() === '5Paisa'.toUpperCase()
                          ? '/broker/instructions/paisa'
                          : name.toUpperCase() === 'Zerodha'.toUpperCase()
                          ? '/broker/instructions/zerodha'
                          : name.toUpperCase() === 'Flat Trade'.toUpperCase()
                          ? '/broker/instructions/flatTrade'
                          : name.toUpperCase() === 'Dhan'.toUpperCase()
                          ? '/broker/instructions/dhan'
                          : name.toUpperCase() === 'Fyers'.toUpperCase()
                          ? '/broker/instructions/fyres'
                          : ''
                      }
                    >
                      <svg
                        className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 14H4V5h16v12z"></path>
                        <path d="M6 8.25h8v1.5H6zM16.5 9.75H18v-1.5h-1.5V7H15v4h1.5zM10 12.25h8v1.5h-8zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5z"></path>
                      </svg>
                    </Link>
                  </Tooltip>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="absolute top-2 right-3">
          <Avatar.Group>
            <Tooltip title={'Broker Name'}>
              <Avatar
                src={`https://eu.ui-avatars.com/api/?name=${'Broker Name'}&size=250`}
              />
            </Tooltip>
            <Tooltip title={'Broker Name'}>
              <Avatar
                src={`https://eu.ui-avatars.com/api/?name=${'Broker Name'}&size=250`}
              />
            </Tooltip>
          </Avatar.Group>
        </div> */}
        {/* <div className="absolute top-5 right-5 text-green-3 text-2xl">
          <BsCheckCircleFill />
        </div> */}
      </div>
    </>
  );
}
export function BrokerCard3({ image, name, description, item, button }) {
  const getInitials = function (string) {
    var names = string.split(' '),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };
  return (
    <>
      <div
        className={`flex items-center rounded-md overflow-hidden relative group`}
        style={{
          boxShadow:
            '2px 4px 9px 1px rgba(0,0,0,0.05),0 1px 1px rgba(0,0,0,0.04)',
        }}
        //  border-green-3
      >
        <div className="flex justify-between gap-1.5 w-full">
          <div className="flex flex-col w-full ">
            <div
              className={`${afterBorder} after:bg-level-4 flex justify-between items-center p-2.5 px-3.5 group-hover:bg-color-primaryColor2 relative ease-in duration-200`}
            >
              <div className="flex gap-2 shrink-0">
                <div className="w-[50px] h-[50px] bg-color-base-1 border-[0.625px] rounded-[4px] border-level-4 flex justify-center items-center p-[6px] shrink-0">
                  <img
                    src={image}
                    preview={false}
                    alt="Broker"
                    className="w-full h-full object-contain"
                  />
                </div>
                <div>
                  <div className="flex flex-col">
                    <span className="text-base font-semibold group-hover:text-whiteNew-0 ease-in duration-200">
                      {name}
                    </span>
                    <span className="text-sm text-color-secondary group-hover:text-whiteNew-0 ease-in duration-200">
                      Brokers
                    </span>
                  </div>
                </div>
              </div>
              <div className="left-strip absolute left-0 h-[51px] w-[5px] rounded-md rounded-tl-none rounded-bl-none bg-blue-2/20 group-hover:bg-transparent ease-in duration-200"></div>
            </div>

            <div className="flex flex-col gap-2.5 items-center justify-between w-full p-2.5 px-3.5">
              <span className="text-xs text-color-secondary/70 font-normal">
                {description}
              </span>
              <div className="flex flex-col leading-6 text-center">
                <p className="py-4 space-y-3">
                  Avl. Balance:{' '}
                  <span style={{ color: '#4074F9' }}>{item?.funds}</span>
                  <br />
                  <span className="fsize-13">Client ID:{item?.id}</span>
                </p>
              </div>
              <div className="flex items-center gap-2 shrink-0 w-full">
                <div className="w-full">{button}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
//  <div className="brockerCard__body">
//    <div className="d-flex flex-row brockerCards">
//      <div className="brocker__img">
//        <img src={image} alt="brocker img" />
//      </div>
//      <div className="connect__button dsktp">
//        {instruct ? (
//          <button
//            className="button"
//            onClick={() => {
//              if (name.toUpperCase() === 'Alice Blue'.toUpperCase()) {
//                history('/broker/instructions/aliceBlue');
//              } else if (name.toUpperCase() === 'Angle One'.toUpperCase()) {
//                history('/broker/instructions/angelone');
//              } else if (name.toUpperCase() === '5Paisa'.toUpperCase()) {
//                history('/broker/instructions/paisa');
//              } else if (name.toUpperCase() === 'Zerodha'.toUpperCase()) {
//                history('/broker/instructions/zerodha');
//              } else if (name.toUpperCase() === 'Flat Trade'.toUpperCase()) {
//                history('/broker/instructions/flatTrade');
//              } else if (name.toUpperCase() === 'Dhan'.toUpperCase()) {
//                history('/broker/instructions/dhan');
//              } else if (name.toUpperCase() === 'Fyers'.toUpperCase()) {
//                history('/broker/instructions/fyres');
//              }
//            }}
//          >
//            Learn More
//          </button>
//        ) : (
//          <>
//            <div className="d-flex position-relative w-100">
//              <Tooltip placement="top" title="Connect">
//                <button
//                  className="btn_default"
//                  onClick={() => {
//                    getBroker(id, name);
//                    showModal();
//                  }}
//                >
//                  <svg
//                    stroke="currentColor"
//                    fill="none"
//                    stroke-width="2"
//                    viewBox="0 0 24 24"
//                    stroke-linecap="round"
//                    stroke-linejoin="round"
//                    height="1em"
//                    width="1em"
//                    xmlns="http://www.w3.org/2000/svg"
//                  >
//                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//                    <path d="M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z"></path>
//                    <path d="M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z"></path>
//                    <path d="M3 21l2.5 -2.5"></path>
//                    <path d="M18.5 5.5l2.5 -2.5"></path>
//                    <path d="M10 11l-2 2"></path>
//                    <path d="M13 14l-2 2"></path>
//                  </svg>
//                </button>
//              </Tooltip>
//              <Tooltip placement="top" title={name}>
//                <Link
//                  to={
//                    name.toUpperCase() === 'Alice Blue'.toUpperCase()
//                      ? '/broker/instructions/aliceBlue'
//                      : name.toUpperCase() === 'Angle One'.toUpperCase()
//                      ? '/broker/instructions/angelone'
//                      : name.toUpperCase() === '5Paisa'.toUpperCase()
//                      ? '/broker/instructions/paisa'
//                      : name.toUpperCase() === 'Zerodha'.toUpperCase()
//                      ? '/broker/instructions/zerodha'
//                      : name.toUpperCase() === 'Flat Trade'.toUpperCase()
//                      ? '/broker/instructions/flatTrade'
//                      : name.toUpperCase() === 'Dhan'.toUpperCase()
//                      ? '/broker/instructions/dhan'
//                      : name.toUpperCase() === 'Fyers'.toUpperCase()
//                      ? '/broker/instructions/fyres'
//                      : ''
//                  }
//                  className="ms-2 btn_default"
//                >
//                  {/* <BsInfoCircle /> */}
//                  <svg
//                    stroke="currentColor"
//                    fill="currentColor"
//                    stroke-width="0"
//                    viewBox="0 0 24 24"
//                    height="1em"
//                    width="1em"
//                    xmlns="http://www.w3.org/2000/svg"
//                  >
//                    <path fill="none" d="M0 0h24v24H0z"></path>
//                    <path d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 14H4V5h16v12z"></path>
//                    <path d="M6 8.25h8v1.5H6zM16.5 9.75H18v-1.5h-1.5V7H15v4h1.5zM10 12.25h8v1.5h-8zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5z"></path>
//                  </svg>
//                </Link>
//              </Tooltip>
//              {/* <Tooltip placement="top" title={name}>
//                 <Link
//                   to={
//                     name.toUpperCase() === 'Alice Blue'.toUpperCase()
//                       ? '/broker/instructions/aliceBlue'
//                       : name.toUpperCase() === 'Angle One'.toUpperCase()
//                       ? '/broker/instructions/angelone'
//                       : name.toUpperCase() === '5Paisa'.toUpperCase()
//                       ? '/broker/instructions/paisa'
//                       : name.toUpperCase() === 'Zerodha'.toUpperCase()
//                       ? '/broker/instructions/zerodha'
//                       : name.toUpperCase() === 'Flat Trade'.toUpperCase()
//                       ? '/broker/instructions/flatTrade'
//                       : name.toUpperCase() === 'Dhan'.toUpperCase()
//                       ? '/broker/instructions/dhan'
//                       : name.toUpperCase() === 'Fyers'.toUpperCase()
//                       ? '/broker/instructions/fyres'
//                       : ''
//                   }
//                   className="position-absolute topPx-7 fsize-12 text-muted ms-3 fweight-4 hoverLink textBColor"
//                   style={{ right: '-25px' }}
//                 >
//                   <BsInfoCircle />
//                 </Link>
//               </Tooltip> */}
//            </div>
//          </>
//        )}
//      </div>
//    </div>
//    <div className="brocker__info">
//      <p className="brocker__name">
//        <span>{name}</span>
//      </p>
//      <p className="brocker__description">{description}</p>
//    </div>
//    <div className="connect__button mobile">
//      <div className="d-flex position-relative w-100">
//        <Tooltip placement="top" title="Connect">
//          <button
//            className="btn_default"
//            onClick={() => {
//              getBroker(id, name);
//              showModal();
//            }}
//          >
//            <svg
//              stroke="currentColor"
//              fill="none"
//              stroke-width="2"
//              viewBox="0 0 24 24"
//              stroke-linecap="round"
//              stroke-linejoin="round"
//              height="1em"
//              width="1em"
//              xmlns="http://www.w3.org/2000/svg"
//            >
//              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
//              <path d="M7 12l5 5l-1.5 1.5a3.536 3.536 0 1 1 -5 -5l1.5 -1.5z"></path>
//              <path d="M17 12l-5 -5l1.5 -1.5a3.536 3.536 0 1 1 5 5l-1.5 1.5z"></path>
//              <path d="M3 21l2.5 -2.5"></path>
//              <path d="M18.5 5.5l2.5 -2.5"></path>
//              <path d="M10 11l-2 2"></path>
//              <path d="M13 14l-2 2"></path>
//            </svg>
//          </button>
//        </Tooltip>
//        <Tooltip placement="top" title={name}>
//          <Link
//            to={
//              name.toUpperCase() === 'Alice Blue'.toUpperCase()
//                ? '/broker/instructions/aliceBlue'
//                : name.toUpperCase() === 'Angle One'.toUpperCase()
//                ? '/broker/instructions/angelone'
//                : name.toUpperCase() === '5Paisa'.toUpperCase()
//                ? '/broker/instructions/paisa'
//                : name.toUpperCase() === 'Zerodha'.toUpperCase()
//                ? '/broker/instructions/zerodha'
//                : name.toUpperCase() === 'Flat Trade'.toUpperCase()
//                ? '/broker/instructions/flatTrade'
//                : name.toUpperCase() === 'Dhan'.toUpperCase()
//                ? '/broker/instructions/dhan'
//                : name.toUpperCase() === 'Fyers'.toUpperCase()
//                ? '/broker/instructions/fyres'
//                : ''
//            }
//            className="ms-0 btn_default brdr_sep"
//          >
//            {/* <BsInfoCircle /> */}
//            <svg
//              stroke="currentColor"
//              fill="currentColor"
//              stroke-width="0"
//              viewBox="0 0 24 24"
//              height="1em"
//              width="1em"
//              xmlns="http://www.w3.org/2000/svg"
//            >
//              <path fill="none" d="M0 0h24v24H0z"></path>
//              <path d="M20 3H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h4v2h8v-2h4c1.1 0 2-.9 2-2V5a2 2 0 0 0-2-2zm0 14H4V5h16v12z"></path>
//              <path d="M6 8.25h8v1.5H6zM16.5 9.75H18v-1.5h-1.5V7H15v4h1.5zM10 12.25h8v1.5h-8zM7.5 15H9v-4H7.5v1.25H6v1.5h1.5z"></path>
//            </svg>
//          </Link>
//        </Tooltip>
//        {/* <Tooltip placement="top" title={name}>
//                 <Link
//                   to={
//                     name.toUpperCase() === 'Alice Blue'.toUpperCase()
//                       ? '/broker/instructions/aliceBlue'
//                       : name.toUpperCase() === 'Angle One'.toUpperCase()
//                       ? '/broker/instructions/angelone'
//                       : name.toUpperCase() === '5Paisa'.toUpperCase()
//                       ? '/broker/instructions/paisa'
//                       : name.toUpperCase() === 'Zerodha'.toUpperCase()
//                       ? '/broker/instructions/zerodha'
//                       : name.toUpperCase() === 'Flat Trade'.toUpperCase()
//                       ? '/broker/instructions/flatTrade'
//                       : name.toUpperCase() === 'Dhan'.toUpperCase()
//                       ? '/broker/instructions/dhan'
//                       : name.toUpperCase() === 'Fyers'.toUpperCase()
//                       ? '/broker/instructions/fyres'
//                       : ''
//                   }
//                   className="position-absolute topPx-7 fsize-12 text-muted ms-3 fweight-4 hoverLink textBColor"
//                   style={{ right: '-25px' }}
//                 >
//                   <BsInfoCircle />
//                 </Link>
//               </Tooltip> */}
//      </div>
//    </div>
//  </div>;
