import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedTicket: JSON.parse(localStorage.getItem('selectedTicket')) ?? {},
  ticketMessageList: [],
};

export const ticketSlice = createSlice({
  name: 'TicketDetails',
  initialState,
  reducers: {
    setSelectedTicket: (state, { payload }) => {
      state.selectedTicket = payload;
    },
    setTicketMessageList: (state, { payload }) => {
      state.ticketMessageList = payload.data;
      state.totalCount = payload.count;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSelectedTicket, setTicketMessageList } = ticketSlice.actions;

export default ticketSlice.reducer;
