import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Switch, Checkbox, Menu } from 'antd';
import { DeleteOutlined, PrinterOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import useToGetId from './useToGetId';
import { updateRequest } from '../../API/strategy/strategyDetail';
import {
  userBacktestDeployments,
  userLiveDeployments,
  userVirtualDeployments,
} from '../../features/UserCredits/userCreditsSlice';
import { toast } from 'react-toastify';
import { formatINR } from '../../utils/utils';
import PremiumBadge from '../../utils/PremiumBadge';
import { FaRegEye } from 'react-icons/fa';
import { MdPlayArrow } from 'react-icons/md';
import {
  backtestingReportsdataStrategy,
  liveReportsdataStrategy,
  setBacktestingReportsDataSeperated,
  setLiveReportsDataSeperated,
  setSelectedKeys,
  setVirtualReportsDataSeperated,
  virtualReportsdataStrategy,
} from '../../features/strategyReport/strategyDataDetailSlice';
import Tooltip from '../Common/Tooltip';
import { updateBacktestingStrategyRequest } from '../../API/strategy/strategyPagePermissionApi';

function useDesktopStrategyTable(tradeOrder, toggleState, refetch = () => { }) {
  let { userid } = useToGetId();
  const { virtual_requests, live_requests, premium_strategies, backtest_requests } = useSelector(
    (state) => state.userCreditsDetails, // credits of the virtual and live requests
  );
  const {
    detailsOfStrategy,
    virtualReportsData,
    liveReportsData,
    backtestingReportsData, selectedKeys
  } = useSelector((state) => state.completeStrategyDetails);
  const [showWarning, setShowWarning] = useState(false);

  const disptach = useDispatch();

  const [tableData, setTableData] = useState([]);
  // const [selectedKeys, setSelectedKeys] = useState([]);
  const [removeModel, setRemoveModel] = useState(false);
  const [reportId, setReportId] = useState('');
  const dispatch = useDispatch();
  const deleteRequestReports = useCallback((value) => {
    setReportId(value);
    setRemoveModel(true);
  }, []);

  useEffect(() => {
    setTableData(tradeOrder);
  }, [tradeOrder]);

  //table actions memu
  const menu = useCallback(
    (record) => (
      <Menu>
        <Menu.Item key="1">
          <Link
            to={
              toggleState === 'backtesting'
                ? `/back-testing/${record._id}`
                : `/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${record._id
                }`
            }
          >
            View
          </Link>
        </Menu.Item>
        <Menu.Item key="2" onClick={() => Link}>
          Print
        </Menu.Item>
        <Menu.Item key="3" onClick={() => deleteRequestReports(record._id)}>
          Delete
        </Menu.Item>
      </Menu>
    ),
    [toggleState],
  );

  //table columns

  const columns = useMemo(() => [
    {
      title: 'Select',
      dataIndex: 'select',
      width: '10%',
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      // render: (text) => dayjs(text).format('DD MMM YY'),
    },
    {
      title: 'Max Cap',
      dataIndex: 'max_cap',
    },
    {
      title: 'No. of order',
      dataIndex: 'orderCount',
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'current_status',
    // },
    {
      title: 'Current Mode',
      dataIndex: 'mode',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
    },
  ]);

  const columnsBacktesting = useMemo(() => [
    {
      title: 'Select',
      dataIndex: 'select',
      width: '10%',
    },
    {
      title: 'Report Name',
      dataIndex: 'report_name',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      // render: (text) => dayjs(text).format('DD MMM YY'),
    },
    {
      title: 'Max Cap',
      dataIndex: 'max_cap',
    },
    {
      title: 'No. of order',
      dataIndex: 'orderCount',
    },
    {
      title: 'Current Status',
      dataIndex: 'current_status',
    },
    // {
    //   title: 'Mode',
    //   dataIndex: 'mode',
    // },
    {
      title: 'Actions',
      dataIndex: 'actions',
    },
  ]);

  const toggleFeature = useCallback(
    (item) => {
      if (
        !detailsOfStrategy?.subscribe_by_user &&
        detailsOfStrategy?.access_type === 'premium' &&
        premium_strategies?.strategies_remaining === 0
      ) {
        return true;
      } else if (
        !item.status &&
        // detailsOfStrategy?.access_type === 'premium' &&
        (toggleState === 'live'
          ? live_requests?.requests_remaining === 0
          : toggleState === 'virtual' ? virtual_requests?.requests_remaining === 0 : backtest_requests?.requests_remaining === 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    [live_requests, virtual_requests, backtest_requests, detailsOfStrategy, toggleState],
  );

  //Update VT trade
  const handleUpdate = useCallback(
    (status, id, strategy_id) => {
      if (detailsOfStrategy?.subscribe_by_user) {
        let vtPayload = {
          vt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let ltPayload = {
          lt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let btPayload = {
          bt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let finalPayload =
          toggleState === 'live'
            ? ltPayload
            : toggleState === 'virtual'
              ? vtPayload
              : btPayload;

        updateRequestStrategy.mutate({ finalPayload, toggleState });
      } else {
        setShowWarning(true);
      }

      // }
    },
    [detailsOfStrategy, toggleState],
  );
  const handleCheckboxChange = useCallback((id, isChecked) => {
    if (isChecked) {
      dispatch(setSelectedKeys([...selectedKeys, id]));
    } else {
      dispatch(setSelectedKeys(selectedKeys.filter((selectedId) => selectedId !== id)));
    }
  }, [dispatch, selectedKeys]);

  //table rows

  const upDateReport = useMutation({
    mutationFn: (payload) =>
      updateBacktestingStrategyRequest(payload, payload._id),
    onSuccess: (res) => {
      if (res.data.status) {
        refetch();
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const backtestingReportSubmission = (values) => {
    var backtest_payload = {
      user_id: values.user_id,
      strategy_id: values.strategy_id,
      status: 'Pending',
      // current_status: type === 'save_and_draft' ? 'Draft' : 'Pending',
      bt_inputs: values?.bt_inputs,
      _id: values?._id,
    };
    upDateReport.mutate(backtest_payload);
  };

  const getStatus = (item) => {
    if (item?.current_status === "Generated") {
      return "badge__green"
    }
    else if (item?.current_status === "Draft") {
      return "bg-component-gray-2 rounded-md p-0.5 text-xs px-2.5"
    }
    else if (item?.current_status === "Pending") {
      return "bg-orange-500/20 text-orange-500 rounded-sm p-0.5 text-xs px-2.5"
    } else {
      return 'bg-yellow-500/20 text-yellow-500 rounded-sm p-0.5 text-xs px-2.5'
    }
  }
  const dataSource = useMemo(
    () =>
      tableData?.map((item) => ({
        key: item._id,
        select: (
          <Checkbox
            checked={selectedKeys?.includes(item?._id)}
            onChange={(e) => handleCheckboxChange(item._id, e.target.checked)}
          />
        ),
        report_name: (
          <>
            <Link
              to={
                toggleState === 'backtesting'
                  ? `/back-testing/${item._id}`
                  : `/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'}/${item._id
                  }`
              }
              className="primary__blue text-blue-2 hover:underline"
            >
              {item?.report_name}
            </Link>
          </>
        ),
        created_at: <>{dayjs(item.created_at).format('DD MMM YY')}</>,
        max_cap: (
          <div className="flex">
            <span>{formatINR(item?.max_cap)}</span>
          </div>
        ),
        current_status: <span className={`${getStatus(item)}`}>
          <span>{item?.current_status}</span>
        </span>,

        mode: (
          <div className="flex items-center gap-1.5">
            {toggleFeature(item) ? (
              <PremiumBadge />
            ) : (
              // <div className="flex items-center gap-1.5">
              //   <div className="flex items-center gap-1.5 bg-blue-2/15 text-blue-2 px-1.5 py-0.5 rounded-md font-semibold text-xs">
              //     Premium Feature
              //   </div>
              //   <Tooltip
              //     title={'Please upgrade your account for premium features.'}
              //   >
              //     <AiOutlineQuestionCircle />
              //   </Tooltip>
              // </div>
              <Switch
                value={item?.status}
                onChange={(checked) =>
                  handleUpdate(checked, item?._id, item?.strategy_id)
                }
              />
            )}
          </div>
        ),

        orderCount: <p>{item?.orderCount ? item?.orderCount : item?.orderData ? item?.orderData : 0}</p>,

        actions: (
          <>
            {/* <Space size="middle">
              <Dropdown
                overlay={menu(item)}
                placement="bottomRight"
                overlayClassName={'dropdownOverlay dropdownDark'}
              >
                <MoreOutlined style={{ cursor: 'pointer' }} />
              </Dropdown>
            </Space> */}
            <div className="flex ite gap-2">
              <Tooltip title={<>View</>}>
                <Link
                  to={
                    toggleState === 'backtesting'
                      ? `/back-testing/${item._id}`
                      : `/view-report/${toggleState === 'virtual' ? 'VT' : 'LT'
                      }/${item._id}`
                  }
                  className="border-[1px] border-solid border-level-2 text-color-primary hover:border-blue-3 hover:bg-blue-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
                >
                  <FaRegEye />
                </Link>
              </Tooltip>
              <Tooltip title={<>Print</>}>
                <button
                  onClick={() => Link}
                  className="border-[1px] border-solid border-level-2 text-color-primary hover:border-yellow-500 hover:bg-yellow-500 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
                >
                  <PrinterOutlined />
                </button>
              </Tooltip>
              <Tooltip title={<>Delete</>}>
                <button
                  onClick={() => deleteRequestReports(item._id)}
                  className="border-[1px] border-solid border-level-2 hover:border-red-3 text-color-primary hover:bg-red-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center"
                >
                  <DeleteOutlined />
                </button>
              </Tooltip>
              {/* <Tooltip title={<>Generate Report</>}>
                <button
                  className={`border-[1px] border-solid border-level-2 hover:border-green-3 text-color-primary hover:bg-green-3 hover:text-whiteNew-0 duration-200 ease-in-out rounded-md w-[25px] h-[25px] flex justify-center items-center ${item?.status !== 'Draft'
                      ? 'cursor-not-allowed'
                      : 'cursor-pointer'
                    }`}
                  disabled={item?.status !== 'Draft'}
                  onClick={() => {
                    backtestingReportSubmission(item);
                  }}
                >
                  <MdPlayArrow />
                </button>
              </Tooltip> */}
            </div>
          </>
        ),
      })),
    [
      tableData,
      toggleState,
      toggleFeature,
      handleCheckboxChange,
      handleUpdate,
      deleteRequestReports,
    ],
  );


  //update the toogle switch
  const updateRequestStrategy = useMutation({
    mutationFn: ({ finalPayload, toggleState }) => {
      return updateRequest(finalPayload, toggleState);
    },
    onSuccess: async (res, { finalPayload }) => {
      // res.data.message
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      const idToUpdate =
        toggleState === 'live' ? finalPayload.lt_id : finalPayload.vt_id;
      const { status } = finalPayload; //to get the status of the toggle
      // -------------- ADDED TO UPDATE DATA WHEN CHANGING MODE ON DRAWER---------------
      if (toggleState === 'virtual') {
        let newData = virtualReportsData?.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        });
        dispatch(virtualReportsdataStrategy(newData));
        let obj = {
          active: newData?.filter((dat) => dat?.status),
          inActive: newData?.filter((dat) => !dat?.status),
        };
        dispatch(setVirtualReportsDataSeperated(obj));
      } else if (toggleState === 'live') {
        let newData = liveReportsData?.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        });
        dispatch(liveReportsdataStrategy(newData));
        let obj = {
          active: newData?.filter((dat) => dat?.status),
          inActive: newData?.filter((dat) => !dat?.status),
        };
        dispatch(setLiveReportsDataSeperated(obj));
      } else if (toggleState === 'backtesting') {
        let newData = backtestingReportsData?.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        });
        dispatch(backtestingReportsdataStrategy(newData));
        let obj = {
          active: newData?.filter((dat) => dat?.status),
          inActive: newData?.filter((dat) => !dat?.status),
        };
        dispatch(setBacktestingReportsDataSeperated(obj));
      }

      // -------------- ADDED TO UPDATE DATA WHEN CHANGING MODE ON DRAWER---------------

      setTableData(
        tableData.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        }),
      ); //update the table data chnaging the toggle switch of the selected request

      let virtual_payload = {
        ...virtual_requests,
        requests_remaining:
          virtual_requests?.requests_remaining === 'Unlimited'
            ? virtual_requests?.requests_remaining
            : virtual_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: virtual_requests?.requests_used + (status ? 1 : -1),
      };
      let live_payload = {
        ...live_requests,
        requests_remaining:
          live_requests?.requests_remaining === 'Unlimited'
            ? live_requests?.requests_remaining
            : live_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: live_requests?.requests_used + (status ? 1 : -1),
      };
      let backtest_payload = {
        ...backtest_requests,
        requests_remaining:
          backtest_requests?.requests_remaining === 'Unlimited'
            ? backtest_requests?.requests_remaining
            : backtest_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: backtest_requests?.requests_used + (status ? 1 : -1),
      };

      //based on the status upadte or removing the requests counts in redux
      toggleState === 'live'
        ? disptach(userLiveDeployments(live_payload))
        : toggleState === 'virtual' ? disptach(userVirtualDeployments(virtual_payload)) : disptach(userBacktestDeployments(backtest_payload));
    },
    onError: (error) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      // console.log(error.response.data.message);
    },
  });

  const value = useMemo(() => {
    return {
      setRemoveModel,
      columns,
      columnsBacktesting,
      dataSource,
      removeModel,
      reportId,
      showWarning,
      setShowWarning,
      detailsOfStrategy,
      tableData,
      handleCheckboxChange,
      toggleFeature,
      handleUpdate,
      deleteRequestReports,
    };
  }, [
    setRemoveModel,
    columns,
    columnsBacktesting,
    dataSource,
    removeModel,
    reportId,
    showWarning,
    setShowWarning,
    detailsOfStrategy,
    tableData,
    handleCheckboxChange,
    toggleFeature,
    handleUpdate,
    deleteRequestReports,
  ]);

  return value;

  // return {
  //   setRemoveModel,
  //   columns,
  //   dataSource,
  //   removeModel,
  //   reportId,
  //   showWarning,
  //   setShowWarning,
  //   detailsOfStrategy,
  // };
}

export default useDesktopStrategyTable;
