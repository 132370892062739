import React from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Tooltip from '../components/Common/Tooltip';
import { useDispatch } from 'react-redux';
import { showPlansModal } from '../features/profile/profilePlanSlice';
import PremiumIcon from '../components/Common/PremiumIcon';

function PremiumBadge() {
  const dispatch = useDispatch();
  return (
    <div className="flex items-center gap-1.5">
      <div
        className="flex items-center bg-blue-2/15 text-blue-2 px-1.5 py-0.5 rounded-md font-semibold text-xs cursor-pointer "
        onClick={() => dispatch(showPlansModal(true))}
      >
        Premium <PremiumIcon />
      </div>
      <Tooltip title={'Please upgrade your account for premium features.'}>
        <AiOutlineQuestionCircle />
      </Tooltip>
    </div>
  );
}

export default PremiumBadge;
