import React from 'react';
import { Col, Row } from 'react-bootstrap';
import StrategyViewCard from './StrategyViewCard';
import { RiTimerLine } from 'react-icons/ri';
import { HiOutlineInformationCircle } from 'react-icons/hi2';
import { afterBorderRight } from '../../utils/defaultClasses';
import { AiOutlineInfoCircle } from 'react-icons/ai';

function StrategyOverview({ detailsOfStrategy }) {
  return (
    <>
      <div>
        <div className="p-10">
          <Row>
            <Col lg={6} className={`${afterBorderRight} relative`}>
              <div className="flex flex-col gap-3 pr-6">
                <h3 className="text-xl">{detailsOfStrategy?.description}</h3>
                <p className="text-color-secondary text-sm">
                  The Price Action helps to predict the future of the market
                  with a very high and reliable degree of accuracy, giving an
                  opportunity to the traders to develop a profitable trading
                  strategy. Price action trading shifts the focus from just the
                  indicators to the more reliable movements of the price.
                  Combined with Exponential Moving Average (EMA): The EMA is a
                  moving average that places a greater weight and significance
                  on the most recent data points. Like all moving averages, this
                  technical indicator is used to produce buy and sell signals
                  based on crossovers and divergences from the historical
                  average. Compare a pair of simple moving averages with each
                  covering different time frames. If a shorter-term simple
                  moving average is above RSI, an uptrend is expected. On the
                  other hand, if the RSI is above a shorter-term average then a
                  downtrend might be the expected outcome.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="pl-10">
                <div className="flex items-center ">
                  <div className="flex gap-2 w-full max-w-[250px] items-center border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden p-3.5">
                    <div>
                      <div>
                        <span className="text-color-primary font-semibold flex items-center gap-1 text-2xl">
                          12
                        </span>
                        <span className="text-color-secondary text-sm flex items-center gap-1 -mt-1">
                          <span className="text-color-secondary/80">
                            <span>EMA PERIOD</span>
                          </span>
                          <span>
                            <AiOutlineInfoCircle className="text-xl" />
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
export default React.memo(StrategyOverview);
