import axios from 'axios';
import config from '../../Config/config';
import { apiHeader } from '../../utils/utils';

export async function getPaymentList({ queryKey }) {
  const { form_header } = apiHeader();
  const [_key, { id, page }] = queryKey;
  const res = await axios.get(
    `${config.base_url}${config.payment_list}?page=${page}&limit=10&user_id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );

  return res.data;
}

export async function getActivePlans() {
  const res = await axios.get(`${config.base_url}${config.user_active_plan}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res.data;
}
