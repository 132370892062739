import React, { useState } from 'react';
import Chart from 'chart.js/auto';
import { CategoryScale } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';

Chart.register(CategoryScale);
export default function BacktestingReportTab({ data }) {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  React.useEffect(() => {
    setChartData({
      labels: data?.[0]?.mt_report?.equity_history?.map((dat) => dat?.[0]),
      datasets: [
        {
          type: 'line',
          label: 'Users Gained ',
          data: data?.[0]?.mt_report?.equity_history?.map((dat) => dat?.[1]),
          // backgroundColor: [
          //   'rgba(75,192,192,1)',
          //   '#ecf0f1',
          //   '#f0331a',
          //   '#f3ba2f',
          //   '#2a71d0',
          // ],
          // borderColor: 'black',
          borderColor: '#36A2EB',
          backgroundColor: '#9BD0F5',
          borderWidth: 1,
          fill: false,
          tension: 0.1,
        },
      ],
    });
  }, [data]);

  return (
    <>
      <div className="space-y-12">
        {/* <div>
          <DataCard />
        </div> */}
        <div className="flex justify-center flex-col items-center">
          <div className="w-full">
            <div className="p-6">
              <Row>
                {Object?.keys(data?.[0]?.mt_report || {}).length > 0 ? (
                  <Col lg={12}>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2.5">
                      {Object.keys(data?.[0]?.mt_report).map((item, index) => {
                        if (item !== 'equity_history') {
                          return (
                            <div
                              key={index}
                              className="flex flex-col gap-0 border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden py-1.5 px-2.5"
                            >
                              <span className="text-color-secondary/80 flex items-center gap-1 text-sm">
                                {item.toUpperCase()}
                              </span>
                              <span className="text-color-secondary text-sm flex items-center gap-1">
                                <span className=" text-color-primary font-semibold">
                                  <span
                                    className={`${
                                      Math.sign(data?.[0]?.mt_report[item]) < 0
                                        ? 'text-red-3'
                                        : 'text-green-3'
                                    }`}
                                  >
                                    {`${data?.[0]?.mt_report[item]}`.toUpperCase()}
                                  </span>
                                </span>
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
          <div className="w-full">
            <LineChart chartData={chartData} />
          </div>
        </div>
      </div>
    </>
  );
}
export function LineChart({ chartData }) {
  return (
    <div className="chart-container">
      {/* <h2 style={{ textAlign: 'center' }}>Line Chart</h2> */}
      <Line
        data={chartData}
        options={{
          plugins: {
            // title: {
            //   display: true,
            //   text: 'Users Gained between 2016-2020',
            // },
            legend: {
              display: false,
            },
          },
        }}
      />
    </div>
  );
}
export const Data = [
  {
    id: 1,
    year: 2016,
    userGain: 80000,
    userLost: 823,
  },
  {
    id: 2,
    year: 2017,
    userGain: 45677,
    userLost: 345,
  },
  {
    id: 3,
    year: 2018,
    userGain: 78888,
    userLost: 555,
  },
  {
    id: 4,
    year: 2019,
    userGain: 90000,
    userLost: 4555,
  },
  {
    id: 5,
    year: 2020,
    userGain: 4300,
    userLost: 234,
  },
];

export function DataCard() {
  const cardData = [
    {
      id: 1,
      title: 2016,
      value: 80000,
    },
    {
      id: 2,
      year: 2017,
      userGain: 45677,
    },
    {
      id: 3,
      year: 2018,
      userGain: 78888,
    },
    {
      id: 4,
      year: 2019,
      userGain: 90000,
    },
    {
      id: 5,
      year: 2020,
      userGain: 4300,
    },
  ];
  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-2.5">
        {Object.keys(cardData).map((item, index) => (
          <div className="flex flex-col gap-0 border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden py-1.5 px-2.5">
            <span className="text-color-secondary/80 flex items-center gap-1 text-sm">
              Total Net Profit
            </span>
            <span className="text-color-secondary text-sm flex items-center gap-1">
              <span className=" text-color-primary font-semibold">
                <span>171000</span>
              </span>
            </span>
          </div>
        ))}
      </div>
    </>
  );
}
