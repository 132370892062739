import { useState } from 'react';
import { Table, Input, Form } from 'antd';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../components/utils/defaultClasses';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import CreateTicketDrawer from './CreateTicketDrawer';
import { RiSearchLine } from 'react-icons/ri';
import useTicketSupport from './hooks/useTicketSupport';
import { DataLoader } from '../../components/Common/DataLoader';
import DeleteConfirmation from '../../components/Common/DeleteConfirmation';

// const suffix = (
//   <AudioOutlined
//     style={{
//       fontSize: 16,
//       color: '#1677ff',
//     }}
//   />
// );

function TicketList() {
  const selectionType = 'checkbox';

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [searchForm] = Form.useForm();

  const {
    refetch,
    isLoading,
    pagination,
    datasource,
    columns,
    rowSelection,
    selectedRowKey,
    deleteTicket,
    onCloseDeleteTicket,
    onSubmitdeleteTicket,
    setDeleteTicket,
  } = useTicketSupport();

  console.log('deleteTicket', deleteTicket);

  const { page, setPage, limit, setLimit, setSearch } = pagination;

  return (
    <div className="d-flex left__bar row" style={{ marginRight: '0px' }}>
      <div className="row px-0 mb-row">
        <div className="px-0 mb-3.5">
          <div className="flex gap-2">
            <div className="">
              <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 512 512"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="M366.05 146a46.7 46.7 0 0 1-2.42-63.42 3.87 3.87 0 0 0-.22-5.26l-44.13-44.18a3.89 3.89 0 0 0-5.5 0l-70.34 70.34a23.62 23.62 0 0 0-5.71 9.24 23.66 23.66 0 0 1-14.95 15 23.7 23.7 0 0 0-9.25 5.71L33.14 313.78a3.89 3.89 0 0 0 0 5.5l44.13 44.13a3.87 3.87 0 0 0 5.26.22 46.69 46.69 0 0 1 65.84 65.84 3.87 3.87 0 0 0 .22 5.26l44.13 44.13a3.89 3.89 0 0 0 5.5 0l180.4-180.39a23.7 23.7 0 0 0 5.71-9.25 23.66 23.66 0 0 1 14.95-15 23.62 23.62 0 0 0 9.24-5.71l70.34-70.34a3.89 3.89 0 0 0 0-5.5l-44.13-44.13a3.87 3.87 0 0 0-5.26-.22 46.7 46.7 0 0 1-63.42-2.32z"
                  ></path>
                  <path
                    fill="none"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    stroke-width="32"
                    d="m250.5 140.44-16.51-16.51m60.53 60.53-11.01-11m55.03 55.03-11-11.01m60.53 60.53-16.51-16.51"
                  ></path>
                </svg>
              </span>
            </div>
            <div className="flex flex-row justify-between w-full">
              <div>
                <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                  List of Tickets
                </h4>
                <span className="flex text-xs text-color-secondary/70">
                  Manage and monitor all your support tickets.
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-row w-full justify-between mt-4 items-center">
            <div>
              <div className={`relative max-w-[220px]`}>
                <Form className="mt-3.5" layout="vertical" form={searchForm}>
                  <Form.Item
                    name="search"
                    className="mb-0"
                    validateDebounce={1000}
                    rules={[
                      ({ getFieldValue }) => ({
                        validator: (_, value) => {
                          setSearch(value);
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <Input
                      allowClear
                      placeholder="Search"
                      // onChange={onSearch}
                      // onPressEnter={onSearch}
                      className={`${formClass} min-h-[30px]`}
                      // onSearch={() => setExpandSearch(!expandSearch)}
                      suffix={
                        <>
                          <span className="">
                            <RiSearchLine />
                          </span>
                        </>
                      }
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <button
                className={`${btnPrimaryClass} text-xs`}
                onClick={showDrawer}
              >
                <PlusOutlined />
                Create Ticket
              </button>
              <button
                className={`${btnSecondayClass} py-1.5 w-[31px] h-[31px]`}
                disabled={selectedRowKey?.length === 0}
                onClick={() => setDeleteTicket(true)}
              >
                <DeleteOutlined />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="row px-0 mb-row">
        <Table
          className="px-0"
          columns={columns}
          dataSource={datasource}
          pagination={
            pagination?.total > 10
              ? {
                  total: pagination?.total,
                  current: page,
                  onChange: (page, pageSize) => {
                    setPage(page);
                    setLimit(pageSize);
                  },
                  // pageSizeOptions: pageSizeOptions,
                  pageSize: limit,
                  position: ['bottomRight'],
                }
              : false
          }
          loading={{
            indicator: (
              <div className=" w-full h-full min-w-[300px] absolute left-1/2 -translate-x-1/2">
                <DataLoader message={'Loading Tickets...'} />
              </div>
            ),
            spinning: !datasource || isLoading,
          }}
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
        />
      </div>

      {open && (
        <CreateTicketDrawer onClose={onClose} open={open} refetch={refetch} />
      )}

      {deleteTicket && (
        <DeleteConfirmation
          props={{
            showModal: deleteTicket,
            hideModal: onCloseDeleteTicket,
            onSubmitDelete: onSubmitdeleteTicket,
          }}
        />
      )}
    </div>
  );
}

export default TicketList;
