import React, { useEffect } from 'react';
import StatusStrips from '../../Common/StatusStrips';

function ReportShowStrip({
  tab,
  reportData,
  setHeaderTopTape = () => {},
  headerTopTape,
  strategyData,
}) {
  useEffect(() => {
    if (
      !reportData?.request_data?.status &&
      !localStorage.getItem('reportTopStrip') &&
      reportData?.request_data
    ) {
      setHeaderTopTape({ ...headerTopTape, top: true });
    } else if (
      reportData?.request_data?.status &&
      !localStorage.getItem('reportMiddleStrip') &&
      reportData?.request_data
    ) {
      setHeaderTopTape({ ...headerTopTape, top: true, middle: true });
    }
  }, [reportData]);

  // console.log(headerTopTape);

  useEffect(() => {
    if (
      strategyData?.name &&
      !strategyData?.subscribe_by_user &&
      !localStorage.getItem('reportfirstTopStrip')
    ) {
      // console.log('called');
      setHeaderTopTape({ ...headerTopTape, firstTop: true });
    }
  }, [strategyData]);

  return (
    <>
      {headerTopTape?.firstTop && (
        <div className="row mt-2 px-0">
          <div className="col-12 px-0">
            <div className="mb-2">
              <StatusStrips
                status="alert"
                title="Alert Warning"
                closeIcon={{
                  show: true,
                  click: () => {
                    setHeaderTopTape((prevHeaderTopTape) => ({
                      ...prevHeaderTopTape,
                      FirstTop: false,
                    }));
                    localStorage.setItem('reportfirstTopStrip', true);
                  },
                }}
                description={`Look like you are no longer subscribe with strategy, so the  ${
                  tab === 'VT' ? 'virtual' : 'live'
                } might not be running Your is inactive.`}
              />
            </div>
          </div>
        </div>
      )}
      {headerTopTape?.top && (
        <div className="row mt-2 px-0">
          <div className="col-12 px-0">
            <div className="mb-2">
              <StatusStrips
                status="warning"
                title="Warning Alarm"
                closeIcon={{
                  show: true,
                  click: () => {
                    setHeaderTopTape((prevHeaderTopTape) => ({
                      ...prevHeaderTopTape,
                      top: false,
                    }));
                    localStorage.setItem('reportTopStrip', true);
                  },
                }}
                description={`Your ${
                  tab === 'VT' ? 'virtual' : 'live'
                } is inactive.please activate the ${
                  tab === 'VT' ? 'virtual' : 'live'
                } first to make sure the continous reports of the market`}
              />
            </div>
          </div>
        </div>
      )}
      {headerTopTape?.middle && (
        <div className="row mt-2 px-0">
          <div className="col-12 px-0">
            <div className="mb-2">
              <StatusStrips
                status="exclusive"
                title="Exclusive Feature"
                closeIcon={{
                  show: true,
                  click: () => {
                    setHeaderTopTape((prevHeaderTopTape) => ({
                      ...prevHeaderTopTape,
                      middle: false,
                    }));
                    localStorage.setItem('reportMiddleStrip', true);
                  },
                }}
                description={`Hey user.we have an feature to pause and play your ${
                  tab === 'VT' ? 'virtual' : 'live'
                } tarde setup during the market hour as per your need`}
              />
            </div>
          </div>
        </div>
      )}
      {headerTopTape?.topLast && (
        <div className="row mt-2 px-0">
          <div className="col-12 px-0">
            <div className="mb-2">
              <StatusStrips
                status="update"
                title="Important Update"
                closeIcon={{
                  show: true,
                  click: () => {
                    setHeaderTopTape((prevHeaderTopTape) => ({
                      ...prevHeaderTopTape,
                      topLast: false,
                    }));
                    localStorage.setItem('reportTopLastStrip', true);
                  },
                }}
                description={`If you have pause ${
                  tab === 'VT' ? 'virtual' : 'live'
                } trade setup. it will be only working for during a day market hour,next market hour it will be auto resume`}
              />
            </div>
          </div>
        </div>
      )}
      {headerTopTape?.bottom && (
        <div className="row mt-2 px-0">
          <div className="col-12 px-0">
            <div className="mb-2">
              <StatusStrips
                status="update"
                title="Success Update"
                closeIcon={{
                  show: true,
                  click: () => {
                    setHeaderTopTape((prevHeaderTopTape) => ({
                      ...prevHeaderTopTape,
                      bottom: false,
                    }));
                    localStorage.setItem('reportBottomStrip', true);
                  },
                }}
                description="Your edit is been successfully updated and will be update soon.chnages will be reflected during the next day working hour of market"
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(ReportShowStrip);
