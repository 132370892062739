import { DownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import React from 'react';
import Drawer from '../../Common/Drawer';

function UnderProcessDrawer({
  underProgress,
  setUnderProgress,
  progressDetails,
}) {
  // console.log('progressDetails', progressDetails);
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Inter',
        },
      }}
    >
      <Drawer
        title="Strategy View"
        onClose={setUnderProgress}
        open={underProgress}
      >
        <div className="flex flex-col gap-2.5">
          <div className="bg-orange-100 p-2 rounded-lg text-orange-500 text-center bg-opacity-60 !text-[13px] border-dashed border-[1px] border-orange-400 flex flex-col items-center gap-1">
            <InfoCircleOutlined className="text-xl" />
            Strategy is in Under Process.
          </div>

          <div className="shadow-md rounded-md border-[1px] border-zinc-200">
            <p className="font-semibold !text-[12px] p-2 bg-zinc-100">
              Strategy Name
            </p>
            <p className="text-zinc-600 p-2">
              {progressDetails?.name || 'Not Available'}
            </p>
          </div>

          <div className="shadow-md rounded-md border-[1px] border-zinc-200">
            <p className="font-semibold p-2 !text-[12px] bg-zinc-100">
              Instrument Name
            </p>
            <p className="text-zinc-600 p-2">
              {progressDetails?.instrument || 'Not Available'}
            </p>
          </div>

          <div className="shadow-md rounded-md border-[1px] border-zinc-200">
            <p className="font-semibold p-2 !text-[12px] bg-zinc-100">
              Minimum Capital (₹)
            </p>
            <p className="text-zinc-600 p-2">
              {progressDetails?.min_amount_required || 0}
            </p>
          </div>

          <a
            className="p-2.5 bg-color-primaryColor1/10 rounded-lg border-[1px] border-color-primaryColor1 flex justify-between gap-3"
            href={progressDetails?.document_link}
          >
            <div className="flex gap-2">
              <div className="h-[35px] w-[35px] rounded-md overflow-hidden bg-color-primaryColor1 flex justify-center items-center p-1.5 text-xs text-whiteNew-0">
                DOC
              </div>
              <div className="flex flex-col gap-0 -space-y-1">
                <span className="flex font-medium text-color-primary text-sm">
                  <span className="flex items-center gap-1.5">Options.doc</span>
                </span>
                <span className="flex text-xs text-color-secondary">29 Kb</span>
              </div>
            </div>
            <div>
              <button className="p-1.5 text-blue-2">
                <DownloadOutlined className="text-xl" />
              </button>
            </div>
          </a>

          {/* <div>
            <div className="p-2.5 bg-color-primaryColor1/10 rounded-lg border-[1px] border-color-primaryColor1 flex justify-between gap-3">
              <div className="flex gap-2">
                <div className="h-[35px] w-[35px] rounded-md overflow-hidden bg-color-primaryColor1 flex justify-center items-center p-1.5 text-xs text-whiteNew-0">
                  PDF
                </div>
                <div className="flex flex-col gap-0 -space-y-1">
                  <span className="flex font-medium text-color-primary text-sm">
                    <span className="flex items-center gap-1.5">
                      options.pdf
                    </span>
                  </span>
                  <span className="flex text-xs text-color-secondary">
                    29 Kb
                  </span>
                </div>
              </div>
              <div>
                <button className="p-1.5 text-blue-2">
                  <DownloadOutlined className="text-xl" />
                </button>
              </div>
            </div>
          </div> */}
        </div>
      </Drawer>
    </ConfigProvider>
  );
}

export default UnderProcessDrawer;
