import React from 'react';
import '../components/Backtesting.css';
import LiveReportInputs from './LiveReportInputs';
import VirtualReportInputs from './VirtualReportInputs';
import BacktestingReportInputs from './BacktestingReportInputs';

function ReportDrawerInputs({
  inputs,
  modalData,
  brokerId,
  strategyID,
  upDateData,
  configData,
  reportName,
  setHeaderTopTape = () => {},
  reportData,
  strategyData,
}) {
  return (
    <div className="row">
      <div className="col-12">
        {modalData === 'live' ? (
          <LiveReportInputs
            inputs={inputs}
            brokerId={brokerId}
            strategyID={strategyID}
            upDateData={upDateData}
            configData={configData}
            setHeaderTopTape={setHeaderTopTape}
            reportName={reportName}
            reportData={reportData}
          />
        ) : modalData === 'virtual' ? (
          <VirtualReportInputs
            inputs={inputs}
            strategyID={strategyID}
            upDateData={upDateData}
            configData={configData}
            setHeaderTopTape={setHeaderTopTape}
            reportName={reportName}
            reportData={reportData}
            strategyData={strategyData}
          />
        ) : (
          <BacktestingReportInputs
            inputs={inputs}
            strategyID={strategyID}
            upDateData={upDateData}
            configData={configData}
            setHeaderTopTape={setHeaderTopTape}
            reportName={reportName}
            reportData={reportData}
            strategyData={strategyData}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(ReportDrawerInputs);
