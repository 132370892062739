import { useMutation } from '@tanstack/react-query';
import {
  addStrategySubscription,
  removeStrategySubscription,
} from '../../API/strategy/startegyMainPage';
// import usePlanDetails from './usePlanDetails';
import useStrategyList from './useStrategyList';
import { toast } from 'react-toastify';

function useSubscribeStrategy(handleOnSuccess) {
  // const { refetch: activePlanFetch } = usePlanDetails();

  const remove_subscription = useMutation({
    mutationFn: (payload) => removeStrategySubscription(payload),
    onSuccess: async (res) => {
      // strategyRefetch();
      handleOnSuccess('unsubscribe');
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const add_subscription = useMutation({
    mutationFn: (payload) => addStrategySubscription(payload),
    onSuccess: async (res) => {
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      // strategyRefetch();
      handleOnSuccess('subscribe');
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });
  return {
    remove_subscription: remove_subscription.mutate,
    add_subscription: add_subscription.mutate,
  };
}

export default useSubscribeStrategy;
