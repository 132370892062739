import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import dayjs from 'dayjs';
import moment from 'moment';

export default function BacktestingOverview({ backtestData }) {
  const [data, setData] = useState({});
  useEffect(() => {
    if (backtestData?.bt_inputs) {
      var mainConfig = {};
      Object.keys(
        backtestData?.bt_inputs ? backtestData?.bt_inputs : {},
      ).forEach((key) => {
        if (key === 'dates') {
          mainConfig['START DATE'] = dayjs(
            backtestData?.bt_inputs[key][0],
          ).format('DD-MM-YYYY');
          mainConfig['END DATE'] = moment(
            backtestData?.bt_inputs[key][1],
            'DD-MM-YYYY hh:mm:ss',
          ).format('DD-MM-YYYY');
        } else {
          mainConfig[key] = backtestData?.bt_inputs[key];
        }
      });

      setData(mainConfig);
    }
  }, [backtestData]);

  return (
    <>
      <div>
        <div className="p-6">
          <Row>
            {Object.keys(data).length > 0 ? (
              <Col lg={12}>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2.5">
                  {Object.keys(data).map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-col gap-0 border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden py-1.5 px-2.5"
                    >
                      <span className="text-color-secondary/80 flex items-center gap-1 text-sm">
                        {item.toUpperCase()}
                      </span>
                      <span className="text-color-secondary text-sm flex items-center gap-1">
                        <span className=" text-color-primary font-semibold text-wrap">
                          <span className="flex">
                            {`${data[item]}`.toUpperCase()}
                          </span>
                        </span>
                      </span>
                    </div>
                  ))}
                </div>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </>
  );
}
