import axios from 'axios';
import config from '../../Config/config';
import { apiHeader } from '../../utils/utils';

const { json_header } = apiHeader();

export async function getBackTestReport({ queryKey }) {
  const [_key, { id }] = queryKey;
  const res = await axios.get(`${config.base_url}${config.get_request}/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  return res?.data;
}
