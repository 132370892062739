import React, { useEffect, useMemo, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { btnPrimaryClass } from '../utils/defaultClasses';
export default function StatusStrips({
  status = 'success',
  title = 'IMPORTANT UPDATE',
  closeIcon,
  sideBtn = null,
  renewBtn = null,
  description = `
            Activate your 'pending' and 'halted' subscriptions by updating
            payment methods!`,
  knowMoreLink = null,
}) {
  const [dataStatus, setDataStatus] = useState([
    {
      bg1: 'text-blue-2',
      bg2: 'from-blue-3 via-blue-3 to-blue-2',
    },
  ]);
  // const [showStrip, setShowStrip] = useState(false);
  useEffect(() => {
    switch (status) {
      case 'warning':
        setDataStatus({
          bg1: 'text-red-2',
          bg2: 'from-red-3 via-red-3 to-red-2',
        });
        break;
      case 'update':
        setDataStatus({
          bg1: 'text-orange-400',
          bg2: 'from-orange-500 via-orange-500 to-orange-400',
        });
        break;
      case 'success':
        setDataStatus({
          bg1: 'text-green-2',
          bg2: 'from-green-3 via-green-3 to-green-2',
        });
        break;
      case 'alert':
        setDataStatus({
          bg1: 'text-[#FFC300]',
          bg2: 'from-[#F08A0A] via-[#F08A0A] to-[#FFC300]',
        });
        break;
      default:
        setDataStatus({
          bg1: 'text-blue-2',
          bg2: 'from-blue-3 via-blue-3 to-blue-2',
        });
    }
  }, [status]);
  //   console.log(dataStatus, status, 'ShowError');
  //   const showStripCompo = () => {
  //     setTimeout(() => {}, delay);
  //   };
  //   const content = useMemo(showStripCompo(), [showStrip, delay]);
  return (
    <>
      <div className="anouncementStrip flex flex-col lg:flex-row bg-color-base-1 relative lg:h-[50px] shadow rounded-[5px] items-center overflow-hidden">
        <div
          className={`stripTitle h-full w-full lg:w-auto text-base font-semibold rounded-tl-sm rounded-bl-sm relative table-cell align-middle lg:pr-[50px] shrink-0 before:hidden lg:before:block after:hidden lg:after:block ${dataStatus?.bg1}`}
        >
          {/*
                Important Updates : text-orange-400
                Warning Alarm : text-red-2
                Exclusive Features : text-blue-2
                Success Updates : text-green-2
                Alert Announcement : text-[#FFC300]
            */}
          <div
            className={`h-full flex lg:justify-center items-center py-1.5 lg:py-0 pl-[15px] text-whiteNew-0 relative z-[1] bg-gradient-to-r bg-gradient-[90deg] ${dataStatus?.bg2}`}
          >
            {/*
                Important Updates : from-orange-500 via-orange-500 to-orange-400
                Warning Alarm : from-red-3 via-red-3 to-red-2
                Exclusive Features : from-blue-3 via-blue-3 to-blue-2
                Success Updates : from-green-3 via-green-3 to-green-2
                Alert Announcement : from-[#F08A0A] via-[#F08A0A] to-[#FFC300]
            */}
            <span className="relative z-[1]">{title}</span>
          </div>
        </div>
        <div className="w-full px-[15px] py-1.5 lg:p-0 flex gap-1">
          <span>
            {description}
            {/* Activate your 'pending' and 'halted' subscriptions by updating
            payment methods! */}
          </span>
          {knowMoreLink?.show && (
            <span
              className="text-blue-3 hover:underline font-semibold cursor-pointer"
              onClick={knowMoreLink?.click}
            >
              {knowMoreLink?.name}
            </span>
          )}
        </div>
        <div className="flex gap-1 justify-center shrink-0 mr-7">
          {renewBtn?.show && (
            <div className="shrink-0">
              <button
                className={`${btnPrimaryClass}`}
                onClick={renewBtn?.click}
              >
                renew plan
              </button>
            </div>
          )}
          {sideBtn?.show && (
            <div className="shrink-0">
              <button className={`${btnPrimaryClass}`} onClick={sideBtn?.click}>
                Upgrade plan
              </button>
            </div>
          )}
        </div>

        {closeIcon?.show && (
          <div className="shrink-0 lg:pr-[15px] text-whiteNew-0 lg:text-color-primary absolute z-[1] top-1.5 right-1.5 lg:relative lg:top-0 lg:right-0">
            <button className="flex justify-center items-center w-[25px] h-[25px] rounded-md">
              <MdClose onClick={closeIcon?.click} />
            </button>
          </div>
        )}
      </div>
    </>
  );
}
