import { useCallback } from 'react';
import {
  chartSocket,
  priceSocket,
  tradeSocket,
  valueSocket,
} from '../../context/socket';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { logoutApi } from '../helpers/api/Auth/auth';
import { resetStore } from '../../app/reduxReset';
import { useDispatch } from 'react-redux';

function useLogout() {
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const userid = mydecodedTokenFunction(token);
  const queryClient = useQueryClient();
  var dispatch = useDispatch();

  const insertNote = useCallback((userid) => {
    return logoutApi(userid);
  }, []);

  const logoutMutation = useMutation({
    mutationFn: insertNote,
    onSuccess: () => {
      localStorage.clear();
      navigate('/');
      queryClient.clear();
      queryClient.invalidateQueries();
      dispatch(resetStore());
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });
  const logoutUser = () => {
    priceSocket.disconnect();
    chartSocket.disconnect();
    tradeSocket.disconnect();
    valueSocket.disconnect();
    logoutMutation.mutate(userid);
  };

  return { logoutUser, isLoading: logoutMutation?.isPending };
}

export default useLogout;
