import React from 'react';
import { btnSecondayClass } from '../utils/defaultClasses';
import { MdOutlineRefresh } from 'react-icons/md';

export default function RefreshButton({ onClick }) {
  return (
    <>
      <div className={`${btnSecondayClass}`} onClick={onClick}>
        <MdOutlineRefresh />
        Refresh
      </div>
    </>
  );
}
