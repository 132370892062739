import { useDispatch, useSelector } from 'react-redux';
import SubscriptionUpgradeSteps from './SubscriptionUpgradeSteps';
import { userNewPlan } from '../../../features/update-plan/UpdatePlanSlice';
import { useEffect } from 'react';

export default function SubscriptionUpgrade() {
  const { userChangedPlan } = useSelector((state) => state.toUpdatePlan);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!userChangedPlan || Object.keys(userChangedPlan).length === 0) {
      const newPlanJSON = localStorage.getItem('new_plan');
      if (newPlanJSON) {
        const newPlan = JSON.parse(newPlanJSON);

        dispatch(userNewPlan(newPlan));
      }
    }
  }, [userChangedPlan]);
  return (
    <>
      <div className="left__bar">
        <div className="container mb-5">
          <div>
            <div className="user__profile mb-3">
              <h2 className="strtgy__ttl">
                Subscribe for Winray Labs in 4 easy steps
              </h2>
            </div>
            <div>
              <SubscriptionUpgradeSteps />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
