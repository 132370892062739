import axios from "axios";
import config from "../../Config/config";

export async function getStrategyLogs({ queryKey }) {

    const [_key, { userid, id }] = queryKey;
    const res = await axios.get(
        `${config.base_url}${config.strategy_logs}?user_id=${userid.id}&strategy_id=${id}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        },
    );
    return res.data;
}