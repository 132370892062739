import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PaymentAddCard } from './PaymentCard';
import AddCardModal from './AddCardModal';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addpaymentsCards,
  deleteUserpaymentCard,
  getUserpaymentCards,
  updateUserpaymentCard,
} from '../../../API/profile/CardsApi';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../../../components/utils/defaultClasses';
import { Image, Modal } from 'antd';
import NoCardsAvailable from './NoCardsAvailable';
import AlertModal from '../../../components/Common/AlertModal';
import useSaveCard from '../../../components/hooks/useSaveCard';

function PaymentMethod() {
  // const { paymentCardList, getCards } = useSaveCard();

  //  const getUserCardsList = useMutation({
  //    mutationFn: getUserpaymentCards,
  //    onSuccess: (res) => {
  //      setPaymentCardlist(res?.data?.data);
  //    },
  //    onError: (err) => {
  //      toast.success(err?.data?.message, {
  //        position: toast.POSITION.BOTTOM_RIGHT,
  //        autoClose: 1000,
  //      });
  //    },
  //  });

  const { data: paymentCardList, refetch: cardsListFetch } = useQuery({
    queryKey: ['payCardsList'],
    queryFn: getUserpaymentCards,
    refetchOnMount: 'true',
    refetchOnWindowFocus: false,
  });

  // console.log('paymentCardList', paymentCardList);

  const [isAddCard, setIsAddCard] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteCardId, setDeleteCardId] = useState('');
  const [formData, setFormData] = useState({
    card_name: '',
    card_number: '',
    expiration_month: null,
    expiration_year: null,
    cvv: '',
    card_type: '',
  });
  const [editCardId, setEditCardId] = useState('');
  const [isEdit, setIsEdit] = useState(false);

  const deleteUserCard = useMutation({
    mutationFn: () => deleteUserpaymentCard(deleteCardId),
    onSuccess: (res) => {
      cardsListFetch();
      toast.success(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    },
    onError: (err) => {},
  });

  const handleDelete = (id) => {
    setDeleteCardId(id);
    setDeleteModal(true);
    // deleteUserCard.mutate(id)
  };

  const handleEdit = (value) => {
    // console.log(value?._id);
    setIsEdit(true);
    setEditCardId(value?._id);
    setFormData({
      card_name: value?.card_name,
      card_number: Number(value?.card_number),
      expiration_month: value?.expiration_date?.slice(3),
      expiration_year: Number('20' + value?.expiration_date?.slice(0, 2)),
      cvv: Number(value?.cvv),
      card_type: value?.card_type,
    });
    setIsAddCard(true);
  };

  const updateUser = useMutation({
    mutationFn: (payload) => updateUserpaymentCard(payload),
    onSuccess: (res) => {
      cardsListFetch();
      toast.success(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    },
    onError: (err) => {},
  });

  const addCardToList = useMutation({
    mutationFn: (payload) => addpaymentsCards(payload),
    onSuccess: (res) => {
      cardsListFetch();
      toast.success(res?.data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 1000,
      });
    },
    onError: (err) => {
      console.log('err', err);
    },
  });

  const handleSubmit = (values) => {
    if (isEdit) {
      let payload = {
        ...values,
        card_id: editCardId,
      };
      updateUser.mutate(payload);
    } else {
      addCardToList.mutate(values);
    }

    setIsEdit(false);
  };

  return (
    <>
      <div className="space-y-4">
        <div>
          <h3 className="text-lg font-medium">My Cards</h3>
        </div>
        {paymentCardList && paymentCardList.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
            {paymentCardList?.map((item) => (
              <div
                key={item?._id}
                className="flex items-center border-[1px] border-dashed border-level-2 rounded-xl overflow-hidden p-3.5"
              >
                <div className="flex justify-between gap-1.5 w-full">
                  <div className="flex flex-col gap-3.5">
                    <div className="flex items-center gap-1.5">
                      <div className="text-base font-semibold">
                        {item?.card_name}
                      </div>
                      {/* <span className="flex bg-green-2/20 text-green-3 rounded-md px-1.5 py-0.5 text-xs">
                <small>Primary</small>
              </span> */}
                    </div>
                    <div className="flex gap-2.5 items-center">
                      <div>
                        <Image
                          src="https://preview.keenthemes.com/metronic8/demo1/assets/media/svg/card-logos/visa.svg"
                          preview={false}
                        />
                      </div>
                      <div className="flex flex-col leading-tight">
                        <div className="text-base font-semibold">
                          {item?.card_type} ****{' '}
                          {item.card_number
                            .toString()
                            ?.slice(
                              item.card_number.toString().length - 4,
                              item.card_number.toString().length,
                            )}
                        </div>
                        <span className="text-xs text-color-secondary/70 font-normal">
                          Card expires at {item?.expiration_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-2 shrink-0">
                    <div>
                      <button
                        className={`${btnSecondayClass}`}
                        onClick={() => handleDelete(item._id)}
                      >
                        <small>Delete</small>
                      </button>
                    </div>
                    <div>
                      <button
                        className={`${btnSecondayClass}`}
                        onClick={() => handleEdit(item)}
                      >
                        <small>Edit</small>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <PaymentAddCard setIsAddCard={setIsAddCard} />
          </div>
        ) : (
          <NoCardsAvailable setIsAddCard={setIsAddCard} />
        )}
      </div>
      <AddCardModal
        isAddCard={isAddCard}
        setIsAddCard={setIsAddCard}
        formData={formData}
        handleSubmit={handleSubmit}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <AlertModal
        isAlert={deleteModal}
        setIsAlert={() => setDeleteModal(false)}
        secondaryButtonName={'Cancel'}
        primaryButtonName={'Delete'}
        secondaryButtonAction={() => setDeleteModal(false)}
        primaryButtonAction={() => {
          setDeleteModal(false);
          deleteUserCard.mutate();
        }}
        title={null}
        subtitile={'Are you sure want to remove the card??'}
      />
      {/* <LogoutModal open={logoutModal} close={setLogoutModal} /> */}

      {/* <Modal
        footer={null}
        open={deleteModal}
        onCancel={() => setDeleteModal(false)}
        centered
        title={<h3 className="">Delete</h3>}
      >
        <div>
          <p className="pb-4">Are you sure want to remove the card?</p>
          <div className="d-flex justify-start gap-2 align-items-center">
            <button
              onClick={() => setDeleteModal(false)}
              className={`${btnSecondayClass}`}
            >
              Cancel
            </button>
            <button
              className={`${btnPrimaryClass}`}
              onClick={() => {
                deleteUserCard.mutate();
                setDeleteModal(false);
              }}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal> */}
    </>
  );
}

export default React.memo(PaymentMethod);
