import axios from 'axios';
import config from '../../../../Config/config';
import mydecodedTokenFunction from '../../../../utils/decodetoken';
import { apiHeader } from '../../../../utils/utils';

export async function userSignUp(payload) {
  const res = await axios.post(`${config.base_url}${config.sign_up}`, payload);
  return res;
}

export async function userLogin(payload) {
  const res = await axios.post(`${config.base_url}${config.login}`, payload);
  return res;
}

export async function userForgetPassword(payload) {
  const res = await axios.post(
    `${config.base_url}${config.forgot_pass}`,
    payload,
  );
  return res;
}

export async function resendEmailVerifyLink(email) {
  const res = await axios.get(
    `${config.base_url}${config.verify_email}/${email}`,
  );
  return res;
}
export async function checkemailpassword(email) {
  const res = await axios.get(
    `${config.base_url}${config.Check_email_password}/${email}`,
  );
  return res;
}

export async function setPasswordLink(payload) {
  const res = await axios.post(`${config.base_url}${config.set_password_link}`, payload);
  return res;
}
export async function userResetPassword(payload, token) {
  const res = await axios.post(
    `${config.base_url}${config.reset_pass}/${token}`,
    payload,
  );
  return res;
}


export async function userSetPassword(payload, token) {
  const res = await axios.post(
    `${config.base_url}${config.set_pass}`,
    payload,
  );
  return res;
}

export async function checkEmail(email) {
  const res = await axios.get(
    `${config.base_url}${config.checkEmail}/${email}`,
  );
  return res;
}

export async function checkVerifiedUser(token, email) {
  const res = await axios.get(
    `${config.base_url}${config.checkVerifiedUser}/${token}?email=${email}`,
  );
  return res;
}

export async function get2FAOTPBYEmail(payload) {
  const res = await axios.post(
    `${config.base_url}${config.twoFactorAuth}`,
    payload,
  );
  return res;
}

export async function resend2FAOTPBYEmail(payload) {
  const res = await axios.post(
    `${config.base_url}${config.resendFactorAuth}`,
    payload,
  );
  return res;
}

export async function updateProfileImage(payload) {
  const { form_header } = apiHeader();

  const res = await axios.post(
    `${config.base_url}${config.update_Profile_Img}`,
    payload,
    form_header,
  );

  return res;
}

export async function logoutApi(payload) {
  const { json_header } = apiHeader();

  const res = await axios.post(
    `${config.base_url}${config.logOut}`,
    payload,
    json_header,
  );

  return res;
}



