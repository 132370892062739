import { tsvParse } from 'd3-dsv';
import { deviceDetect } from 'react-device-detect';
import { toast } from 'react-toastify';

function parseData(parse) {
  return function (d) {
    d.date = parse(d.date);
    d.open = +d.open;
    d.high = +d.high;
    d.low = +d.low;
    d.close = +d.close;
    d.volume = +d.volume;

    return d;
  };
}

export function getData() {
  const promiseIntraDayDiscontinuous = fetch(
    'https://cdn.rawgit.com/rrag/react-stockcharts/master/docs/data/MSFT_INTRA_DAY.tsv',
  )
    .then((response) => response.text())
    .then((data) =>
      tsvParse(
        data,
        parseData((d) => new Date(+d)),
      ),
    );
  return promiseIntraDayDiscontinuous;
}

export const EMAIL_REG =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;

export const toastSpecs = {
  position: toast.POSITION.TOP_CENTER,
  autoClose: 3000,
};

export function useUserDeviceLocation() {
  const deviceData = deviceDetect();
  return deviceData;
}

export function apiHeader() {
  let json_header = {
    headers: {
      'content-type': 'application/json',
    },
  };

  if (localStorage.getItem('token')) {
    json_header = {
      ...json_header,
      headers: {
        ...json_header?.headers,
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
  }

  const form_header = {
    headers: {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  // const json_header = {
  //   headers: {
  //     'content-type': 'application/json',
  //     Authorization: `Bearer ${localStorage.getItem('token')}`,
  //   },
  // };

  return { form_header, json_header };
}
const formatter = Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});

export function formatINR(price) {
  if (isNaN(price)) {
    return formatter.format(Number(0));
  } else {
    return formatter.format(price);
  }
}

export const FileDownloader = async (url) => {
  // URL of the file to be downloaded
  const fileUrl = url;
  const filename = url.split('/').pop();
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function truncate2(str, n) {
  return str?.substr(0, n) + '...' + str?.substr(-n);
}

export const removeDuplicates = (arrayWithDuplicates) => {
  const uniqueArray = arrayWithDuplicates.reduce((accumulator, current) => {
    if (!accumulator.some((item) => item._id === current._id)) {
      accumulator.push(current);
    }
    return accumulator;
  }, []);

  return uniqueArray.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
};

export async function getFileSize(url) {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentLength = response.headers.get('Content-Length');
    if (contentLength === null) {
      throw new Error('Content-Length header is missing');
    }

    const fileSizeInBytes = parseInt(contentLength, 10);
    return fileSizeInBytes;
  } catch (error) {
    console.error('Error fetching file size:', error);
  }
}
