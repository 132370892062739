import { Pagination, Table } from 'antd';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import NoDataFound from './NoDataFound';
import { MdEventNote } from 'react-icons/md';
import { DataLoader } from './DataLoader';
import NoDataFoundV2 from './NoDataFoundV2';
import strategy_img from '../../assets/images/strategy.svg';
// import { DataLoader } from './Loaders';
// import { usePageSize } from '../../hooks/usePageSize';
function TableComponent({
  columns,
  dataSource,
  pagination,
  bordered,
  total,
  currentPage,
  setCurrentPage = () => {},
  rowSelection,
  darkTheme,
  scroll,
  xScroll,
  yScroll,
  length = 0,
  custom = false,
  dataTablesRow,
  position = 'bottomRight',
  size = 'medium',
  onChange = () => {},
  showSorterTooltip,
  icon = <MdEventNote />,
  pageSize = 10,
  setPageSize = () => {},
  showSizeChanger = false,
  pageSizeOptions = [10, 25, 50, 100, 200],
  emptyDataText = '',
  expandable,
  method = 'transact',
  loaderText,
  components = undefined,
  from,
  style,
  loading,
  dynamicNoDataFound,
  noDataFoundHeight,
}) {
  //   const { updatePagination } = usePageSize();
  return (
    <>
      <div className="w-full">
        <div className="relative block w-full max-w-[100vw] card_table">
          <Table
            dataSource={dataSource}
            size={size}
            columns={columns}
            onChange={onChange}
            rowClassName={dataTablesRow ? dataTablesRow : 'dataTablesRow'}
            rowSelection={!rowSelection ? false : { ...rowSelection }}
            expandable={expandable}
            // loading={loading}
            pagination={
              !pagination
                ? false
                : {
                    total: total,
                    showSizeChanger: showSizeChanger,
                    current: currentPage,
                    onChange: (page, pageSize) => {
                      setCurrentPage(page);
                      setPageSize(pageSize);
                    },
                    pageSizeOptions: pageSizeOptions,
                    pageSize: pageSize,
                    position: [position],
                    onShowSizeChange: (page, pageSize) => {
                      const payload = {
                        type: from,
                        page_size: pageSize,
                      };
                    },
                  }
            }
            style={style}
            locale={{
              emptyText:
                dataSource && dataSource?.length === 0 && !loading ? (
                  <Row className="justify-content-center text-center">
                    <Col md={12} className="">
                      <NoDataFoundV2
                        title={
                          emptyDataText !== ''
                            ? emptyDataText
                            : 'No data found.'
                        }
                        icon={
                          <img
                            src={strategy_img}
                            alt="upgrade plan"
                            className="w-full h-full object-contain"
                          />
                        }
                        minHeight={`min-h-[200px] py-2 ${noDataFoundHeight}`}
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row className="justify-content-center text-center">
                    <Col md={12} className="">
                      {dynamicNoDataFound ? (
                        dynamicNoDataFound
                      ) : (
                        <>
                          <NoDataFoundV2
                            title={emptyDataText !== '' ? emptyDataText : ' '}
                            icon={<></>}
                            minHeight={`min-h-[200px] py-2 ${noDataFoundHeight}`}
                          />
                        </>
                      )}
                    </Col>
                  </Row>
                ),
            }}
            loading={{
              indicator: (
                <div className=" w-full h-full min-w-[300px] absolute left-1/2 -translate-x-1/2">
                  <DataLoader
                    message={loaderText ? loaderText : 'Loading Data'}
                  />
                </div>
              ),
              spinning: !dataSource || loading,
            }}
            // locale={{
            //   emptyText: dataSource?.length === 0 && (
            //     <Row className="justify-content-center text-center">
            //       <Col md={12} className="">
            //         <NoDataFoundV2
            //           title={
            //             emptyDataText !== '' ? emptyDataText : 'No data found.'
            //           }
            //           icon={icon}
            //           minHeight={'min-h-[200px] py-2'}
            //         />
            //       </Col>
            //     </Row>
            //   ),
            // }}
            // loading={{
            //   spinning: loading,
            //   indicator: (
            //     <div className=" w-full h-full min-w-[300px] absolute left-1/2 -translate-x-1/2">
            //       <DataLoader
            //         message={loaderText ? loaderText : 'Loading Data...'}
            //       />
            //     </div>
            //   ),
            // }}
            // loading={{
            //   indicator: (
            //     <div className=" w-full h-full min-w-[300px] absolute left-1/2 -translate-x-1/2">
            //       <DataLoader
            //         message={loaderText ? loaderText : 'Loading Data...'}
            //       />
            //     </div>
            //   ),
            //   spinning: !dataSource,
            // }}
            className="dataTables"
            bordered={bordered ? bordered : false}
            scroll={scroll}
            showSorterTooltip={showSorterTooltip}
            components={components}
          />
        </div>
        {custom && (
          <div className="w-full flex justify-end">
            <Pagination
              onChange={(page) => setCurrentPage(page)}
              total={length || 0}
              defaultCurrent={currentPage}
              pageSize={10} //! need to add it dynamically
            />
          </div>
        )}
      </div>
    </>
  );
}

export default React.memo(TableComponent);
