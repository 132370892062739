import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ModalInputFields from '../components/UI/ModalInputFields';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../components/utils/defaultClasses';
import { useMutation } from '@tanstack/react-query';
import {
  createBacktestingStrategyRequest,
  updateBacktestingStrategyRequest,
} from '../API/strategy/strategyPagePermissionApi';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import mydecodedTokenFunction from './decodetoken';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../components/Common/Drawer';
import {
  openBacktestingReportFields,
  openVirualReportFields,
} from '../features/strategyReport/strategyDataDetailSlice';
import NoDataFoundV2 from '../components/Common/NoDataFoundV2';
import { MdCheck, MdClose, MdOutlineEdit } from 'react-icons/md';
import { updateRequest } from '../API/strategy/strategyDetail';
import useBacktestReport from '../components/hooks/useBacktestReport';
import { useGetUserByIdQuery } from '../services/profileDetails';

function BacktestingReportInputs({
  inputs,
  upDateData = () => {},
  strategyID,
  configData,
  reportName,
  setHeaderTopTape = () => {},
  reportData,
  strategyData,
}) {
  const backtestingModalRef = useRef();
  let { id } = useParams();
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  const { backtestRefetch } = useBacktestReport();
  let location = window.location.pathname.split('/')[1];
  const { showBacktestingReportDrawer, detailsOfStrategy } = useSelector(
    (state) => state.completeStrategyDetails,
  );
  console.log('detailsOfStrategy: ', detailsOfStrategy);

  const [noChanges, setNoChanges] = useState(false);
  const [type, setType] = useState('save_and_draft');
  const [fieldsDisable, setFieldsDisable] = useState(false);
  var dispatch = useDispatch();
  const { data, error, isLoading } = useGetUserByIdQuery(userid.id);

  useEffect(() => {
    if (location !== 'strategy') {
      setFieldsDisable(true);
    } else {
      setFieldsDisable(false);
    }
  }, []);

  const mutation = useMutation({
    mutationFn: (payload) => createBacktestingStrategyRequest(payload),
    onSuccess: (res) => {
      backtestRefetch();
      setFieldsDisable(false);
      dispatch(openBacktestingReportFields(false));
      if (res.data.status) {
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const upDateReport = useMutation({
    mutationFn: (payload) => updateBacktestingStrategyRequest(payload, id),
    onSuccess: (res) => {
      if (res.data.status) {
        upDateData();
        setHeaderTopTape({
          top: false,
          middle: false,
          topLast: false,
          bottom: true,
        });
        setFieldsDisable(false);
        dispatch(openBacktestingReportFields(false));
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const backtestingReportSubmission = (values) => {
    console.log('values: ', values);
    console.log('type', type);
    let formatValues = { ...values };
    // Find all inputs that are of type 'time'
    let timeInputs = inputs.filter((item) => item.input_type === 'Time');
    // Format each time input value to 'HH:mm'
    timeInputs.forEach((item) => {
      if (values[item.value_name]) {
        formatValues[item.value_name] = values[item.value_name].format('HH:mm');
      }
    });
    // console.log(configData, formatValues);

    if (location === 'strategy') {
      var payload = {
        user_id: userid.id,
        strategy_id: strategyID,
        status: type === 'save_and_draft' ? 'Draft' : 'Pending',
        // current_status: type === 'save_and_draft' ? 'Draft' : 'Pending',
        bt_inputs: formatValues,
      };
      mutation.mutate(payload);
      backtestingModalRef.current.resetFields();
    } else {
      if (JSON.stringify(formatValues) === JSON.stringify(configData)) {
        setNoChanges(true);
      } else {
        var backtest_payload = {
          user_id: userid.id,
          strategy_id: strategyID,
          status: type === 'save_and_draft' ? 'Draft' : 'Pending',
          // current_status: type === 'save_and_draft' ? 'Draft' : 'Pending',
          bt_inputs: formatValues,
        };
        upDateReport.mutate(backtest_payload);
        setNoChanges(false);
      }
    }
  };

  const getFooter = useMemo(() => {
    if ((inputs && inputs.length === 0) || fieldsDisable) {
      return null;
    } else {
      return (
        <div className="d-flex gap-2 justify-end">
          <button
            className={`${btnSecondayClass}`}
            onClick={() => {
              dispatch(openBacktestingReportFields(false));
              backtestingModalRef.current.resetFields();
              setFieldsDisable(false);
            }}
          >
            Cancel
          </button>
          <button
            type="primary"
            className={`${btnPrimaryClass}`}
            onClick={() => {
              backtestingModalRef.current.submit();
              setType('save_and_draft');
              setFieldsDisable(false);
              // getButtonDisabled();
            }}
          >
            Save & Draft
          </button>
          <button
            type="primary"
            className={`${btnPrimaryClass}`}
            onClick={() => {
              backtestingModalRef.current.submit();
              setType('save_and_run');
              setFieldsDisable(false);
              // getButtonDisabled();
            }}
          >
            Save & Run
          </button>
        </div>
      );
    }
  }, [dispatch, inputs, fieldsDisable]);

  //update the toogle switch
  const updateRequestStrategy = useMutation({
    mutationFn: ({ finalPayload }) => {
      return updateRequest(finalPayload, 'virtual');
    },
    onSuccess: async (res, { finalPayload }) => {
      upDateData();
      // res.data.message
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
    onError: (error) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      // console.log(error.response.data.message);
    },
  });

  const handleUpdate = useCallback(
    (status) => {
      if (strategyData?.subscribe_by_user) {
        let stPayload = {
          st_id: id,
          user_id: userid.id,
          strategy_id: strategyData?._id,
          status: status,
        };

        let finalPayload = stPayload;

        updateRequestStrategy.mutate({ finalPayload });
      }
    },
    [strategyData],
  );

  return (
    <>
      <Drawer
        open={showBacktestingReportDrawer}
        onClose={() => {
          dispatch(openBacktestingReportFields(false));
          backtestingModalRef.current.resetFields();
          setNoChanges(false);
          setFieldsDisable(false);
        }}
        title={
          <div className="flex justify-between items-center pr-6 -my-3">
            <div className="flex flex-col -space-y-1">
              {location === 'strategy' && (
                <h4 className="font-weight-bold mb-0">Generate New BackTest</h4>
              )}
              {location !== 'strategy' && (
                <h4 className="font-weight-bold mb-0">{reportName}</h4>
              )}

              {location !== 'strategy' && (
                <div className="flex items-center">
                  <span className="text-xs text-color-secondary">
                    Last Updated: 04-Jul-2024 3:41:27 PM
                  </span>
                </div>
              )}
            </div>
            {location !== 'strategy' && (
              <div className="flex gap-2 items-center">
                <button
                  className={`${
                    reportData?.request_data?.status
                      ? ' bg-red-3'
                      : 'bg-green-3 '
                  } flex justify-center items-center py-[7px] px-[15px] text-[13px] text-whiteNew-0 rounded-[6px] gap-1 font-normal`}
                  onClick={() => {
                    handleUpdate(
                      reportData?.request_data?.status ? false : true,
                    );
                  }}
                >
                  {reportData?.request_data?.status ? <MdClose /> : <MdCheck />}

                  <span>
                    {reportData?.request_data?.status ? 'InActive' : 'Active'}
                  </span>
                </button>
                <button
                  className={`${btnPrimaryClass} ${
                    fieldsDisable ? '' : 'active'
                  } py-[7px] px-[15px] text-[13px] bg-[#e9f3ff] text-blue-600/80  hover:bg-blue-200 [&.active]:bg-color-primaryColor2 font-semibold [&.active]:text-whiteNew-0 [&.active]:hover:text-whiteNew-0`}
                  onClick={() => {
                    setFieldsDisable(!fieldsDisable);
                  }}
                >
                  <MdOutlineEdit />
                  <span>Edit</span>
                </button>
              </div>
            )}
          </div>
        }
        width={'1200px'}
        footer={getFooter}
      >
        <div className="position-relative tradesetting_tab -mt-3">
          {inputs && inputs.length > 0 ? (
            <ModalInputFields
              ref={backtestingModalRef}
              inputs={inputs}
              primaryButtonName="Generate"
              sumbitForm={backtestingReportSubmission}
              fieldsDisable={fieldsDisable}
              data={data}
              type="backtest"
            />
          ) : (
            <div className="w-full flex justify-center text-center">
              <NoDataFoundV2
                title={'No data found.'}
                className="text-4xl"
                minHeight={'min-h-[200px] py-2'}
              />
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default BacktestingReportInputs;
