import React, { useState } from 'react';
import config from '../../Config/config';
import axios from 'axios';
import { apiHeader } from '../../utils/utils';
import { useSelector } from 'react-redux';
import useToGetId from './useToGetId';

function useRayzorPayment(setPaymentLoading, setSuccessLoading) {
  const { userChangedPlan } = useSelector((state) => state.toUpdatePlan);
  const { json_header } = apiHeader();
  const [showSucessModal, setShowSuccessModal] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [showModalData, setShowModalData] = useState({});
  const { userid } = useToGetId();
  // const [showResponse,setShowResponse]=useState({})
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handlePayment = async (formData) => {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js',
    );
    if (!res) {
      // message.error('Razorpay SDK failed to load. Are you online?');
      return;
    }
    const orderData = {
      amount: Number(userChangedPlan?.price),
      currency: 'INR',
      receipt: 'test_receipt',
      user_id: userid.id,
      subscription_plan_id: userChangedPlan?._id,
    };

    try {
      const orderResponse = await axios.post(
        `${config.base_url}/payment/order`,
        orderData,
        json_header,
      );

      const {
        amount,
        id: order_id,
        currency,
        created_at,
      } = orderResponse?.data?.data;

      const options = {
        key: 'rzp_test_3Vgl1FEfWXXdn0',
        amount,
        currency,
        name: 'Test-1',
        description: 'Test Transaction',
        order_id,
        handler: async (response) => {
          setSuccessLoading(true);
          setShowSuccessModal(true);
          let payload = {
            orderCreationId: created_at,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            billingDetails: formData,
            user_id: userid.id,
            subscription_plan_id: userChangedPlan?._id,
          };

          try {
            const response = await axios.post(
              `${config.base_url}/payment/capture`,
              payload,
              json_header,
            );
            setShowModalData(response?.data);
            setSuccessLoading(false);
            setStartTimer(true);
          } catch (error) {
            console.log('error');
          }
        },
        prefill: {
          name: 'John Doe',
          email: 'john.doe@example.com',
          contact: '9999999999',
        },
        notes: {
          address: 'Razorpay Corporate Office',
        },
        theme: {
          color: '#3399cc',
        },
        modal: {
          ondismiss: function () {
            alert('Payment process was not completed.');
          },
        },
      };

      setPaymentLoading(false);
      const rzp1 = new window.Razorpay(options);

      rzp1.on('payment.failed', function (response) {});
      setTimeout(() => {
        rzp1.open();
      }, 100);
    } catch (error) {
      console.error(error);
    }
  };
  return {
    handlePayment,
    showSucessModal,
    setShowSuccessModal,
    startTimer,
    showModalData,
  };
}

export default useRayzorPayment;
