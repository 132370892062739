import { useCallback } from 'react';
import Drawer from '../../Common/Drawer';

import { useDispatch, useSelector } from 'react-redux';
import { tradeDetailsInDrawer } from '../../../features/detailsTradeSlice';

export default function TradeDetailDrawer() {
  const { selectedTrade, isOpen } = useSelector((state) => state.detailsTrade);
  console.log(selectedTrade, isOpen);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(
      tradeDetailsInDrawer({
        selectedTrade: {},
        isOpen: false,
      }),
    );
  }, []);

  return (
    <Drawer
      title={
        <>
          <div className="flex justify-between items-center">
            <div className="flex flex-col">
              <span className="flex justify-center items-center gap-1.5 font-medium">
                <span className="font-semibold">Trade Summary</span>
              </span>
              {/* <span className="text-color-secondary/80 text-xs font-normal">
									24-May-2024 01:29:15 pm
								</span> */}
            </div>
            <div className="flex -mt-2"></div>
          </div>
        </>
      }
      onClose={handleClose}
      open={isOpen}
      className={``}
      rootClassName={'drawer-root-className'}
      width={'540px'}
      footer={null}
    >
      <div className="">
        {Object.entries(selectedTrade || {}).map(([key, value]) => (
          <div className="w-full space-y-6 mt-3" key={key}>
            <div className="row">
              <div className="col-md-4">
                <span className="text-color-primary">{key}</span>
              </div>
              <div className="col-md-8">
                <span className="text-color-secondary font-medium">
                  <span className="">{value}</span>
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </Drawer>
  );
}
