import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo } from "react";
import { toast } from "react-toastify";
// import { forgotPasswordApi } from "../../helpers/api/Auth/Auth";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../ForgotPassword";
import { setPasswordLink, userResetPassword } from "../../../components/helpers/api/Auth/auth";

const useResetPasswordLink = () => {
  const navigate = useNavigate();
  const insertNote = useCallback((data) => {
    const { email } = data;
    let payload = {
      email: email,
    };
    return setPasswordLink(payload);
  }, []);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res, data) => {
      const { navigateTo } = data;
      navigateTo && navigate(navigateTo);
    },
    onError: (err) => {
      console.log("err", err);
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });
  const ResendLink = useCallback(
    (email, navigateTo) => {
      mutation?.mutate({ email, navigateTo });
    },
    [mutation]
  );

  const value = useMemo(() => {
    return {
      ResendLink,
      isLoading: mutation?.isPending,
    };
  }, [ResendLink, mutation?.isPending]);

  return value;
};

export default useResetPasswordLink;
