import React, { useState } from 'react';
import ModelDelete from '../../Common/DeleteConfirm';
import TableComponent from '../../Common/TableComponent';
import useDesktopStrategyTable from '../../hooks/useDesktopStrategyTable';
import { btnPrimaryClass } from '../../utils/defaultClasses';
import Modal from '../../Common/Modal';

function StrategyDesktopTable({
  tradeOrder,
  toggleState,
  loading,
  pagination,
  handleTableChange,
  refetch,
}) {
  console.log('toggleState: ', toggleState);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const updateReportOnDel = (value) => {
    setDeleteLoading(value);
  };

  const {
    setRemoveModel,
    columns,
    dataSource,
    removeModel,
    reportId,
    columnsBacktesting,
    // showWarning,
    // setShowWarning,
  } = useDesktopStrategyTable(tradeOrder, toggleState, refetch); //startegy desktop table hook
  console.log('dataSource: ', dataSource);

  // Hide the modal
  const hideConfirmationModal = () => {
    setRemoveModel(false);
  };

  return (
    <div>
      <div className="strategy__tbl__data">
        <TableComponent
          columns={toggleState === "backtesting" ? columnsBacktesting : columns}
          dataSource={dataSource}
          pagination={pagination}
          onChange={handleTableChange}
          loading={loading}
          rowKey="_id"
          total={pagination?.total}
        />
      </div>
      <ModelDelete
        from={toggleState === 'live' ? 'live' : toggleState === "backtesting" ? "backtesting" : 'virtual'}
        showModal={removeModel}
        hideModal={hideConfirmationModal}
        id={reportId}
        updateReportOnDel={updateReportOnDel}
        refetch={refetch}
        loading={deleteLoading}
      />
    </div>
  );
}

export default StrategyDesktopTable;
