import { useMutation, useQuery } from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import {
  getAllTickets,
  onDeleteTickets,
} from '../../../components/helpers/api/TicketSupport/ticket';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastSpecs } from '../../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTicket,
  setTicketMessageList,
} from '../../../features/Tickets/tickets.slice';

const useTicketSupport = () => {
  const decodedToken = localStorage.getItem('token');
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  //   console.log(userid, 'userid');
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState('');
  const [selectedRowKey, setSelectedRowKeys] = useState([]);
  const [deleteTicket, setDeleteTicket] = useState(false);
  const { selectedTicket } = useSelector((state) => state.selectedTickets);
  const dispatch = useDispatch();

  const { data, refetch, isLoading, isRefetching } = useQuery({
    queryKey: ['ticketSupport', decodedToken, page, limit, search],
    queryFn: async () => {
      if (decodedToken) {
        return await getAllTickets(page, limit, search, userid?.id);
      } else {
        return null;
      }
    },
  });

  const columns = useMemo(
    () => [
      {
        title: 'Ticket ID',
        dataIndex: 'ticketId',
        key: 'ticketId',
        render: (text) => <a href="*">{text}</a>,
      },
      {
        title: 'Subject',
        dataIndex: 'subject',
        key: 'subject',
        render: (text) => <a href="*">{text}</a>,
      },
      //   {
      //     title: 'Name',
      //     key: 'name',
      //     dataIndex: 'name',
      //   },

      {
        title: 'Category',
        dataIndex: 'category',
        key: 'category',
      },
      {
        title: 'Sub Category',
        key: 'sub_category',
        dataIndex: 'sub_category',

        // render: (_, { tags }) => (
        //   <>
        //     {tags.map((tag) => {
        //       let color;
        //       let icon;
        //       if (tag === 'bug') {
        //         color = 'error';
        //         icon = <BugOutlined />;
        //       }
        //       if (tag === 'support') {
        //         color = 'success';
        //         icon = <AlertOutlined />;
        //       }
        //       if (tag === 'general') {
        //         color = 'processing';
        //         icon = <DesktopOutlined />;
        //       }
        //       return (
        //         <Tag color={color} key={tag} icon={icon}>
        //           {tag.toUpperCase()}
        //         </Tag>
        //       );
        //     })}
        //   </>
        // ),
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        key: 'priority',
      },
      {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
      },
      {
        title: 'Created',
        key: 'created',
        dataIndex: 'created',
      },
    ],
    [],
  );

  const createdAt = useCallback((date) => {
    const startTime = moment(date);
    const endTime = moment();

    // Calculate the total duration
    const duration = moment.duration(endTime.diff(startTime));

    // Extract days and hours
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let displayDifference;
    if (days > 0) {
      displayDifference = `${days} days ${hours} hours ago`;
    } else if (hours > 0) {
      displayDifference = `${hours} hours ${minutes} minutes ago`;
    } else {
      if (minutes === 0) {
        displayDifference = `Just Now`;
      } else {
        displayDifference = `${minutes} minutes ago`;
      }
    }
    return displayDifference;
  }, []);

  const datasource = useMemo(
    () =>
      data?.data?.data?.map((item) => {
        return {
          key: item._id,
          ticketId: (
            <>
              <Link
                to={`/ticket/${item?._id}`}
                className="text-blue-3 hover:underline"
                onClick={() => {
                  if (selectedTicket?._id !== item?._id) {
                    dispatch(
                      setTicketMessageList({ data: [], count: undefined }),
                    );
                  }
                  dispatch(setSelectedTicket(item));
                  localStorage.setItem('selectedTicket', JSON.stringify(item));
                }}
              >
                #{item?.ticket_number || '-'}
              </Link>
            </>
          ),
          subject: item?.title ?? '-',
          name: userid?.name ?? '-',
          priority: item?.priority ?? '-',
          category: item?.category ?? '-',
          sub_category: item?.sub_category ?? '-',
          status: (
            <span
              class={`${
                item.status === 'In Progress'
                  ? 'bg-blue-2/15 text-blue-2 '
                  : item.status === 'Closed'
                  ? 'text-orange-400 bg-orange-100'
                  : item.status === 'Open'
                  ? 'bg-green-3/20 text-green-3'
                  : 'bg-red-2/15 text-red-2'
              }  py-[2px] px-2 text-[13px] rounded-[4px] min-w-[100px] inline-flex justify-center`}
            >
              {item?.status}
            </span>
          ),
          created: createdAt(item?.created_at),
        };
      }),
    [data?.data?.data, userid?.name, createdAt, dispatch, selectedTicket?._id],
  );

  const rowSelection = useMemo(() => {
    return {
      selectedRowKey,
      onChange: (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
      },
      getCheckboxProps: (record) => ({
        disabled: record.disabled, // Column configuration not to be checked
      }),
    };
  }, [selectedRowKey]);

  const onCloseDeleteTicket = useCallback(() => {
    setDeleteTicket(false);
    // setSelectedRowKeys([]);
  }, []);

  const insertDeleteNote = useCallback(() => {
    let payload = {
      ticket_id: selectedRowKey,
    };
    return onDeleteTickets(payload);
  }, [selectedRowKey]);

  const DeleteTicketmutation = useMutation({
    mutationFn: insertDeleteNote,
    onSuccess: (res) => {
      refetch();
      onCloseDeleteTicket();
      setSelectedRowKeys([]);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmitdeleteTicket = useCallback(() => {
    DeleteTicketmutation.mutate();
  }, [DeleteTicketmutation]);

  const values = useMemo(() => {
    return {
      data,
      refetch,
      isLoading,
      isRefetching,
      pagination: {
        page,
        setPage,
        limit,
        setLimit,
        search,
        setSearch,
        total: data?.data?.count,
      },
      datasource,
      columns,
      rowSelection,
      selectedRowKey,
      deleteTicket,
      onCloseDeleteTicket,
      onSubmitdeleteTicket,
      setDeleteTicket,
    };
  }, [
    data,
    refetch,
    isLoading,
    isRefetching,
    page,
    limit,
    search,
    datasource,
    columns,
    rowSelection,
    selectedRowKey,
    deleteTicket,
    onCloseDeleteTicket,
    onSubmitdeleteTicket,
  ]);

  return values;
};

export default useTicketSupport;
