import { useCallback } from 'react';
import Modal from './Modal';
import welcome_img from '../../assets/images/strategy.svg';
import { btnPrimaryClass, btnSecondayClass } from '../utils/defaultClasses';
import { useDispatch, useSelector } from 'react-redux';
import { setOpenWelcomeModal } from '../../features/welcomeModalSlice';
import useLogout from '../hooks/useLogout';
import { webDefine } from '../../webDefine';

export default function WelcomeModal() {
  const { openWelcomeModal, from } = useSelector((state) => state.welcomeModal);
  const dispatch = useDispatch();
  const { logoutUser, isLoading } = useLogout();

  const logOut = () => {
    logoutUser();
  };

  const closeModal = useCallback(() => {
    dispatch(setOpenWelcomeModal({ openWelcomeModal: false, from: from }));
  }, [dispatch, from]);

  return (
    <>
      <div>
        <Modal
          footer={null}
          open={openWelcomeModal}
          setIsOpen={closeModal}
          centered
          title={null}
          maxWidth={`w-full max-w-7xl`}
          preventClosingOutsideClick
          //   bodyClass={'relative'}
          //   bodyHeight={'60vh'}
          bodyClass={
            'w-full overflow-y-auto overflow-x-hidden scrollCss flex justify-center xl:justify-start xl:text-left xl:items-center'
          }
        >
          <div className="items-center flex flex-col justify-center h-full">
            <div className="">
              <div className="bg-color-base-1 rounded-lg beforeImg overflow-hidden before:hidden lg:before:flex before:opacity-50 2xl:before:opacity-100 py-10 h-full lg:px-10">
                <div className="beforeAfterImg2 before:hidden lg:before:flex after:hidden lg:after:flex before:w-[299px] before:h-[717px] before:left-[-130px] before:top-[-48px] after:right-[-194px] after:bottom-[-8px] after:w-[421px] after:h-[114%]"></div>

                <div className="grid xl:grid-cols-2 items-center h-full gap-6">
                  <div className="space-y-6 h-full flex flex-col justify-between">
                    <div className="space-y-6">
                      <h2 className="text-2xl xl:text-4xl font-semibold">
                        {from === 'logout'
                          ? `Log out to the ${webDefine.title} Dashboard`
                          : `Welcome to the ${webDefine.title} Dashbaord`}
                      </h2>
                      <div className="text-lg">
                        <span>Hey ! User</span>
                        <br />
                        <span>
                          {from === 'logout'
                            ? 'Looks like you want to exit. Are you sure that account to be logout?'
                            : `Let's start your journey with our platform exclusive
                          features which makes your earn seem less by providing
                          automate trade experience`}
                        </span>
                        <br />
                        {from === 'logout' && (
                          <span>
                            Make sure all you changes and updates are saved.
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="w-full flex gap-3">
                      {from === 'logout' && (
                        <button
                          className={`${btnSecondayClass} z-10`}
                          disabled={isLoading}
                          onClick={closeModal}
                        >
                          Cancel
                        </button>
                      )}
                      <button
                        className={`${btnPrimaryClass} z-10`}
                        disabled={isLoading}
                        onClick={() => {
                          from === 'logout' ? logOut() : closeModal();
                        }}
                      >
                        {from === 'logout' ? 'Yes log out' : 'Go to Dashboard'}
                      </button>
                    </div>
                  </div>
                  <div className="h-full">
                    <div className="-my-[50px] xl:-my-[90px]">
                      <img src={welcome_img} alt="welcome_img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}
