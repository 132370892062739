import React from 'react';
import StrategyDesktopTable from './StrategyDesktopTable';
import StrategyMobileTable from './StrategyMobileTable';
import { useDispatch, useSelector } from 'react-redux';
import ActionCardUi from '../../../Pages/UserComponents/subscription/ActionCardUi';
import {
  openLiveReportFields,
  openVirualReportFields,
} from '../../../features/strategyReport/strategyDataDetailSlice';

function ShowVirtualTrade({
  toggleState,
  isMobile,
  setBackTestingModalShow,
  virtualPagination,
  handleVirtualTradeTable,
  virtualRefech,
  isFetching,
}) {
  const { virtualReportsData } = useSelector(
    (state) => state.completeStrategyDetails,
  );
  var dispatch = useDispatch();

  return virtualReportsData?.length === 0 ? (
    <ActionCardUi
      title={'You don’t have Virtual Running'}
      subTitle={'Click on the below button to setup Virtual Trade'}
      actionButton={true}
      buttonName={'Add Virtual Trade'}
      handleAction={() => dispatch(openVirualReportFields(true))}
    />
  ) : (
    <div className="col ps-0 pe-0 mt-3 mb-5 mb-lg-2">
      <div>
        {isMobile === false ? (
          <StrategyDesktopTable
            tradeOrder={virtualReportsData}
            toggleState={toggleState}
            refetch={virtualRefech}
            pagination={virtualPagination}
            handleTableChange={handleVirtualTradeTable}
            loading={isFetching}
          />
        ) : (
          <StrategyMobileTable
            tradeOrder={virtualReportsData}
            toggleState={toggleState}
            refetch={virtualRefech}
            pagination={virtualPagination}
            handleTableChange={handleVirtualTradeTable}
            loading={isFetching}
          />
        )}
      </div>
    </div>
  );
}

export default React.memo(ShowVirtualTrade);
