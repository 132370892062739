import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
  useCallback,
} from 'react';
import PortfolioDesktopTable from './PortfolioDesktopTable';
import TradeMobileCards from '../../../utils/TradeMobileCards';
import { getPortfolioTrades } from '../../../API/portfolio/OrdersApi';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';

const TradeOrders = ({
  isMobile = false,
  tab,
  tradesOrdersDate,
  pagination,
  setPagination,
}) => {
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 0,
  // });
  const [tradeOrdersData, setTradeOrdersData] = useState([]);

  const handleTableChange = useCallback((newPagination) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: newPagination.current,
    }));
  }, []);

  const { data, isFetching } = useQuery({
    queryKey: [
      'portfolioTradeOrders',
      { tradesOrdersDate, page: pagination?.current, tab },
    ],
    queryFn: getPortfolioTrades,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && !isFetching) {
      setTradeOrdersData(
        data?.data?.orders_data.map((item) => ({
          ...item,
          // key: item?._id,
        })),
      );
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data?.data?.total_orders,
      }));
    }
  }, [data, isFetching, tab]);

  // async function userTradeOrders(Date, selectedDate, page) {
  //   // console.log('selectedDate', selectedDate);
  //   let { data, finalData } = await getTradeOrders(
  //     Date,
  //     selectedDate,
  //     page,
  //     tab,
  //   );
  //   if (isMobile) {
  //     setMobileTrades((mobileTrades) => [...mobileTrades, ...finalData]);
  //   } else {
  //     setDesktopTrades(
  //       data?.orders_data.map((item) => ({
  //         ...item,
  //         // key: item?._id,
  //       })),
  //     );
  //     setPagination({
  //       ...pagination,
  //       current: page,
  //       total: data?.total_orders,
  //     });
  //   }
  //   // if (mobileTrades.length === data?.total_orders) {
  //   //   setHasMore(false);
  //   // }
  // }

  return (
    <>
      {isMobile === false ? (
        <PortfolioDesktopTable
          tradeOrder={tradeOrdersData}
          from="trades"
          pagination={pagination}
          handleTableChange={handleTableChange}
          tab={tab}
          loading={isFetching}
          emptyDataText="No Trades available for a selected filter,to view Trades please change the date filter."
        />
      ) : (
        <></>
        // <TradeMobileCards
        //   tradeCall={paginationCall}
        //   tradeOrdersData={mobileTrades}
        //   tab={tab}
        //   // userid={userid}
        //   // hasMore={hasMore}
        // />
      )}
    </>
  );
};

export default React.memo(TradeOrders);
