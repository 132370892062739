import upgrade_plan from '../../../img/upgrade_plan.svg';
import { btnPrimaryClass } from '../../../components/utils/defaultClasses';

export default function ActionCardUi({
  title,
  subTitle,
  actionButton,
  buttonName,
  handleAction,
}) {
  // const dispatch = useDispatch();
  // const [showSubscription, setShowSubscription] = useState(false);
  return (
    <>
      <div className="w-fuqll flex items-center justify-center text-center py-6">
        <div className="flex justify-center flex-col items-center">
          <div className="w-[250px]">
            <img
              src={upgrade_plan}
              alt="upgrade plan"
              className="w-full h-full object-contain"
            />
          </div>
          <div className="space-y-6">
            <div className="max-w-[400px]">
              <h2 className="text-color-primary text-xl font-medium">
                {title}
              </h2>
              <p className="text-color-secondary/80 text-sm">{subTitle}</p>
            </div>
            {/* <div className="flex justify-center">
              <button
                className={`${btnPrimaryClass} text-sm`}
                // to={'/upgrade-plan'}
                // target="_blank"
                onClick={() => {
                  dispatch(showPlansModal(true));
                  // setShowSubscription(true);
                  // handleClose(false);
                }}
              >
                <MdOutlineArrowUpward />
                Upgrade Now
              </button>
            </div> */}
            {actionButton && (
              <div className="flex justify-center">
                <button
                  className={`${btnPrimaryClass} text-sm`}
                  // to={'/upgrade-plan'}
                  // target="_blank"
                  onClick={handleAction}
                >
                  {/* <MdOutlineArrowUpward /> */}
                  {buttonName}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <SubscriptionUpgradeModal
        showSubscription={showSubscription}
        setShowSubscription={setShowSubscription}
      /> */}
    </>
  );
}
