import React, { useCallback, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CrossIcon, SpinnerBtn } from '../../../assets/Icon';
import {
  afterBorder2,
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../utils/defaultClasses';
import { Form, Input, Skeleton, Spin } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import {
  setOpenBrokerModal,
  setSelectedBroker,
  setSelectedType,
} from '../../../features/brokers/brokerSlice';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  addBroker,
  getSingleBroker,
  updateBroker,
} from '../../helpers/api/broker/broker';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import { toast } from 'react-toastify';
import { toastSpecs } from '../../../utils/utils';
import { MdOutlineEdit } from 'react-icons/md';
import moment from 'moment';

const BrokerModal = ({ refetch }) => {
  const { openBrokerModal, selectedBroker, selectedType } = useSelector(
    (state) => state.brokerDetails,
  );
  const { userDetails } = useSelector((state) => state.profileDetails);
  const [form] = Form.useForm();
  const decodedToken = localStorage.getItem('token');
  const dispatch = useDispatch();
  const handleCancel = useCallback(() => {
    dispatch(setOpenBrokerModal(false));
    dispatch(setSelectedBroker({}));
    dispatch(setSelectedType('create'));
    form.resetFields();
  }, [form, dispatch]);

  const { data, isLoading } = useQuery({
    queryKey: ['selectedBroker', decodedToken, selectedBroker?._id],
    queryFn: async () => {
      if (decodedToken && selectedBroker?._id) {
        return await getSingleBroker(selectedBroker?._id);
      } else {
        return null;
      }
    },
  });

  const dynamicInput = useMemo(() => {
    return data?.data?.data?.[0]?.input || selectedBroker?.input;
  }, [data, selectedBroker]);

  console.log('selectedBroker', selectedBroker);

  const insertNote = useCallback(() => {
    let inputValue = [];
    const { account_name } = form.getFieldsValue();
    const input = form.getFieldsValue();
    for (let x in form.getFieldsValue()) {
      if (x !== 'account_name' && x !== 'email') {
        inputValue.push({ key_name: x, key_value: input[x] });
      }
    }
    let payload;
    if (selectedType === 'edit') {
      payload = {
        id: selectedBroker._id,
        user_id: userDetails?.data?._id,
        broker_id: selectedBroker?.broker_id,
        broker_name: selectedBroker?.name,
        funds: selectedBroker.funds ? selectedBroker.funds : 0,
        input: inputValue,
        status: selectedBroker.status,
        account_name: account_name,
      };
    } else {
      payload = {
        account_name: account_name,
        user_id: userDetails?.data?._id,
        broker_id: selectedBroker?._id,
        broker_name: selectedBroker?.name,
        input: inputValue,
        status: 'not verified',
      };
    }
    // console.log('attachments', attachments, formData);

    return selectedType === 'edit' || selectedType === 'view'
      ? updateBroker(payload)
      : addBroker(payload);
  }, [form, userDetails, selectedBroker, selectedType]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      handleCancel();
      refetch();
      return toast.success('Broker added successfully.', toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const handelSubmit = (e) => {
    mutation.mutate();
  };

  const Keyname = (data) => {
    let name = '';
    if (data !== '' && data !== null && data !== undefined) {
      let temp = data.split('_').join(' ');
      name = temp.charAt(0).toUpperCase() + temp.slice(1);
    }
    //console.log(name);
    return name;
  };

  const remainingInput = useCallback(() => {
    const result = {};
    dynamicInput?.forEach((item) => {
      result[item.key_name] =
        selectedType === 'view' || selectedType === 'edit'
          ? item.key_value
          : undefined;
    });
    // console.log(result, 'result');
    return result;
  }, [dynamicInput, selectedType]);

  return (
    <Modal
      show={openBrokerModal}
      onHide={handleCancel}
      backdrop="static"
      keyboard={false}
      centered
      contentClassName="bg-color-base-1 rounded-lg overflow-hidden"
    >
      <Modal.Body className="bg-color-base-1 rounded-lg overflow-hidden">
        <Form
          layout="vertical"
          className="space-y-3"
          form={form}
          onFinish={handelSubmit}
          disabled={mutation?.isPending || selectedType === 'view'}
          initialValues={{
            email: userDetails?.data?.email ?? undefined,
            account_name: selectedBroker?.account_name ?? undefined,
            ...remainingInput(),
          }}
        >
          {selectedType === 'edit' || selectedType === 'view' ? (
            <>
              <div className="d-flex align-items-center justify-content-between pb-1.5">
                <div className="flex">
                  <div className="d-flex align-items-center">
                    <img
                      src={selectedBroker ? selectedBroker.logo_img : ''}
                      className="mxp-width-100 w-100 me-3"
                      alt={selectedBroker ? selectedBroker.broker_name : ''}
                    />
                    <div className="flex flex-col -space-y-2">
                      <h4 className="text-lg mb-0">
                        {selectedBroker ? selectedBroker.broker_name : ''}
                      </h4>
                      <div>
                        <small className="text-color-secondary fsize-12">
                          <strong>Created At: </strong>
                          {selectedBroker.created_at
                            ? moment(selectedBroker.created_at).format(
                                'DD MMM, YYYY',
                              )
                            : ''}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <div>
                    <button
                      className={`${btnPrimaryClass} bg-[#e9f3ff] text-blue-600/80 ${
                        selectedType === 'edit' ? 'active' : ''
                      } hover:bg-blue-200 [&.active]:bg-color-primaryColor2 font-semibold [&.active]:text-whiteNew-0 [&.active]:hover:text-whiteNew-0 text-xs py-1`}
                      disabled={mutation.isPending}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        dispatch(
                          setSelectedType(
                            selectedType === 'view' ? 'edit' : 'view',
                          ),
                        );
                      }}
                    >
                      <MdOutlineEdit />
                      <span>Edit</span>
                    </button>
                  </div>
                  <span
                    onClick={handleCancel}
                    className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
                  >
                    <CrossIcon className="text-base text-color-primary" />
                  </span>
                </div>
              </div>
            </>
          ) : (
            <div className="d-flex align-items-center justify-content-between">
              <div className="flex">
                <h4 className="text-lg mb-0 font-medium">Add Broker</h4>
              </div>
              <div className="flex items-center gap-2">
                <span
                  onClick={handleCancel}
                  className={`${btnSecondayClass} Icon w-[28px] h-[28px] height-auto min-auto flex gap-2 relative p-0 text-sm`}
                >
                  <CrossIcon className="text-base text-color-primary" />
                </span>
              </div>
            </div>
          )}
          {/* <h6 className="text-base font-medium mt-4">
              Follow the steps below
            </h6> */}

          <div className="mt-3.5 space-y-3">
            <div className="border-[1px] border-level-4 shadow-sm rounded-lg overflow-hidden">
              <div
                className={`${afterBorder2} after:bg-level-4 p-2.5 bg-color-base-2 relative`}
              >
                <div>
                  <h6 className="text-base font-medium">Account Details</h6>
                </div>
              </div>
              <div className="p-3.5">
                <div className="form-group">
                  {/* <label>Account Name:</label> */}
                  <div className="div__margin form-group">
                    <Form.Item
                      label="Account Name :"
                      name="account_name"
                      rules={[
                        {
                          required: true,
                          message: `Account Name is required.`,
                        },
                      ]}
                      className="mb-0"
                    >
                      <Input
                        type={'text'}
                        name={'accountName'}
                        // onChange={(e) => handelChange(e)}
                        //placeholder="Api Secret :"
                        className={`${formClass}`}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="form-group mt-2">
                  {/* <label>Email Id:</label> */}
                  <div className="div__margin form-group">
                    <Form.Item
                      label="Email Id :"
                      name="email"
                      //   rules={[
                      //     {
                      //       required: true,
                      //       message: `Email Id is required.`,
                      //     },
                      //   ]}
                      className="mb-0"
                    >
                      <Input
                        type={'text'}
                        name={'email'}
                        // onChange={(e) => handelChange(e)}
                        //placeholder="Api Secret :"
                        className={`${formClass}`}
                        disabled={true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className="border-[1px] border-level-4 shadow-sm rounded-lg overflow-hidden">
              <div
                className={`${afterBorder2} after:bg-level-4 p-2.5 bg-color-base-2 relative`}
              >
                <div>
                  <h6 className="text-base font-medium">Credential Details</h6>
                </div>
              </div>
              {isLoading ? (
                <div className="p-3.5">
                  <div className="steps__container">
                    <div className="space-y-2">
                      <div className="form-group">
                        <div className="div__margin form-group flex flex-col gap-4">
                          {[...Array(4)]?.map(() => (
                            <Skeleton.Input
                              active={true}
                              size={'medium'}
                              style={{ width: '100%' }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="p-3.5">
                  {selectedBroker ? (
                    <div className="steps__container">
                      <p>
                        {selectedBroker && selectedBroker.instructions
                          ? selectedBroker.instructions
                          : ''}
                      </p>

                      <div className="">
                        <div className="space-y-2">
                          {dynamicInput.map((el, index) => {
                            return (
                              <div className="form-group">
                                {/* <label> {Keyname(el.key_name)}:</label> */}
                                {el.key_name === 'password' ||
                                el.key_name === 'access_key' ||
                                el.key_name === 'secret_key' ? (
                                  <>
                                    <div className="div__margin form-group">
                                      <Form.Item
                                        label={`${Keyname(el.key_name)} :`}
                                        name={el.key_name}
                                        rules={[
                                          {
                                            required: true,
                                            message: `${Keyname(
                                              el.key_name,
                                            )} is required.`,
                                          },
                                        ]}
                                        className="mb-0"
                                      >
                                        <Input.Password
                                          iconRender={(visible) =>
                                            visible ? (
                                              <span>
                                                <EyeTwoTone className="text-color-primary" />
                                              </span>
                                            ) : (
                                              <span>
                                                <EyeInvisibleOutlined className="text-color-primary" />
                                              </span>
                                            )
                                          }
                                          className={`${formClass}`}
                                          name={el.key_name}
                                          //   onChange={(e) => handelChange(e)}
                                          style={{ borderRadius: '5px' }}
                                          //placeholder="Api Secret :"
                                        />
                                      </Form.Item>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="div__margin form-group">
                                      <Form.Item
                                        label={`${Keyname(el.key_name)} :`}
                                        name={el.key_name}
                                        rules={[
                                          {
                                            required: true,
                                            message: `${Keyname(
                                              el.key_name,
                                            )} is required.`,
                                          },
                                        ]}
                                        className="mb-0"
                                      >
                                        <Input
                                          type={
                                            el.key_name === 'password' ||
                                            el.key_name === 'access_key' ||
                                            el.key_name === 'secret_key'
                                              ? 'password'
                                              : 'text'
                                          }
                                          name={el.key_name}
                                          //   onChange={(e) => handelChange(e)}
                                          //placeholder="Api Secret :"
                                          className={`${formClass} form-control`}
                                        />
                                      </Form.Item>
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{ textAlign: 'center' }}>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{ fontSize: 24 }}
                            spin
                            size="small"
                          />
                        }
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {selectedType !== 'view' && (
            <div className={`d-flex justify-content-end mt-4 gap-2`}>
              <button
                variant="default"
                className={`${btnSecondayClass}`}
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                variant="primary"
                className={`${btnPrimaryClass}`}
                //   onClick={handelSubmit}
                disabled={mutation?.isPending}
              >
                {mutation?.isPending ? (
                  <>
                    <SpinnerBtn />{' '}
                    {selectedType === 'create' ? 'Add Broker' : 'Update'}
                  </>
                ) : selectedType === 'create' ? (
                  'Add Broker'
                ) : (
                  'Update'
                )}
              </button>
            </div>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default BrokerModal;
