import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import { btnSecondayClass } from '../../utils/defaultClasses';
import StrategyOverview from './StrategyOverview';
import StrategyDetailCards from './StrategyDetailCards';
import StrategyDetailsTabs from './StrategyDetailsTabs';
import { StatsUsageCardSmall } from '../../../Pages/UserComponents/stats_usage/StatsUsageCard';
import ShowVirtualTrade from './ShowVirtualTrade';
import useVirtualStrategyDetails from '../../hooks/useVirtualStrategyDetails';
import ShowLiveTrade from './ShowLiveTrade';
import useLiveStrategyDetails from '../../hooks/useLiveStrategyDetails';
import ShowBackingTesing from './ShowBackingTesing';
import { DataLoader } from '../../Common/DataLoader';
import { useDispatch, useSelector } from 'react-redux';
import useReportViewDetails from '../../hooks/useReportViewDetails';
import PremiumIcon from '../../Common/PremiumIcon';
import { showPlansModal } from '../../../features/profile/profilePlanSlice';
import ReportDrawerInputs from '../../../utils/ReportDrawerInputs';
import { HiOutlineBellAlert, HiOutlineBellSlash } from 'react-icons/hi2';
import LogActivityDrawer from '../../Common/logs/LogActivityDrawer';
import { BsClockHistory } from 'react-icons/bs';
import Tooltip from '../../Common/Tooltip';
import StatusStrips from '../../Common/StatusStrips';
import useBacktestReport from '../../hooks/useBacktestReport';
import useActivityLogs from '../../hooks/useActivityLogs';

const StrategyReportView = () => {
  let history = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();
  const { virtual_requests, live_requests } = useSelector(
    (state) => state.userCreditsDetails,
  );
  const [isMobile, setIsMobile] = useState(false);
  const [hideTopStrip, setHideTopStrip] = useState({
    status: '',
    title: '',
    show: false,
    description: '',
    sideBtn: false,
    sideLink: false,
  });
  const [premiumTopStrip, setPremiumTopStrip] = useState({
    status: '',
    title: '',
    show: false,
    description: '',
    sideBtn: false,
    sideLink: false,
  });

  const { virtualReportsData, liveReportsData, backtestingReportsData } =
    useSelector((state) => state.completeStrategyDetails);

  const [knowMoreName, setKnowMoreName] = useState('');

  const handleResize = () => {
    if (window.innerWidth < 769) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  //startegyReport
  const {
    GetInputsData,
    percentTitle,
    premium_strategies,
    toggleState,
    handleSubscription,
    setToggleState,
    loading,
    detailsOfStrategy,
  } = useReportViewDetails();

  const {
    backTestData,
    backTestPage,
    setBackTestPage,
    backTestPagination,
    backtestRefetch,
    handleBacktestingTable,
    isFetching: isFetchingRefetch,
  } = useBacktestReport();

  //virtual reports
  const {
    virtualPagination,
    handleVirtualTradeTable,
    virtualRefech,
    isFetching,
  } = useVirtualStrategyDetails();

  //live reports
  const { livePagination, handleliveTradeTable, liveRefech, liveFetching } =
    useLiveStrategyDetails();

  const { logsData, refetch: refetchLogs } = useActivityLogs();

  const { userDetails } = useSelector((state) => state.profileDetails);
  const [openLogsActivity, setOpenLogsActivity] = useState(false);
  const showLogsActivityDrawer = () => {
    setOpenLogsActivity(true);
    refetchLogs();
  };
  const onClose = () => {
    setOpenLogsActivity(false);
  };
  // const { make_userRestrict } = useSelector((state) => state.profileDetails); // to know the user to retrict

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      toggleState === 'backtesting' &&
      !localStorage.getItem('backtestingStrip') &&
      backTestData?.[0]?.status !== 'Pending' &&
      backTestData?.[0]?.status !== 'Draft'
    ) {
      setHideTopStrip({
        name: 'backtestingStrip',
        status: 'exclusive',
        title: 'Exclusive Features',
        show: true,
        sideLink: false,
        sideBtn: false,
        description:
          'Hey user ! Now you can analyse the strategy through our backtesting feature.',
      });
      // setHideTopStrip(true);
    } else if (
      toggleState === 'backtesting' &&
      !localStorage.getItem('backtestingStrip') &&
      backTestData?.[0]?.status === 'Draft'
    ) {
      setHideTopStrip({
        name: 'backtestingStrip',
        status: 'success',
        title: 'Success update',
        show: true,
        sideLink: false,
        sideBtn: false,
        description:
          'Your backtest generation setup has been successfully submitted and saved in draft. Later you may run and generate the report from the action.',
      });
      // setHideTopStrip(true);
    } else if (
      toggleState === 'backtesting' &&
      !localStorage.getItem('backtestingStrip') &&
      backTestData?.[0]?.status === 'Pending'
    ) {
      setHideTopStrip({
        name: 'backtestingStrip',
        status: 'success',
        title: 'Success update',
        show: true,
        sideLink: false,
        sideBtn: false,
        description:
          'Your backtest generation setup has been successfully submitted and sent for processing . Once backtest is processed you may find out the results.',
      });
      // setHideTopStrip(true);
    } else if (
      toggleState === 'virtual' &&
      !detailsOfStrategy?.subscribe_by_user &&
      virtualReportsData.length > 0 &&
      !localStorage.getItem('bothStripStrategy')
    ) {
      setHideTopStrip({
        name: 'bothStripStrategy',
        status: 'warning',
        title: 'Warning Alarm',
        show: true,
        sideLink: false,
        sideBtn: false,
        description: `As you are no longer subscribe with the strategy so none of your virtual trade is active.Look like you need to subscribe or upgrade your plan `,
      });
    } else if (
      toggleState === 'virtual' &&
      virtual_requests?.requests_remaining === 0 &&
      detailsOfStrategy?.subscribe_by_user &&
      virtualReportsData.length > 0 &&
      !localStorage.getItem('virtualStripOne')
    ) {
      setHideTopStrip({
        name: 'virtualStripOne',
        status: 'alert',
        title: 'Alert Warning',
        show: true,
        sideLink: false,
        sideBtn: true,
        description: `Your current plan doesn't have credit remaining for any more setup of virtual trade.Click to Buy `,
      });
    } else if (
      toggleState === 'virtual' &&
      virtual_requests?.requests_remaining > 0 &&
      detailsOfStrategy?.subscribe_by_user &&
      virtualReportsData.length === 0 &&
      !localStorage.getItem('virtualStripTwo')
    ) {
      setHideTopStrip({
        name: 'virtualStripTwo',
        status: 'update',
        title: 'Important Updates',
        show: true,
        sideLink: false,
        description:
          'Look like you have an credit to setup virtual trade,go head and setup virtual trade to analyse your results without any money in realtime market.',
      });
    } else if (
      toggleState === 'virtual' &&
      virtual_requests?.requests_used === 0 &&
      detailsOfStrategy?.subscribe_by_user &&
      virtualReportsData.length > 0 &&
      !localStorage.getItem('virtualStripThree')
    ) {
      setHideTopStrip({
        name: 'virtualStripThree',
        status: 'update',
        title: 'Important Updates',
        show: true,
        sideLink: false,
        description:
          'Their is no active virtual trade is available now to place order in the market hours ',
      });
    } else if (
      toggleState === 'live' &&
      !detailsOfStrategy?.subscribe_by_user &&
      liveReportsData.length > 0 &&
      !localStorage.getItem('bothLiveStripStrategy')
    ) {
      setHideTopStrip({
        name: 'bothLiveStripStrategy',
        status: 'warning',
        title: 'Warning Alarm',
        show: true,
        sideLink: false,
        sideBtn: false,
        description: `As you are no longer subscribe with the strategy so none of your live trade is active.Look like you need to subscribe or upgrade your plan `,
      });
    } else if (
      toggleState === 'live' &&
      live_requests?.requests_total === 0 &&
      detailsOfStrategy?.subscribe_by_user &&
      !localStorage.getItem('liveStripOne')
    ) {
      setHideTopStrip({
        name: 'liveStripOne',
        status: 'alert',
        title: 'Alert Warning',
        sideBtn: true,
        sideLink: false,
        show: true,
        description: `Your current plan doesn't have credit remaining for any more setup of live trade.Click to Buy `,
      });
    } else if (
      toggleState === 'live' &&
      live_requests?.requests_total > 0 &&
      live_requests?.requests_used === 0 &&
      detailsOfStrategy?.subscribe_by_user &&
      liveReportsData.length === 0 &&
      !localStorage.getItem('liveStripTwo')
    ) {
      setHideTopStrip({
        name: 'liveStripTwo',
        status: 'update',
        title: 'Important Updates',
        show: true,
        sideBtn: false,
        sideLink: false,
        description:
          'Look like you have an credit to setup live trade,go head and setup live trade to analyse your results in realtime market.',
      });
    } else if (
      toggleState === 'live' &&
      live_requests?.requests_total > 0 &&
      live_requests?.requests_used === 0 &&
      detailsOfStrategy?.subscribe_by_user &&
      liveReportsData.length > 0 &&
      !localStorage.getItem('liveStripThree')
    ) {
      setHideTopStrip({
        name: 'liveStripThree',
        status: 'update',
        title: 'Important Updates',
        show: true,
        sideBtn: false,
        sideLink: false,
        description:
          'Their is no active live trade is available now to place order in the market hours',
      });
    } else {
      setHideTopStrip((prevHideTopStrip) => ({
        ...prevHideTopStrip,
        show: false,
        sideBtn: false,
        sideLink: false,
      }));
    }
  }, [
    toggleState,
    virtual_requests,
    detailsOfStrategy,
    liveReportsData,
    virtualReportsData,
    userDetails,
    live_requests,
    backTestData,
  ]);

  useEffect(() => {
    if (detailsOfStrategy?.access_type === 'premium') {
      if (
        premium_strategies?.strategies_total === 0 &&
        !localStorage.getItem('premiumStripOne')
      ) {
        setPremiumTopStrip({
          name: 'premiumStripOne',
          status: 'alert',
          title: 'Alert Warning',
          show: true,
          description: `Your Plan doesn't have a premium features to subscribe premium stratagy.`,
          sideBtn: false,
          sideLink: true,
        });
      } else if (
        premium_strategies?.strategies_total !== 0 &&
        premium_strategies?.strategies_remaining === 0 &&
        !detailsOfStrategy?.subscribe_by_user &&
        !localStorage.getItem('premiumStripTwo')
      ) {
        setPremiumTopStrip({
          name: 'premiumStripTwo',
          status: 'warning',
          title: 'alert',
          show: true,
          description: `Look like your premium credit is exahusted,if you want to have more premium strategy subscription.`,
          sideBtn: true,
          sideLink: false,
        });
      } else if (
        premium_strategies?.strategies_remaining !== 0 &&
        !detailsOfStrategy?.subscribe_by_user &&
        !localStorage.getItem('premiumStripThree')
      ) {
        setPremiumTopStrip({
          name: 'premiumStripThree',
          status: 'warning',
          title: 'Warning Alarm',
          show: true,
          description: `You are no longer you have been subscribed with strategy.To subscribe click on the button given.`,
          sideBtn: false,
          sideLink: false,
        });
      } else {
        setPremiumTopStrip((prevPremiumTopStrip) => ({
          ...prevPremiumTopStrip,
          sideBtn: false,
          sideLink: false,
          show: false,
        }));
      }
    }
  }, [detailsOfStrategy, premium_strategies]);

  return (
    <>
      <div className="left__bar">
        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ textAlign: 'center', minHeight: '50vh' }}
          >
            <DataLoader message={'Loading Data...'} />
          </div>
        ) : (
          <>
            {premiumTopStrip?.show && (
              <div className="row mt-2 px-0">
                <div className="col-12 px-0">
                  <StatusStrips
                    status={premiumTopStrip?.status}
                    title={premiumTopStrip?.title}
                    closeIcon={{
                      show: true,
                      click: () => {
                        setPremiumTopStrip((prevPremiumTopStrip) => ({
                          ...prevPremiumTopStrip,
                          show: false,
                          sideBtn: false,
                          sideLink: false,
                        }));
                      },
                    }}
                    description={premiumTopStrip?.description}
                    knowMoreLink={{
                      show: premiumTopStrip?.sideLink,
                      click: () => dispatch(showPlansModal(true)),
                      name: 'click to buy credits',
                    }}
                    sideBtn={{
                      show: premiumTopStrip?.sideBtn,
                      click: () => dispatch(showPlansModal(true)),
                    }}
                  />
                </div>
              </div>
            )}
            <div className="row bg__white p-2 mt-3 rounded align-items-center">
              <div className="col-lg-6 col-6">
                <div className="flex items-center gap-2">
                  <div className="">
                    <div className="flex">
                      <Link
                        className={`${btnSecondayClass} rounded-full py-2.5`}
                        onClick={() => {
                          history(-1);
                        }}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 24 24"
                          height="20px"
                          width="20px"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                        </svg>
                      </Link>
                    </div>
                  </div>
                  <div className="">
                    <div>
                      <div className="flex items-center gap-1.5">
                        <div className="flex items-center gap-0.5">
                          <h2 className="strtgy__ttl mb-0">
                            {detailsOfStrategy?.name}
                          </h2>
                          {detailsOfStrategy?.access_type === 'premium' && (
                            <PremiumIcon className="text-lg" />
                          )}
                        </div>
                        <span className="badge__green flex m-0">
                          <small>Active</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-12">
                <div className="flex justify-end items-center gap-2">
                  <div>
                    <span className="bg-component-gray-2 rounded-3xl px-2.5 py-1 text-sm font-semibold">
                      1,400 + Subscribers
                    </span>
                  </div>
                  {detailsOfStrategy?.access_type === 'premium' && (
                    <>
                      <StatsUsageCardSmall
                        icon={
                          <>
                            {/* <MdAutoGraph className="text-xl text-color-secondary/80" /> */}
                            {percentTitle?.subtitle}
                          </>
                        }
                        percent={percentTitle?.percent}
                        title={'Premium Credits'}
                        subTitle={percentTitle?.subtitle}
                        infoText={'No credits are available'}
                        size={30}
                      />
                      <div className="py-0.5 hidden lg:flex">
                        <span className="flex w-1 h-7 bg-level-2"></span>
                      </div>
                    </>
                  )}
                  <div className="flex items-center gap-1.5">
                    {detailsOfStrategy?.access_type === 'premium' &&
                    !detailsOfStrategy?.subscribe_by_user &&
                    premium_strategies?.strategies_remaining === 0 ? (
                      <button
                        className="btn__create__strategy align-items-center d-flex gap-1"
                        onClick={() => {
                          dispatch(showPlansModal(true));
                        }}
                      >
                        <span>Upgrade</span>
                      </button>
                    ) : (
                      <div>
                        {detailsOfStrategy?.subscribe_by_user ? (
                          <button
                            className="bg-red-3 flex justify-center items-center py-[7px] px-[15px] text-[13px] text-whiteNew-0 rounded-[6px] gap-1"
                            onClick={() => {
                              handleSubscription(
                                'unsubscribe',
                                detailsOfStrategy?._id,
                              );
                            }}
                          >
                            <HiOutlineBellSlash />
                            <span>Unsubscribe</span>
                          </button>
                        ) : (
                          <button
                            className="btn__create__strategy align-items-center d-flex gap-1"
                            onClick={() => {
                              handleSubscription(
                                'subscribe',
                                detailsOfStrategy?._id,
                              );
                            }}
                          >
                            <HiOutlineBellAlert />
                            <span>Subscribe</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    <Tooltip title={'Logs & Activities'} placement={'bottom'}>
                      <button
                        className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                        onClick={() => {
                          showLogsActivityDrawer();
                        }}
                      >
                        <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg">
                          <BsClockHistory />
                        </span>
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
            {/* //! HERE WORK */}
            <StrategyDetailCards />
            {hideTopStrip?.show && (
              <div className="row mt-2 px-0">
                <div className="col-12 px-0">
                  <StatusStrips
                    status={hideTopStrip?.status}
                    title={hideTopStrip?.title}
                    closeIcon={{
                      show: true,
                      click: () => {
                        localStorage.setItem(hideTopStrip?.name, true);
                        setHideTopStrip((prevHideTopStrip) => ({
                          ...prevHideTopStrip,
                          show: false,
                          sideBtn: false,
                          sideLink: false,
                        }));
                      },
                    }}
                    description={hideTopStrip?.description}
                    sideBtn={{
                      show: hideTopStrip?.sideBtn,
                      click: () => dispatch(showPlansModal(true)),
                    }}
                    // knowMoreLink={{
                    //   show: premiumTopStrip?.sideLink,
                    //   click: () => console.log('called'),
                    //   name: 'click to buy credits',
                    // }}
                  />
                </div>
              </div>
            )}

            <StrategyDetailsTabs
              toggleState={toggleState}
              setToggleState={setToggleState}
              isMobile={isMobile}
              refreshView={
                toggleState === 'virtual' && virtualReportsData?.length > 0
                  ? true
                  : toggleState === 'backtesting' &&
                    backtestingReportsData?.length > 0
                  ? true
                  : toggleState === 'live' && liveReportsData?.length > 0
                  ? true
                  : false
              }
              refetchFunction={
                toggleState === 'virtual'
                  ? virtualRefech
                  : toggleState === 'backtesting'
                  ? backtestRefetch
                  : liveRefech
              }
            />

            <div className="tbl_data row mt-3">
              <div
                className={
                  toggleState === 'overview'
                    ? 'tab-content-active'
                    : 'tabs-content'
                }
              >
                <div className="bg-color-base-1 dark:bg-color-base-1 border-[1px] border-t-[0px] border-level-4 rounded-b-lg">
                  <StrategyOverview
                    detailsOfStrategy={detailsOfStrategy}
                    //  strategyData={strategyData}
                  />
                </div>
              </div>
              <div
                className={
                  toggleState === 'backtesting'
                    ? 'tab-content-active'
                    : 'tabs-content'
                }
              >
                <ShowBackingTesing
                  toggleState={toggleState}
                  isMobile={isMobile}
                  backTestPagination={backTestPagination}
                  handleBacktestingTable={handleBacktestingTable}
                  backtestRefetch={backtestRefetch}
                  isFetching={isFetchingRefetch}
                />
              </div>
              <div
                className={
                  toggleState === 'virtual'
                    ? 'tab-content-active'
                    : 'tabs-content'
                }
              >
                <ShowVirtualTrade
                  toggleState={toggleState}
                  isMobile={isMobile}
                  virtualPagination={virtualPagination}
                  handleVirtualTradeTable={handleVirtualTradeTable}
                  virtualRefech={virtualRefech}
                  isFetching={isFetching}
                />
              </div>

              <div
                className={
                  toggleState === 'live' ? 'tab-content-active' : 'tabs-content'
                }
              >
                <ShowLiveTrade
                  toggleState={toggleState}
                  isMobile={isMobile}
                  livePagination={livePagination}
                  handleliveTradeTable={handleliveTradeTable}
                  liveRefech={liveRefech}
                  isFetching={liveFetching}
                />
              </div>
            </div>
          </>
        )}
      </div>

      <ReportDrawerInputs
        inputs={GetInputsData()}
        modalData={toggleState}
        strategyID={id}
      />
      {/* </div>
      </Drawer> */}
      <LogActivityDrawer
        onClose={onClose}
        open={openLogsActivity}
        logsData={logsData}
      />
    </>
  );
};

export default StrategyReportView;
