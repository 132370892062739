import React, { useCallback, useState } from 'react';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { ExitTradeApi } from '../API/OrderExit';
import CustomDatePicker from './Common/CustomDatePicker';
import RefreshButton from './Common/RefreshButton';
import NeedHelpButtonDrawer from './UI/NeedHelpButtonDrawer';
import { Tabs } from 'antd';
import TableComponent from './Common/TableComponent';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import ReportOpenTable from '../utils/ReportOpenTable';
import Heatmap from './UI/Heatmap';

import ReportClosedOrder from './UI/ReportPage/ReportClosedOrder';
import ReportTradeOrder from './UI/ReportPage/ReportTradeOrder';

const utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const openColumns = [
  {
    title: 'Symbol',
    dataIndex: 'tradingsymbol',
  },
  {
    title: 'Order Time',
    dataIndex: 'entryTime',
    render: (entryTime) =>
      dayjs.utc(entryTime).format('DD-MMM-YYYY hh:mm:ss a'),
  },
  {
    title: 'Lot Size',
    dataIndex: 'lot_size',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity_left',
  },
  {
    title: 'Avg.',
    dataIndex: 'entryPrice',
  },
  {
    title: 'LTP',
    dataIndex: 'livePrice',
  },
  {
    title: 'P&L',
    key: 'pnl',
    dataIndex: 'pnl',
    render: (pnl) => (
      <span>
        {pnl > 0 ? (
          <BsArrowUp className="text-success" />
        ) : (
          <BsArrowDown className="text-danger" />
        )}
        {
          <span className={pnl > 0 ? 'text-success' : 'text-danger'}>
            {pnl}
          </span>
        }
      </span>
    ),
  },
  {
    title: 'Charge',
    dataIndex: 'charges',
    render: (text) => <span className="pnl_charge">{text}</span>,
  },
];

const ReportTabs = ({
  lastOrder,
  tab,
  brokerID,
  reportData,

  isMobile = false,
  // reportStatus,
}) => {
  const [toggleState, setToggleState] = useState('openOrder');
  const [closedOrdersDate, setClosedOrdersDate] = useState([]);
  const [tradesOrdersDate, setTradesOrdersDate] = useState([]);
  const [closedOrderDateRangeValue, setClosedOrderDateRangeValue] = useState([
    null,
    null,
  ]);
  const [tradeOrderDateRangeValue, setTradeOrderDateRangeValue] = useState([
    null,
    null,
  ]);

  const [tradesPagination, setTradesPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [closedOrdersPagination, setClosedOrdersPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isChecked, setIsChecked] = useState(false);

  const { TabPane } = Tabs;

  const handleTradesDateChange = useCallback(
    (date, dateString) => {
      setTradeOrderDateRangeValue(date);
      setTradesOrdersDate(dateString);
    },

    [],
  );

  const handleClosedOrderDateChange = useCallback((date, dateString) => {
    setClosedOrderDateRangeValue(date);
    setClosedOrdersDate(dateString);
  }, []);

  //Trade Exit Function Call
  const handleExitOrder = (status) => {
    if (status === 'order-exit') {
      setIsChecked(true);
      setTimeout(() => {
        setIsChecked(false);
      }, 500);
    }
    let data = [...lastOrder];
    let payload = {
      exchange: data[0]?.exchange,
      request_id: data[0]?.request_id,
      user_id: data[0]?.user_id,
      strategy_id: data[0]?.strategy_id,
      option_type: data[0]?.option_type,
      strike_price: data[0]?.strike_price,
      lot_size: data[0]?.lot_size,
      order_type: data[0]?.order_type,
      tradingsymbol: data[0]?.tradingsymbol,
      market_type: null,
      expiry: '',
      position_type: data[0]?.position_type,
      quantity_left: data[0]?.quantity,
      transaction_type: data[0]?.transaction_type,
      trade_action: data[0]?.trade_action
        ? data[0]?.trade_action
        : data[0]?.trade_type,
      trade_type: data[0]?.trade_action
        ? data[0]?.trade_action
        : data[0]?.trade_type,
    };

    if (status === 'day-exit') {
      payload.day_status = false;
    }

    if (tab === 'VT') {
      ExitTradeApi(payload, tab === 'LT' ? 'live' : 'virtual');
    } else {
      payload.credential_id = brokerID;
      ExitTradeApi(payload, tab === 'LT' ? 'live' : 'virtual');
    }
  };

  const onTabChange = useCallback(
    (key) => {
      if (tradesOrdersDate[0]) {
        setTradesOrdersDate([]);
        setTradesPagination({ ...tradesPagination, current: 1 });
        setTradeOrderDateRangeValue([null, null]);
      }
      if (closedOrdersDate[0]) {
        setClosedOrdersDate([]);
        setClosedOrdersPagination({ ...closedOrdersPagination, current: 1 });
        setClosedOrderDateRangeValue([null, null]);
      }
      setToggleState(key);
    },
    [
      closedOrdersDate,
      closedOrdersPagination,
      tradesOrdersDate,
      tradesPagination,
    ],
  );
  const operations = (
    <div className="w-auto d-flex align-items-center gap-2">
      <div>
        <NeedHelpButtonDrawer />
      </div>
      {toggleState === 'openOrder' && (
        <>
          <div
            style={{
              display: `${lastOrder.length === 0 ? 'none' : 'block'}`,
            }}
          >
            <div
              className={`d-flex btn_swipe_exit mx-2 ${
                lastOrder.length === 0 ? 'opacity-50' : ''
              }`}
            >
              <div className="button r" id="button-1">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={isChecked}
                  disabled={lastOrder.length === 0}
                  onChange={() => handleExitOrder('order-exit')}
                />
                <div className="knobs"></div>
                <div className="layer"></div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: `${lastOrder.length === 0 ? 'none' : 'block'}`,
            }}
          >
            <button
              className="d-flex btn__exit mx-2"
              onClick={() => handleExitOrder('day-exit')}
            >
              <Tooltip title="Kill Switch" placement="top">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-power"
                  viewBox="0 0 16 16"
                >
                  <path d="M7.5 1v7h1V1h-1z" />
                  <path d="M3 8.812a4.999 4.999 0 0 1 2.578-4.375l-.485-.874A6 6 0 1 0 11 3.616l-.501.865A5 5 0 1 1 3 8.812z" />
                </svg>
              </Tooltip>
            </button>
          </div>
        </>
      )}
      {toggleState === 'closeOrder' && (
        <CustomDatePicker
          onChange={handleClosedOrderDateChange}
          value={closedOrderDateRangeValue}
        />
      )}
      {toggleState === 'trades' && (
        <CustomDatePicker
          onChange={handleTradesDateChange}
          value={tradeOrderDateRangeValue}
        />
      )}

      <div>
        <RefreshButton />
      </div>
    </div>
  );
  console.log(reportData);

  return (
    <>
      <div className="pb-1.5">
        <Tabs
          activeKey={toggleState}
          onChange={onTabChange}
          className="strtgy__tbl"
          tabBarExtraContent={operations}
        >
          <TabPane tab="Open Order" key="openOrder">
            <span>
              {' '}
              {isMobile === false ? (
                <TableComponent
                  columns={openColumns}
                  dataSource={lastOrder}
                  loading={false}
                  emptyDataText={
                    reportData?.request_data?.status
                      ? `We haven't received any order yet, once the order is placed it
              will be listed here.`
                      : 'oops currently virtual not active and running'
                  }
                />
              ) : (
                <ReportOpenTable tradeOrder={lastOrder} />
              )}
            </span>
          </TabPane>
          <TabPane tab="Closed Order" key="closeOrder">
            <>
              {isMobile === false ? (
                <>
                  <ReportClosedOrder
                    pagination={closedOrdersPagination}
                    setPagination={setClosedOrdersPagination}
                    closedOrdersDate={closedOrdersDate}
                  />
                </>
              ) : (
                <></>
                // <ReportClosedTable
                // // paginationCall={paginationCall}
                // // mobileClosedOrders={mobileClosedOrders}
                // />
              )}
            </>
          </TabPane>
          <TabPane tab="Trades" key="trades">
            <>
              {isMobile === false ? (
                <>
                  <ReportTradeOrder
                    pagination={tradesPagination}
                    setPagination={setTradesPagination}
                    tradesOrdersDate={tradesOrdersDate}
                  />
                </>
              ) : (
                <></>
                // <ReportTradesTable
                // // paginationCall={paginationCall}
                // // closedCards={mobileTrades}
                // />
              )}
            </>
          </TabPane>
          <TabPane tab="P&L" key="p&l">
            <div className="heatmap__bg">
              <Heatmap from="report" tab={tab === 'VT' ? 'virtual' : 'live'} />
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default React.memo(ReportTabs);
