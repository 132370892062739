import React, { useRef } from 'react';
import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { MdClose, MdLock } from 'react-icons/md';
import { btnPrimaryClass } from '../utils/defaultClasses';

export default function GetStartedCard({
  // primaryButtonName,
  // primaryButton,
  // primaryButtonAction,
  // secondaryButton = null,
  // secondaryButtonName,
  // secondaryButtonAction,
  handleClose,
  customSlides,
  firstBtn,
  secondBtn = null,
}) {
  return (
    <>
      <div className="bg-color-base-1 my-6 shadow rounded-lg relative beforeImg overflow-hidden before:hidden lg:before:flex before:opacity-50 2xl:before:opacity-100 py-3.5">
        <div className="beforeAfterImg before:hidden lg:before:flex after:hidden lg:after:flex"></div>
        <div className="flex justify-between mt-3.5 px-3.5">
          <div className="">
            <h4 className="font-semibold" onClick={() => console.log('iocn')}>
              Get Started
            </h4>
            <div className="flex w-[30px] h-[4px] bg-blue-2 rounded-md mt-1.5"></div>
          </div>

          <div className="z-20">
            <button
              className="flex justify-center items-center w-[25px] h-[25px] text-color-primary rounded-md "
              onClick={handleClose}
            >
              <MdClose />
            </button>
          </div>
        </div>
        <div className="flex justify-center items-center w-full">
          <GetStartedSlider customSlides={customSlides} />
        </div>
        <div className="flex justify-center w-full mt-2.5 mb-2.5 gap-3">
          {secondBtn?.show && (
            <button
              className={`text-blue-3 hover:underline font-semibold text-l min-w-[150px]`}
              onClick={firstBtn?.onClick}
            >
              {/* Skip & Get Started */}
              {firstBtn?.title}
            </button>
          )}
          {secondBtn?.show && (
            <button
              className={`${btnPrimaryClass}`}
              onClick={secondBtn?.onClick}
            >
              {/* Skip & Get Started */}
              {secondBtn?.title}
            </button>
          )}
        </div>
      </div>
    </>
  );
}

function GetStartedSlider({ customSlides }) {
  return (
    <div className="w-full max-w-[3840px] relative z-[1]">
      <Swiper
        hashNavigation={{ watchState: true }}
        slidesPerView={3}
        freeMode
        effect="fade"
        pagination={{ clickable: true }}
        loop={false}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        modules={[Pagination, Navigation]}
        breakpoints={{
          320: { slidesPerView: 1, spaceBetween: 10 },
          576: { slidesPerView: 1, spaceBetween: 10 },
          768: { slidesPerView: 1, spaceBetween: 10 },
          1200: { slidesPerView: 4, spaceBetween: 30 },
          1400: { slidesPerView: 4, spaceBetween: 30 },
          1600: { slidesPerView: 4, spaceBetween: 30 },
        }}
        className="h-full"
      >
        {(customSlides || slides).map((slide, index) => (
          <SwiperSlide
            key={index}
            className="relative p-2.5 pb-8 h-full swiperSlider"
          >
            <div className="min-h-[163px] flex justify-center items-center">
              <GetStartedBox {...slide} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

const slides = [
  {
    icon: (
      <div className="w-[40px] h-[40px] border-[10px] border-blue-3 rounded-full"></div>
    ),
    title: 'Subscribe or Create Strategy',
    subtitle:
      'Platform allows you to subscribe to the strategy from the marketplace or create a custom strategy',
  },
  {
    icon: (
      <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
        <MdLock />
      </div>
    ),
    title: 'Generate Backtesting',
    subtitle:
      'Features allow you to generate backtesting reports for your strategy by providing access to historical data',
  },
  {
    icon: (
      <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
        <MdLock />
      </div>
    ),
    title: 'Papertrade / Virtual Trade',
    subtitle:
      'Exclusive feature to test your strategy and analyze your backtest report by virtual trade on live market',
  },
  {
    icon: (
      <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
        <MdLock />
      </div>
    ),
    title: 'Trade Live',
    subtitle:
      "Once you're ready, push your strategy with real money on live market hours by connecting your broker account to place orders and monitor",
  },
];

function GetStartedBox({ icon, title, subtitle }) {
  return (
    <div className="flex flex-col justify-center items-center text-center relative px-3.5">
      <div className="Step--Connector hidden lg:flex">
        <div className="Connector--Content"></div>
      </div>
      <div className="min-h-[40px] flex justify-center items-center">
        {icon}
      </div>
      <div className="mt-3.5 space-y-3">
        <h6 className="text-lg font-medium">{title}</h6>
        <p>{subtitle}</p>
      </div>
    </div>
  );
}
