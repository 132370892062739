import axios from 'axios';
import config from '../../Config/config';

export async function deleteRequest(id) {
  const res = await axios.delete(`${config.base_url}/admin/st-request/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  return { res };
}

export async function getStrategyList({ queryKey }) {
  const [_key, { page, categorySearch, userid }] = queryKey;
  const res = await axios.get(
    `${config.base_url}${config.get_subsciption}?page=${page}&limit=10&type=${categorySearch}&user_id=${userid.id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  // console.log(res.data);
  return res.data;
}

export async function createVirtualStrategyRequest(payload) {
  const res = await axios.post(
    `${config.base_url}${config.virtual_trade}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}
export async function createBacktestingStrategyRequest(payload) {
  const res = await axios.post(
    `${config.base_url}${config.add_request}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}

export async function updateBacktestingStrategyRequest(payload, request_id) {
  const res = await axios.put(
    `${config.base_url}${config.update_backtesting_request}/${request_id}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  console.log(res);
  return res;
}
export async function updateVirtualStrategyRequest(payload) {
  const res = await axios.put(
    `${config.base_url}${config.virtual_trade}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  console.log(res);
  return res;
}

export async function createLiveStrategyRequest(payload) {
  const res = await axios.post(
    `${config.base_url}${config.live_trade}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res;
}

export async function updateLiveStrategyRequest(payload) {
  const res = await axios.put(
    `${config.base_url}${config.live_trade}`,
    payload,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  console.log(res);
  return res;
}
