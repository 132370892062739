import { Modal } from 'antd';
import { btnPrimaryClass, btnSecondayClass } from '../utils/defaultClasses';

const DeleteConfirmation = ({ props }) => {
  const { showModal = false, hideModal, onSubmitDelete } = props;
  // console.log(showModal, 'showModal');
  return (
    <Modal
      footer={null}
      open={showModal}
      onCancel={hideModal}
      style={{ marginTop: '200px' }}
      title={<h3 className="">Delete Confirmation?</h3>}
    >
      <div>
        <p className="pb-4">Are you sure you want to delete?</p>
        <div className="d-flex justify-start gap-2 align-items-center">
          <button onClick={hideModal} className={`${btnSecondayClass}`}>
            Cancel
          </button>
          <button
            className={`${btnPrimaryClass}`}
            onClick={onSubmitDelete}
            // disabled={isLoading}
          >
            Delete
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
