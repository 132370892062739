import {
  afterBorderTop,
  btnPrimaryClass,
} from '../../../components/utils/defaultClasses';
import ReactQuill from 'react-quill';
import { MdOutlineSend } from 'react-icons/md';
import FileUpload from '../../../components/Common/FileUpload';
import { Form } from 'antd';
import useTicketMessageEditor from '../hooks/useMessageEditor';
import { SpinnerBtn } from '../../../assets/Icon';

export default function SupportChatBox({ props }) {
  const { refetch, Pagination } = props;
  return (
    <>
      <div className={`p-3.5 relative ${afterBorderTop}`}>
        <Editor refetch={refetch} pagination={Pagination} />
      </div>
    </>
  );
}

export function Editor({ refetch, pagination }) {
  const { form, fileList, message, onSubmit, isLoading } =
    useTicketMessageEditor(refetch, pagination);

  return (
    <>
      <Form
        layout="vertical"
        className="space-y-3"
        form={form}
        onFinish={onSubmit}
        disabled={isLoading}
        initialValues={{ attachments: [] }}
      >
        <div>
          <Form.Item
            name="message"
            // rules={[
            //   {
            //     required: true,
            //     message: 'Title is required.',
            //   },
            // ]}
            className="mb-0"
          >
            <ReactQuill
              //   theme="snow"
              // value={value}
              // onChange={setValue}
              readOnly={isLoading}
              className="border-[1px] border-level-2 rounded-lg overflow-hidden"
            />
          </Form.Item>
          <div className="my-2">
            <Form.Item
              name="attachments"
              className="mb-0"
              valuePropName="fileList"
              // getValueFromEvent={normFile}
            >
              <FileUpload form={form} fileList={fileList} />
            </Form.Item>
          </div>
          <div className="flex justify-end mt-3">
            {/* <div className="flex items-center gap-2">
            <button
              className={`flex justify-center items-center gap-1 border-[1px] border-level-2 p-1.5 rounded-md px-2.5`}
            >
              <HiOutlineUpload /> Upload
            </button>
            <div className="flex items-center gap-0.5 bg-blue-2/10 p-0.5 pl-1.5 rounded-md border-[1px] border-blue-3 border-dashed text-xs">
              <span className="flex">doc.pdf</span>
              <span className="text-red-3 p-0.5 cursor-pointer" type="button">
                <MdOutlineClose />
              </span>
            </div>
          </div> */}
            <div>
              <button
                className={`${btnPrimaryClass} text-sm`}
                // onClick={() => form.submit()}
                disabled={message === '<p><br></p>' || !message || isLoading}
              >
                {isLoading ? (
                  <>
                    <SpinnerBtn /> Send <MdOutlineSend />
                  </>
                ) : (
                  <>
                    Send <MdOutlineSend />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </Form>
    </>
  );
}
