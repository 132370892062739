import React, { useEffect, useState } from 'react';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { tradeDetailsInDrawer } from '../../../features/detailsTradeSlice';
import dayjs from 'dayjs';
import TableComponent from '../../Common/TableComponent';
import TradeDetailDrawer from '../StrategyDetails/TradeDetailDrawer';

function ReportTradeBacktesting({ data, refetchBacktestingData, isFetching }) {
  const [tradeOrdersData, setTradeOrdersData] = useState([]);
  var dispatch = useDispatch();
  let { id, tab } = useParams();
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
  });
  const showTradeDetails = (record) => {
    let tradeDetails = {
      'Trade Id': record?._id,
      // 'Strategy Name': record?.st_name,
      // 'Report Name': record?.report_name,
      'Trade Date': dayjs.utc(record?.date).format('DD-MMM-YYYY hh:mm:ss a'),
      Day: record?.day,
      Symbol: record?.stock,
      'Trade Type': '-',
      'Position Type': record?.position_type || '-',
      'Lot size': record?.lot_size || '-',
      Quantity: record?.quantity || '-',
      'Expiry ': record?.expiry || '-',
      'Entry Time ': dayjs
        .utc(record?.entry_time)
        .format('DD-MMM-YYYY hh:mm:ss a'),
      'Entry Price ': record?.entry_price || '-',
      'Exit Price ': record?.exit_price || '-',
      'Exit Time':
        dayjs.utc(record?.exit_time).format('DD-MMM-YYYY hh:mm:ss a') || '-',
      'Exit Type': record?.exit_type || '-',
      Points: record?.points || '-',
      'Gross P&L': record?.pnl || '-',
      'Brokeragee (brokerage)': record?.brokerage || '-',
      'Net P&L (net_pnl)': record?.net_pnl || '-',
    };
    dispatch(
      tradeDetailsInDrawer({
        selectedTrade: tradeDetails,
        isOpen: true,
      }),
    );
  };

  const tradeColumns = [
    // {
    //   title: 'Trade Id',
    //   dataIndex: '_id',
    //   render: (text, record) => (
    //     <p
    //       onClick={() => {
    //         showTradeDetails(record);
    //       }}
    //       className="text-bluePrimary-1  cursor-pointer"
    //     >
    //       {/* {truncate(text, 4)} */}
    //       {text}
    //     </p>
    //   ),
    // },
    {
      title: 'Symbol',
      dataIndex: 'stock',
      render: (text, record) => (
        <p
          onClick={() => {
            showTradeDetails(record);
          }}
          className="text-bluePrimary-1  cursor-pointer"
        >
          {text}
        </p>
      ),
    },
    {
      title: 'Entry Time',
      dataIndex: 'entry_time',
      render: (text) => (
        <>
          {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
          {
            <p>
              <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
            </p>
          }
        </>
      ),
    },
    {
      title: 'Entry Price',
      dataIndex: 'entry_price',
    },
    {
      title: 'Exit Price',
      dataIndex: 'exit_price',
    },
    {
      title: 'Exit Time',
      dataIndex: 'exit_time',
      render: (text) => (
        <>
          {<small>{dayjs.utc(text).format('DD-MMM-YYYY')}</small>}
          {
            <p>
              <small>{dayjs.utc(text).format('hh:mm:ss a')}</small>
            </p>
          }
        </>
      ),
    },

    {
      title: 'Exit Type',
      dataIndex: 'exit_type',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'P&L',
      key: 'pnl',
      dataIndex: 'pnl',
      render: (pnl) => (
        <span>
          {pnl > 0 ? (
            <BsArrowUp className="text-success" />
          ) : (
            <BsArrowDown className="text-danger" />
          )}
          {
            <span className={pnl > 0 ? 'text-success' : 'text-danger'}>
              {pnl}
            </span>
          }
        </span>
      ),
    },
  ];

  const handleTableChange = (newPagination) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: newPagination.current,
    }));
  };
  //   const { data, isFetching } = useQuery({
  //     queryKey: [
  //       'reportTradeOrders',
  //       { tradesOrdersDate, page: pagination?.current, tab, id },
  //     ],
  //     queryFn: getReportTrades,
  //     refetchOnMount: 'always',
  //     refetchOnWindowFocus: false,
  //   });

  useEffect(() => {
    if (data && !isFetching) {
      setTradeOrdersData(
        data?.map((item, index) => ({
          ...item,
          _id: index + 1,
          key: index + 1,
        })),
      );
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data?.length,
      }));
    }
  }, [data, isFetching, tab]);
  return (
    <div>
      <TableComponent
        columns={tradeColumns}
        dataSource={tradeOrdersData}
        pagination={pagination}
        onChange={handleTableChange}
        total={pagination?.total}
        loading={isFetching}
        emptyDataText="None of the Placed Order is available for a selected filter,to view Placed Order please change the date filter."
      />
      <TradeDetailDrawer />
    </div>
  );
}

export default ReportTradeBacktesting;
