import axios from 'axios';
import config from '../../../../Config/config';
import mydecodedTokenFunction from '../../../../utils/decodetoken';
import { apiHeader } from '../../../../utils/utils';

export async function getSingleBroker(id) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${config.base_url}${config.broker}/${id}`,
    json_header,
  );
  return res;
}

export async function addBroker(payload) {
  const { json_header } = apiHeader();
  const res = await axios.post(
    `${config.base_url}${config.broker_crendientials}`,
    payload,
    json_header,
  );
  return res;
}

export async function updateBroker(payload) {
  const { json_header } = apiHeader();
  const res = await axios.put(
    `${config.base_url}${config.broker_crendientials}`,
    payload,
    json_header,
  );
  return res;
}
