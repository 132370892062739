import React from 'react';
import TradingViewWidget from 'react-tradingview-widget';
import { TVChartContainer } from './TVChartContainer';
import Header from './Header';

import Sidebar from './Sidebar';

const ChartPage = () => {
  // if (chartSocket.connected !== true) {
  //   chartSocket.connect();
  // }
  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <div className="container-fluid chart__main">
        <TVChartContainer />
        {/* <TradingViewWidget
        autosize={true}
        symbol="NASDAQ:AAPL"
        interval="D"
        timezone="Etc/UTC"
        theme="light"
        locale="en"
        toolbar_bg="#f1f3f6"
        enable_publishing={false}
        withdateranges={true}
        hide_side_toolbar={false}
        allow_symbol_change={true}
        details={true}
        hotlist={true}
        studies={["BollingerBandsR@tv-basicstudies"]}
        container_id="tradingview_38dc5"
      /> */}
      </div>
    </>
  );
};

export default ChartPage;
