import axios from 'axios';
import config from '../../Config/config';
import { apiHeader } from '../../utils/utils';

const { json_header } = apiHeader();

export async function getAllPlanDetails() {
  const res = await axios.get(
    `${config.base_url}${config.get_allPlans}?page=1&limit=10`,

    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    },
  );
  return res.data.data;
}
