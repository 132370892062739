import React, { useEffect, useState } from 'react';
import Modal from '../../../components/Common/Modal';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../../../components/utils/defaultClasses';
import { Col, Row } from 'react-bootstrap';
import { Logo } from '../../../assets/Icon';
import { useNavigate } from 'react-router-dom';
import useRayzorPayment from '../../../components/hooks/useRayzorPayment';
import dayjs from 'dayjs';
import { Box, CircularProgress } from '@mui/material';

function SubscriptionConfirmationModal({
  showSucessModal,
  setShowSuccessModal,
  startTimer,
  showModalData,
  successLoading,
}) {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(30);
  const [minutes, setMinutes] = useState(0);
  // const { showSucessModal, setShowSuccessModal, startTimer, showModalData } =
  //   useRayzorPayment();
  // const [sessionExpiredModal, setSessionExpiredModal] = useState(false);

  useEffect(() => {
    if (startTimer) {
      let interval = null;

      interval = setInterval(() => {
        if (seconds === 0) {
          clearInterval(interval);

          setShowSuccessModal(false);
          navigate('/profile');
          window.location.reload();
          // } else {
          //   setMinutes(minutes - 1);
          //   setSeconds(59);
          // }
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [seconds, minutes, startTimer]);

  return (
    <Modal
      footer={null}
      setIsOpen={setShowSuccessModal}
      open={showSucessModal}
      centered
      title={null}
      closeIcon={false}
      maxWidth={`w-full max-w-xl`}
      bodyClass={`p-6 lg:p-[50px]`}
    >
      <div>
        <Row className="items-center">
          {successLoading ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <CircularProgress />
              <div className="mt-2">Please wait...</div>
            </Box>
          ) : (
            <>
              <Col xs={12} md={12}>
                <div>
                  <div className="text-6xl text-green-3 flex justify-start relative">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                </div>
                <div className="flex justify-start px-3.5 mt-3.5">
                  <div className="space-y-6">
                    <div>
                      <div className={'text-color-primary text-xl font-medium'}>
                        <p className="">You're all set ✅</p>
                      </div>
                      <div>
                        <span className="text-color-secondary">
                          Your subscription activated. Your next billing will be
                          on <span> </span>
                          {''}
                          {''}
                          {''}
                          {dayjs(showModalData?.expired).format('DD/MM/YYYY')}.
                        </span>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="border-[1px] border-level-2 rounded-md p-3.5 py-2.5">
                        <div>
                          <span className="font-semibold">
                            Status: <span className="text-green-2">Paid</span>
                          </span>
                        </div>
                        <div>
                          <span className="">
                            Amount:
                            {showModalData?.successPaymentData?.amount /
                              100}.00{' '}
                            {showModalData?.successPaymentData?.currency}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12}>
                <div className="d-flex justify-start gap-2 align-items-center mt-8 px-3.5">
                  <button
                    className={`${btnPrimaryClass} text-sm min-w-[90px]`}
                    type="button"
                    onClick={() => {
                      setShowSuccessModal(false);
                      navigate('/profile');
                      window.location.reload();
                    }}
                  >
                    Go to Billings
                  </button>
                </div>
                <div className="px-3.5 mt-2">
                  <span className="text-color-secondary italic text-xs">
                    You will be automatically redirected to the billing page in
                    0:
                    {seconds >= 10 ? seconds : `0${seconds}`} sec.
                  </span>
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </Modal>
  );
}

export default React.memo(SubscriptionConfirmationModal);
