import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { tradeDetailsInDrawer } from '../../../features/detailsTradeSlice';
import { useDispatch } from 'react-redux';
import truncate from '../../Truncate';
import TableComponent from '../../Common/TableComponent';
import TradeDetailDrawer from '../StrategyDetails/TradeDetailDrawer';
import { useQuery } from '@tanstack/react-query';
import { getReportClosedOrders } from '../../../API/report';
import { useParams } from 'react-router-dom';

function ReportClosedOrder({ pagination, setPagination, closedOrdersDate }) {
  const [closedOrdersData, setClosedOrdersData] = useState([]);
  var dispatch = useDispatch();
  let { id, tab } = useParams();

  const closedColumns = [
    {
      title: 'Order Id',
      dataIndex: '_id',
      render: (text, record) => (
        <p
          onClick={() => {
            showClosedOrdersDetails(record);
          }}
          className="text-bluePrimary-1  cursor-pointer"
        >
          {truncate(text, 4)}
        </p>
      ),
    },
    {
      title: 'Symbol',
      dataIndex: 'tradingsymbol',
    },
    {
      title: 'Order Time',
      dataIndex: 'order_timestamp',
      render: (text) => dayjs.utc(text).format('DD-MMM-YYYY hh:mm:ss a'),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
    },

    {
      title: 'Lot Size',
      dataIndex: 'lot_size',
    },

    {
      title: 'Trigger Price',
      dataIndex: 'trigger_price',
    },
    {
      title: 'Trade Action',
      dataIndex: 'trade_action',
    },

    {
      title: 'Transaction Type',
      dataIndex: 'transaction_type',
    },

    {
      title: 'Exit Type',
      dataIndex: 'exit_type',
      render: (text) => (text ? text : '-'),
    },
  ];

  const showClosedOrdersDetails = (record) => {
    let orderDetails = {
      'Order Id': record?._id,
      // 'Strategy Name': record?.strategys[0]?.name,
      // 'Report Name': record?.vt_requests[0]?.vt_inputs?.report_name,
      'Order Date': dayjs.utc(record?.date).format('DD-MMM-YYYY hh:mm:ss a'),
      Day: record?.day,
      'Order Time': dayjs
        .utc(record?.order_timestamp)
        .format('DD-MMM-YYYY hh:mm:ss a'),
      Symbol: record?.tradingsymbol,
      Quantity: record?.quantity,
      'Lot Size': record?.lot_size || '-',
      'Option Type': record?.option_type || '-',
      'Strike Price': record?.strike_price || '-',
      Expiry: record?.expiry || '-',
      'Trigger Price': record?.trigger_price || '-',
      'Exit Type': record?.exit_type || '-',
      'Order Type': record?.order_type || '-',
      'Position Type': record?.position_type || '-',
      'Transaction Type': record?.transaction_type || '-',
      'Trade Action': record?.trade_action || '-',
      'Quantity Left': record?.quantity_left || '-',
    };
    dispatch(
      tradeDetailsInDrawer({
        selectedTrade: orderDetails,
        isOpen: true,
      }),
    );
  };

  const handleTableChange = (newPagination) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: newPagination.current,
    }));
  };
  const { data, isFetching } = useQuery({
    queryKey: [
      'reportClosedOrders',
      { closedOrdersDate, page: pagination?.current, tab, id },
    ],
    queryFn: getReportClosedOrders,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && !isFetching) {
      // const finalData = data.orders_data;
      setClosedOrdersData(data.orders_data);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data?.total_orders,
      }));
    }
  }, [data, isFetching, tab]);
  return (
    <div>
      <TableComponent
        columns={closedColumns}
        dataSource={closedOrdersData}
        pagination={pagination}
        onChange={handleTableChange}
        total={pagination?.total}
        loading={isFetching}
        emptyDataText="None of the Placed Order is available for a selected filter,to view Placed Order please change the date filter."
      />
      <TradeDetailDrawer />
    </div>
  );
}

export default ReportClosedOrder;
