import React from 'react';
import useBacktestReport from '../../hooks/useBacktestReport';
import StrategyMobileTable from './StrategyMobileTable';
import StrategyDesktopTable from './StrategyDesktopTable';
import ActionCardUi from '../../../Pages/UserComponents/subscription/ActionCardUi';
import { useDispatch, useSelector } from 'react-redux';
import { openBacktestingReportFields } from '../../../features/strategyReport/strategyDataDetailSlice';

function ShowBackingTesing({ toggleState, isMobile, setBackTestingModalShow }) {
  console.log('toggleState: ', toggleState);
  const {
    backTestData,
    isFetching,
    backTestPage,
    setBackTestPage,
    backTestPagination,
    backtestRefetch,
    handleBacktestingTable,
  } = useBacktestReport();
  const { backtestingReportsData } = useSelector(
    (state) => state.completeStrategyDetails,
  );
  const dispatch = useDispatch();
  return backtestingReportsData?.length === 0 ? (
    <div className="min-h-[calc(100svh-400px)] flex justify-center items-center">
      <ActionCardUi
        title={'No BackTest is been Generated Yet'}
        subTitle={
          ' Hsitorrical data allows you to generate a report of your strategy, click on the button.'
        }
        actionButton={true}
        buttonName={'Generate BackTest'}
        handleAction={() => dispatch(openBacktestingReportFields(true))}
      />
    </div>
  ) : (
    // <></>

    // // </div>
    <div className="col ps-0 pe-0 mt-3">
      {isMobile ? (
        <StrategyMobileTable
          tradeOrder={backtestingReportsData?.data}
          toggleState={toggleState}
          loading={isFetching}
          getVirtualTradeData={backtestRefetch}
          pagination={backTestPagination}
          handleTableChange={handleBacktestingTable}
        />
      ) : (
        <StrategyDesktopTable
          tradeOrder={backtestingReportsData}
          toggleState={toggleState}
          loading={isFetching}
          refetch={backtestRefetch}
          pagination={backTestPagination}
          handleTableChange={handleBacktestingTable}
        />
      )}
    </div>
  );
}

export default ShowBackingTesing;
