import { useEffect, useRef, useState } from 'react';
import { BiSolidCube } from 'react-icons/bi';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import SunscriptionInfoCard from './SunscriptionInfoCard';
import SubscriptionUpgradeCardV2 from './SubscriptionUpgradeCardV2';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { btnPrimaryClass } from '../../../components/utils/defaultClasses';
import { useDispatch, useSelector } from 'react-redux';
import { userNewPlan } from '../../../features/update-plan/UpdatePlanSlice';
import { useNavigate } from 'react-router-dom';
import { showPlansModal } from '../../../features/profile/profilePlanSlice';

export default function SubscriptionDetailCompoV2({
  showDetail,
  setShowDetail,
  noAddons = false,
  toggleHandle = () => {},
}) {
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const { userDetails } = useSelector((state) => state.profileDetails);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(
    userDetails?.active_plan?.subscriptionPlanData?.plan_type === 'month'
      ? 'month'
      : 'annual',
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { overAllPlansList } = useSelector((state) => state.toUpdatePlan); //all plans

  const [subscriptionPlan, setSubscriptionPlan] = useState([]);

  const handleButtonClick = (card) => {
    let selectPlan = overAllPlansList.find((item) => item._id === card._id);
    if (window.location.pathname === '/checkout') {
      dispatch(userNewPlan(selectPlan)); //pushing the selected plan to the redux
      localStorage.setItem('new_plan', JSON.stringify(selectPlan));
      toggleHandle('1');
    } else {
      dispatch(showPlansModal(false));
      setTimeout(() => {
        navigate('/checkout');
      }, 500);
      dispatch(userNewPlan(selectPlan)); //pushing the selected plan to the redux
      localStorage.setItem('new_plan', JSON.stringify(selectPlan));
    }
  };

  const onPlanSelected = (card) => {
    const updatedPlans = subscriptionPlan.map((plan) =>
      plan.title === card.title
        ? { ...plan, selected: true }
        : { ...plan, selected: false },
    );
    setSubscriptionPlan(updatedPlans);
  };

  const getPlanIcon = (item) => {
    if (item?.plan_name === 'Free Unlimited')
      return <BiSolidCube className="text-6xl text-yellow-500 " />;
    if (item?.plan_name === 'Starter')
      return <BiSolidCube className="text-6xl text-blue-3 rotate-45" />;
    if (item?.plan_name === 'Plus')
      return (
        <BiSolidCube className="text-6xl text-landingPurple-60 rotate-180" />
      );
    if (item?.plan_name === 'Pro')
      return <BiSolidCube className="text-6xl text-green-700 rotate-180" />;
    if (item?.plan_name === 'Enterprise')
      return <BiSolidCube className="text-6xl text-zinc-700 rotate-180" />;
  };

  const getPlanTitle = (item) => {
    if (item?.plan_name === 'Free Unlimited') return 'For Growing Trader';
    if (item?.plan_name === 'Starter') return 'For Growing Trader';
    if (item?.plan_name === 'Plus') return ' For Professional Trader';

    if (item?.plan_name === 'Pro') return 'For Broker House';
    if (item?.plan_name === 'Enterprise') return 'For Broker House';
  };

  const getButtonText = (item) => {
    if (
      item.plan_name ===
      userDetails?.active_plan?.subscriptionPlanData?.plan_name
    ) {
      return 'Renew Plan';
    } else {
      // console.log(overAllPlansList);

      let getName = overAllPlansList
        .filter(
          (item) =>
            item.plan_name ===
            userDetails?.active_plan?.subscriptionPlanData?.plan_name,
        )
        .filter((item) => item.plan_type === subscriptionPeriod);

      return Number(getName[0]?.price) > item?.price
        ? 'Choose Plan'
        : 'Upgrade Plan';
    }
  };

  useEffect(() => {
    if (overAllPlansList.length > 0) {
      const subtitle =
        subscriptionPeriod === 'annual' ? 'per year' : 'per month';

      let getPlan = overAllPlansList.filter(
        (item) => item.plan_type === subscriptionPeriod,
      );

      let freePlan = overAllPlansList.find(
        (item) => item.plan_type === 'free_unlimited',
      );

      const allPlans = [freePlan, ...getPlan];

      if (allPlans) {
        const list = allPlans.map((item) => {
          const isSelected =
            item.plan_name ===
            userDetails?.active_plan?.subscriptionPlanData?.plan_name;
          const planList = item?.features_data?.map((feature) => {
            const featureValue = feature.value;
            let featureContent = featureValue;

            if (
              feature.key === 'bulk_trades' ||
              feature.key === 'derivatives_trading' ||
              feature.key === 'equity_trading'
            ) {
              featureContent =
                featureValue === 'Yes' ? (
                  <FaCheck className="text-green-3 text-lg font-medium" />
                ) : (
                  <FaTimes className="text-red-3 text-lg font-medium" />
                );
            }

            return <>{featureContent}</>;
          });

          return {
            icon: getPlanIcon(item),
            selected: isSelected,
            title: item.plan_name,
            price: item.price,
            subtitle: subtitle,
            btnText: getButtonText(item),
            plan_list_title: getPlanTitle(item),
            plan_list: planList,
            plan_active: isSelected,
            plan_type: item.plan_type,
            plan_name: item.plan_name,
            _id: item._id,
          };
        });

        setSubscriptionPlan(list);
      }
    }
  }, [overAllPlansList, userDetails, subscriptionPeriod]);

  return (
    <div className="space-y-6">
      <div className="flex justify-center">
        <div className="flex gap-1.5 p-0.5 bg-component-gray-1 rounded-lg">
          <button
            className={`${
              subscriptionPeriod === 'annual'
                ? 'bg-color-base-1 text-color-primaryColor1'
                : ''
            } py-1.5 px-3.5 min-w-[120px] rounded-lg font-medium duration-200 flex justify-center items-center gap-2`}
            onClick={() => {
              setSubscriptionPeriod('annual');
            }}
          >
            Annual{' '}
            <span className="flex px-1.5 py-0.5 bg-blue-3/15 text-color-primary rounded-lg">
              -15%
            </span>
          </button>

          <button
            className={`${
              subscriptionPeriod === 'month'
                ? 'bg-color-base-1 text-color-primaryColor1'
                : ''
            } py-1.5 px-3.5 min-w-[120px] rounded-lg font-medium duration-200`}
            onClick={() => {
              setSubscriptionPeriod('month');
            }}
          >
            Monthly
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-flex">
          <div className="relative p-2.5 pb-8 h-full w-full">
            <SunscriptionInfoCard showDetail={showDetail} />
          </div>
        </div>
        <div className="col-12 col-md-6 col-xl-8 col-xxl-9">
          <Swiper
            hashNavigation={{
              watchState: true,
            }}
            slidesPerView={3}
            freeMode={true}
            effect="fade"
            pagination={{ clickable: true }}
            loop={false}
            navigation={{
              prevEl: navigationPrevRef.current,
              nextEl: navigationNextRef.current,
            }}
            onSwiper={(s) => {}}
            onBeforeInit={(swiper) => {
              navigationPrevRef.current = swiper.params.navigation.prevEl;
              navigationNextRef.current = swiper.params.navigation.nextEl;
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Navigation]}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              576: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 10,
              },

              1200: {
                slidesPerView: 2,
                spaceBetween: 10,
              },

              1400: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1600: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            className={'h-full'}
          >
            <SwiperSlide className="relative p-2.5 pb-8 h-full d-md-none">
              <SunscriptionInfoCard showDetail={showDetail} />
            </SwiperSlide>
            {subscriptionPlan
              ?.sort((a, b) => Number(a.price) - Number(b.price))
              .map((items, index) => (
                <SwiperSlide className="relative p-2.5 pb-8 h-full" key={index}>
                  <SubscriptionUpgradeCardV2
                    key={index}
                    data={items}
                    showDetail={showDetail}
                    handleButtonClick={handleButtonClick}
                    onPlanSelected={onPlanSelected}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
      </div>
      <div className="row">
        {/* <div className="col-12 col-md-6 col-xl-4 col-xxl-3 d-none d-md-flex"></div> */}
        <div className="col-12">
          <div className="flex justify-center">
            <button
              className="text-color-primaryColor1 hover:underline"
              onClick={() => {
                setShowDetail(!showDetail);
              }}
            >
              {showDetail ? 'View less' : 'View all features'}
            </button>
          </div>
        </div>
      </div>
      {noAddons ? (
        <div>
          <div
            className={`border-[1px] border-level-2 rounded-xl bg-color-base-4 h-full relative overflow-hidden shadow-md`}
          >
            <div className="row">
              <div className="col-xl-3">
                <div className=" relative h-full min-h-[150px]">
                  <div className="absolute inset-0 bg-color-base-2 h-full p-3.5 justify-center items-center flex flex-shrink-0 basis-auto flex-grow-0">
                    <div className="flex flex-col items-center gap-2.5">
                      <h6 className="font-medium text-lg">ADD-ONS</h6>
                      <button className={`flex px-6 ${btnPrimaryClass}`}>
                        Add On
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-9">
                <div className="divide-y divide-level-2 p-3.5">
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Strategy Builder</span>
                    </div>
                    <div>
                      <span>Free</span>
                    </div>
                    <div>
                      <span></span>
                    </div>
                  </div>
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Backtesting</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>Credit Based</span>
                    </div>
                    <div>
                      <span>1 Credit = INR 49.00/-</span>
                    </div>
                  </div>
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Virtual Trades</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>Plan Based</span>
                    </div>
                  </div>
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Live Trades</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>Plan Based</span>
                    </div>
                  </div>
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Strategy Development</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>Hours Based</span>
                    </div>
                    <div>
                      <span>INR 1999.00/-</span>
                    </div>
                  </div>

                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Historical Data</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>API Credit Based</span>
                    </div>
                    <div>
                      <span>1 API Credit = INR 19.00/-</span>
                    </div>
                  </div>
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Bluk Order Placement</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>Plan Based</span>
                    </div>
                    <div>
                      <span>INR 1499.00/Month</span>
                    </div>
                  </div>
                  <div className="flex items-center py-2.5">
                    <div className="w-full max-w-[250px]">
                      <span>Premium Strategies</span>
                    </div>
                    <div className="w-full max-w-[250px]">
                      <span>Dynamic Pricing</span>
                    </div>
                    <div>
                      <span>individual Strategy Based Monthly Access</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}
