import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ModalInputFields from '../components/UI/ModalInputFields';
import {
  btnPrimaryClass,
  btnSecondayClass,
} from '../components/utils/defaultClasses';
import { useMutation } from '@tanstack/react-query';
import {
  createVirtualStrategyRequest,
  updateVirtualStrategyRequest,
} from '../API/strategy/strategyPagePermissionApi';
import useVirtualStrategyDetails from '../components/hooks/useVirtualStrategyDetails';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import mydecodedTokenFunction from './decodetoken';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '../components/Common/Drawer';
import { openVirualReportFields } from '../features/strategyReport/strategyDataDetailSlice';
import NoDataFoundV2 from '../components/Common/NoDataFoundV2';
import { MdCheck, MdClose, MdOutlineEdit } from 'react-icons/md';
import { updateRequest } from '../API/strategy/strategyDetail';
import moment from 'moment';

function VirtualReportInputs({
  inputs,
  upDateData,
  strategyID,
  configData,
  reportName,
  setHeaderTopTape = () => {},
  reportData,
  strategyData,
}) {
  const virtualModalRef = useRef();
  let { id } = useParams();
  let getuserBots = localStorage.getItem('token');
  let userid = mydecodedTokenFunction(getuserBots);
  const { virtualRefech } = useVirtualStrategyDetails();
  let location = window.location.pathname.split('/')[1];
  const { showVirtualReportDrawer } = useSelector(
    (state) => state.completeStrategyDetails,
  );
  const [, setNoChanges] = useState(false);
  const [fieldsDisable, setFieldsDisable] = useState(false);
  var dispatch = useDispatch();

  useEffect(() => {
    if (location !== 'strategy' && location !== 'back-testing') {
      setFieldsDisable(true);
    } else {
      setFieldsDisable(false);
    }
  }, [location]);

  const mutation = useMutation({
    mutationFn: (payload) => createVirtualStrategyRequest(payload),
    onSuccess: (res) => {
      virtualRefech();
      dispatch(openVirualReportFields(false));
      if (res.data.status) {
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const upDateReport = useMutation({
    mutationFn: (payload) => updateVirtualStrategyRequest(payload),
    onSuccess: (res) => {
      if (res.data.status) {
        upDateData();
        setHeaderTopTape({
          top: false,
          middle: false,
          topLast: false,
          bottom: true,
        });
        dispatch(openVirualReportFields(false));
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const virtualReportSubmission = (values) => {
    let formatValues = { ...values };
    // Find all inputs that are of type 'time'
    let timeInputs = inputs.filter((item) => item.input_type === 'Time');
    // Format each time input value to 'HH:mm'
    timeInputs.forEach((item) => {
      if (values[item.value_name]) {
        formatValues[item.value_name] = values[item.value_name].format('HH:mm');
      }
    });

    if (location === 'strategy' || location === 'back-testing') {
      var payload = {
        user_id: userid.id,
        strategy_id: strategyID,
        status: false,
        current_status: 'pending',
        vt_inputs: formatValues,
      };
      mutation.mutate(payload);
      virtualModalRef.current.resetFields();
    } else {
      if (JSON.stringify(formatValues) === JSON.stringify(configData)) {
        setNoChanges(true);
      } else {
        var virtual_payload = {
          user_id: userid.id,
          strategy_id: strategyID,
          status: true,
          vt_id: id,
          vt_inputs: formatValues,
        };
        upDateReport.mutate(virtual_payload);
        setNoChanges(false);
      }
    }
  };

  const getFooter = useMemo(() => {
    if ((inputs && inputs.length === 0) || fieldsDisable) {
      return null;
    } else {
      return (
        <div className="d-flex gap-2 justify-end">
          <button
            className={`${btnSecondayClass}`}
            onClick={() => {
              dispatch(openVirualReportFields(false));
              virtualModalRef.current.resetFields();
              setFieldsDisable(false);
            }}
          >
            Cancel
          </button>
          <button
            type="primary"
            className={`${btnPrimaryClass}`}
            onClick={() => {
              virtualModalRef.current.submit();
              // setFieldsDisable(true);
              // getButtonDisabled();
            }}
          >
            Save & Submit
          </button>
        </div>
      );
    }
  }, [dispatch, inputs, fieldsDisable]);

  //update the toogle switch
  const updateRequestStrategy = useMutation({
    mutationFn: ({ finalPayload }) => {
      return updateRequest(finalPayload, 'virtual');
    },
    onSuccess: async (res, { finalPayload }) => {
      upDateData();
      // res.data.message
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
    onError: (error) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const handleUpdate = useCallback(
    (status) => {
      if (strategyData?.subscribe_by_user) {
        let vtPayload = {
          vt_id: id,
          user_id: userid.id,
          strategy_id: strategyData?._id,
          status: status,
        };

        let finalPayload = vtPayload;

        updateRequestStrategy.mutate({ finalPayload });
      }
    },
    [strategyData],
  );
  function getActiveStatus() {
    if (reportData?.request_data?.status === true) {
      return (
        <span className="badge__green flex m-0">
          <small>Active</small>
        </span>
      );
    }
    if (reportData?.request_data?.status === false) {
      return (
        <span className="bg-red-3 text-whiteNew-0 px-[8px] py-[4px] rounded-[4px] text-[12px] flex m-0 ">
          <small>Inactive</small>
        </span>
      );
    }
  }
  return (
    <>
      <Drawer
        open={showVirtualReportDrawer}
        onClose={() => {
          dispatch(openVirualReportFields(false));
          virtualModalRef.current.resetFields();
          setNoChanges(false);
          setFieldsDisable(false);
        }}
        title={
          <div className="flex justify-between items-center pr-6 -my-3">
            <div className="flex flex-row justify-center items-center">
              <div className="flex flex-col -space-y-1">
                {(location === 'strategy' || location === 'back-testing') && (
                  <h4 className="font-weight-bold mb-0">Virtual Trade Setup</h4>
                )}
                {location !== 'strategy' && location !== 'back-testing' && (
                  <h4 className="font-weight-bold mb-0">{reportName}</h4>
                )}

                {location !== 'strategy' && location !== 'back-testing' && (
                  <div className="flex items-center">
                    <span className="text-xs text-color-secondary">
                      {moment(reportData?.request_data?.updated_at)?.format(
                        'MMMM Do YYYY, h:mm:ss a',
                      )}
                    </span>
                  </div>
                )}
              </div>
              <div className="ms-2">{getActiveStatus()}</div>
            </div>
            {location !== 'strategy' && location !== 'back-testing' && (
              <div className="flex gap-2 items-center">
                <button
                  className={`${
                    reportData?.request_data?.status
                      ? ' bg-red-3'
                      : 'bg-green-3 '
                  } flex justify-center items-center py-[7px] px-[15px] text-[13px] text-whiteNew-0 rounded-[6px] gap-1 font-normal`}
                  onClick={() => {
                    handleUpdate(
                      reportData?.request_data?.status ? false : true,
                    );
                  }}
                >
                  {reportData?.request_data?.status ? <MdClose /> : <MdCheck />}

                  <span>
                    {reportData?.request_data?.status ? 'InActive' : 'Active'}
                  </span>
                </button>
                <button
                  className={`${btnPrimaryClass} ${
                    !fieldsDisable ? 'bg-blue-400' : 'active'
                  } py-[7px] px-[15px] text-[13px] bg-[#e9f3ff] text-blue-600/80  hover:bg-blue-200 [&.active]:bg-color-primaryColor2 font-semibold [&.active]:text-whiteNew-0 [&.active]:hover:text-whiteNew-0`}
                  onClick={() => {
                    setFieldsDisable(!fieldsDisable);
                  }}
                >
                  <MdOutlineEdit />
                  <span>Edit</span>
                </button>
              </div>
            )}
          </div>
        }
        width={'1200px'}
        footer={getFooter}
      >
        <div className="position-relative tradesetting_tab -mt-3">
          {inputs && inputs.length > 0 ? (
            <ModalInputFields
              ref={virtualModalRef}
              inputs={inputs}
              primaryButtonName="Generate"
              sumbitForm={virtualReportSubmission}
              fieldsDisable={fieldsDisable}
            />
          ) : (
            <div className="w-full flex justify-center text-center">
              <NoDataFoundV2
                title={'No data found.'}
                className="text-4xl"
                minHeight={'min-h-[200px] py-2'}
              />
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default VirtualReportInputs;
