import { Form } from 'antd';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { userSignUp } from '../../helpers/api/Auth/auth';

const useSignUp = () => {
  let history = useNavigate();
  const [form] = Form.useForm();
  const isAgreed = Form.useWatch('agree_terms_conditions', form);

  const insertNote = useCallback(() => {
    const { email, first_name, last_name, mobileNum, password, role } =
      form.getFieldsValue();

    let payload = {
      signup_type: role,
      email,
      first_name,
      last_name,
      phone_no: mobileNum,
      password,
      user_accessibility: ['1', '2'],
      default_stock: [],
    };

    return userSignUp(payload);
  }, [form]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      // console.log('res', res);
      const { email } = form.getFieldsValue();
      if (res?.data?.status) {
        history('/home');
        if (res?.data?.message === 'User added successfully.') {
          history(`/auth-verify-email?email=${email}`);
        } else {
          //console.log(res.data.message);
          return toast.error(res?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      } else {
        if (res.data.message === 'Email already registered.') {
          return toast.error(res?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        } else {
          return toast.error(res?.data?.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      }
    },
    onError: (err) => {
      console.log('err', err);
      return toast.error('something went wrong server error', {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const SignUp = useCallback(() => {
    // var minMaxLength = /^[\s\S]{8,16}$/,
    //   upper = /[A-Z]/,
    //   lower = /[a-z]/,
    //   number = /[0-9]/;
    // if (
    //   minMaxLength.test(signUpData.password) &&
    //   upper.test(signUpData.password) &&
    //   lower.test(signUpData.password) &&
    //   number.test(signUpData.password)
    // ) {
    //   // console.log("Is a Valid password");
    //   setPasswordError('');
    // } else {
    //   return setPasswordError(
    //     'Password Should contain atleast one Uppercase, Lowercase and Numeric',
    //   );
    // }
    mutation.mutate();
  }, [mutation]);

  const value = useMemo(() => {
    return { form, isAgreed, SignUp, isLoading: mutation?.isPending };
  }, [form, isAgreed, SignUp, mutation?.isPending]);

  return value;
};

export default useSignUp;
