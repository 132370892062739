import { Logo } from '../../assets/Icon';
import { Form, Skeleton } from 'antd';
import { btnPrimaryClass } from '../../components/utils/defaultClasses';
import { Card } from 'react-bootstrap';
import useResendEmailVerifyLink from '../../components/hooks/useResendEmailVerifyLink';
import OtpTimer from '../../components/Common/OtpTimer';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function VerifyEmail() {
  const navigate = useNavigate();
  const {
    ResendLink,
    query,
    isExpired,
    message,
    isLoading,
    queryClient,
    isPasswordVerified,
    userID,
    backToLogin,
  } = useResendEmailVerifyLink();

  useEffect(() => {
    // Cleanup function to remove data on unmount
    return () => {
      queryClient.removeQueries(['emailVerify']); // Remove the specific query data
    };
  }, []);
  // const handleButtonClick = () => {
  //   const email = query.get('email');
  //   if (!isPasswordVerified) {
  //     navigate(`/user-set-password?userID=${userID}&email=${email}`);
  //   } else {
  //     navigate('/');
  //   }
  // };

  return (
    <div className="bg-color-base-2/30 dark:bg-color-base-4">
      <section className="container-xxl">
        <div className="min-h-screen flex justify-center items-center relative z-10">
          <div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
            <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden relative z-10">
              <Card.Body className="p-[2rem]">
                <div className="flex justify-center mb-4 mt-2">
                  <div className="appBrand max-w-[180px]">
                    <Logo />
                  </div>
                </div>
                <div className="pt-2 mb-4 flex flex-col">
                  {isLoading ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <h2 className="mb-1 text-lg text-color-primary">
                        {message?.title}
                      </h2>
                      <p className="text-[13px] text-color-secondary/90">
                        {message?.Description}
                      </p>
                    </>
                  )}
                </div>
                <Form layout="vertical">
                  {isLoading ? (
                    <Skeleton.Input
                      active={true}
                      size="default"
                      shape="default"
                      block={true}
                    />
                  ) : (
                    <>
                      {(message?.button === 'login' ||
                        message?.button === 'setPassword') && (
                        <div className="text-center mt-3.5">
                          <Form.Item>
                            <button
                              type="primary"
                              htmlType="submit"
                              className={`${btnPrimaryClass} w-full`}
                              onClick={() =>
                                !isPasswordVerified
                                  ? navigate(
                                      `/user-set-password?userID=${userID}&email=${query.get(
                                        'email',
                                      )}&token=${query.get('token')}`,
                                    )
                                  : navigate('/')
                              }
                            >
                              {!isPasswordVerified ? 'Set password' : 'Login'}
                            </button>
                          </Form.Item>
                        </div>
                      )}
                      {message?.button === 'resend_email' && !isExpired && (
                        <>
                          <div className="text-center mt-3.5 flex flex-row">
                            <Form.Item>
                              <button
                                type="button"
                                className={`${btnPrimaryClass} w-full`}
                                onClick={() => ResendLink(query.get('email'))}
                              >
                                Resend Email
                              </button>
                            </Form.Item>
                            {backToLogin()}
                          </div>
                        </>
                      )}
                      {(message?.button === 'counter' || isExpired) && (
                        <>
                          <div className="flex justify-center w-full min-h-[34px]">
                            <OtpTimer
                              minutes={1}
                              seconds={30}
                              text="Resend Email Link in"
                              ButtonText="Resend Email"
                              background="none"
                              textColor="unset"
                              buttonColor="#cc8411"
                              className="w-full"
                              resend={() => ResendLink(query.get('email'))}
                            />
                          </div>
                          {backToLogin()}
                        </>
                      )}
                    </>
                  )}
                </Form>
                {message?.button !== 'login' &&
                  message?.button !== 'setPassword' && (
                    <div className="mt-3">
                      <p className="text-xs text-gray-400 text-center">
                        <i>
                          In case the email is not received in the Inbox, please
                          check the Spam folder.
                        </i>
                      </p>
                    </div>
                  )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </section>
    </div>
  );
}
