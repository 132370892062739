import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  creditList: {},
  premium_strategies: {},
  virtual_requests: {},
  live_requests: {},
  backtest_requests: {},
};

export const userCreditsDetails = createSlice({
  name: 'userCreditsDetails',
  initialState,
  reducers: {
    userCreditList: (state, { payload }) => {
      state.creditList = payload;
    },
    userPremiumStrategies: (state, { payload }) => {
      state.premium_strategies = payload;
    },
    userVirtualDeployments: (state, { payload }) => {
      // console.log('payload', payload);
      state.virtual_requests = payload;
    },
    userBacktestDeployments: (state, { payload }) => {
      // console.log('payload', payload);
      state.backtest_requests = payload;
    },
    userLiveDeployments: (state, { payload }) => {
      state.live_requests = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  userCreditList,
  userPremiumStrategies,
  userLiveDeployments,
  userVirtualDeployments, userBacktestDeployments
} = userCreditsDetails.actions;

export default userCreditsDetails.reducer;
