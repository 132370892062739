import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import {
  get2FAOTPBYEmail,
  resend2FAOTPBYEmail,
} from '../../../components/helpers/api/Auth/auth';
import { useNavigate } from 'react-router-dom';
import useSearchQuery from '../../../components/SignUP/hooks/useSearchQuery';

const useTwoFactorAuth = () => {
  const [form] = Form.useForm();
  const [otpError, setOtpError] = useState();
  const [otp, setOTP] = useState();
  const navigate = useNavigate();
  const query = useSearchQuery();

  // console.log('otp', otp);/

  const insertNote = (otp) => {
    const payload = {
      otp: otp,
      email: query.get('email'),
    };

    return get2FAOTPBYEmail(payload);
  };

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      localStorage.setItem('token', res.data.access_token);
      localStorage.setItem('allbots', res.data.bot_list);
      localStorage.setItem(
        'st_access',
        JSON.stringify(res.data.st_access ? res.data.st_access : []),
      );
      if (res.data.status === true) {
        navigate('/portfolio/virtual');
      }
      // localStorage.removeItem('2FATotalAttempt');
    },
    onError: (err) => {
      setOtpError('Invalid OTP');
    },
  });

  const insertResendNote = (otp) => {
    const payload = {
      email: query.get('email'),
    };

    return resend2FAOTPBYEmail(payload);
  };

  const resendOTP = useMutation({
    mutationFn: insertResendNote,
    onSuccess: (user) => {},
    onError: () => {
      // setOtpError('Invalid OTP');
    },
  });

  const submitForm = useCallback(() => {
    let testOTP = otp ? otp?.length : 0;
    if (testOTP < 6) {
      setOtpError('OTP should be of 6 digit code.');
    } else {
      mutation.mutate(otp);
    }
    // form.resetFields();
  }, [otp, mutation]);

  const udateOTP = useCallback(
    (value) => {
      otpError && setOtpError();
      setOTP(value);
    },
    [otpError],
  );

  const value = useMemo(() => {
    return {
      form,
      otpError,
      udateOTP,
      setOtpError,
      submitForm,
      resendOTP,
      isLoading: mutation.isLoading,
    };
  }, [
    form,
    otpError,
    udateOTP,
    setOtpError,
    submitForm,
    resendOTP,
    mutation.isLoading,
  ]);

  return value;
};

export default useTwoFactorAuth;
