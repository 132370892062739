import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Form } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  checkVerifiedUser,
  userResetPassword,
  userSetPassword,
} from '../../../components/helpers/api/Auth/auth';
import useSearchQuery from '../../../components/SignUP/hooks/useSearchQuery';
import useSetPasswordLink from './useSetPasswordLink';
import { checkTokenExpired } from '../../../utils/decodetoken';
import useResetPasswordLink from './useResetPasswordLink';
// import mydecodedTokenFunction from '../../../utils/decodetoken';

const useResetPassword = (type) => {
  let navigate = useNavigate();
  const query = useSearchQuery();
  //   const decodedToken = mydecodedTokenFunction(query.get('token'));
  const [form] = Form.useForm();
  const decodedToken = checkTokenExpired(query.get('token'));
  const userEmail = query.get('email');
  const [tokenExpired, setTokenExpired] = useState();
  const [isExpired, setIsExpired] = useState(false);

  const { ResendLink: ResendSetPasswordLink } = useSetPasswordLink();
  const { ResendLink: ResendResetPasswordLink } = useResetPasswordLink();

  const queryClient = useQueryClient();

  let { data, isLoading } = useQuery({
    queryKey: ['emailVerify'],
    queryFn: async () => {
      if (query.get('token') && type === 'set') {
        return await checkVerifiedUser(query.get('token'), query.get('email'));
      } else {
        return null;
      }
    },
  });

  const isPasswordVerified = useMemo(() => {
    return data?.data?.password_set || false;
  }, [data]);

  const message = useMemo(() => {
    if (type === 'reset') {
      if (!query.get('token') && !decodedToken)
        return {
          title: 'Check Your Email ✉️',
          Description: (
            <>
              Reset password link sent to your email address:{' '}
              {<span className="font-bold">{query.get('email')}</span>} Please
              check your email and click the link to create new password.
            </>
          ),
          button: 'counter',
          backtologin: false,
        };
      if (!decodedToken)
        return {
          title: 'Reset password Link has Expired❗',
          Description: `Your link has expired. Please click on the 'Resend
                          Email' button to receive a new one.`,
          button: 'resend_email',
          backtologin: false,
        };
      if (decodedToken)
        return {
          title: `${type === 'set' ? 'Set' : 'Reset'} Password 🔒`,
          Description: `${userEmail ? userEmail : 'john.doe@email.com'}`,
          button: 'form',
          backtologin: false,
        };
    }

    if (!query.get('token') && !isPasswordVerified && !decodedToken)
      return {
        title: 'Check Your Email ✉️',
        Description: (
          <>
            Set password link sent to your email address:{' '}
            {<span className="font-bold">{query.get('email')}</span>} Please
            check your email and click the link to set password.
          </>
        ),
        button: 'counter',
        backtologin: false,
      };
    if (isPasswordVerified)
      return {
        title: 'Password already set successfully ✅',
        Description: `Let's login to continue.`,
        button: 'login',
        backtologin: false,
      };

    if (!isPasswordVerified && decodedToken)
      return {
        title: `${type === 'Set' ? 'Set' : 'Reset'} Password 🔒`,
        Description: `${userEmail ? userEmail : 'john.doe@email.com'}`,
        button: 'form',
        backtologin: false,
      };

    if (!isPasswordVerified && !decodedToken)
      return {
        title: 'Set password Link has Expired❗',
        Description: `Your link has expired. Please click on the 'Resend
                          Email' button to receive a new one.`,
        button: 'resend_email',
        backtologin: false,
      };
  }, [decodedToken, query, isPasswordVerified, type, userEmail]);

  const addCustomError = (from, message) => {
    form.setFields([
      {
        name: from,
        errors: [message],
      },
    ]);
  };

  const insertNote = useCallback(
    (type) => {
      const { new_password } = form.getFieldsValue();

      let payload = {
        password: new_password,
      };

      if (type === 'set') {
        payload = { ...payload, user_id: query.get('userID') };
        return userSetPassword(payload);
      } else {
        return userResetPassword(payload, query.get('token'));
      }
    },
    [form, query],
  );

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res, type) => {
      //   console.log('res', res);
      if (!res?.data?.status) {
        if (res.data.message === 'TokenExpiredjwt expired') {
          setTokenExpired('Token Expired.');
        } else {
          addCustomError('new_password', res.data.message);
        }
      }
      if (res?.data?.status) {
        navigate(`/`);
      }
      // navigate('/');
    },
    onError: (err) => {
      return toast.error(err.response.data.message.name, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
    },
  });

  const onSubmit = useCallback(() => {
    mutation?.mutate(type);
  }, [mutation, type]);

  const backToLogin = useCallback(() => {
    return (
      <div className="d-flex justify-content-center mt-3">
        <Link className={`text-blue-2 hover:text-blue-2`} to="/">
          <i className="fa fa-unlock me-1" aria-hidden="true"></i> Back To Login
        </Link>
      </div>
    );
  }, []);

  const value = useMemo(() => {
    return {
      query,
      form,
      userEmail,
      tokenExpired,
      message,
      onSubmit,
      isExpired,
      setIsExpired,
      isLoading: mutation?.isPending,
      isLoadingData: isLoading,
      queryClient,
      ResendSetPasswordLink,
      ResendResetPasswordLink,
      backToLogin,
    };
  }, [
    query,
    form,
    userEmail,
    tokenExpired,
    message,
    onSubmit,
    isLoading,
    mutation?.isPending,
    isExpired,
    queryClient,
    setIsExpired,
    ResendSetPasswordLink,
    ResendResetPasswordLink,
    backToLogin,
  ]);

  return value;
};
export default useResetPassword;
