import React from 'react';
import { MdEventNote } from 'react-icons/md';
import { btnPrimaryClass } from '../utils/defaultClasses';
import strategy_img from '../../assets/images/strategy.svg';

export default function NoDataFoundV2({
  title,
  className,
  icon = (
    <>
      <img
        src={strategy_img}
        alt="upgrade plan"
        className="w-full h-full object-contain"
      />
    </>
  ),
  minHeight,
  subTitle = '',
  button = undefined,
  buttonProps,
  onSubmit,
  onCancel,
  primaryBtnIcon,
}) {
  // console.log(subTitle, 'subTitle');
  return (
    <React.Fragment>
      <div
        className={`${
          minHeight ? minHeight : 'min-h-[200px] py-3'
        } flex items-center flex-col justify-center min-h-[calc(100svh-400px)]`}
      >
        <div className="flex flex-col items-center">
          <div>
            <div className="text-3xl text-color-secondary/70">
              {icon ? (
                <div className={className}>{icon}</div>
              ) : (
                <>
                  <MdEventNote />
                </>
              )}
            </div>
          </div>
          <div className="text-center flex flex-col mt-1.5">
            <div className="text-base text-color-primary/80 dark:text-zinc-200">
              {title ? title : 'No Data Found'}
            </div>
            {subTitle !== null ? (
              <div
                className={`text-sm text-color-secondary/70 dark:text-zinc-500 ${className}`}
              >
                {subTitle ? subTitle : ''}
              </div>
            ) : null}
          </div>
        </div>
        {buttonProps?.secondaryBtnText ||
        buttonProps?.primaryBtnText ||
        button ? (
          <>
            <div className="p-2 flex mt-1.5 items-center gap-2.5">
              {buttonProps?.secondaryBtnText ? (
                <>
                  <button
                    onClick={onCancel}
                    type="button"
                    className={`text-color-primaryColor2 text-sm font-medium capitalize`}
                  >
                    {buttonProps?.secondaryBtnText}
                  </button>
                  <span className="text-color-secondary font-semibold">Or</span>
                </>
              ) : null}
              {buttonProps?.primaryBtnText ? (
                <>
                  <button
                    onClick={onSubmit}
                    className={`${btnPrimaryClass} capitalize min-w-[145px] text-sm`}
                  >
                    {buttonProps?.primaryBtnIcon}
                    {buttonProps?.primaryBtnText}
                  </button>
                </>
              ) : null}
              {button}
            </div>
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
}
