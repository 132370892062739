import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  startegySubscribeList: [],
};

export const subscribesStragies = createSlice({
  name: 'subscribedDetails',
  initialState,
  reducers: {
    userSubscribedStrategies: (state, { payload }) => {
      state.startegySubscribeList = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userSubscribedStrategies } = subscribesStragies.actions;

export default subscribesStragies.reducer;
