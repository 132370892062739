import React from 'react';
import { Tooltip as TooltipAntd } from 'antd';
export default function Tooltip({
  children,
  title,
  align,
  arrow,
  autoAdjustOverflow,
  color,
  defaultOpen,
  destroyTooltipOnHide,
  getPopupContainer,
  mouseEnterDelay,
  mouseLeaveDelay,
  overlayClassName,
  overlayStyle,
  overlayInnerStyle,
  placement,
  trigger,
  open,
  zIndex,
  onOpenChange,
  className,
  childClassname,
  from,
}) {
  React.useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);
  return (
    <React.Fragment>
      <TooltipAntd
        title={
          from === 'dashboard' ? (
            <span onClick={(e) => e.stopPropagation()}>{title}</span>
          ) : (
            title
          )
        }
        align={align}
        arrow={arrow}
        autoAdjustOverflow={autoAdjustOverflow}
        color={color}
        defaultOpen={defaultOpen}
        destroyTooltipOnHide={
          destroyTooltipOnHide ? destroyTooltipOnHide : true
        }
        className={className}
        getPopupContainer={getPopupContainer}
        mouseEnterDelay={mouseEnterDelay}
        mouseLeaveDelay={mouseLeaveDelay}
        overlayClassName={overlayClassName}
        overlayStyle={overlayStyle}
        overlayInnerStyle={overlayInnerStyle}
        placement={placement}
        trigger={trigger}
        open={open}
        zIndex={zIndex}
        onOpenChange={onOpenChange}
      >
        <span className={`${childClassname ? childClassname : 'flex'}`}>
          {children}
        </span>
      </TooltipAntd>
    </React.Fragment>
  );
}
