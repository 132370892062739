import { FaCrown } from 'react-icons/fa';

export default function PremiumIcon({ className = 'text-sm' }) {
  return (
    <>
      <div
        className={`flex ml-0.5 p-0.5 text-lg text-blue-2 rounded-md ${
          className ? className : null
        }`}
      >
        <FaCrown />
      </div>
    </>
  );
}
