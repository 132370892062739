import React, { useEffect, useState } from 'react';
import { WarningIcon } from '../../../assets/Icon';
import { useDispatch, useSelector } from 'react-redux';
import { userNewPlan } from '../../../features/update-plan/UpdatePlanSlice';
import { useNavigate } from 'react-router-dom';
import {
  showPlansModal,
  // userRemainder,
} from '../../../features/profile/profilePlanSlice';
import StatusStrips from '../../../components/Common/StatusStrips';

function SubscriptionExpireStrip({ lastOrder, showBottomStrip }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [headerTopTape, setHeaderTopTape] = useState({
    top: false,
    middle: false,
    bottom: false,
  });

  const { userDetails } = useSelector((state) => state.profileDetails);

  const { overAllPlansList } = useSelector((state) => state.toUpdatePlan); //all plans
  const [topStripDescription, setTopStripDescription] = useState('');
  const [showRenew, setShowRenew] = useState(false);

  const handleRenew = () => {
    let getPlan = overAllPlansList.find(
      (item) =>
        item._id === userDetails?.active_plan?.subscriptionPlanData?._id,
    );
    navigate('/checkout');
    dispatch(userNewPlan(getPlan));
    localStorage.setItem('new_plan', JSON.stringify(getPlan));
  };

  useEffect(() => {
    if (
      userDetails?.active_plan?.expired_at &&
      userDetails?.active_plan?.subscriptionPlanData?.plan_type !==
        'free_unlimited' &&
      !localStorage.getItem('virtualPortfolioTopStrip')
    ) {
      const expiryDateObj = new Date(userDetails?.active_plan?.expired_at);
      const todayDateObj = new Date();
      const diffTime = expiryDateObj - todayDateObj;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (diffDays <= 5 && diffDays >= 0) {
        if (
          userDetails?.active_plan?.subscriptionPlanData?.plan_type ===
          'signup_trial'
        ) {
          console.log('signup');
        } else {
          setShowRenew(true);
        }
        setTopStripDescription(
          `Your current plan be expired in less than ${diffDays} days! To continue enjoying the business tool`,
        );
        setHeaderTopTape((prevHeaderTopTape) => ({
          ...prevHeaderTopTape,
          top: true,
        }));
      }
      // signup_trial
    } else if (
      userDetails?.active_plan?.subscriptionPlanData?.plan_type ===
        'free_unlimited' &&
      !localStorage.getItem('virtualPortfolioTopStrip')
    ) {
      setTopStripDescription(`Your plan is expired`);
      setHeaderTopTape((prevHeaderTopTape) => ({
        ...prevHeaderTopTape,
        top: true,
      }));
    }
  }, [userDetails]);

  useEffect(() => {
    if (
      lastOrder.length > 0 &&
      !localStorage.getItem('virtualPortfolioMiddleStrip')
    ) {
      setHeaderTopTape((prevHeaderTopTape) => ({
        ...prevHeaderTopTape,
        middle: true,
      }));
    }
  }, [lastOrder.length]);

  useEffect(() => {
    if (
      showBottomStrip &&
      !localStorage.getItem('virtualPortfolioBottomStrip')
    ) {
      setHeaderTopTape((prevHeaderTopTape) => ({
        ...prevHeaderTopTape,
        bottom: true,
      }));
    }
  }, [showBottomStrip]);

  return (
    <>
      <>
        {headerTopTape?.top && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="warning"
                  title="Warning Alarm"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        top: false,
                      }));
                      localStorage.setItem('virtualPortfolioTopStrip', true);
                    },
                  }}
                  description={topStripDescription}
                  sideBtn={{
                    show: true,
                    click: () => dispatch(showPlansModal(true)),
                  }}
                  renewBtn={{
                    show: showRenew,
                    click: () => handleRenew(),
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {headerTopTape?.middle && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="exclusive"
                  title="Exclusive Feature"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        middle: false,
                      }));
                      localStorage.setItem('virtualPortfolioMiddleStrip', true);
                    },
                  }}
                  description="While having a order in the market you may also use manual and exit button next to refresh button in the below table.Swap to exit"
                />
              </div>
            </div>
          </div>
        )}
        {headerTopTape?.bottom && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="update"
                  title="Important Update"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        bottom: false,
                      }));
                      localStorage.setItem('virtualPortfolioBottomStrip', true);
                    },
                  }}
                  description="Need not to worry about your trade data,we are here.later you may find the data in closed order or in trades tab"
                />
              </div>
            </div>
          </div>
        )}
      </>
    </>

    // <>
    //   {expireMessage?.topMessage !== '' && (
    //     <div className="">
    //       <div className="relative  flex justify-center">
    //         <div className="w-full flex items-center border-[1px] border-dashed border-yellow-400 bg-yellow-400/10 p-3.5 py-1.5 max-w-[100%] relative">
    //           <div className="flex justify-between gap-2 items-center w-full">
    //             <div className="flex gap-2 w-full">
    //               <div className="shrink-0">
    //                 <WarningIcon
    //                   className={
    //                     'stroke-yellow-600 fill-yellow-500/50 text-2xl'
    //                   }
    //                 />
    //               </div>
    //               <div className="w-full">
    //                 <div>
    //                   <p className="text-sm text-color-primary font-semibold">
    //                     {expireMessage?.topMessage}
    //                   </p>
    //                 </div>
    //                 <div className="flex flex-row justify-between w-full">
    //                   <p className="text-xs text-color-primary ">
    //                     <span>
    //                       To enjoy the feature of trading tools, please
    //                     </span>
    //                     <span
    //                       className="text-blue-2 font-semibold hover:text-blue-3 px-[4px] cursor-pointer"
    //                       onClick={() =>
    //                         handleNaviagte(expireMessage?.bottomMessage[0])
    //                       }
    //                     >
    //                       {expireMessage?.bottomMessage[0]}
    //                     </span>
    //                     {expireMessage?.bottomMessage?.length > 1 && (
    //                       <>
    //                         <span>or</span>
    //                         <span
    //                           className="text-blue-2 font-semibold hover:text-blue-3 px-[4px] cursor-pointer"
    //                           onClick={() =>
    //                             handleNaviagte(expireMessage?.bottomMessage[1])
    //                           }
    //                         >
    //                           {expireMessage?.bottomMessage[1]}
    //                         </span>
    //                       </>
    //                     )}
    //                   </p>
    //                 </div>
    //               </div>
    //             </div>
    //             <div className="shrink-0">
    //               <span
    //                 className="text-xs hover:text-yellow-800 px-1.5 rounded-md cursor-pointer py-1.5 bg-yellow-200 text-yellow-600 border-[1px] border-yellow-400 border-dashed"
    //                 onClick={() => remindMeLater()}
    //               >
    //                 Remind me later
    //               </span>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </>
  );
}

export default React.memo(SubscriptionExpireStrip);
