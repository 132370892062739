// import React from 'react';
// import { DatePicker, Space } from 'antd';
// import dayjs from 'dayjs';

// const { RangePicker } = DatePicker;

// const CustomDatePicker = ({ onChange }) => (
//   <div className="d-flex cstm__dt__pick">
//     <Space direction="vertical">
//       <RangePicker
//         onChange={onChange}
//         popupClassName="cust_popup dark-ant-picker-dropdown"
//         placeholder={['Today', '']}
//         disabledDate={(current) => {
//           return dayjs().add(0, 'days') <= current;
//         }}
//       />
//     </Space>
//   </div>
// );

// export default CustomDatePicker;

import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

import moment from 'moment';
import { formClass } from '../utils/defaultClasses';

const { RangePicker } = DatePicker;
const rangePresets = [
  {
    label: (
      <>
        <span className="text-color-primary">Last 7 Days</span>
      </>
    ),
    value: [dayjs().add(-7, 'd'), dayjs()],
  },
  {
    label: (
      <>
        <span className="text-color-primary">Last 14 Days</span>
      </>
    ),
    value: [dayjs().add(-14, 'd'), dayjs()],
  },
  {
    label: (
      <>
        <span className="text-color-primary">Last 30 Days</span>
      </>
    ),
    value: [dayjs().add(-30, 'd'), dayjs()],
  },
  {
    label: (
      <>
        <span className="text-color-primary">Last 90 Days</span>
      </>
    ),
    value: [dayjs().add(-90, 'd'), dayjs()],
  },
];

// const customFormat = (value) => `custom format: ${value.format('DD-MM-YYYY')}`;
const CustomDatePicker = ({ onChange, classname, value }) => (
  <div className={`d-flex  ${classname}`}>
    <Space direction="vertical">
      <RangePicker
        onChange={onChange}
        presets={rangePresets}
        value={value}
        // defaultValue={dayjs('2015/01/01', ' DD-MM-YYYY')}
        // format={customFormat}
        className={`${formClass}`}
        popupClassName="cust_popup dark-ant-picker-dropdown"
        placeholder={[
          moment().format('DD-MM-YYYY'),
          moment().format('DD-MM-YYYY'),
        ]}
        disabledDate={(current) => {
          return dayjs().add(0, 'days') <= current;
        }}
      />
    </Space>
  </div>
);

export default CustomDatePicker;

//  <DatePicker
//    defaultValue={dayjs('2015/01/01', dateFormat)}
//    format={customFormat}
//  />;
