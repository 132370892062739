import axios from 'axios';
import { useEffect, useState } from 'react';
import config from '../../Config/config';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { fetchReportData } from '../../API/report';
import { valueSocket } from '../../context/socket';
import { calculateBrokerage } from '../../utils/BrokerCharges';

function useReportOpenOrder() {
  let getuserBots = localStorage.getItem('token');
  let { id, tab } = useParams();

  const [strategyData, setStrategyData] = useState({});
  const [priceData, setPriceData] = useState('');
  const [overAllPnL, setOverAllPnL] = useState('');
  const [openOrder, setOpenOrder] = useState([]);
  const [lastOrder, setLastOrder] = useState([]);
  const [brokerStatus, setBrokerStatus] = useState('');
  const [newOrder, setNewOrder] = useState([]);
  const [inputs, setInputs] = useState([]);
  const [fundBalance, setFundBalance] = useState(null);
  // const [tradeSymbols, setTradeSymbols] = useState({});

  const { data: reportData, refetch: upDateReport } = useQuery({
    queryKey: ['reportData', { tab, id }],
    queryFn: fetchReportData,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });

  //To Get Open-Orders Data
  useEffect(() => {
    const openOrders = async () => {
      try {
        const response = await axios.get(
          `${config.base_url}${tab === 'VT' ? config.virtual_trade : config.live_trade
          }/get-request-open-orders/${id}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        );
        const ordersData = response?.data?.orders_data;
        const finalOrder = ordersData
          ?.filter((obj) => obj.quantity_left > 0)
          .map((item) => ({
            ...item.vt_entry,
            quantity_left: item.quantity_left,
          })); //filtering the objects quantity_left>0
        // console.log('finalOrder', finalOrder);
        setOpenOrder(
          finalOrder.map((item) => ({
            ...item,
            livePrice: 0,
            entryTime: item?.order_timestamp,
            pnl: 0,
            key: 0,
            entryPrice: item?.trigger_price,
            charges: 0,
            entryQuantity: item?.quantity,
          })),
        ); //assign new params to the object
        // setTradeSymbols(
        //   ordersData
        //     .filter((item) => item?.quantity_left > 0)
        //     .map((item) => item.vt_entry.tradingsymbol),
        // );
        valueSocket.connect();
        valueSocket.emit('trade_orders', {
          user_id: [id],
        });
      } catch (error) {
        console.log(error);
      }
    };
    openOrders();
    return () => {
      valueSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (openOrder[0]?.strike_price) {
      const resultArray = `${(tab === 'LT'
        ? reportData?.request_data?.lt_inputs
        : reportData?.request_data?.vt_inputs
      )?.symbol.replace(/\s/g, '_')}_${openOrder[0]?.strike_price}_${openOrder[0]?.option_type
        }`;
      //to emit symbol
      valueSocket.emit('option_price', { symbol: [resultArray] });
    }
  }, [reportData, openOrder, tab]);

  //To Assign the liveprice,pnl and charges to the Open-Order
  useEffect(() => {
    const updatedData = openOrder.map((item) => {
      if (item.tradingsymbol === priceData?.optionData?.symbol) {
        item.livePrice = priceData?.optionData?.price;
        const calculateResult = calculateBrokerage(
          item.entryPrice,
          priceData?.optionData?.price,
          item.lot_size * item.quantity_left,
          item.position_type,
          reportData?.request_data?.broker_name === 'Kotak'
            ? 'Kotak'
            : 'Zerodha',
        );
        item.pnl = calculateResult[1].toFixed(2);
        item.key = new Date().getTime();
        item.charges = calculateResult[0].toFixed(2);
      }
      return item;
    });
    // console.log('updatedData', updatedData);

    setLastOrder(updatedData);
  }, [openOrder, priceData, reportData]);

  //valueSocket to emit symbol
  useEffect(() => {
    valueSocket.on('get_option_price', (livePriceData) => {
      setPriceData(livePriceData);
    });
  }, []);

  //Open-Order Trade data comes through the Socket
  useEffect(() => {
    valueSocket.on('get_trade_orders', (Data) => {
      setOpenOrder((prevData) => {
        let updatedData = [...prevData];
        const existingObjectIndex = updatedData.findIndex(
          (item) => item.request_id === Data.orderDataNew.request_id,
        ); //finding the new data exits in the open order or not
        if (existingObjectIndex !== -1) {
          if (Data?.orderDataNew?.quantity_left === 0) {
            updatedData = []; //removing the object from open-orders whose quantity_left == 0
            // setTradeSymbols([]);
            setLastOrder([]);
            // setTimeout(() => {
            //   // pnlData('today', '');
            //   setBrokerStatus(Data?.orderDataNew);
            // }, '2000');
            valueSocket.emit('option_price', { symbol: [] });
          } else {
            updatedData[existingObjectIndex] = {
              ...updatedData[existingObjectIndex],
              ...Data.orderDataNew, //updating the order which already exits
            };
          }
        } else {
          setNewOrder(Data.orderDataNew); //when new trades comes
        }
        return updatedData;
      });
    });
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    //   brokerFundRef?.current?.getBrokerFund();
    // }, '2000');

    if (newOrder.trigger_price) {
      newOrder.entryPrice = newOrder.trigger_price;
      newOrder.entryQuantity = newOrder.quantity;
      newOrder.entryTime = newOrder.order_timestamp;
      setOpenOrder([newOrder]);
    }
  }, [newOrder]);

  //To get Strategy Details
  useEffect(() => {
    // let cancel = false;
    if (reportData?.request_data?.strategy_id) {
      axios
        .get(
          `${config.base_url}/admin/strategy/${reportData?.request_data?.strategy_id}`,
          {
            headers: { Authorization: `Bearer ${getuserBots}` },
          },
        )
        .then((res) => {
          // console.log('res.data', res.data);
          // tab === 'VT' &&
          setStrategyData(res?.data?.data);
          // tab === 'LT' && setStrategyData(res.data?.data?.lt_inputs);
        })
        .catch(function (err) {
          console.log(err);
        });
    }
  }, [getuserBots, reportData, tab]);

  useEffect(() => {
    const VtModalData = strategyData?.[
      tab === 'VT' ? 'vt_inputs' : tab === 'LT' ? 'lt_inputs' : "st_inputs"
    ]?.map((strategy) => {
      strategy.value_default =
        tab === 'LT'
          ? reportData?.request_data?.lt_inputs?.[strategy.value_name]
          : tab === 'VT' ? reportData?.request_data?.vt_inputs?.[strategy.value_name] : reportData?.request_data?.st_inputs?.[strategy.value_name];

      return strategy;
    });
    setInputs(VtModalData);
  }, [strategyData, reportData, tab]);

  // //To Get Overall PNL and Todat Pnl
  async function pnlData(selectedDate) {
    try {
      let apiUrl = '';
      if (typeof selectedDate !== 'string') {
        apiUrl = `${config.base_url}${tab === 'VT' ? config.virtual_trade : config.live_trade
          }/getpnl/${id}?day=custom&start=${selectedDate[0]}&end=${selectedDate[1]
          }`;
      } else {
        apiUrl = `${config.base_url}${tab === 'VT' ? config.virtual_trade : config.live_trade
          }/getpnl/${id}?day=today`;
      }
      const response = await axios.get(apiUrl, {
        headers: { Authorization: `Bearer ${getuserBots}` },
      });
      const data = response.data;
      setOverAllPnL(data);
      // setTodayPnL(data?.filterPnl);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  useEffect(() => {
    if (reportData?.request_data?.credential_id) {
      function getBrokerFund() {
        axios
          .get(
            `${config.Kill_trade_base_url}/get_broker_fund?credential_id=${reportData?.request_data?.credential_id}`,
            {
              headers: { Authorization: `Bearer ${getuserBots}` },
            },
          )
          .then((res) => {
            setFundBalance(
              res?.data?.data?.net_balance === null
                ? 0
                : res?.data?.data?.net_balance,
            );
          })
          .catch(function (err) {
            console.log(err);
          });
      }

      getBrokerFund();
    }
  }, [reportData]);

  useEffect(() => {
    pnlData('Today');
  }, []);

  return {
    reportData,
    overAllPnL,
    lastOrder,
    inputs,
    upDateReport,
    pnlData,
    fundBalance,
    strategyData,
  };
}

export default useReportOpenOrder;
