import React, { useRef, forwardRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Logo } from '../../../assets/Icon';
import { btnPrimaryClass } from '../../utils/defaultClasses';
import { MdPrint } from 'react-icons/md';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useReactToPrint } from 'react-to-print';

export default function SubscriptionInvoice() {
  const contentToPrint = useRef(null);
  const { showBillingDetails } = useSelector((state) => state.profileDetails);
  const handlePrint = useReactToPrint({
    documentTitle: 'winraylabs',
    onBeforePrint: () => console.log('before printing...'),
    onAfterPrint: () => console.log('after printing...'),
    removeAfterPrint: true,
  });

  let { page } = useParams();

  useEffect(() => {
    if (page === 'print') {
      handlePrint(null, () => contentToPrint.current);
    }
  }, [page]);

  return (
    <>
      <div className="min-h-screen min-w-[100vw] bg-whiteNew-0">
        <div className="d-flex left__bar row mt-0 pt-[68px]">
          <div>
            <SubscriptionInvoiceLayout
              ref={contentToPrint}
              showBillingDetails={showBillingDetails}
            />
            <div className="max-w-3xl mx-auto">
              <div className="flex justify-end mt-3">
                <button
                  className={`${btnPrimaryClass}`}
                  onClick={() => {
                    handlePrint(null, () => contentToPrint.current);
                  }}
                >
                  <MdPrint />
                  Print
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const SubscriptionInvoiceLayout = forwardRef(({}, ref) => {
  let showBillingDetails =
    JSON.parse(sessionStorage.getItem('printDetails')) || {};

  return (
    <>
      <div
        className="max-w-3xl mx-auto p-6 bg-white rounded shadow-md my-6"
        id="invoice"
        ref={ref}
      >
        <div className="grid grid-cols-2 items-center">
          <div>
            <div className="appBrand max-w-[180px]">
              <Logo />
            </div>
          </div>

          <div className="text-right">
            <p>Winray Labs</p>
            <p className="text-gray-500 text-sm">sales@winraylabs.com</p>
            <p className="text-gray-500 text-sm mt-1">+91-982341232</p>
            <p className="text-gray-500 text-sm mt-1">VAT: 8657671212</p>
          </div>
        </div>

        <div className="grid grid-cols-2 items-center mt-8">
          <div>
            <p className="font-bold text-gray-800">Bill to :</p>
            <p className="text-gray-500">
              {showBillingDetails?.billing_details?.first_name}{' '}
              {showBillingDetails?.billing_details?.last_name}
              {/* ABC LLC. */}
              <br />
              {showBillingDetails?.billing_details?.address_1} ,
              {showBillingDetails?.billing_details?.state},
              {showBillingDetails?.billing_details?.country}-{' '}
              {showBillingDetails?.billing_details?.post_code}
              {/* 102, San-Fransico, CA, USA */}
            </p>
            <p className="text-gray-500">
              {showBillingDetails?.userData?.email}
            </p>
          </div>

          <div className="text-right">
            <p className="">
              Invoice number:
              <span className="text-gray-500">
                {showBillingDetails?.invoice_number}
              </span>
            </p>
            <p>
              Invoice date:{' '}
              <span className="text-gray-500">
                {' '}
                {dayjs(
                  showBillingDetails?.userSubscriptionPlanData?.created_at,
                ).format('DD/MM/YYYY')}
              </span>
              <br />
              Due date:
              <span className="text-gray-500">
                {dayjs(
                  showBillingDetails?.userSubscriptionPlanData?.expired_at,
                ).format('DD/MM/YYYY')}
              </span>
            </p>
          </div>
        </div>

        <div className="-mx-4 mt-8 flow-root sm:mx-0">
          <table className="min-w-full">
            <colgroup>
              <col className="w-full sm:w-1/2"></col>
              <col className="sm:w-1/6"></col>
              <col className="sm:w-1/6"></col>
              <col className="sm:w-1/6"></col>
            </colgroup>
            <thead className="border-b border-gray-300 text-gray-900">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Item
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  Unit Price
                </th>
                <th
                  scope="col"
                  className="hidden px-3 py-3.5 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                >
                  No. of Units
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-0"
                >
                  Total/month
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-gray-200">
                <td className="max-w-0 py-3.5 pl-4 pr-3 text-sm sm:pl-0">
                  <div className="font-medium text-gray-900">
                    {showBillingDetails?.PlanData?.plan_name} Plan
                  </div>
                  <div className="truncate text-gray-500">
                    {/* {getPlanTitle(showBillingDetails?.PlanData?.plan_name)} */}
                    {/* For Growing Trader */}
                  </div>
                </td>
                <td className="hidden px-3 py-3.5 text-right text-sm text-gray-500 sm:table-cell">
                  ₹{showBillingDetails?.PlanData?.price} /user/
                  {showBillingDetails?.PlanData?.plan_type}
                </td>
                <td className="hidden px-3 py-3.5 text-right text-sm text-gray-500 sm:table-cell">
                  1
                </td>
                <td className="py-3.5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                  ₹{showBillingDetails?.PlanData?.price}
                </td>
              </tr>

              <tr className="border-b border-gray-200">
                <td className="max-w-0 py-3.5 pl-4 pr-3 text-sm sm:pl-0">
                  <div className="font-medium text-gray-900">Tax</div>
                  <div className="truncate text-gray-500 text-[12px]">
                    * Tax mentioned may vary. The final tax amount will be
                    reflected in your invoice.Confirm
                  </div>
                </td>
                <td className="hidden px-3 py-3.5 text-right text-sm text-gray-500 sm:table-cell"></td>
                <td className="hidden px-3 py-3.5 text-right text-sm text-gray-500 sm:table-cell"></td>
                <td className="py-3.5 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-0">
                  ₹ 0.00 *
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="row"
                  colspan="3"
                  className="hidden pl-4 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  Subtotal
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                >
                  Subtotal
                </th>
                <td className="pl-3 pr-6 pt-6 text-right text-sm text-gray-500 sm:pr-0">
                  ₹{showBillingDetails?.PlanData?.price}
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  colspan="3"
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                >
                  Tax
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                >
                  Tax
                </th>
                <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                  ₹0.00
                </td>
              </tr>
              {/* <tr>
                  <th
                    scope="row"
                    colspan="3"
                    className="hidden pl-4 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell sm:pl-0"
                  >
                    Discount
                  </th>
                  <th
                    scope="row"
                    className="pl-6 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
                  >
                    Discount
                  </th>
                  <td className="pl-3 pr-6 pt-4 text-right text-sm text-gray-500 sm:pr-0">
                    - 10%
                  </td>
                </tr> */}
              <tr>
                <th
                  scope="row"
                  colspan="3"
                  className="hidden pl-4 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell sm:pl-0"
                >
                  Total
                </th>
                <th
                  scope="row"
                  className="pl-6 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                >
                  Total
                </th>
                <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-0">
                  ₹{showBillingDetails?.PlanData?.price}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        {/* <div className="border-t-2 pt-4 text-xs text-gray-500 text-center mt-16">
    Please pay the invoice before the due date. You can pay the invoice by logging in to your account from our client portal.
  </div> */}
      </div>
    </>
  );
});
