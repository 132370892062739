import React from 'react';
import StrategyViewCard from './StrategyViewCard';
import { RiShareLine, RiTimerLine } from 'react-icons/ri';
import StrategyOverview from './StrategyOverview';
import { BsArrowDownShort, BsArrowUpShort } from 'react-icons/bs';
import { HiMiniAdjustmentsVertical } from 'react-icons/hi2';
import { BiDollarCircle } from 'react-icons/bi';
import { MdOutlineAutoGraph, MdOutlineInstallDesktop } from 'react-icons/md';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { formatINR } from '../../../utils/utils';

function StrategyDetailCards() {
  const { detailsOfStrategy } = useSelector(
    (state) => state.completeStrategyDetails,
  );

  return (
    <div className="mt-3">
      <div className="row">
        <div className="col-12 px-0 py-2.5">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 gap-3">
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Created At'}
                tooltipData={`Creation date of the strategy`}
                subtitle={dayjs(detailsOfStrategy?.created_at).format(
                  'DD MMM, YYYY h:mm a',
                )}
                // subtitle={'01 Dec, 2022 5:15 pm'}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
                  </>
                }
                title={'Instrument'}
                tooltipData={`Trading instrument for this strategy`}
                subtitle={detailsOfStrategy?.instrument}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <HiMiniAdjustmentsVertical className="text-4xl text-blue-2" />
                  </>
                }
                title={'Min Cap'}
                tooltipData={`Minimum required amount for strategy`}
                subtitle={formatINR(detailsOfStrategy?.min_amount_required)}
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <RiTimerLine className="text-4xl text-blue-2" />
                  </>
                }
                title={'Risk'}
                tooltipData={`Risk percentage on funds allocated to this strategy`}
                subtitle={
                  <>
                    {detailsOfStrategy?.risk_factor
                      ? `${detailsOfStrategy?.risk_factor}%`
                      : ''}
                    <BsArrowDownShort className="text-red-2 text-xl" />
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    {detailsOfStrategy?.growth_factor
                      ? `${detailsOfStrategy?.growth_factor}%`
                      : ''}

                    <MdOutlineAutoGraph className="text-4xl text-blue-2" />
                  </>
                }
                title={'Growth'}
                tooltipData={`Potential growth percentage of allocated funds in this strategy`}
                subtitle={
                  <>
                    <BsArrowUpShort className="text-green-2 text-xl" />
                  </>
                }
              />
            </div>
            <div>
              <StrategyViewCard
                icon={
                  <>
                    <BiDollarCircle className="text-4xl text-blue-2" />
                  </>
                }
                title={'Fee'}
                tooltipData={`Fee amount associated with this strategy`}
                subtitle={'100'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(StrategyDetailCards);
