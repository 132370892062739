import { useMutation } from '@tanstack/react-query';
import { Form } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { userLogin } from '../../../components/helpers/api/Auth/auth';
import { useUserDeviceLocation } from '../../../utils/utils';
import { Link, useNavigate } from 'react-router-dom';
import useResendEmailVerifyLink from '../../../components/hooks/useResendEmailVerifyLink';
import useSetPasswordLink from './useSetPasswordLink';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import { setOpenWelcomeModal } from '../../../features/welcomeModalSlice';
import { useDispatch } from 'react-redux';

const useLogin = () => {
  let userDetails = secureLocalStorage.getItem('User_Details');
  const [showPassword, setShowPassword] = useState(false);
  const [form] = Form.useForm();
  const deviceData = useUserDeviceLocation();
  const navigate = useNavigate();
  const { ResendLink } = useResendEmailVerifyLink();
  const { ResendLink: ResendSetPasswordLink } = useSetPasswordLink();
  const dispatch = useDispatch();

  const insertNote = useCallback(() => {
    const { email, password } = form.getFieldsValue();

    let payload = {
      email: email,
      password: password,
      device_info: deviceData,
    };

    return userLogin(payload);
  }, [form, deviceData]);

  const addCustomError = (from, message) => {
    form.setFields([
      {
        name: from,
        errors: [message],
      },
    ]);
  };

  const navigateTo = useCallback(
    (to) => {
      const { email, password, remember } = form.getFieldsValue();
      navigate(to);
      if (remember) {
        secureLocalStorage.setItem('User_Details', {
          email: email,
          password: password,
        });
      }
      secureLocalStorage.setItem('toGetPass', {
        // email: email,
        password: password,
      });
    },
    [form, navigate],
  );

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      const { email } = form.getFieldsValue();
      localStorage.setItem('token', res.data.token);
      localStorage.setItem('allbots', res.data.bot_list);
      localStorage.setItem(
        'st_access',
        JSON.stringify(res.data.st_access ? res.data.st_access : []),
      );
      if (res.data.status === true) {
        navigateTo('/portfolio/virtual');
        const firstLogin = mydecodedTokenFunction(res.data.token);
        // console.log('firstLogin', firstLogin);

        if (firstLogin?.first_login) {
          setTimeout(() => {
            dispatch(
              setOpenWelcomeModal({ openWelcomeModal: true, from: 'login' }),
            );
          }, 1000);
        }
      } else {
        if (res.data.message === 'Invalid password.') {
          addCustomError('password', 'Invalid Password.');
        } else if (res.data.message === 'Please verify your email.') {
          addCustomError(
            'email',
            <div className="d-flex gap-0.5" onClick={() => ResendLink(email)}>
              Email id is not verified.
              <Link
                className={`text-blue-2 hover:text-blue-2`}
                to={`/auth-verify-email?email=${email}`}
              >
                Resend verification link.
              </Link>
            </div>,
          );
        } else if (res.data.message === 'Email not registered.') {
          addCustomError(
            'email',
            'Email id is not registered, Please Sign Up.',
          );
        } else if (res.data.message === 'OTP sent to the registered email.') {
          navigateTo(`/auth-verify-otp?email=${email}`);
        } else {
          return toast.error(res.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000,
          });
        }
      }
    },
    onError: (err) => {
      console.log('err', err);

      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });
  const setEmailErrors = useCallback(
    (message) => {
      const { email } = form.getFieldsValue();

      if (message === 'Email is not verified.') {
        return (
          <div className="d-flex gap-0.5" onClick={() => ResendLink(email)}>
            Email id is not verified.
            <Link
              className={`text-blue-2 hover:text-blue-2`}
              to={`/auth-verify-email?=${email}`}
            >
              Resend verification link.
            </Link>
          </div>
        );

        // return "";
      } else if (message === 'Password not set yet.') {
        return (
          <div
            className="d-flex gap-0.5"
            onClick={() => ResendSetPasswordLink(email)}
          >
            Please set your password.
            <Link
              className={`text-blue-2 hover:text-blue-2`}
              to={`/user-set-password?email=${email}`}
              // to={`/auth-admin-verify-email?email=${email}`}
            >
              Resend the password setup link.
            </Link>
          </div>
        );
      } else if (message === 'Email ID does not exist in our platform.') {
        return 'Email id is not registered';
      } else {
        return message;
      }
    },
    [ResendLink, ResendSetPasswordLink, form],
  );

  const loginHandle = useCallback(() => {
    mutation.mutate();
  }, [mutation]);

  const value = useMemo(() => {
    return {
      form,
      userDetails,
      showPassword,
      isLoading: mutation.isPending,
      setEmailErrors,
      setShowPassword,
      loginHandle,
    };
  }, [
    form,
    userDetails,
    showPassword,
    setEmailErrors,
    mutation.isPending,
    setShowPassword,
    loginHandle,
  ]);

  return value;
};

export default useLogin;
