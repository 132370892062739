import React, { useCallback, useEffect, useState } from 'react';
import PortfolioDesktopTable from './PortfolioDesktopTable';
import ClosedMobileCards from '../../../utils/ClosedMobileCards';
import { getClosedOrders } from '../../../API/portfolio/OrdersApi';
import { useQuery } from '@tanstack/react-query';

const ClosedOrders = ({
  isMobile = false,
  closedOrdersDate,
  tab,
  pagination,
  setPagination,
  // paginationCall,
}) => {
  // const [pagination, setPagination] = useState({
  //   current: 1,
  //   pageSize: 10,
  //   total: 0,
  // });
  const [closedOrdersData, setClosedOrdersData] = useState([]);

  const handleTableChange = useCallback((newPagination) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      current: newPagination.current,
    }));
  }, []);

  const { data, isFetching } = useQuery({
    queryKey: [
      'portfolioClosedOrders',
      { closedOrdersDate, page: pagination?.current, tab },
    ],
    queryFn: getClosedOrders,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data && !isFetching) {
      setClosedOrdersData(
        data?.orders_data.map((item) => ({
          ...item,
          strategys: item?.strategys[0]?.name,
          vt_requests:
            tab === 'live'
              ? item?.lt_requests[0]?.lt_inputs?.report_name
              : item?.vt_requests[0]?.vt_inputs?.report_name,
        })),
      );
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: data?.total_orders,
      }));
    }
  }, [data, isFetching, tab]);

  return (
    <>
      {isMobile === false ? (
        <PortfolioDesktopTable
          tradeOrder={closedOrdersData}
          from="close"
          pagination={pagination}
          handleTableChange={handleTableChange}
          tab={tab}
          loading={isFetching}
          emptyDataText="None of the Placed Order is available for a selected filter,to view Placed Order please change the date filter."
        />
      ) : (
        <></>
        // <ClosedMobileCards
        //   paginationCall={paginationCall}
        //   closedTrades={closedOrdersData}
        //   tab={tab}
        // />
      )}
    </>
  );
};

export default React.memo(ClosedOrders);
