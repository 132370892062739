import { useState, useEffect, useCallback } from 'react';
import { StatsUsageCardSmall } from './UserComponents/stats_usage/StatsUsageCard';
import { MdAutoGraph, MdLock } from 'react-icons/md';
import TableComponent from '../components/Common/TableComponent';
import useAdminStrategyList from '../components/hooks/useAdminStrategyList';
import NeedHelpButtonDrawer from '../components/UI/NeedHelpButtonDrawer';
import StatusStrips from '../components/Common/StatusStrips';
import GetStartedCard from '../components/Common/GetStartedCard';

const StrategyMarketplaceList = () => {
  let {
    pagination,
    setCategorySearch,
    handleTableChange,
    categorySearch,
    isFetching,
    premium_strategies,
    dataSource,
    columns,
  } = useAdminStrategyList();

  const [showGetStarted, setShowGetStarted] = useState(true);

  const [headerTopTape, setHeaderTopTape] = useState({
    top: true,
    middle: true,
  });

  const [percentTitle, setPercentTitle] = useState({});

  const getButtonClass = useCallback(
    (buttonName) => {
      const baseClasses =
        'px-3.5 font-medium duration-150 ease-in-out min-w-[80px]';
      const activeClasses = 'bg-blue-2/15 text-blue-2';
      const inactiveClasses =
        'hover:bg-color-base-2 text-color-secondary hover:text-color-primary';

      return categorySearch === buttonName
        ? `${baseClasses} ${activeClasses}`
        : `${baseClasses} ${inactiveClasses}`;
    },
    [categorySearch],
  );

  useEffect(() => {
    //to make the percentage of premium credits for  statsusage
    if (premium_strategies) {
      const { strategies_used, strategies_remaining, strategies_total } =
        premium_strategies;
      if (strategies_total === 0) {
        setPercentTitle({
          percent: 0,
          subtitle: '0/0',
        });
      } else if (strategies_total !== 0 && strategies_used === 0) {
        setPercentTitle({
          percent: 0,
          subtitle: `${strategies_used}/${strategies_total}`,
        });
      } else {
        setPercentTitle({
          percent: (strategies_used / strategies_total) * 100,
          subtitle: `${strategies_used}/${strategies_total}`,
        });
      }
    }
  }, [premium_strategies]);

  const slides = [
    {
      icon: (
        <div className="w-[40px] h-[40px] border-[10px] border-blue-3 rounded-full"></div>
      ),
      title: 'Choose Strategy',
      subtitle:
        'Market place allows to select or choose strategies from the trending listprepare by the team of experts ',
    },
    {
      icon: (
        <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
          <MdLock />
        </div>
      ),
      title: 'Subscribe strategy',
      subtitle:
        'Once you are selected the strategy then you may subscribe it as per your active plan in your account',
    },
    {
      icon: (
        <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
          <MdLock />
        </div>
      ),
      title: 'Analyse Strategy',
      subtitle: `This process allows you to track the movements of strategy's by running a backtest and virtual tarde`,
    },
    {
      icon: (
        <div className="bg-component-gray-4 dark:bg-component-gray-2 rounded-full flex justify-center items-center text-whiteNew-0 p-1.5 text-xl">
          <MdLock />
        </div>
      ),
      title: 'Trade Live',
      subtitle:
        "Once you're assured with performance of your selected strategy then connect your broker and set live trade ",
    },
  ];

  return (
    <div className="d-flex left__bar row" style={{ marginRight: '0px' }}>
      <div className="row px-0 mb-row">
        <div className="px-0">
          <div className="flex gap-2">
            <div className="">
              <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 256 256"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                  className="shrink-0"
                >
                  <path d="M104,188a36,36,0,1,1-36-36A36,36,0,0,1,104,188ZM34.34,117.66a8,8,0,0,0,11.32,0L60,103.31l14.34,14.35a8,8,0,0,0,11.32-11.32L71.31,92,85.66,77.66A8,8,0,0,0,74.34,66.34L60,80.69,45.66,66.34A8,8,0,0,0,34.34,77.66L48.69,92,34.34,106.34A8,8,0,0,0,34.34,117.66Zm173,70.34,14.35-14.34a8,8,0,0,0-11.32-11.32L196,176.69l-14.34-14.35a8,8,0,0,0-11.32,11.32L184.69,188l-14.35,14.34a8,8,0,0,0,11.32,11.32L196,199.31l14.34,14.35a8,8,0,0,0,11.32-11.32ZM157.66,50.34,155.31,48H176a8,8,0,0,0,0-16H136a8,8,0,0,0-8,8V80a8,8,0,0,0,16,0V59.31l2.34,2.35c17.93,17.93,17.9,35.4,14.71,46.9-4.64,16.77-19.36,31.77-35,35.68A8,8,0,0,0,128,160a8.13,8.13,0,0,0,2-.24c21.21-5.3,40.35-24.6,46.53-46.93C182.58,90.78,175.9,68.59,157.66,50.34Z"></path>
                </svg>
              </span>
            </div>
            <div className="">
              <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                Strategy Marketplace
              </h4>
              <span className="flex text-xs text-color-secondary/70">
                Manage and monitor all your Strategies.
              </span>
            </div>
          </div>
        </div>
      </div>
      {showGetStarted && (
        <div className="row px-0">
          <div className="col-12 px-0">
            <div className="flex justify-center items-center w-full">
              <GetStartedCard
                customSlides={slides}
                handleClose={() => setShowGetStarted(false)}
              />
            </div>
          </div>
        </div>
      )}
      {headerTopTape?.top && !localStorage.getItem('marketPlaceTopStrip') && (
        <div className="row mt-2 px-0">
          <div className="col-12 px-0">
            <div className="mb-2">
              <StatusStrips
                status="exclusive"
                title="Exclusive Feature"
                closeIcon={{
                  show: true,
                  click: () => {
                    setHeaderTopTape((prevHeaderTopTape) => ({
                      ...prevHeaderTopTape,
                      top: false,
                    }));
                    localStorage.setItem('marketPlaceTopStrip', true);
                  },
                }}
                description="Marketplace allow you to choose and subscribestrategy from trending derivates trading to push your fund under the export experience knowledge"
              />
            </div>
          </div>
        </div>
      )}
      {headerTopTape?.middle &&
        !localStorage.getItem('marketPlaceMiddleStrip') && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <div className="mb-2">
                <StatusStrips
                  status="update"
                  title="Important Update"
                  closeIcon={{
                    show: true,
                    click: () => {
                      setHeaderTopTape((prevHeaderTopTape) => ({
                        ...prevHeaderTopTape,
                        middle: false,
                      }));
                      localStorage.setItem('marketPlaceMiddleStrip', true);
                    },
                  }}
                  description="Our platform allow the user to choose or subscribe startegy from the free and premium"
                />
              </div>
            </div>
          </div>
        )}
      <div className="row px-0 align-items-center mt-3 mb-3 mb-row">
        <div className="col-5 col-lg-6 px-0">
          <div className="flex">
            <div className="flex items-center rounded-md overflow-hidden border-[1px] border-level-2 divide-x divide-level-2 font-normal">
              <button
                className={getButtonClass('all')}
                onClick={() => {
                  setCategorySearch('all');
                }}
              >
                All
              </button>
              <button
                className={getButtonClass('free')}
                onClick={() => {
                  setCategorySearch('free');
                }}
              >
                Free
              </button>
              <button
                className={getButtonClass('premium')}
                onClick={() => setCategorySearch('premium')}
              >
                Premium
              </button>
            </div>
          </div>
        </div>
        <div className="col-7 col-lg-6 d-flex justify-content-end items-center px-0 gap-1.5">
          <div>
            <NeedHelpButtonDrawer />
          </div>
          <div className="flex items-center gap-2">
            <div className="-my-3">
              <StatsUsageCardSmall
                icon={<>{percentTitle?.subtitle}</>}
                percent={percentTitle?.percent}
                title={'Premium Credits'}
                subTitle={percentTitle?.subtitle}
                infoText={'No credits are available'}
                size={32}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row px-0 mb-row">
        <div className="col-lg-12 px-0 strategy__tbl__data">
          <div>
            <TableComponent
              dataSource={dataSource}
              columns={columns}
              pagination={pagination}
              onChange={handleTableChange}
              total={pagination?.total}
              loading={isFetching}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StrategyMarketplaceList;
