import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

const TicketOnSuccess = ({ props }) => {
  const { closeModal, Title, TicketID } = props;
  return (
    <div className="">
      <div className="flex flex-col items-center text-center">
        <div className="mb-2">
          <span className="flex flex-col bg-blue-500 h-10 w-10 rounded-full items-center justify-center">
            <CheckOutlined className="text-lg text-white" />
          </span>
        </div>
        <p className="text-lg mb-2">{Title || '-'}</p>
        <span className="text-blue-600">#{TicketID || '-'}</span>
        <span className="w-full h-[1px] bg-zinc-200 my-3"></span>
        <span>
          We are liable to process your concern , please be patient our team
          will be touch with your in next 24 hr.{' '}
          {/* <span className="font-semibold">05 july 2024</span> */}
        </span>
        {/* <span>You can track your status in support section</span> */}
      </div>

      {/* <div className="flex flex-col items-center text-center bg-zinc-100 p-2 rounded-md border-zinc-200 border-[1px] mt-6 mb-3">
        <p>
          A confirmation has been mailed to{' '}
          <span className="text-blue-600">johndoe@gmail.com</span>
        </p>
      </div> */}
      <div className="flex flex-col gap-2 mt-5">
        <button
          className="bg-blue-500 p-2.5 text-white rounded-md font-semibold"
          onClick={closeModal}
        >
          Got it
        </button>
        {/* <button className="bg-zinc-200 p-2.5 text-zinc-800 bg-opacity-70 rounded-md font-semibold">
          Close
        </button> */}
        {/* <button>Close</button> */}
      </div>
    </div>
  );
};

export default TicketOnSuccess;
