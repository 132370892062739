import axios from 'axios';
import config from '../../../../Config/config';
import mydecodedTokenFunction from '../../../../utils/decodetoken';
import { apiHeader } from '../../../../utils/utils';

export async function userSignUp(payload) {
  const res = await axios.post(`${config.base_url}${config.sign_up}`, payload);
  return res;
}

export async function userLogin(payload) {
  const res = await axios.post(`${config.base_url}${config.login}`, payload);
  return res;
}

export async function userForgetPassword(payload) {
  const res = await axios.post(
    `${config.base_url}${config.forgot_pass}`,
    payload,
  );
  return res;
}

export async function resendEmailVerifyLink(email) {
  const res = await axios.get(
    `${config.base_url}${config.verify_email}/${email}`,
  );
  return res;
}
export async function checkemailpassword(email) {
  const res = await axios.get(
    `${config.base_url}${config.Check_email_password}/${email}`,
  );
  return res;
}

export async function setPasswordLink(payload) {
  const res = await axios.post(
    `${config.base_url}${config.set_password_link}`,
    payload,
  );
  return res;
}
export async function userResetPassword(payload, token) {
  const res = await axios.post(
    `${config.base_url}${config.reset_pass}/${token}`,
    payload,
  );
  return res;
}

export async function userSetPassword(payload, token) {
  const res = await axios.post(`${config.base_url}${config.set_pass}`, payload);
  return res;
}

export async function checkEmail(email) {
  const res = await axios.get(
    `${config.base_url}${config.checkEmail}/${email}`,
  );
  return res;
}

export async function getAllTickets(page, limit, search, user_id) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${config.base_url}${config.get_all_tickets}?page=${page}&limit=${limit}&search_key=${search}&user_id=${user_id}`,
    json_header,
  );
  return res;
}

export async function createTicketeSupport(payload) {
  const { form_header } = apiHeader();
  const res = await axios.post(
    `${config.base_url}${config.create_ticket}`,
    payload,
    form_header,
  );
  return res;
}

export async function onDeleteTickets(payload) {
  const { json_header } = apiHeader();
  const res = await axios.post(
    `${config.base_url}${config.ticket_delete}`,
    payload,
    json_header,
  );
  return res;
}

export async function getTicketSupportReplies(page, limit, id) {
  const { json_header } = apiHeader();
  const res = await axios.get(
    `${config.base_url}${config.ticket_replies}?page=${page}&limit=${limit}&ticket_id=${id}`,
    json_header,
  );
  return res;
}

export async function sendTicketMessage(payload) {
  const { form_header } = apiHeader();
  const res = await axios.post(
    `${config.base_url}${config.ticket_message}`,
    payload,
    form_header,
  );
  return res;
}
