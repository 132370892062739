import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Link from '@mui/material/Link';
import { btnPrimaryClass, btnSecondayClass } from '../../utils/defaultClasses';
import {
  MdKeyboardArrowDown,
  MdOutlineInstallDesktop,
  MdPlayArrow,
} from 'react-icons/md';
import { useMutation, useQuery } from '@tanstack/react-query';
import BackTestingDetailCards from './BackTestingDetailCards';
import BackTestingTabs from './BackTestingTabs';
import BacktestingOverview from './BacktestingOverview';
import config from '../../../Config/config';
import axios from 'axios';
import { apiHeader } from '../../../utils/utils';
import { getBackTestReport } from '../../../API/report/backtestApi';
import NoDataFoundV2 from '../../Common/NoDataFoundV2';
import Tooltip from '../../Common/Tooltip';
import { BsClockHistory } from 'react-icons/bs';
import LogActivityDrawer from '../../Common/logs/LogActivityDrawer';
import { Dropdown } from 'antd';
import BacktestingReportTab from '../StrategyDetails/BacktestingReportTab';
import Drawer from '../../Common/Drawer';
import ModalInputFields from '../ModalInputFields';
import ActionCardUi from '../../../Pages/UserComponents/subscription/ActionCardUi';
import ReportTradeBacktesting from '../ReportPage/ReportTradeBacktesting';
import { toast } from 'react-toastify';
import { updateBacktestingStrategyRequest } from '../../../API/strategy/strategyPagePermissionApi';
import ReportDrawerInputs from '../../../utils/ReportDrawerInputs';
import { useDispatch } from 'react-redux';
import {
  openLiveReportFields,
  openVirualReportFields,
} from '../../../features/strategyReport/strategyDataDetailSlice';
import StatusStrips from '../../Common/StatusStrips';
import AccordianSmooth from '../../Common/AccordianSmooth';
import StrategyViewCard from '../StrategyDetails/StrategyViewCard';

const BackTestingReport = () => {
  let history = useNavigate();
  let { id } = useParams();
  const [toggleState, setToggleState] = useState('overview');
  const [tradeType, setTradeType] = useState('virtual');
  const [backtestData, setBacktestData] = useState([]);
  const [backtestRequestData, setBacktestRequestData] = useState([]);
  const [strategyData, setStrategyData] = useState([]);
  const [strategyDataFull, setFullStrategyData] = useState([]);
  const [inputsForm, setInputsForm] = useState([]);
  const [showBacktestDrawer, setShowBacktestDrawer] = useState(false);
  const [showAccordian, setShowAccordian] = useState(false);
  // const { json_header } = apiHeader();
  const [hideTopStrip, setHideTopStrip] = useState({
    status: '',
    title: '',
    show: false,
    description: '',
    sideBtn: false,
    sideLink: false,
  });
  const toggleTab = useCallback((index) => {
    setToggleState(index);
  }, []);

  const {
    data,
    refetch: refetchBacktestingData,
    isFetching,
  } = useQuery({
    queryKey: ['backtest_report', { id }],
    queryFn: getBackTestReport,
    refetchOnMount: 'always',
  });

  const upDateReport = useMutation({
    mutationFn: (payload) =>
      updateBacktestingStrategyRequest(payload, payload._id),
    onSuccess: (res) => {
      if (res.data.status) {
        refetchBacktestingData();
        return toast.success('Update Successfully ', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
    },
    onError: (err) => {
      return toast.error(err.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
    },
  });

  const backtestingReportSubmission = useCallback(
    (values) => {
      var backtest_payload = {
        user_id: values.user_id,
        strategy_id: values.strategy_id,
        status: 'Pending',
        bt_inputs: values?.bt_inputs,
        _id: values?._id,
      };
      upDateReport.mutate(backtest_payload);
    },
    [upDateReport],
  );
  useEffect(() => {
    if (data) {
      setBacktestData(data?.data?.[0]);
      setBacktestRequestData(data?.dataRequest);
    }
  }, [data]);

  const [openLogsActivity, setOpenLogsActivity] = useState(false);
  const showLogsActivityDrawer = () => {
    setOpenLogsActivity(true);
  };
  const onClose = () => {
    setOpenLogsActivity(false);
  };

  // useEffect(() => {
  //   axios
  //     .get(`${config.base_url}${config.get_request}/${id}`, json_header)
  //     .then((res) => {
  //       // setLoading(false);
  //       // setBTData(res?.data?.data);
  //       // setConfigData(res?.data?.data[0]?.config);
  //       // setTrades(res.data?.data[0]?.trades);
  //       // let tempStock = {};
  //       // res.data?.data[0]?.trades.map((item) => {
  //       //   if (tempStock[item.stock] === undefined) {
  //       //     tempStock[item.stock] = [item.date];
  //       //   } else {
  //       //     tempStock[item.stock] = [...tempStock[item.stock], item.date];
  //       //   }
  //       // });
  //       // let stock = [];
  //       // let dates = [];
  //       // for (let x in tempStock) {
  //       //   stock.push(x);
  //       //   dates.push(tempStock[x]);
  //       // }
  //       // getCandles(stock[0], dates[0][0]);
  //       // setStocks(stock);
  //       // setCurrentStock(stock[0]);
  //       // setVariableDates(dates);
  //       // setCurrentDate(dates[0][0]);
  //       // setStockDate(dates[0]);
  //       // setReportData(res.data?.data[0]?.mt_report);
  //     })
  //     .catch(function (status) {});
  // }, []);
  useEffect(() => {
    if (
      !localStorage.getItem('backtestingStrip') &&
      data?.dataRequest?.status === 'Draft'
    ) {
      setHideTopStrip({
        name: 'backtestingStrip',
        status: 'update',
        title: ':Important Update',
        show: true,
        sideLink: false,
        sideBtn: false,
        description:
          'It’s seems that backtest setup is been draft , you may click on play icon and run the setup to generate the backtest reports.',
      });
      // setHideTopStrip(true);
    } else if (
      !localStorage.getItem('backtestingStrip') &&
      (data?.dataRequest?.status === 'Pending' ||
        data?.dataRequest?.status === 'Processing')
    ) {
      setHideTopStrip({
        name: 'backtestingStrip',
        status: 'update',
        title: 'Important update',
        show: true,
        sideLink: false,
        sideBtn: false,
        description:
          ' Once the backtest is been processed then you may able to find your reports and trades below.',
      });
    } else if (
      !localStorage.getItem('backtestingStrip') &&
      data?.dataRequest?.status === 'Generated'
    ) {
      setHideTopStrip({
        name: 'backtestingStrip',
        status: 'success',
        title: 'Success update',
        show: true,
        sideLink: false,
        sideBtn: false,
        description:
          'Your backtest report is been successfully generated you have look at below in reports and trades tab.',
      });
      // setHideTopStrip(true);
    }
  }, [data?.dataRequest?.status]);
  // To get Strategy Details
  useEffect(() => {
    // let cancel = false;
    if (backtestRequestData?.strategy_id) {
      axios
        .get(
          `${config.base_url}/admin/strategy/${backtestRequestData?.strategy_id}`,
          {
            headers: {
              'content-type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          },
        )
        .then((res) => {
          setFullStrategyData(res.data?.data);
          setStrategyData(res.data?.data?.st_inputs);
          // tab === 'LT' && setStrategyData(res.data?.data?.lt_inputs);
        })
        .catch(function (err) {
          console.error(err);
        });
    }
  }, [backtestRequestData]);

  useEffect(() => {
    const VtModalData = strategyData?.map((strategy) => {
      strategy.value_default = backtestData?.config[strategy.value_name];

      return strategy;
    });
    setInputsForm(VtModalData);
  }, [strategyData, backtestData]);

  const getFooter = useMemo(
    () => {
      return (
        <>
          <div className="d-flex gap-2 justify-end">
            <button
              className={`${btnSecondayClass}`}
              // onClick={() => {
              //   dispatch(openVirualReportFields(false));
              //   virtualModalRef.current.resetFields();
              // }}
            >
              Cancel
            </button>
            <button
              type="primary"
              className={`${btnPrimaryClass}`}
              // onClick={() => {
              //   virtualModalRef.current.submit();
              //   // getButtonDisabled();
              // }}
            >
              Save & Submit
            </button>
          </div>
        </>
      );
      //  if (inputs && inputs.length === 0) {
      //    return null;
      //  } else {
      //    return (
      //      <div className="d-flex gap-2 justify-end">
      //        <button
      //          className={`${btnSecondayClass}`}
      //          onClick={() => {
      //            dispatch(openVirualReportFields(false));
      //            virtualModalRef.current.resetFields();
      //          }}
      //        >
      //          Cancel
      //        </button>
      //        <button
      //          type="primary"
      //          className={`${btnPrimaryClass}`}
      //          onClick={() => {
      //            virtualModalRef.current.submit();
      //            // getButtonDisabled();
      //          }}
      //        >
      //          Save & Submit
      //        </button>
      //      </div>
      //    );
      //  }
    },
    [
      // dispatch,
      // inputs
    ],
  );
  const dispatch = useDispatch();

  const generateInputsFromBacktest = (data, strategyDataFull) => {
    let configData = data?.dataRequest?.bt_inputs;
    let inputData =
      tradeType === 'virtual'
        ? strategyDataFull?.vt_inputs
        : strategyDataFull?.lt_inputs;
    let newMapData = inputData?.map((input) => {
      let obj = {
        ...input,
        value_default: configData[input.value_name],
      };
      return obj;
    });
    return newMapData;
  };
  return (
    <>
      <div className="left__bar">
        <div className="row bg__white p-2 mt-3 rounded align-items-center">
          <div className="col-lg-6 col-6">
            <div className="flex items-center gap-2">
              <div className="">
                <div className="flex">
                  <Link
                    // to={'/strategies'}
                    // className="btn__back"
                    className={`${btnSecondayClass} rounded-full py-2.5`}
                    onClick={() => {
                      history(-1);
                    }}
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="20px"
                      width="20px"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path>
                    </svg>
                  </Link>
                </div>
              </div>
              <div className="">
                <div>
                  <div className="flex items-center gap-1.5">
                    <h2 className="strtgy__ttl">
                      {backtestRequestData?.bt_inputs?.report_name.toUpperCase()}
                    </h2>
                    <span className="badge__green flex m-0">
                      <small>{backtestRequestData?.status}</small>
                    </span>
                  </div>
                  <span class="primary__text__mute fs-7 time_update m-0 flex">
                    Last Updated:{' '}
                    {dayjs(backtestRequestData?.updated_at).format(
                      'DD-MMM-YYYY h:mm:ss a',
                    )}
                    {/* 15-May-2024<span class="ml-2">4:23:50 PM</span> */}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-12">
            <div className="flex justify-end items-center gap-2">
              {/* <StatsUsageCardSmall
                icon={<>1/10</>}
                percent={10}
                title={'Premium Credits'}
                subTitle={20}
                infoText={'No credits are available'}
                size={30}
              /> */}
              {/* <div className="py-0.5 hidden lg:flex">
                <span className="flex w-1 h-7 bg-level-2"></span>
              </div> */}
              <div className="flex items-center gap-1.5">
                {data?.dataRequest?.status === 'Draft' && (
                  <div className="flex items-center">
                    <Tooltip placement="bottom" title="Generate Report">
                      <button
                        className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                        onClick={() => {
                          backtestingReportSubmission(data?.dataRequest);
                        }}
                        // disabled={getPauseIcon()}
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 16 16"
                          height="1em"
                          width="1em"
                          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg text-green-3"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z"></path>
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                )}
                {data?.dataRequest?.status === 'Generated' && (
                  <div className="flex items-center">
                    <button
                      className="bg-color-primaryColor2 hover:bg-color-primaryColor2/95 flex justify-center items-center py-[7px] px-[12px] text-[13px] min-h-[34px] text-whiteNew-0 rounded-[6px] rounded-r-none gap-1 font-normal"
                      onClick={() => {}}
                    >
                      <span>Set Trade</span>
                    </button>{' '}
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: '1',
                            label: (
                              <div className="flex items-center gap-1.5">
                                Set Virtual Trade
                              </div>
                            ),
                            onClick: () => {
                              dispatch(openVirualReportFields(true));

                              setTradeType('virtual');
                            },
                          },
                          {
                            key: '2',
                            label: (
                              <div className="flex items-center gap-1.5">
                                Set Live Trade
                              </div>
                            ),
                            onClick: () => {
                              dispatch(openLiveReportFields(true));
                              setTradeType('live');
                            },
                          },
                        ],
                      }}
                      placement="bottomRight"
                      overlayClassName={'dropdownOverlay dropdownDark'}
                      trigger={['click']}
                      className="bg-color-primaryColor2 hover:bg-color-primaryColor2/95 flex justify-center items-center py-[7px] px-[7px] text-[18px] min-h-[34px] text-whiteNew-0 rounded-[6px] rounded-l-none gap-1 font-normal border-l border-whiteNew-0/60 [&.ant-dropdown-open]:border-transparent "
                    >
                      <div onClick={(e) => e.preventDefault()}>
                        <MdKeyboardArrowDown />
                      </div>
                    </Dropdown>
                  </div>
                )}
              </div>
              {/* <Tooltip title="Trade Setting" placement="bottom">
                <button
                  className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                  onClick={() => {
                    setShowBacktestDrawer(true);
                  }}
                >
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 1024 1024"
                    height="1em"
                    width="1em"
                    className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M924.8 625.7l-65.5-56c3.1-19 4.7-38.4 4.7-57.8s-1.6-38.8-4.7-57.8l65.5-56a32.03 32.03 0 0 0 9.3-35.2l-.9-2.6a443.74 443.74 0 0 0-79.7-137.9l-1.8-2.1a32.12 32.12 0 0 0-35.1-9.5l-81.3 28.9c-30-24.6-63.5-44-99.7-57.6l-15.7-85a32.05 32.05 0 0 0-25.8-25.7l-2.7-.5c-52.1-9.4-106.9-9.4-159 0l-2.7.5a32.05 32.05 0 0 0-25.8 25.7l-15.8 85.4a351.86 351.86 0 0 0-99 57.4l-81.9-29.1a32 32 0 0 0-35.1 9.5l-1.8 2.1a446.02 446.02 0 0 0-79.7 137.9l-.9 2.6c-4.5 12.5-.8 26.5 9.3 35.2l66.3 56.6c-3.1 18.8-4.6 38-4.6 57.1 0 19.2 1.5 38.4 4.6 57.1L99 625.5a32.03 32.03 0 0 0-9.3 35.2l.9 2.6c18.1 50.4 44.9 96.9 79.7 137.9l1.8 2.1a32.12 32.12 0 0 0 35.1 9.5l81.9-29.1c29.8 24.5 63.1 43.9 99 57.4l15.8 85.4a32.05 32.05 0 0 0 25.8 25.7l2.7.5a449.4 449.4 0 0 0 159 0l2.7-.5a32.05 32.05 0 0 0 25.8-25.7l15.7-85a350 350 0 0 0 99.7-57.6l81.3 28.9a32 32 0 0 0 35.1-9.5l1.8-2.1c34.8-41.1 61.6-87.5 79.7-137.9l.9-2.6c4.5-12.3.8-26.3-9.3-35zM788.3 465.9c2.5 15.1 3.8 30.6 3.8 46.1s-1.3 31-3.8 46.1l-6.6 40.1 74.7 63.9a370.03 370.03 0 0 1-42.6 73.6L721 702.8l-31.4 25.8c-23.9 19.6-50.5 35-79.3 45.8l-38.1 14.3-17.9 97a377.5 377.5 0 0 1-85 0l-17.9-97.2-37.8-14.5c-28.5-10.8-55-26.2-78.7-45.7l-31.4-25.9-93.4 33.2c-17-22.9-31.2-47.6-42.6-73.6l75.5-64.5-6.5-40c-2.4-14.9-3.7-30.3-3.7-45.5 0-15.3 1.2-30.6 3.7-45.5l6.5-40-75.5-64.5c11.3-26.1 25.6-50.7 42.6-73.6l93.4 33.2 31.4-25.9c23.7-19.5 50.2-34.9 78.7-45.7l37.9-14.3 17.9-97.2c28.1-3.2 56.8-3.2 85 0l17.9 97 38.1 14.3c28.7 10.8 55.4 26.2 79.3 45.8l31.4 25.8 92.8-32.9c17 22.9 31.2 47.6 42.6 73.6L781.8 426l6.5 39.9zM512 326c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm79.2 255.2A111.6 111.6 0 0 1 512 614c-29.9 0-58-11.7-79.2-32.8A111.6 111.6 0 0 1 400 502c0-29.9 11.7-58 32.8-79.2C454 401.6 482.1 390 512 390c29.9 0 58 11.6 79.2 32.8A111.6 111.6 0 0 1 624 502c0 29.9-11.7 58-32.8 79.2z"></path>
                  </svg>
                </button>
              </Tooltip> */}
              {data?.dataRequest?.status === 'Generated' && (
                <div>
                  <Tooltip title={'Logs & Activities'} placement={'bottom'}>
                    <button
                      className={`${btnSecondayClass} w-[35px] h-[35px] relative`}
                      onClick={() => {
                        showLogsActivityDrawer();
                      }}
                    >
                      <span className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-lg">
                        <BsClockHistory />
                      </span>
                    </button>
                  </Tooltip>
                </div>
              )}
            </div>
          </div>
        </div>
        <BackTestingDetailCards
          // backtestData={backtestData}
          backtestData={backtestRequestData}
          strategyData={strategyDataFull}
        />
        {hideTopStrip?.show && (
          <div className="row mt-2 px-0">
            <div className="col-12 px-0">
              <StatusStrips
                status={hideTopStrip?.status}
                title={hideTopStrip?.title}
                closeIcon={{
                  show: true,
                  click: () => {
                    localStorage.setItem(hideTopStrip?.name, true);
                    setHideTopStrip((prevHideTopStrip) => ({
                      ...prevHideTopStrip,
                      show: false,
                      sideBtn: false,
                      sideLink: false,
                    }));
                  },
                }}
                description={hideTopStrip?.description}
                // sideBtn={{
                //   show: hideTopStrip?.sideBtn,
                //   click: () => dispatch(showPlansModal(true)),
                // }}
                // knowMoreLink={{
                //   show: premiumTopStrip?.sideLink,
                //   click: () => console.log('called'),
                //   name: 'click to buy credits',
                // }}
              />
            </div>
          </div>
        )}

        <BackTestingTabs
          toggleState={toggleState}
          toggleTab={toggleTab}
          showBacktestDrawer={showBacktestDrawer}
          setShowBacktestDrawer={setShowBacktestDrawer}
          data={data}
          showAccordian={showAccordian}
          setShowAccordian={setShowAccordian}
          // virtualData={virtualData}
          // setBackTestingModalShow={setBackTestingModalShow}
          // isMobile={isMobile}
          // liveData={liveData}
          // handleDateChange={handleDateChange}
        />

        <div className="tbl_data row mt-3">
          <div
            className={
              toggleState === 'overview' ? 'tab-content-active' : 'tabs-content'
            }
          >
            <div className="bg-color-base-1 dark:bg-color-base-4 border-[1px] border-t-[0px] border-level-4 rounded-b-lg">
              <BacktestingOverview backtestData={backtestRequestData} />

              {/* When under progress */}
              {/* <div className="min-h-[calc(100svh-400px)] flex justify-center items-center">
                <ActionCardUi
                  title={'Under Progress'}
                  subTitle={
                    'Be Patience we are building somethingreally awesome! to enhance your experience of our platform'
                  }
                  actionButton={false}
                />
              </div> */}
            </div>
          </div>
          {/* <div
            className={
              toggleState === 'graphs' ? 'tab-content-active' : 'tabs-content'
            }
          ></div> */}

          <div
            className={
              toggleState === 'reports' ? 'tab-content-active' : 'tabs-content'
            }
          >
            {/* <ShowLiveTrade toggleState={toggleState} /> */}
            {!['Processing', 'Draft', 'Pending']?.includes(
              data?.dataRequest?.status,
            ) ? (
              <BacktestingReportTab data={data?.data} />
            ) : (
              <>
                {data?.dataRequest?.status === 'Draft' ? (
                  <div className="min-h-[calc(100svh-400px)] flex justify-center items-center">
                    <ActionCardUi
                      title={'Setup is been Not running'}
                      subTitle={
                        ' Look like the setup is not been running , so to generate a report click on the below button '
                      }
                      actionButton={true}
                      buttonName={'Generate report'}
                      handleAction={() => {
                        backtestingReportSubmission(data?.dataRequest);
                      }}
                    />
                  </div>
                ) : data?.dataRequest?.status === 'Processing' ||
                  data?.dataRequest?.status === 'Pending' ? (
                  <div className="min-h-[calc(100svh-400px)] flex justify-center items-center">
                    <ActionCardUi
                      title={'Wait ! We are under Progress'}
                      subTitle={
                        'Your setup is been running please be patience it might take sometime'
                      }
                      actionButton={false}
                    />
                  </div>
                ) : null}
                z{/* <NoDataFoundV2 /> */}
              </>
            )}
          </div>
          <div
            className={
              toggleState === 'trades' ? 'tab-content-active' : 'tabs-content'
            }
          >
            {backtestData?.trades && backtestData?.trades.length > 0 ? (
              <>
                <div className="">
                  <AccordianSmooth showAccordian={showAccordian}>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 gap-3 mb-3">
                      <StrategyViewCard
                        icon={
                          <>
                            <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
                          </>
                        }
                        title={'Instrument'}
                        tooltipData={`Trading instrument for this strategy`}
                        subtitle={20}
                      />
                    </div>
                  </AccordianSmooth>
                </div>
                <ReportTradeBacktesting
                  refetchBacktestingData={refetchBacktestingData}
                  data={data?.data?.[0]?.trades || []}
                  isFetching={isFetching}
                />
              </>
            ) : (
              <>
                {data?.dataRequest?.status === 'Draft' ? (
                  <div className="min-h-[calc(100svh-400px)] flex justify-center items-center">
                    <ActionCardUi
                      title={'Setup is been Not running'}
                      subTitle={
                        ' Look like the setup is not been running , so to generate a report click on the below button '
                      }
                      actionButton={true}
                      buttonName={'Generate reports'}
                      handleAction={() => {
                        backtestingReportSubmission(data?.dataRequest);
                      }}
                    />
                  </div>
                ) : data?.dataRequest?.status === 'Processing' ||
                  data?.dataRequest?.status === 'Pending' ? (
                  <div className="min-h-[calc(100svh-400px)] flex justify-center items-center">
                    <ActionCardUi
                      title={'Wait ! We are under Progress'}
                      subTitle={
                        'Your setup is been running please be patience it might take sometime'
                      }
                      actionButton={false}
                    />
                  </div>
                ) : null}
                z{/* <NoDataFoundV2 /> */}
              </>
            )}
          </div>
        </div>

        {/* <Modal
          open={backTestingModalShow}
          setIsOpen={setBackTestingModalShow}
          maxWidth={`w-full max-w-6xl`}
          preventClosingOutsideClick
          header={
            <div className="flex flex-col justify-center py-2">
              <div className="w-full flex items-center">
                <span className="text-color-primary text-base font-medium">
                  {toggleState === 'virtual'
                    ? 'VirtualTrade'
                    : 'Live Trade Setup'}
                </span>
              </div>
            </div>
          }
          footer={null}
          bodyClass={
            'w-full overflow-y-auto flex flex-col justify-between overflow-x-hidden scrollCss h-full'
          }
        >
          <div className="row justify-content-center position-relative tradesetting_tab -mt-5">
            <BackTesting
              inputs={GetInputsData()}
              modalData={toggleState}
              setFetchState={false}
              strategy_id={id}
              onClose={setBackTestingModalShow}
              getLiveTradeData={liveRefech}
              getVirtualTradeData={virtualRefech}
            />
          </div>
        </Modal> */}

        <Drawer
          open={showBacktestDrawer}
          onClose={() => {
            setShowBacktestDrawer(false);
          }}
          title={
            <div className="flex justify-between items-center pr-6 -my-3">
              <div className="flex flex-col -space-y-1">
                <div>
                  <h4 className="mb-0">Report Name</h4>
                </div>
                <div className="flex items-center">
                  <div>
                    <span className="text-xs text-color-secondary">
                      Last Updated: 04-Jul-2024 3:41:27 PM
                    </span>
                  </div>
                  <div></div>
                </div>
              </div>
              {/* <div className="flex gap-2 items-center">
                <button
                  className="btn__create__strategy align-items-center d-flex gap-1 items-center font-normal"
                  onClick={() => {}}
                >
                  <MdOutlineEdit />
                  <span>New Backtest</span>
                </button>
              </div> */}
            </div>
          }
          width={'1200px'}
          footer={getFooter}
        >
          <div className="position-relative tradesetting_tab -mt-3">
            {inputsForm && inputsForm.length > 0 ? (
              <ModalInputFields
                // ref={virtualModalRef}
                inputs={inputsForm}
                primaryButtonName="Generate"
                // sumbitForm={virtualReportSubmission}
              />
            ) : (
              <div className="w-full flex justify-center text-center">
                <NoDataFoundV2
                  title={'No data found.'}
                  className="text-4xl"
                  minHeight={'min-h-[200px] py-2'}
                />
              </div>
            )}
          </div>
        </Drawer>
        <LogActivityDrawer onClose={onClose} open={openLogsActivity} />
        {/* //!WORKING HERE */}
        <ReportDrawerInputs
          inputs={generateInputsFromBacktest(data, strategyDataFull)}
          modalData={tradeType}
          strategyID={data?.dataRequest?.strategy_id}
          // upDateData={}
          configData={data?.dataRequest?.bt_inputs}
          reportName={data?.dataRequest?.bt_inputs?.report_name}
          // setHeaderTopTape={}
          // reportData={}
          strategyData={strategyDataFull}
        />
      </div>
    </>
  );
};

export default BackTestingReport;
