import React, { useCallback, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TableComponent from '../../../components/Common/TableComponent';
import CustomDatePicker from '../../../components/Common/CustomDatePicker';
import Tooltip from '../../../components/Common/Tooltip';
import { btnSecondayClass } from '../../../components/utils/defaultClasses';
import { MdDownload, MdOutlineInstallDesktop } from 'react-icons/md';
import AccordianSmooth from '../../../components/Common/AccordianSmooth';
import StrategyViewCard from '../../../components/UI/StrategyDetails/StrategyViewCard';
import useReports from '../hooks/useReports';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatINR } from '../../../utils/utils';
import truncate from '../../../components/Truncate';
import { BsArrowDown, BsArrowUp } from 'react-icons/bs';
import { tradeDetailsInDrawer } from '../../../features/detailsTradeSlice';
import TradeDetailDrawer from '../../../components/UI/StrategyDetails/TradeDetailDrawer';
import { Switch } from 'antd';
import PremiumBadge from '../../../utils/PremiumBadge';
import useToGetId from '../../../components/hooks/useToGetId';
import { useMutation } from '@tanstack/react-query';
import { updateRequest } from '../../../API/strategy/strategyDetail';
import { toast } from 'react-toastify';
import {
  setLiveReportsdata,
  setVirtualReportsdata,
} from '../../../features/reports/reports.slice';
import {
  userBacktestDeployments,
  userLiveDeployments,
  userVirtualDeployments,
} from '../../../features/UserCredits/userCreditsSlice';

export default function Reports() {
  const [showAccordian, setShowAccordian] = useState(false);

  const {
    virtualPagination,
    livePagination,
    tradePagination,
    handleVirtualTradeTable,
    handleLiveTradeTable,
    setVstatus,
    setlstatus,
    vstatus,
    lstatus,
    onceCall,
    onceCallLive,
    handleTradeOrderDateChange,
    tradeOrderDateRangeValue,
    handleUserTradeTable,
    virtualLoading,
    liveLoading,
    tradeLoading,
  } = useReports();
  const { liveReportsData, virtualReportsData, userTradeData } = useSelector(
    (state) => state.tradeReportData,
  );
  console.log('virtualReportsData: ', virtualReportsData);

  const getStatus = (item) => {
    if (item?.current_status === 'Generated') {
      return 'badge__green';
    } else if (item?.current_status === 'Draft') {
      return 'bg-component-gray-2 rounded-md p-0.5 text-xs px-2.5';
    } else if (item?.current_status === 'Pending') {
      return 'bg-orange-500/20 text-orange-500 rounded-sm p-0.5 text-xs px-2.5';
    } else {
      return 'bg-yellow-500/20 text-yellow-500 rounded-sm p-0.5 text-xs px-2.5';
    }
  };
  const {
    virtual_requests,
    live_requests,
    premium_strategies,
    backtest_requests,
  } = useSelector(
    (state) => state.userCreditsDetails, // credits of the virtual and live requests
  );
  const toggleFeature = useCallback(
    (item, toggleState) => {
      if (
        !item?.strategyData?.subscribe_by_user &&
        item?.strategyData?.access_type === 'premium' &&
        premium_strategies?.strategies_remaining === 0
      ) {
        return true;
      } else if (
        !item.status &&
        // detailsOfStrategy?.access_type === 'premium' &&
        (toggleState === 'live'
          ? live_requests?.requests_remaining === 0
          : toggleState === 'virtual'
          ? virtual_requests?.requests_remaining === 0
          : backtest_requests?.requests_remaining === 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    [
      premium_strategies?.strategies_remaining,
      live_requests?.requests_remaining,
      virtual_requests?.requests_remaining,
      backtest_requests?.requests_remaining,
    ],
  );
  let { userid } = useToGetId();
  const disptach = useDispatch();
  const updateRequestStrategy = useMutation({
    mutationFn: ({ finalPayload, toggleState }) => {
      return updateRequest(finalPayload, toggleState);
    },
    onSuccess: async (res, { finalPayload, toggleState }) => {
      // res.data.message
      toast.success(res?.data?.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
      });
      const idToUpdate =
        toggleState === 'live' ? finalPayload.lt_id : finalPayload.vt_id;
      const { status } = finalPayload; //to get the status of the toggle
      // -------------- ADDED TO UPDATE DATA WHEN CHANGING MODE ON DRAWER---------------
      if (toggleState === 'virtual') {
        let newData = virtualReportsData?.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        });
        dispatch(setVirtualReportsdata(newData));
      } else if (toggleState === 'live') {
        let newData = liveReportsData?.map((item) => {
          return item?._id === idToUpdate ? { ...item, status: status } : item;
        });
        dispatch(setLiveReportsdata(newData));
      }

      // -------------- ADDED TO UPDATE DATA WHEN CHANGING MODE ON DRAWER---------------

      // setTableData(
      //   tableData.map((item) => {
      //     return item?._id === idToUpdate ? { ...item, status: status } : item;
      //   }),
      // ); //update the table data chnaging the toggle switch of the selected request

      let virtual_payload = {
        ...virtual_requests,
        requests_remaining:
          virtual_requests?.requests_remaining === 'Unlimited'
            ? virtual_requests?.requests_remaining
            : virtual_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: virtual_requests?.requests_used + (status ? 1 : -1),
      };
      let live_payload = {
        ...live_requests,
        requests_remaining:
          live_requests?.requests_remaining === 'Unlimited'
            ? live_requests?.requests_remaining
            : live_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: live_requests?.requests_used + (status ? 1 : -1),
      };
      let backtest_payload = {
        ...backtest_requests,
        requests_remaining:
          backtest_requests?.requests_remaining === 'Unlimited'
            ? backtest_requests?.requests_remaining
            : backtest_requests?.requests_remaining + (status ? -1 : 1),
        requests_used: backtest_requests?.requests_used + (status ? 1 : -1),
      };

      //based on the status upadte or removing the requests counts in redux
      toggleState === 'live'
        ? disptach(userLiveDeployments(live_payload))
        : toggleState === 'virtual'
        ? disptach(userVirtualDeployments(virtual_payload))
        : disptach(userBacktestDeployments(backtest_payload));
    },
    onError: (error) => {
      return toast.error(error.response.data.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      // console.log(error.response.data.message);
    },
  });

  const handleUpdate = useCallback(
    (status, id, strategy_id, detailsOfStrategy, toggleState) => {
      if (detailsOfStrategy?.subscribe_by_user) {
        let vtPayload = {
          vt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let ltPayload = {
          lt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let btPayload = {
          bt_id: id,
          user_id: userid.id,
          strategy_id: strategy_id,
          status: status,
        };
        let finalPayload =
          toggleState === 'live'
            ? ltPayload
            : toggleState === 'virtual'
            ? vtPayload
            : btPayload;

        updateRequestStrategy.mutate({ finalPayload, toggleState });
      }
    },
    [updateRequestStrategy, userid.id],
  );
  const dataSource = useMemo(
    () =>
      virtualReportsData?.map((item) => ({
        key: item._id,
        //  select: (
        //    <Checkbox
        //      checked={selectedKeys?.includes(item?._id)}
        //      onChange={(e) => handleCheckboxChange(item._id, e.target.checked)}
        //    />
        //  ),
        report_name: (
          <>
            <Link
              to={`/view-report/VT/${item._id}`}
              className="primary__blue text-blue-2 hover:underline"
            >
              {item?.report_name}
            </Link>
          </>
        ),
        created_at: <>{dayjs(item.created_at).format('DD MMM YY')}</>,
        max_cap: (
          <div className="flex">
            <span>{formatINR(item?.max_cap)}</span>
          </div>
        ),
        current_status: (
          <span className={`${getStatus(item)}`}>
            <span>{item?.current_status}</span>
          </span>
        ),
        mode: (
          <div className="flex items-center gap-1.5">
            {toggleFeature(item) ? (
              <PremiumBadge />
            ) : (
              <Switch
                value={item?.status}
                onChange={(checked) =>
                  handleUpdate(
                    checked,
                    item?._id,
                    item?.strategy_id,
                    item,
                    'virtual',
                  )
                }
              />
            )}
          </div>
        ),

        orderCount: (
          <p>
            {item?.orderCount
              ? item?.orderCount
              : item?.orderData
              ? item?.orderData
              : 0}
          </p>
        ),
      })),
    [handleUpdate, toggleFeature, virtualReportsData],
  );
  const dispatch = useDispatch();
  // const dataSource = [...Array(10)].map((id) => {
  //   return {
  //     key: '1',
  //     TradeId: (
  //       <>
  //         <span>Skyra</span>
  //       </>
  //     ),
  //     Strategy: (
  //       <>
  //         <span>Custom</span>
  //       </>
  //     ),
  //     ReportName: (
  //       <>
  //         <span>testing</span>
  //       </>
  //     ),
  //     Status: (
  //       <>
  //         <div>
  //           <span
  //             class={`${
  //               item.status === 'In Progress'
  //                 ? 'bg-blue-2/15 text-blue-2 '
  //                 : item.status === 'Closed'
  //                 ? 'text-orange-400 bg-orange-100'
  //                 : item.status === 'Open'
  //                 ? 'bg-green-3/20 text-green-3'
  //                 : 'bg-red-2/15 text-red-2'
  //             }  py-[2px] px-1.5 text-xs rounded-[4px] min-w-[80px] inline-flex justify-center`}
  //           >
  //             {item?.status}
  //           </span>
  //         </div>
  //       </>
  //     ),
  //   };
  // });

  // const columns = [
  //   {
  //     title: 'Trade Id',
  //     dataIndex: 'TradeId',
  //     key: 'TradeId',
  //   },
  //   {
  //     title: 'Strategy',
  //     dataIndex: 'Strategy',
  //     key: 'Strategy',
  //   },
  //   {
  //     title: 'Report Name',
  //     dataIndex: 'report_name',
  //     key: 'ReportName',
  //   },
  //   {
  //     title: 'Status',
  //     dataIndex: 'Status',
  //     key: 'Status',
  //   },
  // ];
  const showTradeDetails = React.useCallback(
    (record) => {
      let tradeDetails = {
        'Trade Id': record?._id,
        // 'Strategy Name': record?.st_name,
        // 'Report Name': record?.report_name,
        'Trade Date': dayjs.utc(record?.date).format('DD-MMM-YYYY hh:mm:ss a'),
        Day: record?.day,
        Symbol: record?.stock,
        'Trade Type': '-',
        'Position Type': record?.position_type || '-',
        'Lot size': record?.lot_size || '-',
        Quantity: record?.quantity || '-',
        'Expiry ': record?.expiry || '-',
        'Entry Time ': dayjs
          .utc(record?.entry_time)
          .format('DD-MMM-YYYY hh:mm:ss a'),
        'Entry Price ': record?.entry_price || '-',
        'Exit Price ': record?.exit_price || '-',
        'Exit Time':
          dayjs.utc(record?.exit_time).format('DD-MMM-YYYY hh:mm:ss a') || '-',
        'Exit Type': record?.exit_type || '-',
        Points: record?.points || '-',
        'Gross P&L': record?.pnl || '-',
        'Brokeragee (brokerage)': record?.brokerage || '-',
        'Net P&L (net_pnl)': record?.net_pnl || '-',
      };
      dispatch(
        tradeDetailsInDrawer({
          selectedTrade: tradeDetails,
          isOpen: true,
        }),
      );
    },
    [dispatch],
  );
  const columns = useMemo(
    () => [
      {
        title: 'Report Name',
        dataIndex: 'report_name',
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        // render: (text) => dayjs(text).format('DD MMM YY'),
      },
      {
        title: 'Max Cap',
        dataIndex: 'max_cap',
      },
      {
        title: 'No. of order',
        dataIndex: 'orderCount',
      },
      {
        title: 'Current Mode',
        dataIndex: 'mode',
      },
      // {
      //   title: 'Current Mode',
      //   dataIndex: 'mode',
      // },
    ],
    [],
  );
  const dataSource2 = useMemo(
    () =>
      liveReportsData?.map((item) => ({
        key: item._id,
        //  select: (
        //    <Checkbox
        //      checked={selectedKeys?.includes(item?._id)}
        //      onChange={(e) => handleCheckboxChange(item._id, e.target.checked)}
        //    />
        //  ),
        report_name: (
          <>
            <Link
              to={`/view-report/LT/${item._id}`}
              className="primary__blue text-blue-2 hover:underline"
            >
              {item?.report_name}
            </Link>
          </>
        ),
        created_at: <>{dayjs(item.created_at).format('DD MMM YY')}</>,
        max_cap: (
          <div className="flex">
            <span>{formatINR(item?.max_cap)}</span>
          </div>
        ),
        current_status: (
          <span className={`${getStatus(item)}`}>
            <span>{item?.current_status}</span>
          </span>
        ),
        mode: (
          <div className="flex items-center gap-1.5">
            {toggleFeature(item) ? (
              <PremiumBadge />
            ) : (
              <Switch
                value={item?.status}
                onChange={(checked) =>
                  handleUpdate(
                    checked,
                    item?._id,
                    item?.strategy_id,
                    item,
                    'live',
                  )
                }
              />
            )}
          </div>
        ),

        orderCount: (
          <p>
            {item?.orderCount
              ? item?.orderCount
              : item?.orderData
              ? item?.orderData
              : 0}
          </p>
        ),
      })),
    [handleUpdate, liveReportsData, toggleFeature],
  );
  const dataSource3 = useMemo(
    () =>
      userTradeData?.map((item) => ({
        key: item._id,
        trade_id: (
          <p
            onClick={() => {
              showTradeDetails(item);
            }}
            className="text-bluePrimary-1  cursor-pointer"
          >
            {truncate(item?._id, 4)}
          </p>
        ),
        strategy: item?.strategyData?.name,
        symbol: item?.stock,
        report_name: (
          <>
            <Link
              to={`/view-report/LT/${item._id}`}
              className="primary__blue text-blue-2 hover:underline"
            >
              {item?.vtRequestData?.vt_inputs?.report_name ||
                item?.ltRequestData?.lt_inputs?.report_name}
            </Link>
          </>
        ),
        max_cap: (
          <div className="flex">
            <span>{formatINR(item?.max_cap)}</span>
          </div>
        ),
        entry_time: dayjs
          ?.utc(item?.entry_time)
          ?.format('DD-MMM-YYYY hh:mm:ss a'),
        entry_price: item?.entry_price,
        exit_time: dayjs
          ?.utc(item?.exit_time)
          ?.format('DD-MMM-YYYY hh:mm:ss a'),
        current_status: (
          <span className={`${getStatus(item)}`}>
            <span>{item?.current_status}</span>
          </span>
        ),
        p_n_l: (
          <span>
            {item?.net_pnl?.toFixed(2) > 0 ? (
              <BsArrowUp className="text-success" />
            ) : (
              <BsArrowDown className="text-danger" />
            )}
            {
              <span
                className={
                  item?.net_pnl?.toFixed(2) > 0 ? 'text-success' : 'text-danger'
                }
              >
                {item?.net_pnl?.toFixed(2)}
              </span>
            }
          </span>
        ),
        tradeMode: item?.tradeMode,
      })),
    [showTradeDetails, userTradeData],
  );
  const columns2 = useMemo(
    () => [
      {
        title: 'Report Name',
        dataIndex: 'report_name',
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        // render: (text) => dayjs(text).format('DD MMM YY'),
      },
      {
        title: 'Max Cap',
        dataIndex: 'max_cap',
      },
      {
        title: 'No. of order',
        dataIndex: 'orderCount',
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'current_status',
      // },
      {
        title: 'Current Mode',
        dataIndex: 'mode',
      },
    ],
    [],
  );
  const columns3 = useMemo(
    () => [
      {
        title: 'Trade Id',
        dataIndex: 'trade_id',
      },
      {
        title: 'Trade Mode',
        dataIndex: 'tradeMode',
      },
      {
        title: 'Strategy',
        dataIndex: 'strategy',
      },

      {
        title: 'Report Name',
        dataIndex: 'report_name',
      },
      {
        title: 'Symbol',
        dataIndex: 'symbol',
      },
      {
        title: 'Entry Time',
        dataIndex: 'entry_time',
      },
      {
        title: 'Entry Price',
        dataIndex: 'entry_price',
      },

      {
        title: 'Exit Time',
        dataIndex: 'exit_time',
      },
      {
        title: 'P&L',
        dataIndex: 'p_n_l',
      },
    ],
    [],
  );

  const getButtonClassV = useCallback(
    (buttonName) => {
      const baseClasses =
        'px-3.5 pt-[5px] pb-[3px] font-medium duration-150 ease-in-out min-w-[80px] disabled:opacity-60 text-xs border-b-[2px] border-b-transparent';
      const activeClasses = ' text-blue-2 border-b-blue-2';
      const inactiveClasses =
        'hover:bg-color-base-2 text-color-secondary hover:text-color-primary border-b-transparent';

      return vstatus === buttonName
        ? `${baseClasses} ${activeClasses}`
        : `${baseClasses} ${inactiveClasses}`;
    },
    [vstatus],
  );
  const getButtonClassL = useCallback(
    (buttonName) => {
      const baseClasses =
        'px-3.5 pt-[5px] pb-[3px] font-medium duration-150 ease-in-out min-w-[80px] disabled:opacity-60 text-xs border-b-[2px] border-b-transparent';
      const activeClasses = ' text-blue-2 border-b-blue-2';
      const inactiveClasses =
        'hover:bg-color-base-2 text-color-secondary hover:text-color-primary border-b-transparent';

      return lstatus === buttonName
        ? `${baseClasses} ${activeClasses}`
        : `${baseClasses} ${inactiveClasses}`;
    },
    [lstatus],
  );

  return (
    <>
      <div className="broker_page left__bar ">
        <div className="pt-2.5">
          <div>
            <Row>
              <Col md={12}>
                <div className="mb-6 flex justify-between items-center">
                  <div className="flex gap-2">
                    <div className="">
                      <span className="flex bg-blue-2/20 p-2 rounded-md text-blue-2 text-2xl">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="1em"
                          height="1em"
                          fill="currentColor"
                          className="bi bi-person-gear shrink-0"
                          viewBox="0 0 16 16"
                        >
                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0ZM8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm.256 7a4.474 4.474 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10c.26 0 .507.009.74.025.226-.341.496-.65.804-.918C9.077 9.038 8.564 9 8 9c-5 0-6 3-6 4s1 1 1 1h5.256Zm3.63-4.54c.18-.613 1.048-.613 1.229 0l.043.148a.64.64 0 0 0 .921.382l.136-.074c.561-.306 1.175.308.87.869l-.075.136a.64.64 0 0 0 .382.92l.149.045c.612.18.612 1.048 0 1.229l-.15.043a.64.64 0 0 0-.38.921l.074.136c.305.561-.309 1.175-.87.87l-.136-.075a.64.64 0 0 0-.92.382l-.045.149c-.18.612-1.048.612-1.229 0l-.043-.15a.64.64 0 0 0-.921-.38l-.136.074c-.561.305-1.175-.309-.87-.87l.075-.136a.64.64 0 0 0-.382-.92l-.148-.045c-.613-.18-.613-1.048 0-1.229l.148-.043a.64.64 0 0 0 .382-.921l-.074-.136c-.306-.561.308-1.175.869-.87l.136.075a.64.64 0 0 0 .92-.382l.045-.148ZM14 12.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0Z" />
                        </svg>
                      </span>
                    </div>
                    <div className="">
                      <div className="flex gap-2 items-center">
                        <h4 className="strtgy__ttl d-flex align-items-center mb-0">
                          Reports
                        </h4>
                      </div>

                      <span className="flex text-xs text-color-secondary/70">
                        Manage and monitor all your reports.
                      </span>
                    </div>
                  </div>
                  <div>
                    <div></div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="">
            <Row className="g-4">
              <Col xl={6} className="h-full">
                <div>
                  <div className="border-[1px] shadow-md shadow-level-4 border-level-4 dark:border-level-1 border-solid p-3.5 rounded-xl space-y-3.5  min-h-[689px]">
                    <div className="flex justify-between w-full items-center">
                      <h3 className="font-medium">Virtual Report</h3>
                      <div>
                        <div className="flex items-center overflow-hidden shadow-sm rounded-md font-normal ">
                          <button
                            className={getButtonClassV('all')}
                            onClick={() => {
                              onceCall.current = false;
                              setVstatus('all');
                            }}
                          >
                            All
                          </button>
                          <button
                            className={getButtonClassV('active')}
                            onClick={() => {
                              onceCall.current = false;
                              setVstatus('active');
                            }}
                          >
                            Active
                          </button>
                          <button
                            className={getButtonClassV('inActive')}
                            onClick={() => {
                              onceCall.current = false;
                              setVstatus('inActive');
                            }}
                          >
                            In Active
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <TableComponent
                        dataSource={dataSource}
                        columns={columns}
                        loading={virtualLoading}
                        onChange={handleVirtualTradeTable}
                        pagination={virtualPagination}
                        total={virtualPagination?.total}
                        // noDataFoundHeight={'max-h-[300px] overflow-hidden'}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={6} className="h-full">
                <div>
                  <div className="border-[1px] shadow-md shadow-level-4 border-level-4 dark:border-level-1 border-solid p-3.5 rounded-xl space-y-3.5  min-h-[689px]">
                    <div className="flex justify-between w-full items-center">
                      <h3 className="font-medium">Live Report</h3>
                      <div>
                        <div className="flex items-center overflow-hidden shadow-sm rounded-md font-normal ">
                          <button
                            className={getButtonClassL('all')}
                            onClick={() => {
                              onceCallLive.current = false;
                              setlstatus('all');
                            }}
                          >
                            All
                          </button>
                          <button
                            className={getButtonClassL('active')}
                            onClick={() => {
                              onceCallLive.current = false;
                              setlstatus('active');
                            }}
                          >
                            Active
                          </button>
                          <button
                            className={getButtonClassL('inActive')}
                            onClick={() => {
                              onceCallLive.current = false;
                              setlstatus('inActive');
                            }}
                          >
                            In Active
                          </button>
                        </div>
                      </div>
                    </div>
                    <div>
                      <TableComponent
                        dataSource={dataSource2}
                        columns={columns2}
                        loading={liveLoading}
                        pagination={livePagination}
                        total={livePagination.total}
                        onChange={handleLiveTradeTable}
                        // noDataFoundHeight={'max-h-[300px] overflow-hidden'}
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                <div>
                  <div className="border-[1px] shadow-md shadow-level-4 border-level-4 dark:border-level-1 border-solid p-3.5 rounded-xl space-y-3.5">
                    <div className="flex justify-between w-full items-center">
                      <h3 className="font-medium">Latest Trade Reports</h3>
                      <div className="flex justify-end items-center gap-2.5">
                        <CustomDatePicker
                          onChange={handleTradeOrderDateChange}
                          value={tradeOrderDateRangeValue}
                        />
                        <div>
                          <button
                            className={`${btnSecondayClass}`}
                            onClick={() => {
                              setShowAccordian(!showAccordian);
                            }}
                          >
                            {showAccordian ? 'Hide' : 'Show'} Summary
                          </button>
                        </div>
                        <Tooltip title={<>Print</>}>
                          <button className={`${btnSecondayClass} py-2.5`}>
                            <MdDownload />
                          </button>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="">
                      <div className="">
                        <AccordianSmooth showAccordian={showAccordian}>
                          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 gap-3 mb-3">
                            <StrategyViewCard
                              icon={
                                <>
                                  <MdOutlineInstallDesktop className="text-4xl text-blue-2" />
                                </>
                              }
                              title={'Instrument'}
                              tooltipData={`Trading instrument for this strategy`}
                              subtitle={20}
                            />
                          </div>
                        </AccordianSmooth>
                      </div>
                      <TableComponent
                        dataSource={dataSource3}
                        columns={columns3}
                        loading={tradeLoading}
                        onChange={handleUserTradeTable}
                        pagination={tradePagination}
                        total={tradePagination.total}
                        // noDataFoundHeight={'max-h-[300px] overflow-hidden'}
                      />
                      <TradeDetailDrawer />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}
