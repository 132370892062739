import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useCallback, useMemo, useState } from 'react';
import mydecodedTokenFunction from '../../../utils/decodetoken';
import { useParams } from 'react-router-dom';
import {
  getTicketSupportReplies,
  sendTicketMessage,
} from '../../../components/helpers/api/TicketSupport/ticket';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import { toastSpecs } from '../../../utils/utils';
import { toast } from 'react-toastify';

const useTicketMessageEditor = (refetch, pagination) => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const fileList = Form.useWatch('attachments', form);
  const message = Form.useWatch('message', form);
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));

  const closeModal = () => {
    form.resetFields();
  };

  const insertNote = useCallback(() => {
    const { message, attachments } = form.getFieldsValue();

    const formData = new FormData();
    formData.append('description', message);
    attachments.map((item) =>
      formData.append('attachments', item.originFileObj),
    );
    formData.append('user_id', userid?.id);
    formData.append('ticket_id', id);
    formData.append('replies_by', 'user');

    // console.log('attachments', attachments, formData);
    return sendTicketMessage(formData);
  }, [form, userid, id]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      if (pagination?.page > 1) {
        pagination.setPage(1);
      } else {
        refetch();
      }
      closeModal();
      //   return toast.success('Ticket created successfully.', toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    console.log('onSubmit', form.getFieldsValue());
    mutation.mutate();
  }, [mutation]);

  const values = useMemo(() => {
    return {
      form,
      fileList,
      message,
      onSubmit,
      isLoading: mutation.isPending,
    };
  }, [form, fileList, message, onSubmit, mutation.isPending]);

  return values;
};

export default useTicketMessageEditor;
