import React from 'react';
import { Form, Input } from 'antd';
import { Link } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { Logo } from '../../assets/Icon';
import {
  btnPrimaryClass,
  formClass,
} from '../../components/utils/defaultClasses';
import useForgotPassword from './hooks/useForgotPassword';

function ForgotPassword() {
  const { form, submitForm } = useForgotPassword();

  return (
    <>
      <div className="bg-color-base-2/30 dark:bg-color-base-4">
        <section className="container-xxl">
          <div className="min-h-screen flex justify-center items-center relative z-10">
            <div className="auth-wrapper relative w-full max-w-[400px] py-4 before:hidden after:hidden md:before:flex md:after:flex">
              <Card className="bg-color-base-1 border-gray-100 dark:border-transparent shadow-md shadow-level-4 rounded-lg overflow-hidden relative z-10">
                <Card.Body className="p-[2rem]">
                  <div className="flex justify-center mb-4 mt-2">
                    <div className="appBrand max-w-[180px]">
                      <Logo />
                    </div>
                  </div>
                  <div className="pt-2 mb-4 flex flex-col">
                    <h2 className="mb-1 text-lg text-color-primary">
                      Forgot Password? 🔒
                    </h2>
                    <p className="text-[13px] text-color-secondary/90">
                      Enter your email and we'll send you instructions to reset
                      your password.
                    </p>
                  </div>
                  <div>
                    <Form form={form} layout="vertical" onFinish={submitForm}>
                      <Form.Item
                        label="Email"
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: 'Email field required.',
                          },
                        ]}
                      >
                        <Input
                          className={`${formClass}`}
                          placeholder="Email"
                        />
                      </Form.Item>
                      <div className="text-center mt-3.5">
                        <Form.Item>
                          <button
                            type="primary"
                            htmlType="submit"
                            className={`${btnPrimaryClass} w-full`}
                          >
                            Send Reset Link
                          </button>
                        </Form.Item>
                      </div>

                      <div className="d-flex justify-content-center">
                        <Link
                          className={`text-blue-2 hover:text-blue-2`}
                          to="/"
                        >
                          <i
                            className="fa fa-unlock me-1"
                            aria-hidden="true"
                          ></i>{' '}
                          Back To Login
                        </Link>
                      </div>
                    </Form>
                  </div>
                </Card.Body>
              </Card>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default ForgotPassword;
