import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  overAllPlansList: [],
  userChangedPlan: {},
};

export const toUpdatePlan = createSlice({
  name: 'toUpdatePlan',
  initialState,
  reducers: {
    userNewPlan: (state, { payload }) => {
      // console.log('payload', payload);
      state.userChangedPlan = payload;
    },
    allPlansList: (state, { payload }) => {
      state.overAllPlansList = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { userNewPlan, allPlansList } = toUpdatePlan.actions;

export default toUpdatePlan.reducer;
