import React from 'react';
import '../../ReportPdf.css';
import zerodha1 from '../../../../assets/images/zerodha1.png';
import zerodha2 from '../../../../assets/images/zerodha2.png';
import zerodha3 from '../../../../assets/images/zerodha3.png';
import Sidebar from '../../Sidebar';
import { Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function ZerodhaInstructions() {
  document.querySelector('body').style.overflow = 'hidden';
  let history = useNavigate();

  return (
    <>
      {/* <Header /> */}
      {/* <Sidebar /> */}
      <div className="mt-5 left__bar">
        <div fluid className="conatiner-fluid instruction ps-0 pe-0">
          <div className="flex">
            <div className="fixedWidth flex">
              <div className="instructionS">
                <div className="p-0 mt-lg-3">
                  <h5 className="strtgy__ttl">Overview</h5>
                  <span className="sub__ttl d-flex gap-2 flex align-items-center mt-2 mb-4">
                    <Link
                      onClick={() => {
                        history(-1);
                      }}
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"></path>
                      </svg>
                    </Link>
                    <Link
                      onClick={() => {
                        history(-1);
                      }}
                    >
                      Go Back
                    </Link>
                  </span>
                  <div
                    class="nav flex-column nav-pills"
                    id="v-pills-tab"
                    role="tablist"
                    aria-orientation="vertical"
                  >
                    <a
                      class="nav-link active"
                      id="v-pills-home-tab"
                      data-toggle="pill"
                      href="#v-pills-Zerodha-API-key"
                      role="tab"
                      aria-controls="v-pills-Zerodha-API-key"
                      aria-selected="true"
                    >
                      <label>1</label>
                      <span>Steps to get Zerodha API key</span>
                    </a>
                    <a
                      class="nav-link"
                      id="v-pills-profile-tab"
                      data-toggle="pill"
                      href="#v-pills-TOTP"
                      role="tab"
                      aria-controls="v-pills-TOTP"
                      aria-selected="false"
                    >
                      <label>2</label>
                      <span>Step to create TOTP</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex">
              <div className="mleSje">
                <div class="tab-content" id="v-pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="v-pills-Zerodha-API-key"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="mb-4">
                      <h4>Steps to get Zerodha API key:</h4>
                      <p>
                        <ul>
                          <li>
                            <p>
                              Sign up on Signup / Kite Connect developer to
                              create a Kite Connect developer account.
                            </p>
                          </li>
                          <li>
                            <p>
                              Log in to your Kite Connect developer account.
                            </p>
                            <p>
                              Url :{' '}
                              <a
                                href="https://developers.kite.trade/login"
                                target="_blank"
                              >
                                Login / Kite Connect developer
                              </a>
                            </p>
                            <div className="mt-4 mb-4">
                              <img src={zerodha1} alt="Kite Connect Image" />
                            </div>
                          </li>
                          <li>
                            Click on Create App.
                            <div className="mt-4 mb-4">
                              <img src={zerodha2} alt="create App Image" />
                            </div>
                          </li>
                          <li>
                            Enter the Zerodha Client ID, Redirect URL, and
                            Postback URL.
                            <div className="mt-4 mb-4">
                              <img src={zerodha3} alt="Api details Image" />
                            </div>
                          </li>
                          <li>Give a name to the app and describe it.</li>
                          <li>Click on create.</li>
                        </ul>
                      </p>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="v-pills-TOTP"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="mb-4">
                      <h4>Step to create TOTP:</h4>
                      <p>
                        <ul style={{ paddingLeft: '30px' }}>
                          <li>
                            <p>Login to kite.zerodha.com.</p>
                          </li>
                          <li>
                            <p>Click on the Client ID.</p>
                          </li>
                          <li>
                            <p>Click on My profile / Settings.</p>
                          </li>
                          <li>
                            <p>Click on Password & Security.</p>
                          </li>
                          <li>
                            <p>Click on Enable External TOTP.</p>
                          </li>
                          <li>
                            <p>
                              Enter the OTP received on the email and click on
                              Verify.
                            </p>
                          </li>
                          <li>
                            <p>Enter the TOTP and kite login password.</p>
                          </li>
                          <li>
                            <p>Click on Enable.</p>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ZerodhaInstructions;
