import axios from 'axios';
import React from 'react';
import config from '../Config/config';
import { toast } from 'react-toastify'; // Assuming you have a toast library installed

// Load the token from localStorage
let getuserBots = localStorage.getItem('token');

export function getTradeExit(data, status, tab) {
  let payload = {
    exchange: data?.exchange,
    request_id: data?.request_id,
    user_id: data?.user_id,
    strategy_id: data?.strategy_id,
    option_type: data?.option_type,
    strike_price: data?.strike_price,
    lot_size: data?.lot_size,
    order_type: data?.order_type,
    tradingsymbol: data?.tradingsymbol,
    market_type: null,
    expiry: '',
    position_type: data?.position_type,
    quantity_left: data?.quantity,
    transaction_type: data?.transaction_type,
    trade_action: data?.trade_action ? data?.trade_action : data?.trade_type,
    trade_type: data?.trade_action ? data?.trade_action : data?.trade_type,
  };
  if (status === 'day-exit') {
    payload.day_status = false;
  }
  if (tab === 'virtual') {
    ExitTradeApi(payload, tab);
  } else {
    payload.credential_id = data?.credential_id;
    ExitTradeApi(payload, tab);
  }
}

export const ExitTradeApi = (payload, tab) => {
  axios
    .post(
      `${config.Kill_trade_base_url}${
        tab === 'live' ? config.exit_LT : config.exit_VT
      }`,
      payload,
      {
        headers: { Authorization: `Bearer ${getuserBots}` },
      },
    )
    .then((res) => {
      if (res) {
        toast.success('Exited Successfully', {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 1000,
        });
      }
    })
    .catch(function (err) {
      console.log(err);
    });
};

// export default getTradeExit;
