import { useCallback, useState } from 'react';
import FileUpload from '../../components/Common/FileUpload';
import TextArea from 'antd/es/input/TextArea';
import {
  btnPrimaryClass,
  btnSecondayClass,
  formClass,
} from '../../components/utils/defaultClasses';
import { ConfigProvider, Form, Input, Select } from 'antd';
import { BsChevronDown } from 'react-icons/bs';
import Drawer from '../../components/Common/Drawer';
import { toast } from 'react-toastify';
import { toastSpecs } from '../../utils/utils';
import { useMutation } from '@tanstack/react-query';
import mydecodedTokenFunction from '../../utils/decodetoken';
import { createTicketeSupport } from '../../components/helpers/api/TicketSupport/ticket';
import { SpinnerBtn } from '../../assets/Icon';
import TicketOnSuccess from './TicketOnSuccess';

export default function CreateTicketDrawer({ onClose, open, refetch }) {
  const [form] = Form.useForm();
  const fileList = Form.useWatch('attachments', form);
  const userid = mydecodedTokenFunction(localStorage.getItem('token'));
  const [showSuccessmodal, setShowSuccessModal] = useState(false);
  const [ticketNumber, setTicketNumber] = useState('');

  const closeModal = () => {
    onClose();
    form.resetFields();
    setTicketNumber('');
  };

  const insertNote = useCallback(() => {
    const { title, category, sub_category, description, attachments } =
      form.getFieldsValue();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('category', category);
    formData.append('sub_category', sub_category);
    formData.append('description', description);
    attachments.map((item) =>
      formData.append('attachments', item.originFileObj),
    );

    formData.append('user_id', userid?.id);
    // console.log('attachments', attachments, formData);
    return createTicketeSupport(formData);
  }, [form, userid]);

  const mutation = useMutation({
    mutationFn: insertNote,
    onSuccess: (res) => {
      console.log(res, 'res');
      refetch && refetch();
      setTicketNumber(res?.data?.data?.ticket_number);
      setShowSuccessModal(true);
      return toast.success('Ticket created successfully.', toastSpecs);
    },
    onError: (err) => {
      //   console.log("err", err);
      return toast.error(err.response.data.message, toastSpecs);
    },
  });

  const onSubmit = useCallback(() => {
    // console.log('asdas', form.getFieldsValue());
    mutation.mutate();
  }, [mutation]);

  return (
    <>
      <Drawer
        title={showSuccessmodal ? 'Successfully Created' : 'Create a Ticket'}
        onClose={closeModal}
        open={open}
        maskClosable={false}
        footer={
          showSuccessmodal ? null : (
            <>
              <div className="flex w-full justify-end">
                <div className="flex justify-end gap-2 items-center">
                  <button
                    onClick={() => {
                      onClose();
                    }}
                    className={`${btnSecondayClass} text-sm`}
                  >
                    Cancel
                  </button>
                  <button
                    className={`${btnPrimaryClass} text-sm`}
                    onClick={() => {
                      form.submit();
                    }}
                    disabled={mutation?.isPending}
                  >
                    {mutation?.isPending ? (
                      <>
                        <SpinnerBtn /> Create
                      </>
                    ) : (
                      'Create'
                    )}
                  </button>
                </div>
              </div>
            </>
          )
        }
      >
        {showSuccessmodal ? (
          <TicketOnSuccess
            props={{
              closeModal,
              Title: `Your ${form.getFieldValue(
                'category',
              )} ticket is been created successfully`,
              TicketID: ticketNumber,
            }}
          />
        ) : (
          <div className="-mt-3">
            <ConfigProvider>
              <Form
                layout="vertical"
                className="space-y-3"
                form={form}
                onFinish={onSubmit}
                disabled={mutation?.isPending}
                initialValues={{ attachments: [] }}
              >
                <div>
                  <div className="flex flex-col gap-1">
                    <Form.Item
                      label={
                        <>
                          <span className="flex items-center gap-0.5 font-normal">
                            Title
                            <span className="text-red-2">*</span>
                          </span>
                        </>
                      }
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: 'Title is required.',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Input
                        placeholder="Please Enter Title"
                        className={`${formClass}`}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col gap-1">
                    <Form.Item
                      label={
                        <>
                          <span className="flex items-center gap-0.5 font-normal">
                            Category
                            <span className="text-red-2">*</span>
                          </span>
                        </>
                      }
                      name="category"
                      rules={[
                        {
                          required: true,
                          message: 'Category is required.',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        defaultValue="Select Category"
                        size="large"
                        suffixIcon={
                          <>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                              <BsChevronDown
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </>
                        }
                        options={[
                          {
                            value: 'Trade Support',
                            label: 'Trade Support',
                          },
                          {
                            value: 'Technical Support',
                            label: 'Technical Support',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div>
                  <div className="flex flex-col gap-1">
                    <Form.Item
                      label={
                        <>
                          <span className="flex items-center gap-0.5 font-normal">
                            Sub Category
                            <span className="text-red-2">*</span>
                          </span>
                        </>
                      }
                      name="sub_category"
                      rules={[
                        {
                          required: true,
                          message: 'Sub Category is required.',
                        },
                      ]}
                      className="mb-0"
                    >
                      <Select
                        defaultValue="Select Sub Category"
                        size="large"
                        suffixIcon={
                          <>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                              <BsChevronDown
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </>
                        }
                        options={[
                          {
                            value: 'Startegy',
                            label: 'Startegy',
                          },
                          {
                            value: 'Backtesting',
                            label: 'Backtesting',
                          },
                          {
                            value: 'Virtual Trade',
                            label: 'Virtual Trade',
                          },
                          {
                            value: 'Broker',
                            label: 'Broker',
                          },
                          {
                            value: 'Live Trades',
                            label: 'Live Trades',
                          },
                          {
                            value: 'Trades Report',
                            label: 'Trades Report',
                          },
                        ]}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-12 col-md-12">
                  <Form.Item
                    label={
                      <>
                        <span className="flex items-center gap-0.5 font-normal">
                          Description
                          <span className="text-red-2">*</span>
                        </span>
                      </>
                    }
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Description is required.',
                      },
                    ]}
                    className="mb-0"
                  >
                    {/* <TextArea
                    rows={4}
                    className={`${formClass}`}
                    name="content"
                    placeholder="Write more detail here"
                  /> */}
                    <TextArea
                      id="exampleFormControlTextarea1"
                      rows={4}
                      placeholder="Write more detail here"
                      className={`${formClass}`}
                    ></TextArea>
                  </Form.Item>
                </div>{' '}
                {/* <div className="col-12 col-md-12">
              <Form.Item
                label={
                  <>
                    <span className="flex items-center gap-0.5 font-medium">
                      Upload Attachments
                      <span className="text-red-2">*</span>
                    </span>
                  </>
                }
                name="content"
                rules={[
                  {
                    required: true,
                    message: 'Please enter your content.',
                  },
                ]}
                className="mb-0"
              >
                <div className="flex flex-col items-start gap-2">
                  <div className="flex items-center gap-2">
                    <div className="flex items-center gap-0.5 bg-blue-2/10 p-0.5 pl-1.5 rounded-md border-[1px] border-blue-3 border-dashed text-xs">
                      <span className="flex">doc.pdf</span>
                      <span
                        className="text-red-3 p-0.5 cursor-pointer"
                        type="button"
                      >
                        <MdOutlineClose />
                      </span>
                    </div>
                    <div className="flex items-center gap-0.5 bg-blue-2/10 p-0.5 pl-1.5 rounded-md border-[1px] border-blue-3 border-dashed text-xs">
                      <span className="flex">doc.pdf</span>
                      <span
                        className="text-red-3 p-0.5 cursor-pointer"
                        type="button"
                      >
                        <MdOutlineClose />
                      </span>
                    </div>
                  </div>
                  <button
                    className={`flex justify-center items-center gap-1 border-[1px] border-level-2 p-1.5 rounded-md px-2.5`}
                  >
                    <HiOutlineUpload /> Upload
                  </button>
                </div>
              </Form.Item>
            </div> */}
                <div>
                  <h3 className="pb-2 text-color-primary">Attachments</h3>
                  <Form.Item
                    name="attachments"
                    className="mb-0"
                    valuePropName="fileList"
                    // getValueFromEvent={normFile}
                  >
                    <FileUpload
                      form={form}
                      fileList={fileList}
                      className="text-color-primary"
                    />
                  </Form.Item>
                </div>
              </Form>
            </ConfigProvider>
          </div>
        )}
      </Drawer>
    </>
  );
}
